import {Component, Input, OnInit} from '@angular/core';
import {LexofficeService} from '../../../services/lexoffice.service';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'lexoffice-tag',
  templateUrl: './lexoffice-tag.component.html',
  styleUrls: ['./lexoffice-tag.component.scss']
})
export class LexofficeTagComponent implements OnInit {

  @Input()
  public type: 'logo' | 'icon' = 'logo';

  @Input()
  public active: boolean = true;

  constructor(
    public authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

}
