<h2>Kontakte aus Lexware Office importieren </h2>
<div class="box-header d-flex align-items-center justify-content-between p-b-10">
  <div><lexoffice-tag></lexoffice-tag></div>
  <div class="d-flex">

    <nz-input-group style="width: 400px"
                    [nzPrefix]="prefiXTemplate">
      <input nz-input [minLength]="3" placeholder="Kontakt suchen (min. 3 Zeichen)" nzSize="small" [(ngModel)]="search"
             (ngModelChange)="triggerSearch()" (keyup.enter)="triggerSearch(true)">
    </nz-input-group>
    <ng-template #prefiXTemplate>
      <i nz-icon nzType="search" nzTheme="outline"></i>
    </ng-template>
  </div>

</div>
<nz-table class="m-v-15" #contactTable
          [nzData]="lexContacts"
          [nzFrontPagination]="false"
          [nzLoading]="loading"
          [nzTotal]="lexResult?.totalElements"
          [(nzPageSize)]="pagingParameters.size"
          [nzPageIndex]="pagingParameters.page+1"
          (nzQueryParams)="getContacts($event)"
          nzSize="small">
  <thead>
  <tr>
    <th>Nr.</th>
    <th>Name</th>
    <th>Adresse</th>
    <th>E-Mail</th>
    <th>Telefon</th>
    <th style="width: 80px"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let dataItem of contactTable.data">
    <td>
      {{ dataItem.roles?.customer?.number }}
      {{ dataItem.roles?.vendor?.number }}
    </td>
    <td>
      <strong>{{ dataItem.company?.name }}</strong>
      <div *ngFor="let cp of dataItem.company?.contactPersons">
        {{ cp.salutation }}
        {{ cp.firstName }}
        {{ cp.lastName }}
      </div>
      {{ dataItem.person?.salutation }}
      {{ dataItem.person?.firstName }}
      {{ dataItem.person?.lastName }}
    </td>
    <td>
      <div *ngFor="let address of dataItem.addresses?.billing">
        {{ address.street }}<br>
        {{ address.zip }} {{address.city}}
      </div>
    </td>
    <td>
      <div *ngFor="let mail of dataItem.emailAddresses?.business">
        {{ mail }}<br>
      </div>
      <div *ngFor="let mail of dataItem.emailAddresses?.office">
        {{ mail }}<br>
      </div>
      <div *ngFor="let mail of dataItem.emailAddresses?.private">
        {{ mail }}<br>
      </div>
      <div *ngFor="let mail of dataItem.emailAddresses?.other">
        {{ mail }}<br>
      </div>
    </td>
    <td>
      <div *ngFor="let phone of dataItem.phoneNumbers?.business">
        {{ phone }} (Geschäftlich)
      </div>
      <div *ngFor="let phone of dataItem.phoneNumbers?.office">
        {{ phone }} (Büro)
      </div>
      <div *ngFor="let phone of dataItem.phoneNumbers?.private">
        {{ phone }} (Privat)
      </div>
      <div *ngFor="let phone of dataItem.phoneNumbers?.other">
        {{ phone }} (Andere)
      </div>
      <div *ngFor="let phone of dataItem.phoneNumbers?.mobile">
        {{ phone }} (Mobil)
      </div>
      <div *ngFor="let phone of dataItem.phoneNumbers?.fax">
        {{ phone }} (Fax)
      </div>
    </td>
    <td>
      <nz-tag class="m-t-5" *ngIf="alreadyImportedIds.includes(dataItem.id)" nzColor="green"><i nz-icon nzType="check-circle" nzTheme="outline"></i> importiert</nz-tag>
      <button *ngIf="!alreadyImportedIds.includes(dataItem.id)" (click)="select(dataItem)" nz-button nzType="link" nzSize="small">wählen
      </button>
    </td>
  </tr>
  </tbody>
</nz-table>
