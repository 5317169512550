import {Injectable} from '@angular/core';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../../environments/environment';
import {ApiService} from '../../services/api.service';
import {NotificationService} from './notification.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {Router} from '@angular/router';
import {FileBrowserComponent} from '../components/file-browser/file-browser.component';
import {WorkLog} from '../../../../../database-models';
import {NzModalService} from 'ng-zorro-antd/modal';
import {
  LexofficeWorklogToInvoiceComponent, ModalData as LOWorklogToInvoiceModalData
} from '../components/lexoffice/lexoffice-worklog-to-invoice/lexoffice-worklog-to-invoice.component';

@Injectable({
  providedIn: 'root'
})
export class LexofficeService {

  public authCodeFlowConfig: AuthConfig = {
    issuer: environment.lexofficePartnerApi.authorizationurl,
    tokenEndpoint: environment.lexofficePartnerApi.authorizationurl,
    loginUrl: environment.lexofficePartnerApi.authorizationurl,
    redirectUri: environment.lexofficePartnerApi.redirectUri, // environment.webURL + '/company',
    clientId: environment.lexofficePartnerApi.clientId,
    responseType: environment.lexofficePartnerApi.responseType,
    oidc: environment.lexofficePartnerApi.oidc,
    scope: environment.lexofficePartnerApi.scope,
    showDebugInformation: environment.lexofficePartnerApi.showDebugInformation,
    customQueryParams: {
      connection_name: environment.lexofficePartnerApi.customQueryParams.connection_name,
      grant_type: environment.lexofficePartnerApi.customQueryParams.grant_type
    }
  };

  constructor(
    private oauthService: OAuthService,
    private api: ApiService,
    private router: Router,
    private modalService: NzModalService,
    private notification: NzNotificationService
  ) {
  }

  public async startOauth(): Promise<void> {

    this.oauthService.configure(this.authCodeFlowConfig);
    this.oauthService.events.subscribe(e => console.log(e));
    this.oauthService.initCodeFlow();

  }

  public async revokeConnection(): Promise<void> {

    this.api.lexofficeRvokeConnection().subscribe(res => {
      this.notification.success('LexOffice wurde erfolgreich getrennt', 'Die Verbindung zwischen LexOffice und der Hausmeisterapp.com wurde erfolgreich getrennt.');
      setTimeout(() => {
        location.reload();
      }, 2000);
    }, error => {
      this.notification.error('Fehler', 'Es war nicht möglich die Verbindung zu trennen. Bitte versuchen Sie es später erneut oder trennen Sie die Verbindung in LexOffice.');
    });

  }

  public worklogToInvoice(worklogs: WorkLog[]): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const modal = this.modalService.create<LexofficeWorklogToInvoiceComponent, LOWorklogToInvoiceModalData>({
        nzContent: LexofficeWorklogToInvoiceComponent,
        nzData: {
          worklogs: worklogs
        },
        nzStyle: {
          top: '10px'
        },
        nzFooter: null,
        nzWidth: '95%',
      });

      modal.afterClose.subscribe((data: any) => {
        if (data) {
          resolve(data);
        } else {
          reject();
        }
      });
    });

  }

}
