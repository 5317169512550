<div class="banner" [ngClass]="{ 'expired' : licenseService.expired }" *ngIf="licenseService.showLicenseBanner" [routerLink]="'/company'">
  <ng-container *ngIf="licenseService.expired">
    <span>Ihre Testphase ist abgelaufen. Ihr Zugriff wurde eingeschränkt. Klicken Sie hier um zu Abonnieren</span><br><br>
    Sollten Sie nicht reagieren, wird Ihr Account und alle Objekte in kürze gesperrt.
  </ng-container>
  <ng-container *ngIf="!licenseService.expired">
    <span>Ihre Testphase endet am {{ licenseService.company?.billing?.testPeriodEnd | date:'dd.MM.y' }}</span><br>
    <small>Es entstehen für Sie keine Kosten, solange Sie nicht aktiv abonnieren.</small>
  </ng-container>
</div>

