import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {File, Object, ObjectNote, Room, User} from "../../../../../../database-models";
import {AuthenticationService} from "../../services/authentication.service";
import {environment} from "../../../../environments/environment";
import {EditFileComponent, ModalData as EditFileModalData} from "../edit-file/edit-file.component";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalService} from "ng-zorro-antd/modal";
import {ApiService} from "../../../services/api.service";
import {NzUploadFile} from "ng-zorro-antd/upload";

@Component({
	selector: 'app-object-note',
	templateUrl: './object-note.component.html',
	styleUrls: ['./object-note.component.scss']
})

export class ObjectNoteComponent implements OnInit {

	@Input() public object: Object;
	@Input() public room: Room;

	@Output() public updateObject: EventEmitter<string> = new EventEmitter<string>();
	@Output() public updateRoom: EventEmitter<string> = new EventEmitter<string>();

	public objectnotes: ObjectNote[];
	public originalObjectnotes: ObjectNote[];
	public selectedNote: ObjectNote;
	public preSelectedNote: ObjectNote;

	public imageUploads: NzUploadFile[] = [];
	public currentUser: User;

	public backendURL = environment.apiURL;

	public commentInput: string;
	public searchInput: string;

	public managerOnly: boolean = false;
	public isTouched: boolean = false;
	public uploading = false;
	public isLoading = false;

	public editorConfig: any = {
		imagedropandpaste: {
			handler: (imagedataurl, type, imagedata) => {
				console.log('Images not allowed');
			}
		},
		toolbar: [
			['bold', 'italic', 'underline', 'strike'],
			[{'header': 1}, {'header': 2}],
			[{'list': 'ordered'}, {'list': 'bullet'}],
			[{'align': []}],
		]
	};

	constructor(
		private autenticationService: AuthenticationService,
		private message: NzMessageService,
		private modalService: NzModalService,
		public api: ApiService,
	) {
	}

	ngOnInit(): void {
		if (this.object) {
			this.getObjectNotes();
		}

		if (this.room) {
			this.getRoomNotes();
		}

		this.autenticationService.currentUser.subscribe(user => {
			this.currentUser = user;
		});
	}

	public reload(): void {
		this.getObjectNotes();
		this.selectedNote = null;
		if (this.objectnotes) {
			this.preSelectedNote = this.objectnotes[0];
		}
	}

	public getRoomNotes(): void {
		this.isLoading = true;
		this.api.getRoomNotes(this.room?.id).subscribe((objectnotes: ObjectNote[]) => {
			this.objectnotes = objectnotes;
			this.originalObjectnotes = objectnotes;
			let currIndex = this.objectnotes.findIndex(note => note.id === this.preSelectedNote?.id);
			if (currIndex >= 0) {
				this.preSelectedNote = this.objectnotes[currIndex];
			} else {
				this.preSelectedNote = this.objectnotes[0];
			}

			this.isLoading = false;
		}, onerror => {
			console.log(onerror);
			this.message.error('Ticketnotes konnten nicht geladen werden');
			this.isLoading = false;
		});
	}

	public getObjectNotes(): void {
		this.isLoading = true;
		this.api.getObjectNotes(this.object?.id).subscribe((objectnotes: ObjectNote[]) => {
			this.objectnotes = objectnotes;
			this.originalObjectnotes = objectnotes;
			let currIndex = this.objectnotes.findIndex(note => note.id === this.preSelectedNote?.id);
			if (currIndex >= 0) {
				this.preSelectedNote = this.objectnotes[currIndex];
			} else {
				this.preSelectedNote = this.objectnotes[0];
			}

			this.isLoading = false;
		}, onerror => {
			console.log(onerror);
			this.message.error('Ticketnotes konnten nicht geladen werden');
			this.isLoading = false;
		});
	}

	public updateObjectNote(): void {
		this.isLoading = true;
		const formData = new FormData();
		// tslint:disable-next-line:no-any
		this.imageUploads.forEach((file: any) => {
			formData.append('files[]', file);
		});


		formData.append('note', JSON.stringify(this.selectedNote));
		if (!this.selectedNote.id) {
			this.preSelectedNote = null;
		}

		this.api.createObjectNote(formData).subscribe((objectnote: ObjectNote) => {
			this.message.success('Notiz erfolgreich gespeichert');
			this.selectedNote = null;
      this.imageUploads = [];
			this.isLoading = false;
			if (this.object) {
				this.getObjectNotes();
			} else if (this.room) {
				this.getRoomNotes();
			}
		}, error => {
			this.message.error('Fehler beim speichern!');
		});
	}

	public preSelecte(item: ObjectNote): void {
		this.preSelectedNote = item
	}

	public selecteNote(item?: ObjectNote): void {
		if (item) {
			this.isTouched = false;
			this.selectedNote = item;
			this.imageUploads = [];
		} else {
			this.selectedNote = {
				titel: '',
				text: '',
				object_id: this.object ? this.object.id : null,
				room_id: this.room ? this.room.id : null,
				managerOnly: this.managerOnly
			} as ObjectNote
		}
	};

	public isCurrentUser(commentOwner: string): boolean {
		return commentOwner === this.currentUser.id;
	}

	public deleteObjectNote(noteId): void {
		this.modalService.confirm({
			nzTitle: '<i>Notiz löschen</i>',
			nzContent: 'Sind Sie sich sicher, dass Sie diese Notiz löschen möchten?',
			nzOnOk: () => {
				this.api.deleteObjectNote(noteId).subscribe((objectnote: ObjectNote) => {
					this.message.success('Erfolgreich gelöscht');
					if (this.object) {
						this.getObjectNotes();
					} else {
						this.getRoomNotes();
					}
					this.preSelectedNote = null;
				});
			}
		});
	}

	public beforeUpload = (file: NzUploadFile): boolean => {
		this.imageUploads = this.imageUploads.concat(file);
		return false;
	};

	public editFile(data: File): void {
		const newObjectModal = this.modalService.create<EditFileComponent, EditFileModalData>({
			nzContent: EditFileComponent,
			nzData: {
				file: data
			},
			nzWidth: '600px',
			nzFooter: null,
			nzMaskClosable: true,
		});

		newObjectModal.afterClose.subscribe(data => {
			this.getObjectNotes();
		});
	}

	public deleteImage(id: string, index: number, note: ObjectNote): void {
		this.modalService.confirm({
			nzTitle: '<i>Datei löschen</i>',
			nzContent: 'Sind Sie sich sicher, dass Sie diese Datei unwiderruflich löschen möchten?',
			nzOnOk: () => {
				this.api.deleteFile(id).subscribe(res => {
					this.message.success('Datei erfolgreich gelöscht');
					note.files.splice(index, 1);
					this.getObjectNotes();
				}, error => {
					this.message.error('Es ist ein Fehler aufgetreten!');
				});
			}
		});
	}

	public toggleManagerOnly() {
		this.selectedNote.managerOnly = !this.selectedNote.managerOnly;
		this.noteIsTouched();
	}

	public noteIsTouched() {
		this.isTouched = true;
	}

	public searchFilter() {
		this.isLoading = true;
		this.preSelectedNote = null;
		if (this.searchInput) {
			let searchString = this.searchInput.toLowerCase();
			this.objectnotes = this.originalObjectnotes.filter((note: ObjectNote) => {
				return note?.titel?.toLowerCase().includes(searchString)
					|| note?.text?.toLowerCase().includes(searchString)
					|| note.user.name?.toLowerCase().includes(searchString)
					|| note.createdAt?.toLowerCase().includes(searchString);
			});
		} else {
			this.objectnotes = this.originalObjectnotes;
		}
		this.isLoading = false;
	}

	public close(): void {
		if (this.isTouched) {
			this.modalService.confirm({
				nzTitle: '<i>Bearbeiten abbrechen</i>',
				nzContent: 'Sind Sie sich sicher, dass Sie diesen Vorgang abbrechen möchten? Alle Änderungen gehen verloren.',
				nzOnOk: () => this.selectedNote = null
			});
		}
		this.getObjectNotes();
	};
}
