<div class="text-center" *ngIf="object.property_management">
  <nz-avatar [nzSize]="150" nzIcon="bank"></nz-avatar>

  <h5 class="m-b-0 m-t-20">
    <i class="color-blue m-r-5" *ngIf="object.property_management.verified"
       nz-icon nzType="safety-certificate" nzTheme="fill" nz-tooltip nzTooltipTitle="Verifiziert"></i> <span
    class="text-dark">{{object.property_management?.name}}</span>
  </h5>
  <div *ngIf="object.property_management.address">{{object.property_management.address}}</div>
  <div
    *ngIf="object.property_management.city">{{object.property_management.zip + ' ' + object.property_management.city}}</div>
  <div *ngIf="object.property_management.phone"><strong>Tel.:</strong> {{object.property_management.phone}}</div>
  <div *ngIf="object.property_management.fax"><strong>Fax:</strong> {{object.property_management.fax}}</div>
  <div *ngIf="object.property_management.email"><strong>Mail:</strong> {{object.property_management.email}}</div>

  <small class="inline-block m-t-20">Die Hausverwaltung hat Zugriff auf die Objekt-Informationen des zugewiesenen
    Objekts, einschließlich aller zugehörigen Tickets. Die Hausverwaltung kann keine Objekte und Tickets bearbeiten oder
    löschen.</small>

  <a *appIsManager class="m-t-10" nz-button nzType="link" (click)="removeConnection()" nzDanger>Hausverwaltung entfernen</a>
</div>
<div *ngIf="!object.property_management">
  <nz-spin [nzSpinning]="loading">
    <h3>Hausverwaltung Verknüpfen</h3>

    <p>Suchen Sie nach einer Hausverwaltung in unserer Datenbank:</p>

    <nz-select
      nzShowSearch
      nzServerSearch
      nzPlaceHolder="Geben Sie einen Suchbegriff ein"
      [(ngModel)]="selectedPropertyManagement"
      [nzShowArrow]="false"
      [nzFilterOption]="nzFilterOption"
      (nzOnSearch)="search($event)"
      class="w-100"
      nzAllowClear
    >
      <nz-option *ngFor="let propertyManagement of propertyManagements" nzCustomContent
                 [nzLabel]="propertyManagement.name" [nzValue]="propertyManagement">
        <i class="color-blue m-r-5" *ngIf="propertyManagement.verified"
           nz-icon nzType="safety-certificate" nzTheme="fill" nz-tooltip
           nzTooltipTitle="Verifiziert"></i>
        <span nz-tooltip
              [nzTooltipTitle]="propertyManagement.name + ' - ' + propertyManagement.address + ', ' +
        propertyManagement.zip+ ' ' +propertyManagement.city">
        <strong>{{propertyManagement.name}}</strong> - <small>{{propertyManagement.address}}
          , {{propertyManagement.zip}} {{propertyManagement.city}}</small>
      </span>
      </nz-option>
    </nz-select>

    <nz-collapse *ngIf="!selectedPropertyManagement" [nzBordered]="false">
      <nz-collapse-panel class="border-0" nzHeader="Die gesuchte Hausverwaltung ist nicht dabei?" [nzActive]="false">
        Sollte die Hausverwaltung noch nicht bei
        Hausmeisterapp.com registriert sein, können Sie der Hausverwaltung eine <strong>kostenlose</strong>
        Registrierung
        vorschlagen.<br><br>
        Link zur Registrierung:<br>
        <strong nz-typography nzCopyable
                nzCopyText="https://app.hausmeisterapp.com/hausverwaltung-application">https://app.hausmeisterapp.com/hausverwaltung-application</strong>
      </nz-collapse-panel>
    </nz-collapse>

    <ng-container *ngIf="selectedPropertyManagement">
      <nz-card class="m-t-20">
        <i class="color-blue m-r-5" *ngIf="selectedPropertyManagement.verified"
           nz-icon nzType="safety-certificate" nzTheme="fill" nz-tooltip nzTooltipTitle="Verifiziert"></i>
        <strong>{{selectedPropertyManagement.name}}</strong>
        <div *ngIf="selectedPropertyManagement.address">{{selectedPropertyManagement.address}}</div>
        <div
          *ngIf="selectedPropertyManagement.city">{{selectedPropertyManagement.zip + ' ' + selectedPropertyManagement.city}}</div>
      </nz-card>

      <small>Die Verknüpfung muss anschließend von der Hausverwaltung bestätigt werden. Sobald dies erfolgt ist, ist der
        Vorgang abgeschlossen.</small>
    </ng-container>
    <button (click)="startRequest()" class="m-t-10" [disabled]="!selectedPropertyManagement" nz-button nzType="primary"
            nzBlock>Verknüpfung starten
    </button>
  </nz-spin>
</div>
