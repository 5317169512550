import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import * as L from 'leaflet';
import {LatLng, LatLngTuple, Marker} from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, AfterViewInit {

  @Input()
  public markersLatLong: LatLngTuple;

  private map;

  public initMap(): void {

    try {
      this.map.remove();
    } catch (e) {
    }

    this.map = L.map('map', {
      center: [51.1020, 10.2832],
      zoom: 4
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
    });

    tiles.addTo(this.map);

    var myIcon = L.divIcon({
      className: 'map-div-icon',
      html: '<div class=\'marker-pin\'></div><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="home" aria-hidden="true"><path d="M946.5 505L534.6 93.4a31.93 31.93 0 00-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z"></path></svg>',
      iconSize: [30, 42],
      iconAnchor: [15, 42]
    });

    if (this.markersLatLong[0] && this.markersLatLong[1]) {
      var marker = L.marker(this.markersLatLong, {icon: myIcon}).addTo(this.map);

      // @ts-ignore
      var group = new L.featureGroup([marker]);

      this.map.fitBounds(group.getBounds());
    }
  }


  constructor() {
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnInit(): void {
  }

}
