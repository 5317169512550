import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from "../../../../../../database-models";
import {EditContactComponent, ModalData as EditContactModalData} from "../edit-contact/edit-contact.component";
import {EditContactV2Component, ModalData as EditContactV2ModalData} from "../edit-contact-v2/edit-contact-v2.component";
import {NzModalService} from "ng-zorro-antd/modal";
import {ApiService} from "../../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {TableData} from "../master-table/master-table.component";
import * as uuid from 'uuid';
import {Router} from "@angular/router";
import {NzMessageService} from "ng-zorro-antd/message";
import ca from '@mobiscroll/angular/dist/js/i18n/ca';
import {getColorByType} from '../../../../../../helper-functions';


@Component({
	selector: 'app-contact-select-list',
	templateUrl: './contact-select-list.component.html',
	styleUrls: ['./contact-select-list.component.scss']
})
export class ContactSelectListComponent implements OnInit {

	@Input() public contacts: Contact[];
	@Input() public objId: string;

	@Input() public mode: 'default' | 'link' | 'unlink' = 'default' ;

	@Output() public setRelation: EventEmitter<Contact> = new EventEmitter<Contact>();

	@Output() public unsetRelation: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public tableData: TableData = {
    items: [],
    data: []
  };

  public contactsOriginal: Contact[] = [];
  public contactsLoading = true;

  public sortOrders = {
    name: null,
    adresse: null,
    typ: null
  };


	constructor(
		private modalService: NzModalService,
		public api: ApiService,
    public authenticationService: AuthenticationService,
    public router: Router,
    private message: NzMessageService,
  ) {}

	ngOnInit(): void {
		this.contacts = []
		this.contacts.forEach(contact => {
			contact._showEmail = false;
			contact._showPhone = false;
			contact._showAdresse = false;
		});
	}

	public toggleShowEmail(contact: Contact): void {
		contact._showEmail = !contact._showEmail;
	}

	public toggleShowPhones(contact: Contact): void {
		contact._showPhone = !contact._showPhone;
	}

	public toggleShowAddresses(contact: Contact): void {
		contact._showAdresse = !contact._showAdresse;
	}

	public getMapLink(address: any): void {
		const query = encodeURIComponent(`${address.address} ${address.city} ${address.zip}`);
		const url = `https://www.google.com/maps/search/?api=1&query=${query}`;

		window.open(url, '_blank');
	}

	public sendEmail(email: string) {
		const mailtoLink = `mailto:${email}`;
		window.location.href = mailtoLink;
	}

	public startPhoneCall(phonenumber: string) {
		const PhonetoLink = `tel:${phonenumber}`;
		window.location.href = PhonetoLink;
	}

	public unset(id: string): void {
		this.unsetRelation.emit(id)
	}

	public set(contact: Contact): void {
		this.setRelation.emit(contact)
	}

  // TableHeadFilter
  public orderFn = {
    name: (a, b) => a.name?.localeCompare(b.name),
    typ: (a, b) => a.typ?.localeCompare(b.typ),
    adresse: (a, b) => a.address?.localeCompare(b.address),
    city: (a, b) => a.city?.localeCompare(b.city),
  };

  public sortChanged(change: any) {
    localStorage.setItem('tableheaderContact', JSON.stringify(this.sortOrders));
  }

  public getContacts(): void {
    let params: any = {};
    if (this.objId) {
      params.objectId = this.objId;
    }
    this.api.getContacts(params).subscribe((contacts: Contact[]) => {
      this.tableData.data = contacts;
      this.contactsOriginal = this.contacts = contacts;
      this.contactsLoading = false;
    }, onerror => {
      console.log(onerror);
    });
  }

  public editContact(contact?: Contact): void {
    if (contact && !contact?.v2) {
      const newModal = this.modalService.create<EditContactComponent, EditContactModalData>({
        nzContent: EditContactComponent,
        nzData: {
          contact,
          contactId: contact?.id
        },
        nzWidth: '1000px',
        nzFooter: null
      });
      newModal.afterClose.subscribe((data: any) => {
        this.getContacts();
      });

    } else {
      const newModal = this.modalService.create<EditContactV2Component, EditContactV2ModalData>({
        nzContent: EditContactV2Component,
        nzData: {
          contact
        },
        nzWidth: '1000px',
        nzFooter: null,
        nzMaskClosable: false
      });
      newModal.afterClose.subscribe((data: any) => {
        this.getContacts();
      });
    }

  }

  public upgradeContact(contact: Contact): void {
    let newContact = {
      id: contact.id,
      name: contact.name,
      type: contact.type,
      contact_addresses: [],
      contact_contactPeople: [],
      contact_emails: [],
      contact_phones: [],
      v2: true
    } as Contact;

    if (contact.address || contact.city || contact.zip) {
      newContact.contact_addresses.push({
        id: uuid.v4(),
        type: 'Adresse',
        address: contact.address,
        zip: contact.zip,
        city: contact.city,
      });
    }

    if (contact.phone) {
      newContact.contact_phones.push({
        id: uuid.v4(),
        type: 'Andere',
        phonenumber: contact.phone
      });
    }

    if (contact.emergency) {
      newContact.contact_phones.push({
        id: uuid.v4(),
        type: 'Notfall',
        phonenumber: contact.emergency
      });
    }

    if (contact.fax) {
      newContact.contact_phones.push({
        id: uuid.v4(),
        type: 'Fax',
        phonenumber: contact.fax
      });
    }

    if (contact.email) {
      newContact.contact_emails.push({
        id: uuid.v4(),
        type: 'Privat',
        email: contact.email
      });
    }

    this.editContact(newContact);

  }

  public deleteContact(id: string): void {
    this.modalService.warning({
      nzTitle: '<i>Kontakt löschen</i>',
      nzContent: 'Sind Sie sicher, dass Sie diesen Kontakt löschen möchten? Alle Daten gehen verloren.',
      nzOnOk: () => this.delete(id),
      nzCancelText: 'Abbrechen',
      nzOnCancel: () => this.router.navigate(['contacts']),
    });
  }

  private delete(id: string): void {
    this.api.deleteContact(id).subscribe((contact: Contact) => {
      this.getContacts();
      this.message.success('Kontakt erfolgreich gelöscht.');
    });
  }


  protected readonly getColorByType = getColorByType;
}
