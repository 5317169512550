import {Component, OnInit} from '@angular/core';
import {TimetrackingService} from "../../../services/timetracking.service";
import {User} from "../../../../../../../database-models";
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
  selector: 'app-time-tracking',
  templateUrl: './time-tracking.component.html',
  styleUrls: ['./time-tracking.component.css']
})
export class TimeTrackingComponent implements OnInit {

  public currentUser: User;

  public isLoading = false;

  public sortOrders = {
    timestamp: 'descend',
  };

  constructor(
      public timeService: TimetrackingService,
      private authService: AuthenticationService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.timeService.load()
    this.authService.currentUser.subscribe(value => {
      this.currentUser = value;
    });

    const storedOrderFn = localStorage.getItem('tableheaderTime');
    if (storedOrderFn) {
      this.sortOrders = JSON.parse(storedOrderFn);
    }
  }

  // TableHeadFilter
  public orderFn = {
    timestamp: (a, b) => a.timestamp?.localeCompare(b.timestamp),
  }

  public sortChanged(change: any) {
    localStorage.setItem('tableheaderTime', JSON.stringify(this.sortOrders));
  }

}
