import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../../services/api.service';
import {Company, ObjectNote} from '../../../../../../../database-models';
import {LexofficeService} from '../../../services/lexoffice.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {AuthenticationService} from '../../../services/authentication.service';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-lexoffice-auth',
  templateUrl: './lexoffice-auth.component.html',
  styleUrls: ['./lexoffice-auth.component.css']
})
export class LexofficeAuthComponent implements OnInit {

  @Input()
  public company: Company;

  @Output()
  public doRefresh: EventEmitter<boolean> = new EventEmitter();


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    public lexofficeService: LexofficeService,
    private notification: NzNotificationService,
    private authService: AuthenticationService,
    private modalService: NzModalService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log(params);

      if (params.error) {
        this.notification.error('Fehler bei der Verbindung zu Lexware Office', 'Die Verbindung zu Lexware Office konnte nicht hergestellt werden! Fehlercode: ' + params.error, {nzDuration: 8000})
        this.router.navigate(['company'], { queryParams: { tab: 'api' }});
      } else {

        const nonce = sessionStorage.getItem('nonce');
        const PKCE_verifier = sessionStorage.getItem('PKCE_verifier');

        const paramsToSend = {
          code: params.code,
          nonce,
          PKCE_verifier
        };

        if (params.code) {
          this.api.lexofficeHandleAuthcode(paramsToSend).subscribe(res => {
            this.notification.success('Lexware Office wurde erfolgreich verbunden', 'Die Verbindung zwischen Lexware Office und der Hausmeisterapp.com wurde erfolgreich hergestellt.');
            this.doRefresh.emit(true);
            this.authService.loadCurrentUser();
            this.router.navigate(['company'], { queryParams: { tab: 'api' }});
          }, error => {
            this.notification.error('Fehler', 'Es war nicht möglich die Verbindung zu speichern. Bitte versuchen Sie es später erneut oder aktualisieren Sie die Seite.');
            this.router.navigate(['company'], { queryParams: { tab: 'api' }});
          });
        }
      }

    });
  }

  public revokeLexoffice(): void {
    this.modalService.confirm({
      nzTitle: 'Lexware Office Trennen',
      nzContent: 'Sind Sie sicher, dass Sie die Verbindung zu Lexware Office trennen möchten?',
      nzOnOk: () => {
       this.lexofficeService.revokeConnection();
      }
    });
  }

}
