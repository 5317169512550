import {Component, inject, Injector, Input, OnInit, Optional} from '@angular/core';
import {Contact, Contact_Object, ContactTypes, Object} from '../../../../../../database-models';
import {MasterTableComponent} from '../master-table/master-table.component';
import {ApiService} from '../../../services/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

export interface ModalData {
	customerId?: string;
  objId?: string;
}

@Component({
  selector: 'app-select-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent extends MasterTableComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  public objId: string;
  public customerId: string;

  public contact: Contact;
  public allContacts: Contact[];
  public objContacts: Contact[];
  public customer_Contacts: Contact[];
  public availableContacts: Contact[];

  public contactsOriginal: Contact[] = [];
  public contacts: Contact_Object[];


  public title = 'Kontakte';
  public nameSearch: string;
  public isLoading = false;

  constructor(
      injector: Injector,
      private api: ApiService,
      private message: NzMessageService,
      public router: Router,
      private modalService: NzModalService,
      public formBuilder: FormBuilder,
      public route: ActivatedRoute,
      private notification: NzNotificationService,
      @Optional() private modal: NzModalRef) {

    super(injector);
    this.contact = {} as Contact;
  }

  ngOnInit() {
    this.objId ??= this.data.objId;
    this.customerId ??= this.data.customerId;
      this.getAllContacts()
  }

  public getAllContacts(): void {
    this.isLoading = true;
    this.api.getContacts().subscribe(allContacts => {
      this.allContacts = allContacts;
      if (this.objId) {
        this.getContactsByObject();
      } else if (this.customerId) {
        this.getContactsByCustomer()
      } else {
        this.isLoading = false;
      }

    }, onError => {
      console.log(onError);
    });
  }

  public getContactsByObject(): void {
    this.isLoading = true;
    this.api.getContacts({objectId: this.objId}).subscribe(objContacts => {
      this.objContacts = objContacts;
      this.availableContacts = this.allContacts?.filter(contact => {
        return !this.objContacts.some(objContact => objContact.id === contact.id);
      });
      this.isLoading = false;
    });
  }

  public getContactsByCustomer(): void {
    this.isLoading = true;
    this.api.getContactsbyCustomer(this.customerId).subscribe(customer_Contacts => {
      this.customer_Contacts = customer_Contacts;

      this.availableContacts = this.allContacts?.filter(contact => {
        return !this.customer_Contacts.some(cusContact => cusContact.id === contact.id);
      });
      this.isLoading = false;
    });
  }

  public filter() {
    if (this.nameSearch) {
      this.availableContacts = this.contactsOriginal.filter(contacts =>
          contacts.name.toLowerCase().indexOf(this.nameSearch.toLowerCase()) !== -1);
    } else {
      this.availableContacts = this.contactsOriginal;
    }
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }


  public setRelation(contact: Contact): void {
      if (this.objId) {
        const sendData = {
          objectId: this.objId,
          contactId: contact.id
        };

        this.api.setObjectsOfContacts(sendData).subscribe((data: any) => {
          this.message.success('Erfolgreich verknüpft');
          if (this.modal) {
            this.modal.destroy({
              data: data
            });
          } else {
            this.router.navigate(['objects/' + this.objId]);
          }
        }, error => {
          this.notification.create(
              'error',
              'Verknüpfen fehlgeschlagen!',
              error.error
          );
        });
      } else if (this.customerId) {
        const sendData = {
          customerId: this.customerId,
          contactId: contact.id
        };

        this.api.setCustomerOfContacts(sendData).subscribe((data: any) => {
          this.message.success('Erfolgreich verknüpft');
          if (this.modal) {
            this.modal.destroy({
              data: data
            });
          } else {
            this.router.navigate(['objects/' + this.objId]);
          }
        }, error => {
          this.notification.create(
              'error',
              'Verknüpfen fehlgeschlagen!',
              error.error
          );
        });
      }
  }
}
