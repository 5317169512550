import {Component, inject, Input, OnInit, Optional, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  Company, Contact,
  Image,
  Object,
  Priority, Room,
  State,
  Ticket,
  TicketNote,
  User
} from '../../../../../database-models';
import {environment} from '../../../environments/environment';
import {ApiService} from '../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';

export interface ModalData {
	object?: Object;
  ticket?: Ticket;
  mailImport?: any;
}

@Component({
  selector: 'app-new-ticket',
  templateUrl: './new-ticket.component.html',
  styleUrls: ['./new-ticket.component.css']
})
export class NewTicketComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA,{optional: true});
  @ViewChild('notificationTemplate', {static: false})
  private notificationTemplate: TemplateRef<any>;

  public loading: boolean = false;
  public ticketForm: FormGroup;

  @Input() public ticket: Ticket;
  @Input() public mailImport: any;
  @Input() public object: Object;

  public ticketLoading = true;
  public objects: Object[];
  public company: Company;
  public priorities: Priority[];

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    private modal: NzModalRef,
    private notificationService: NzNotificationService,
    public authenticationService: AuthenticationService
  ) {
    this.ticket ??= this.data.ticket;
    this.mailImport ??= this.data.mailImport;
    if (!this.ticket) this.ticket = {} as Ticket;


    this.getObjects();
    this.getPriorities();
    this.ticketLoading = false;

  }

  private initForm(): void {
    this.ticketForm = this.formBuilder.group({
      priority_id: [this.ticket.priority_id, [Validators.required]],
      name: [this.ticket.name, [Validators.required]],
      description: [this.ticket.description],
      private: [this.ticket.private],
      worklogneeded: [this.ticket.worklogneeded]
    });
  }

  ngOnInit() {
    this.object ??= this.data.object;
    this.ticket ??= this.data.ticket;
    if (this.object) {
      this.ticket.object = this.object
      this.ticket.object_id = this.object.id
    }
    this.ticket.object_id = this.ticket.object?.id;
    this.ticket.contact_id = this.ticket.contact?.id;
    console.log(this.ticket);
    this.ticket.private = this.authenticationService.currentUserValue.company?.privateTicketsDefault;
    this.initForm();
    this.authenticationService.currentUser.subscribe(user => {
      this.company = user.company;
    });
  }

  public referenceSelected(value: Object | Contact): void {
    switch (value.member) {
      case 'Object':
        this.ticket.object_id = value.id;
        this.ticket.contact_id = null;
        break;
      case 'Contact':
        this.ticket.contact_id = value.id;
        this.ticket.object_id = null;
        break;
    }
  }

  public createTicket(): void {
    this.loading = true;
    const sendData = this.ticketForm.getRawValue();

    this.ticket.name = sendData.name;
    this.ticket.description = sendData.description;
    this.ticket.private = sendData.private;
    this.ticket.worklogneeded = sendData.worklogneeded;
    this.ticket.priority_id = sendData.priority_id;
    this.ticket.room_id = this.ticket.room?.id;

    this.ticket.mailImport = this.mailImport;


    this.api.createTicket(this.ticket).subscribe((ticket: Ticket) => {
      this.message.success('Erfolgreich gespeichert');
      this.ticket = ticket;
      this.notificationService.template(this.notificationTemplate, {
        nzData: this.ticket, nzDuration: 9000,
      });
      this.modal.triggerOk();
      this.loading = false;
    }, error => {
      this.message.error('Ticket konnte nicht erstellt werden!');
      this.loading = false;
    });
  }

  public cancel(): void {
    if (this.ticketForm.touched) {
      this.modalService.confirm({
        nzTitle: '<i>Bearbeiten abbrechen</i>',
        nzContent: 'Sind Sie sich sicher, dass Sie diesen Vorgang abbrechen möchten? Alle Änderungen gehen verloren.',
        nzOnOk: () => this.modal.triggerCancel()
      });
    } else {
      this.modal.triggerCancel()
    }

  }

  public getObjects(): void {
    this.api.getObjects().subscribe((objects: Object[]) => {
      this.objects = objects;
      // this.initForm();
    }, onerror => {
      console.log(onerror);
      this.message.error('Objekte konnten nicht geladen werden');
    });
  }

  public getPriorities(): void {
    this.api.getPriorities().subscribe((priorities: Priority[]) => {
      this.priorities = priorities;

      for (const priority of priorities) {
        if (priority.default) {
          this.ticketForm.get('priority_id').setValue(priority.id);
        }
      }

    }, onerror => {
      this.message.error('Prioritäten konnten nicht geladen werden');
    });
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }
}
