<h2>{{ title }}</h2>
<nz-divider></nz-divider>

<nz-tabset>
  <nz-tab (nzSelect)="getAbsentsByUser();" nzTitle="Historie">
    <ng-container *ngIf="userAbsents?.length <= 0">
      <span><p>Aktuell liegen keine Abwesenheits Einträge vor.</p></span>
    </ng-container>

    <ng-container *ngIf="userAbsents?.length > 0">
      <nz-table nzSize="small" [nzData]="['']">
        <thead>
        <tr>
          <th>Mitarbeiter</th>
          <th>Von / Bis</th>
          <th>Tage</th>
          <th>Notiz</th>
          <th>Erstellt</th>
          <th style="width: 5%;"></th>
          <th style="width: 5%;"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let absent of userAbsents; let i = index">
          <td>{{ absent.user?.name }}
            <nz-tag style="margin-left: 15px"
                    [nzColor]="absent.absentType?.color">{{ absent.absentType?.name }}
            </nz-tag>
          </td>
          <td>
            {{ absent.start | date:'dd.MM.y' }} <br>
            {{ absent.end | date:'dd.MM.y' }}
          </td>
          <td *ngIf="absent.halfDay">0,5 Tag</td>
          <td *ngIf="!absent.halfDay">{{ calculateDays(absent.start, absent.end) + ' Tage' }}</td>
          <td>{{ absent.note ? absent.note : '-' }}</td>
          <td>{{ absent.createdAt | date:'dd.MM.y H:mm' }}</td>
          <td style="padding-left: 15px;">
            <ng-container *ngIf="absent.approved">
              <i style="margin-left: 5px; font-size: x-large" nz-icon nzType="schedule" nzTheme="outline"></i>
            </ng-container>
            <ng-container *ngIf="!absent.approved">
              <button nzSize="small" nz-button nzType="primary" nzShape="circle"
                      (click)="setAbsent(true, absent.id)">
                <i nz-icon nzType="check" nzTheme="outline"></i>
              </button>
            </ng-container>
          </td>
          <td style="padding-left: 15px;">
            <button nzSize="small" nz-button nzType="link" nzDanger nzShape="circle"
                    (click)="deleteAbsent(absent.id, i)">
              <i nz-icon nzType="delete"></i></button>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </ng-container>
    <button class="pull-right m-t-20" nz-button nzSize="small" nzType="link" (click)="absentOverview()">
      Jahresübersicht
    </button>
  </nz-tab>
  <nz-tab nzTitle="Abwesenheit erstellen">
    <form nz-form nzLayout="vertical" [formGroup]="absentForm" *ngIf="absentForm">
      <div class="m-b-10">
        <div nz-row>
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label nzRequired nzFor="type">Grund der Abwesenheit</nz-form-label>
              <nz-form-control>
                <nz-select [disabled]="isLoading" formControlName="typeId" nzSize="small" class="w-100">
                  <nz-option *ngFor="let type of absentTypes" nzCustomContent [nzLabel]="type?.name"
                             [nzValue]="type.id">
                    {{ type?.name }}
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label nzFor="halfDay">Dauer</nz-form-label>
              <nz-form-control>
                <nz-radio-group formControlName="halfDay">
                  <label nz-radio [nzValue]="false">Ganzer Tag</label>
                  <label nz-radio [nzValue]="true">Halber Tag</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label nzFor="daterange">Datum</nz-form-label>
              <nz-form-control>
                <mbsc-datepicker [controls]="['calendar']"
                                 label="Datum"
                                 labelStyle="stacked"
                                 [disabled]="isLoading"
                                 [select]="absentForm.get('halfDay').value ? 'date' : 'range'"
                                 [colors]="color"
                                 [labels]="label"
                                 [invalid]="rangeSelected"
                                 [calendarSize]="1"
                                 [showRangeLabels]="true"
                                 [inRangeInvalid]="true"
                                 [rangeEndInvalid]="false"
                                 theme="ios"
                                 inputStyle="outline"
                                 formControlName="daterange"
                                 calendarType="month"

                >


                </mbsc-datepicker>

              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label nzFor="note">Notiz</nz-form-label>
              <nz-form-control>
                    <textarea formControlName="note" [nzAutosize]="{ minRows: 2, maxRows: 6 }" nz-input
                              placeholder="Notiz schreiben (optional)" nzAutosize></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>


        </div>


      </div>
    </form>

    <nz-collapse *ngIf="false">
      <nz-collapse-panel nzHeader="Dateien hochladen">

        <div style="margin: 5px">
          <span><p>Hier können Sie eine Arbeitsunfähigkeitsbescheinigung hochladen (optional)</p></span>
        </div>

        <nz-upload
          [nzFileList]="AU_Upload"
          [nzFileType]="'image/png,image/jpeg,image/jpg,image/gif,image/bmp'"
          [nzSize]="10000"
        >
          <button nz-button>
            <span nz-icon nzType="upload"></span>
            Hochladen
          </button>
        </nz-upload>
      </nz-collapse-panel>
    </nz-collapse>

    <nz-divider></nz-divider>
    <button class="m-t-10" nzSize="small" nz-button nzType="link" (click)="close()">Abbrechen</button>

    <button class="m-t-10 pull-right" nzSize="small"
            nz-button nzType="primary" (click)="setAbsent()"><i nz-icon
                                                                nzType="plus"></i>Erstellen
    </button>

  </nz-tab>


</nz-tabset>
