import {Component, EventEmitter, inject, Input, OnInit, Optional, Output} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../services/api.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {ObjectNote, Room} from "../../../../../../database-models";
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from "ng-zorro-antd/modal";

export interface ModalData {
	room?: Room;
	objectId?: string;
	title?: string;
}

@Component({
	selector: "app-edit-object-room",
	templateUrl: "./edit-object-room.component.html",
	styleUrls: ["./edit-object-room.component.css"],
})
export class EditObjectRoomComponent implements OnInit {
	public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
	public room: Room;
	public objectId: string;
  public title: string;

	@Output() public unSetRoom: EventEmitter<any> = new EventEmitter<any>();

	public isLoading = false;

	public roomForm: FormGroup;

	constructor(
		public formBuilder: FormBuilder,
		public api: ApiService,
		private message: NzMessageService,
		private modalService: NzModalService,
		@Optional() private modal: NzModalRef
	) {
	}

	ngOnInit(): void {
    this.room ??= this.data.room;
    this.objectId ??= this.data.objectId;
    this.title ??= this.data.title;

		if (!this.room) {
			this.room = {
				object_id: this.objectId
			} as Room
		}

		console.log(this.room);
		this.roomForm = this.formBuilder.group({
			name: [this.room?.name, [Validators.required]],
			description: [this.room?.description],
		});
	}

	public save(): void {
		this.isLoading = true;
		const sendData = this.roomForm.getRawValue();
		console.log(sendData)
		this.room.name = sendData?.name;
		this.room.description = sendData?.description;

		console.log(this.room);
		this.api.setRoomsByObject(this.room).subscribe((room: Room) => {
				this.message.success("Erfolgreich gespeichert");
				this.isLoading = false;
				this.modal.close(room.id ? room : this.room);
			},
			(error) => {
				this.message.error("Failed");
				this.isLoading = false;
				this.modal.close();
			}
		);
	}

	public delete(): void {
		this.isLoading = true;
		this.modalService.confirm({
			nzTitle: '<i>Raum löschen</i>',
			nzContent: 'Sind Sie sich sicher, dass Sie diesen Raum löschen möchten?',
			nzOnOk: () => {
				this.api.deletetRoomsByObject(this.room.id).subscribe((value) => {
						this.message.success("Raum erfolgreich gelöscht");
						this.isLoading = false;
						this.modal.close();
					},
					(error) => {
						this.message.error("Failed");
						this.isLoading = false;
						this.modal.close();
					}
				);
			}
		});
	}
}
