<div class="p-b-10">



  <div class="text-right">
    <button nz-button class="m-r-5" nzType="link" nzDanger *ngIf="checkForActiveFilters()"
            (click)="resetFilter()"> alle Filter zurücksetzen
    </button>

    <button class="m-r-5" nz-button nzType="primary" (click)="editCounter()"><i nz-icon
                                                                                nzType="plus-circle"></i>Neu
    </button>

    <nz-input-group nz-tooltip style="width: 250px"
                    nzTooltipTitle="Sie können hier nach einer Zählernummer suchen"
                    [nzPrefix]="prefiXTemplate" [nzSuffix]="inputClearTpl">
      <input [(ngModel)]="filter.search" (keyup)="triggerSearch()" (keyup.enter)="triggerSearch(true)" nz-input
             placeholder="Zählernummer suchen"
             nzSize="small">
    </nz-input-group>
    <ng-template #inputClearTpl>
      <span
        nz-icon
        class="ant-input-clear-icon"
        nzTheme="fill"
        nzType="close-circle"
        *ngIf="filter.search"
        (click)="filter.search = null;getCounters()"
      ></span>
    </ng-template>
    <ng-template #prefiXTemplate>
      <i nz-icon nzType="search" nzTheme="outline"></i>
    </ng-template>
    <nz-badge [nzCount]="checkForActiveFilters()">
      <button class="m-l-5" nz-button nzType="text" (click)="toogleFilter()"><i nz-icon
                                                                                [nzTheme]="filter.show ? 'outline' : 'fill'"
                                                                                nzType="filter"></i>
      </button>
    </nz-badge>
  </div>
</div>

<div class="m-b-10" *ngIf="filter.show">
  <nz-row [nzGutter]="[8, 8]">

  <app-object-customer-select nz-col nzSpan="8" [multiple]="true" [(reference)]="filter.objects" [entities]="['Object']"
                              (referenceChange)="getCounters(null, true)"
                              [hideLabel]="true" [allowClear]="true"></app-object-customer-select>
  </nz-row>
</div>

<nz-card>
  <nz-table class="m-t-10" #counterTable [nzFrontPagination]="false" (nzQueryParams)="getCounters($event)" [nzLoading]="loading" [nzData]="counters"
            (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" [nzTotal]="tableState.total"
            [(nzPageSize)]="tableState.pageSize"
            [(nzPageIndex)]="tableState.pageIndex"
            [nzShowSizeChanger]="true"
            [nzPageSizeOptions]="[5, 50, 100]">
    <thead>
    <tr>
      <th [nzChecked]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)">
      </th>
      <th nzColumnKey="counterNumber" [nzSortFn]="true">Zählernummer
      </th>
      <th nzColumnKey="counterType.name" [nzSortFn]="true">Typ
      </th>
      <th>
        Zählerstand
      </th>
      <th nzColumnKey="counterStatuses.readingDate" [nzSortFn]="true">
        Letzte Ablesung
      </th>
      <th nzColumnKey="nextReadingDate" [nzSortFn]="true">
        Nächste Ablesung
      </th>
      <th nzColumnKey="note" [nzSortFn]="true">Notiz
      </th>
      <th nzWidth="120px"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let counter of counterTable.data; index as i">
      <td
        [nzChecked]="setOfCheckedId.has(counter.id)"
        (nzCheckedChange)="onItemChecked(counter.id, $event)"
        (dblclick)="$event.stopPropagation()"
      ></td>


      <td>
        <strong>{{ counter?.counterNumber }}</strong>
        <p class="m-t-10"><i nz-icon nzType="home" nzTheme="outline"></i> {{counter?.object?.name}}</p>
      </td>
      <td>
        {{ counter?.counterType?.name }}
      </td>
      <td>


        <ng-container *ngIf="counter.counterStatuses.length <= 0">
          <p>noch keine Zählerstände</p>
        </ng-container>
        <div class="m-b-10" *ngIf="counter.counterStatuses.length > 0">

            <strong>{{ counter?.counterStatuses[0]?.count }}</strong> {{ '(' + counter?.counterType?.unit + ')' }}

        </div>
        <a nz-tooltip
           (click)="editCounterStatus(counter); $event.stopPropagation()">
          <i nz-icon nzType="plus"
             nzTheme="outline"></i> Zählerstand erfassen
        </a>


      </td>
      <td>
        <div *ngIf="counter?.counterStatuses[0]?.readingDate" class="m-b-10">
          {{ counter?.counterStatuses[0]?.readingDate | date:'dd.MM.y' }} <small>({{ moment(counter?.counterStatuses[0]?.readingDate).fromNow() }})</small>
        </div>
        <a nz-tooltip
           (click)="showCounterStatusHistorieList(counter); $event.stopPropagation()">
          alle anzeigen
        </a>
      </td>
      <td>
        <div *ngIf="counter?.nextReadingDate" class="m-b-10">
          {{ counter?.nextReadingDate | date:'dd.MM.y' }} <small>({{ moment(counter?.nextReadingDate).fromNow() }})</small>
        </div>

      </td>
      <td>{{ counter?.note }}</td>
      <td>
        <button *appIsManager nzSize="small" class="m-r-10" nz-button nzType="default" nzShape="circle"
                (click)="editCounter(counter); $event.stopPropagation()"><i nz-icon nzType="edit"></i>
        </button>

        <button nz-dropdown [nzDropdownMenu]="counterMenu" nzSize="small" nz-button nzType="default"
                nzShape="circle"
        ><i nz-icon nzType="down" nzTheme="outline"></i>
        </button>
        <nz-dropdown-menu #counterMenu="nzDropdownMenu">
          <ul nz-menu>
            <li *ngIf="counter.counterStatuses?.length > 0" (click)="exportReport(counter, null, false)"
                nz-menu-item>
              <a><i nz-icon nzType="file-pdf" nzTheme="outline"></i> Alle Zählerstände (PDF)</a>
            </li>
            <li *ngIf="counter.counterStatuses?.length > 0" (click)="exportReport(counter, 1, false)"
                nz-menu-item>
              <a><i nz-icon nzType="file-pdf" nzTheme="outline"></i> Letzter Zählerstand (PDF)</a>
            </li>
            <li *ngIf="counter.counterStatuses?.length > 0" nz-menu-divider></li>
            <li *ngIf="counter.counterStatuses?.length > 0" (click)="exportReport(counter, null, true)"
                nz-menu-item>
              <a><i nz-icon nzType="file-pdf" nzTheme="outline"></i> Alle Zählerstände mit Bildern
                (PDF)</a>
            </li>
            <li *ngIf="counter.counterStatuses?.length > 0" (click)="exportReport(counter, 1, true)"
                nz-menu-item>
              <a><i nz-icon nzType="file-pdf" nzTheme="outline"></i> Letzter Zählerstand mit Bild
                (PDF)</a>
            </li>
            <li *ngIf="counter.counterStatuses?.length > 0" nz-menu-divider></li>
            <li (click)="deleteCounter(counter.id, i)" nz-menu-item>
              <a><i nz-icon nzType="delete"></i> Löschen</a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </td>
    </tr>
    </tbody>
  </nz-table>

</nz-card>


<button class="m-t-20 m-l-10" [disabled]="setOfCheckedId.size <= 0" nzSize="small" nzType="default" nz-button
        nz-dropdown [nzDropdownMenu]="menu"
        nzPlacement="bottomCenter"><i nz-icon nzType="file-pdf" nzTheme="outline"></i> Exportieren
</button>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="exportOverviewReport(false)"><i class="p-r-5" nz-icon nzType="file-pdf"
                                                              nzTheme="outline"></i>
      ( {{ setOfCheckedId.size }} ) Zähler Exportieren
    </li>
    <li nz-menu-item (click)="exportOverviewReport(true)"><i class="p-r-5" nz-icon nzType="file-pdf"
                                                             nzTheme="outline"></i>
      ( {{ setOfCheckedId.size }} ) Zähler Exportieren mit Bilder
    </li>
  </ul>
</nz-dropdown-menu>

