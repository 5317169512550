import {Component, OnInit, Optional} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {Object} from '../../../../../../../database-models';
import * as _ from 'lodash';
import {ExportService} from '../../../services/export.service';
import {Papa} from 'ngx-papaparse';

@Component({
  selector: 'app-create-objects',
  templateUrl: './create-objects.component.html',
  styleUrls: ['./create-objects.component.css']
})
export class CreateObjectsComponent implements OnInit {

  public csvExample = 'name,address_line1,zip,city,objectNumber\nMusterobjekt 1,Musterstr. 123,12345,Musterhausen,1120';

  public form: FormGroup;

  public importInProgress = false;

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    private message: NzMessageService,
    private modalService: NzModalService,
    public exportService: ExportService,
    private papa: Papa,
    @Optional() private modal: NzModalRef
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      objects: this.formBuilder.array([])
    });
    this.addObject();
  }

  get objects() {
    return this.form.controls['objects'] as FormArray;
  }

  public addObject(): void {
    const object = this.formBuilder.group({
      name: ['', [Validators.required]],
      address_line1: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      city: ['', [Validators.required]],
      objectNumber: ['']
    });
    this.objects.push(object);
  }

  public deleteObject(index: number) {
    this.objects.removeAt(index);
  }

  public cloneObject(index: number) {
    const newGroup: FormGroup = _.cloneDeep(this.objects.at(index));
    newGroup.patchValue(this.objects.at(index));
    this.objects.push(newGroup);
  }

  public save(): void {
    let objects = this.form.getRawValue().objects;
    console.log(objects);
    this.api.createObject(objects).subscribe((object: Object) => {
      this.message.success('Erfolgreich gespeichert');
      this.modal.close();
    });
  }


  public cancel(): void {
    if (this.form.touched) {
      this.modalService.confirm({
        nzTitle: '<i>Bearbeiten abbrechen</i>',
        nzContent: 'Sind Sie sicher, dass Sie abbrechen möchten? Alle Änderungen gehen verloren.',
        nzOnOk: () => {
          this.modal.close();
        }
      });
    } else {
      this.modal.close();
    }
  }


  public downloadCSV(): void {
    this.exportService.saveAsFile(this.csvExample, 'Hausmeisterapp-Objekte-Import.csv', 'text/csv');
  }

  public handleFileSelect(evt) {
    if (evt.type === 'start') {
      this.importInProgress = true;
      var files = evt.fileList; // FileList object
      var file = files[0]?.originFileObj;
      var reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (event: any) => {
        var csv = event.target.result; // Content of CSV file
        let result = this.papa.parse(csv, {
          header: true,
          skipEmptyLines: true,
          complete: (results, file) => {
            console.log('Parsed: ', results, file);

            if (results.data.length > 300) {
              results.data.length = 300;
              alert('Limit von 300 Objekten für CSV-Import überschritten. Es werden nur die ersten 300 Einträge importiert. Bitte erstellen Sie einen zweiten Import für alle weiteren Objekte.');
            }

            this.objects.clear();
            let objs = results.data;
            for (let obj of objs) {
              const object = this.formBuilder.group({
                name: [obj.name, [Validators.required]],
                address_line1: [obj.address_line1, [Validators.required]],
                zip: [obj.zip, [Validators.required]],
                city: [obj.city, [Validators.required]],
                objectNumber: [obj.objectNumber]
              });
              this.objects.push(object);
            }
            this.importInProgress = false;
          }
        });
      };
    }

  }
}
