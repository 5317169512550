import {Component, Input, OnInit} from '@angular/core';
import {WorkLog} from "../../../../../../database-models";
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-gps-list',
  templateUrl: './gps-list.component.html',
  styleUrls: ['./gps-list.component.css']
})
export class GpsListComponent implements OnInit {

  public mapsUrl = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyAByxN6OqklAULsFBuDxu4r1rXwOAv0BKA&q='

  @Input() public worklog: WorkLog;

  constructor() { }

  ngOnInit(): void {
  }


  protected readonly environment = environment;
}
