<nz-spin [nzSpinning]="isLoading">
  <nz-card>

    <div>
      <nz-table nzTemplateMode class="permission-table" #permissionTable
                [nzBordered]="true" [nzScroll]="{ y: '60vh' }">
        <thead>
        <tr>
          <th nzLeft class="xAchse" nzWidth="250px" style="background-color: #cccccc;"></th>
          <th class="xAchse" nzWidth="125px" style="background-color: #eeeeee;"
              *ngFor="let user of users" [ngClass]="{ 'locked' : !user.isActive }">

            <i nz-tooltip
               *ngIf="!user.isActive"
               nzTooltipTitle="Gesperrt"
               class="m-b-5"
               nz-icon
               nzType="lock"
               nzTheme="outline"></i>
            <strong>{{user.name}}</strong>


            <ng-container *ngIf="user.isAdmin || user.isManager"><i nz-tooltip
                                                                    nzTooltipTitle="{{ user.isAdmin ? 'Admin' : 'Manager'}}"
                                                                    class="m-t-5"
                                                                    style="color: #FF8F00;"
                                                                    nz-icon
                                                                    nzType="info-circle"
                                                                    nzTheme="outline"></i></ng-container>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td nzLeft>Aktion für alle Objekte</td>
          <td class="text-center" [ngClass]="{ 'locked' : !user.isActive }" *ngFor="let user of users">
            <ng-container *ngIf="!user.isAdmin">
              <small>
              <a (click)="toggleAll(user, true)">Alle aktivieren</a><br>
              <a class="color-red" (click)="toggleAll(user, false)">Alle deaktivieren</a></small>
            </ng-container>
          </td>
        </tr>
        <tr *ngFor="let obj of objects">
          <td nzLeft nz-tooltip
              nzTooltipTitle="{{obj.address_line1 + ' ' + obj.zip + ' ' + obj.city}}"
              style="background-color: #eeeeee">
            <strong>{{obj.name}}</strong>
          </td>
          <td class="text-center" [ngClass]="{ 'locked' : !user.isActive }" *ngFor="let user of users">
            <ng-container *ngIf="!user.isAdmin">
              <nz-switch [(ngModel)]="user._objects[obj.id]"
                         nzSize="small">
              </nz-switch>
            </ng-container>
            <ng-container *ngIf="user.isAdmin">
              <i class="color-orange" nz-icon nzType="check" nzTheme="outline"></i>
            </ng-container>

            <ng-template #checkedTemplate><span style="font-size: 10px" nz-icon nzType="check"></span></ng-template>
            <ng-template #unCheckedTemplate><span nz-icon nzType="close"></span></ng-template>
          </td>
        </tr>
        </tbody>
      </nz-table>
    </div>
    <div class="m-t-20">
      <button nz-button nzType="primary" type="submit" (click)="save()">
        Berechtigungen Speichern
      </button>
    </div>
  </nz-card>
</nz-spin>
