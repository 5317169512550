
<nz-card *ngIf="recurringRule">
<div nz-row nzGutter="16">
  <div nz-col nzSpan="12">
    <nz-radio-group [(ngModel)]="recurringRule.repeat" (ngModelChange)="buildRule()">
      <label nz-radio-button nzValue="daily">Täglich</label>
      <label nz-radio-button nzValue="weekly">Wöchentlich</label>
      <label nz-radio-button nzValue="monthly">Monatlich</label>
      <label nz-radio-button nzValue="yearly">Jährlich</label>
    </nz-radio-group>



    <div class="custom-repeat-settings m-t-20">
      Wiederhole alle
      <input nz-input type="number" min="1" [(ngModel)]="recurringRule.interval" (ngModelChange)="buildRule()"
             class="m-h-5"
             style="width: 60px; border-radius: 8px"/>
      <span *ngIf="recurringRule.repeat === 'daily'">Tage</span>
      <span *ngIf="recurringRule.repeat === 'weekly'">Wochen an folgenden Wochentagen:</span>


      <span *ngIf="recurringRule.repeat === 'monthly'">
          Monate am

      <br><br>


        <nz-radio-group [(ngModel)]="monthlyCondition" (ngModelChange)="buildRule()">
          <div nz-radio nzValue="day">
            <nz-select style="width: 100px" [(ngModel)]="recurringRule.day" (ngModelChange)="buildRule()">
              <nz-option *ngFor="let day of monthlyDays" [nzValue]="day"
                         [nzLabel]="day"></nz-option>
            </nz-select>
            Tag des Monats
          </div>

          <div class="horizontal-divider"></div>

          <div  nz-radio nzValue="weekday">
            <nz-select style="width: 150px" nzPlaceHolder="Ersten" [(ngModel)]="recurringRule.pos"
                       (ngModelChange)="buildRule()">
              <nz-option *ngFor="let pos of bysepos" [nzValue]="pos.value"
                         [nzLabel]="pos.text"></nz-option>
            </nz-select>
             <nz-select class="m-l-10" style="width: 150px" nzPlaceHolder="Montag" [(ngModel)]="recurringRule.weekDays"
                        (ngModelChange)="buildRule()">
              <nz-option *ngFor="let weekday of weekDays" [nzValue]="weekday.value"
                         [nzLabel]="weekday.longname"></nz-option>
            </nz-select>
            des Monats
          </div>

      </nz-radio-group>




    </span>




      <span *ngIf="recurringRule.repeat === 'yearly'" class="m-t-20">
          Jahre am



       <br><br>


        <nz-radio-group [(ngModel)]="yearlyCondition" (ngModelChange)="buildRule()">
          <div nz-radio nzValue="monthday">
            <nz-select style="width: 100px" [(ngModel)]="recurringRule.day" (ngModelChange)="buildRule()">
              <nz-option *ngFor="let day of monthlyDays" [nzValue]="day"
                         [nzLabel]="day"></nz-option>
            </nz-select>

              <nz-select class="m-l-10" style="width: 150px" nzPlaceHolder="Januar" [(ngModel)]="recurringRule.month"
                         (ngModelChange)="buildRule()">
              <nz-option *ngFor="let month of months" [nzValue]="month.value"
                         [nzLabel]="month.text"></nz-option>
            </nz-select>
          </div>

          <div class="horizontal-divider"></div>

          <div nz-radio nzValue="weekmonthday">
            <nz-select style="width: 100px" nzPlaceHolder="Ersten" [(ngModel)]="recurringRule.pos"
                       (ngModelChange)="buildRule()">
              <nz-option *ngFor="let pos of bysepos" [nzValue]="pos.value"
                         [nzLabel]="pos.text"></nz-option>
            </nz-select>
             <nz-select class="m-l-10" style="width: 100px" nzPlaceHolder="Montag" [(ngModel)]="recurringRule.weekDays"
                        (ngModelChange)="buildRule()">
              <nz-option *ngFor="let weekday of weekDays" [nzValue]="weekday.value"
                         [nzLabel]="weekday.longname"></nz-option>
            </nz-select>
            im
              <nz-select class="m-l-10" style="width: 100px" nzPlaceHolder="Januar" [(ngModel)]="recurringRule.month"
                         (ngModelChange)="buildRule()">
              <nz-option *ngFor="let month of months" [nzValue]="month.value"
                         [nzLabel]="month.text"></nz-option>
            </nz-select>
          </div>

      </nz-radio-group>

    </span>



      <div *ngIf="recurringRule.repeat === 'weekly'" class="m-t-20">
        <label nz-checkbox *ngFor="let day of weekDays" [(ngModel)]="day.checked"
               (ngModelChange)="buildRule()">{{day.name}}</label>
      </div>
  </div>

  </div>
  <div nz-col nzSpan="12" class="p-l-40" style="border-left: 1px solid #999999">


    <h6><i nz-icon nzType="sync" nzTheme="outline"></i> Wiederholungen</h6>

    <div class="m-t-20">

      <nz-radio-group [(ngModel)]="condition" (ngModelChange)="buildRule()">
        <label class="m-0" nz-radio nzValue="never">enden niemals</label> <br>
        <div class="horizontal-divider"></div>
        <br>
        <label class="m-0" nz-radio nzValue="count">werden <input [disabled]="condition != 'count'" nz-input
                                                                  type="number" min="1" class="m-h-5"
                                                                  style="width: 60px; height: 30px; border-radius: 8px"
                                                                  [(ngModel)]="recurringRule.count"
                                                                  (ngModelChange)="buildRule()"/> mal wiederholt</label>
        <br>
        <div class="horizontal-divider"></div>
        <br>
        <label class="m-b-15" nz-radio nzValue="until">enden am </label>
      </nz-radio-group>

      <ng-container *ngIf="condition === 'until'">
        <mbsc-datepicker

          (onChange)="buildRule()"
          inputStyle="outline"
          [(ngModel)]="recurringRule.until"
          [controls]="['date']"
          [label]="'Datum'"
          labelStyle="stacked"
          dataTimezone="utc"
          displayTimezone="local"
          [timezonePlugin]="momentPlugin"
          theme="ios"
          [stepMinute]="1">
        </mbsc-datepicker>
      </ng-container>

    </div>

  </div>
</div>



</nz-card>
