<nz-spin [nzSpinning]="importInProgress">
  <h2>Objekte hinzufügen</h2>
  <form nz-form [formGroup]="form">
    <ng-container formArrayName="objects">
      <ng-container nz-row *ngFor="let object of objects.controls; let i = index">
        <div nz-row [formGroupName]="i">
          <nz-form-item nz-col nzSpan="6" class="p-r-5">
            <nz-form-label nzFor="name" nzRequired>Objektname</nz-form-label>
            <nz-form-control nzErrorTip="Bitte geben Sie einen Namen für das Objekt ein">
              <input formControlName="name" nz-input placeholder="Geben Sie dem Objekt einen Namen">
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-col nzSpan="4" class="p-r-5">
            <nz-form-label nzFor="objectNumber">Objektnummer</nz-form-label>
            <nz-form-control>
              <input formControlName="objectNumber" nz-input placeholder="Objektnummer (optional)">
            </nz-form-control>
          </nz-form-item>

          <nz-form-item nz-col nzSpan="6" class="p-r-5">
            <nz-form-label nzFor="address_line1" nzRequired>
              Adresse
            </nz-form-label>
            <nz-form-control nzErrorTip="Bitte geben Sie eine Adresse für das Objekt ein">
              <input formControlName="address_line1" nz-input placeholder="Adresse">
            </nz-form-control>
          </nz-form-item>

          <nz-form-item nz-col nzSpan="2" class="p-r-5">
            <nz-form-label nzFor="zip" nzRequired>PLZ</nz-form-label>
            <nz-form-control nzErrorTip="Postleitzahl eingeben">
              <input formControlName="zip" nz-input placeholder="PLZ">
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-col nzSpan="4">
            <nz-form-label nzFor="city" nzRequired>Ort</nz-form-label>
            <nz-form-control nzErrorTip="Ort eingeben">
              <input formControlName="city" nz-input placeholder="Ort">
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-col nzSpan="2">
            <div class="m-t-40">
              <a (click)="cloneObject(i)" nz-button nzType="link"><i nz-icon nzType="copy"
                                                                     nzTheme="outline"></i></a>
              <a (click)="deleteObject(i)" *ngIf="objects.length > 1" nz-button nzDanger nzType="link"><i
                nz-icon nzType="delete" nzTheme="outline"></i></a>
            </div>

          </nz-form-item>
        </div>
      </ng-container>
    </ng-container>
    <button (click)="addObject()" class="m-b-20" nz-button nzBlock nzType="dashed"><i nz-icon nzType="plus"
                                                                                      nzTheme="outline"></i> weiteres
      Objekt hinzufügen
    </button>


    <div nz-row>
      <div nz-col nzSpan="12">
        <button class="m-r-10" nz-button nzType="link" (click)="downloadCSV()"><i nz-icon nzType="download"
                                                                                  nzTheme="outline"></i> CSV-Vorlage
        </button>
        <nz-upload
          [nzAccept]="'.csv'"
          (nzChange)="handleFileSelect($event)"
          [nzShowUploadList]="false"
        >
          <button nz-button>
            <span nz-icon nzType="upload"></span>
            CSV-Import
          </button>
        </nz-upload>
      </div>
      <div nz-col nzSpan="12" class="text-right">
        <button class="m-r-10" nz-button nzType="default" (click)="cancel()" type="button">Abbrechen
        </button>
        <button nz-button nzType="primary" type="submit" (click)="save()"
                [disabled]="form.invalid">
          Speichern
        </button>
      </div>
    </div>

  </form>
</nz-spin>
