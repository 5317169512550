import {Component, Input, OnInit} from '@angular/core';
import {Ticket, WorkLog} from '../../../../../../../database-models';
import {environment} from '../../../../../environments/environment';
import {EditWorklogComponent, ModalData as EditWorklogModalData} from '../../edit-worklog/edit-worklog.component';
import moment from 'moment';
import {NzModalService} from 'ng-zorro-antd/modal';
import {ApiService} from '../../../../services/api.service';
import {saveAs} from "file-saver";

@Component({
  selector: 'app-ticket-overview',
  templateUrl: './ticket-overview.component.html',
  styleUrls: ['./ticket-overview.component.css']
})
export class TicketOverviewComponent implements OnInit {

  public backendURL = environment.apiURL;

  private date: Date = new Date();

  @Input()
  public activeTicket: Ticket;

  @Input()
  public openNewTab: boolean = false;

  /**
   * Wird gesetzt wenn es nicht als Popover eingebunden wird, sondern direkt als Template
   */
  @Input()
  public embeddedMode: boolean = false;

  constructor(
    private modalService: NzModalService,
    private api: ApiService,
  ) { }

  ngOnInit(): void {
  }

  public isOverdue(ticket: Ticket | any): boolean {
    if (!ticket.doUntil || ticket.done || ticket.state?.isClosed) {
      return false;
    } else {
      const convDate = new Date(ticket.doUntil);
      return convDate < this.date;
    }

  }

  public editWorklog(worklog: WorkLog): void {
    const newObjectModal = this.modalService.create<EditWorklogComponent, EditWorklogModalData>({
      nzContent: EditWorklogComponent,
      nzData: {
        worklogId: worklog.id
      },
      nzWidth: '1500px',
      nzFooter: null,
      nzMaskClosable: true,
    });

    newObjectModal.afterClose.subscribe((data: any) => {
    });
  }

  public downloadPDF(worklog: WorkLog, ticket: Ticket): void {
    this.api.downloadWorklogPDF({id: worklog.id}).subscribe((res) => {
      let name = 'AP_' + moment(worklog.start).format('DD-MM-YYYY') + (ticket.ticketNumber ? '_' + ticket.ticketNumber : '') + '.pdf'
      saveAs(res, name);
      saveAs(res, worklog.id + ".pdf");
    });
  }


}
