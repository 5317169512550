import { Component, Input, Output, EventEmitter } from '@angular/core';
import { File } from '../../../../../../database-models';
import { environment } from 'src/environments/environment';

const imageTypes = ['image/jpeg', 'image/png', 'image/gif'];

function fileIsImage(type: string): boolean {
  return imageTypes.includes(type);
}

function onlyImages(value: File[]): File[] {
  return value?.filter(i => fileIsImage(i.type)) || [];
}

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html'
})
export class LightboxComponent {
  @Input({transform: onlyImages})
  images: File[] = [];

  @Input()
  nzGutter = 8;

  @Input()
  nzSpan = 12;

  /**
   * sets max-height for thumbnail previews
   */
  @Input()
  maxHeight = 160;

  /**
   * if true, disables "edit", "delete", .. buttons
   */
  @Input()
  disableControls = false;

  /**
   * if true, shows the 'createdAt' date below thumbnails
   */
  @Input()
  showDateAsCaption = false;

  @Output() close = new EventEmitter<void>();
  @Output() delete = new EventEmitter<{file: File, index: number}>();
  @Output() edit = new EventEmitter<{file: File, index: number}>();

  protected selectedImageIndex: number | null = null;
  protected backendURL = environment.apiURL + '/';

  openImage(imageIdx: number): void {
    this.selectedImageIndex = imageIdx;
  }

  closeImage(): void {
    this.selectedImageIndex = null;
    this.close.emit();
  }

  editImage(event: {file: File, index: number}): void {
    this.edit.emit({file: event.file, index: event.index});
    this.selectedImageIndex = null;
  }

  deleteImage(event: {file: File, index: number}): void {
    this.delete.emit({file: event.file, index: event.index});
    this.selectedImageIndex = null;
  }
}