import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {Wasteplan} from "../../../../../../../database-models";
import {NzModalService} from "ng-zorro-antd/modal";
import {WasteplanImportComponent, ModalData as WasteplanImportModalData} from "../wasteplan-import/wasteplan-import.component";
import {EditWasteplanComponent, ModalData as EditWasteplanModalData} from "../edit-wasteplan/edit-wasteplan.component";
import {NzMessageService} from "ng-zorro-antd/message";

@Component({
  selector: 'app-wasteplan-list',
  templateUrl: './wasteplan-list.component.html',
  styleUrls: ['./wasteplan-list.component.css']
})
export class WasteplanListComponent implements OnInit {

  @Input() public objId: string;

  public wastePlans: Wasteplan[];

  public isLoading = false;

  constructor(
      public api: ApiService,
      private modalService: NzModalService,
      private message: NzMessageService,
  ) {
  }

  ngOnInit(): void {
    this.getWasteplanByObject();
  }

  public getWasteplanByObject(): void {
    this.isLoading = true;
    this.api.getWasteplanByObject(this.objId).subscribe(value => {
      this.wastePlans = value;
      this.isLoading = false;
    })
  }

  public import(): void {
    const newModal = this.modalService.create<WasteplanImportComponent, WasteplanImportModalData>({
      nzContent: WasteplanImportComponent,
      nzMaskClosable: false,
      nzData: {
        objectId: this.objId
      },
      nzFooter: null,
      nzWidth: 1300,
    });

    newModal.afterClose.subscribe((data?: any) => {
      this.getWasteplanByObject();
    });
  }

  public editWasteplan(item?: Wasteplan): void {
    const editModal = this.modalService.create<EditWasteplanComponent, EditWasteplanModalData>({
      nzContent: EditWasteplanComponent,
      nzMaskClosable: true,
      nzData: {
        wasteplan: item,
        objId: this.objId,
      },
      nzFooter: null,
      nzWidth: 700,
    });

    editModal.afterClose.subscribe((data?: any) => {
      this.getWasteplanByObject();
    });
  }

  public deleteWasteplan(id: string, index: number): void {
    this.modalService.confirm({
      nzTitle: 'Abfuhrtermin löschen',
      nzContent: 'Sind Sie sich sicher, dass Sie diesen Abfuhrtermin unwiderruflich löschen möchten?',
      nzOnOk: () => {
        this.isLoading = true;
        this.api.deleteWasteplan(id).subscribe(res => {
          this.wastePlans.splice(index, 1);
          this.message.success('Abfuhrtermin wurde gelöscht');
          this.isLoading = false;
          this.getWasteplanByObject();
        }, error => {
          this.message.error('Abfuhrtermin konnte nicht gelöscht werden');
        });
      }
    });
  }


  public deleteWasteplansByObjectId(): void {
    this.modalService.confirm({
      nzTitle: 'Alle Abfuhrtermine für dieses Objekt löschen',
      nzContent: 'Sind Sie sich sicher, dass Sie alle Abfuhrtermine für dieses Objekt unwiderruflich löschen möchten?',
      nzOnOk: () => {
        this.isLoading = true;
        this.api.deleteWasteplansByObjectId(this.objId).subscribe(res => {
          this.message.success('Abfuhrtermine wurden gelöscht');
          this.isLoading = false;
          this.getWasteplanByObject();
        }, error => {
          this.message.error('Abfuhrtermine konnten nicht gelöscht werden');
        });
      }
    });
  }
}
