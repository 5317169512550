<h2>Unterschrift hinzufügen</h2>

    <div nz-row>
      <nz-form-item nz-col nzSpan="24">
        <nz-form-label nzFor="name" nzRequired>Name</nz-form-label>
        <nz-form-control nzErrorTip="Bitte geben Sie einen Namen ein">
          <input [(ngModel)]="signature.name" nz-input placeholder="Name">
        </nz-form-control>
      </nz-form-item>
    </div>

  <div nz-row class="signature-pad">
    <canvas #canvas
            class="signature-pad-canvas"
            width="350"
            height="200"
            style="touch-action: none;">
    </canvas>
  </div>

  <div nz-row class="signature-button">
    <button [disabled]="signaturePad.isEmpty() || !signature.name"
            (click)="save()"
            nz-button
            nzType="primary"
            type="submit"
            class="m-t-20 m-r-5 height-max w-40"
    >Speichern</button>
    <button [disabled]="signaturePad.isEmpty()"
            (click)="signaturePad.clear()"
            nz-button
            nzDanger
            nzType="delete"
            class="m-t-20 height-max w-40"
    >Löschen</button>
  </div>

