import {Component, Input, OnInit} from '@angular/core';
import {Ticket} from '../../../../../../../database-models';
import {ApiService} from '../../../../services/api.service';
import {Router} from "@angular/router";
import {environment} from '../../../../../environments/environment';


@Component({
  selector: 'ticket-tag',
  templateUrl: './ticket-tag.component.html',
  styleUrls: ['./ticket-tag.component.scss']
})
export class TicketTagComponent implements OnInit {
  public backendURL = environment.apiURL;

  get ticketId(): string {
    return this._ticketId;
  }

  @Input()
  set ticketId(value: string) {
    this._ticketId = value;
    this.getTicket();
  }


  private _ticketId: string;



  public ticket: Ticket;

  @Input()
  public openNewTab: boolean = false;


  constructor(
    private api: ApiService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public getTicket() {
    this.api.getTicket(this._ticketId).subscribe(ticket => {
      this.ticket = ticket;
    })
  }

  public openTicket() {
    if (this.openNewTab) {
      window.open('/tickets/' + this.ticket.id, '_blank');
    } else {
      this.router.navigate(['/tickets/' + this.ticket.id])
    }
  }

}
