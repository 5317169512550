import {Component, inject, Input, OnInit, Optional} from '@angular/core';
import {File, Image, ObjectNote, TicketNote} from '../../../../../../../database-models';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import {environment} from '../../../../../environments/environment';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {EditFileComponent, ModalData as EditFileModalData} from "../../edit-file/edit-file.component";

export interface ModalData {
	note: ObjectNote;
}

@Component({
  selector: 'app-edit-note',
  templateUrl: './edit-note.component.html',
  styleUrls: ['./edit-note.component.scss']
})
export class EditNoteComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});

  public note: ObjectNote;

  public title = 'Notiz bearbeiten';

  public imageUploads: NzUploadFile[] = [];
  public uploading = false;
  public backendURL = environment.apiURL;

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    private message: NzMessageService,
    private modalService: NzModalService,
    @Optional() private modal: NzModalRef
  ) { }

  ngOnInit(): void {
    this.note ??= this.data.note;
  }

  public beforeUpload = (file: NzUploadFile): boolean => {
    this.imageUploads = this.imageUploads.concat(file);
    return false;
  }


  public updateObjectNote(): void {

    const formData = new FormData();
    // tslint:disable-next-line:no-any
    this.imageUploads.forEach((file: any) => {
      formData.append('files[]', file);
    });

    formData.append('note', JSON.stringify(this.note));
    console.log('Note: ', formData);
    this.api.createObjectNote(formData).subscribe((objectnote: ObjectNote) => {
      this.modal.close(objectnote);
      this.message.success('Erfolgreich gesendet');
    });
  }

  public deleteImage(id: string, index: number, note: ObjectNote): void {
    this.modalService.confirm({
      nzTitle: '<i>Bild löschen</i>',
      nzContent: 'Sind Sie sicher, dass Sie das Bild unwiderruflich löschen möchten?',
      nzOnOk: () => {
        this.api.deleteFile(id).subscribe(res => {
          this.message.success('Bild erfolgreich gelöscht');
          note.files.splice(index, 1);
        }, error => {
          this.message.error('Es ist ein Fehler aufgetreten!');
        });
      }
    });
  }

  public editFile(data: File): void {
    const newObjectModal = this.modalService.create<EditFileComponent, EditFileModalData>({
      nzContent: EditFileComponent,
      nzData: {
        file: data
      },
      nzWidth: '600px',
      nzFooter: null,
      nzMaskClosable: true,
    });

    newObjectModal.afterClose.subscribe(data => {
    });
  }


}
