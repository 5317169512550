<div class="box-header d-flex align-items-center justify-content-between m-b-10">
  <div>
    <nz-range-picker [nzRenderExtraFooter]="rangePickerFooter" [nzAllowClear]="false" (nzOnOk)="getConsumablesStats()"
                     nzFormat="dd.MM.yyyy" [(ngModel)]="filter.dateRange"
                     (ngModelChange)="getConsumablesStats()"></nz-range-picker>

    <ng-template #rangePickerFooter>
      <button nz-button nzType="link" nzSize="small" (click)="setRange('today')">Heute</button>
      <button nz-button nzType="link" nzSize="small" (click)="setRange('tw')">diese Woche</button>
      <button nz-button nzType="link" nzSize="small" (click)="setRange('lw')">letzte Woche</button>
      <button nz-button nzType="link" nzSize="small" (click)="setRange('tm')">dieser Monat</button>
      <button nz-button nzType="link" nzSize="small" (click)="setRange('lm')">letzter Monat</button>
      <button nz-button nzType="link" nzSize="small" (click)="setRange('tj')">dieses Jahr</button>
      <button nz-button nzType="link" nzSize="small" (click)="setRange('lj')">letztes Jahr</button>
    </ng-template>

    <nz-select class="m-l-10" style="min-width: 300px" nzShowSearch [nzAllowClear]="true"
               nzPlaceHolder="Nach Objekt filtern"
               [compareWith]="compareById" [(ngModel)]="filter.object" (ngModelChange)="consumed = null">
      <nz-option nzCustomContent *ngFor="let object of objectsForFilter" [nzLabel]="object.name" [nzValue]="object">
        <strong>{{object.name}}</strong> - <small>{{object.address_line1}}, {{object.zip}} {{object.city}}</small>
      </nz-option>
    </nz-select>

    <button class="m-l-5" [disabled]="!filter.object" (click)="getConsumablesStats()" nz-button nzType="primary">Auswertung laden</button>
  </div>
  <div></div>

</div>
<nz-card>


  <nz-spin [nzSpinning]="consumablesLoading">


        <nz-table #consumablesTable [nzData]="consumed">
          <thead>
          <tr>
            <th>Name</th>
            <th style="width: 120px;">Anzahl</th>
            <th>Datum</th>
            <th>Links</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of consumablesTable.data">
            <td>
              {{data.consumable.name}}
            </td>
            <td>{{data.number}}</td>
            <td>
              {{data.createdAt | date:'dd.MM.y H:mm'}}
            </td>
            <td>
              <a class="m-r-20" target="_blank" *ngIf="data.worklogId"
                 [href]="'worklogs/' + data.worklogId"><i nz-icon nzType="file-done"
                                                                              nzTheme="outline"></i>
                Arbeitsprotokoll</a>
              <a target="_blank" *ngIf="data.ticketId"
                 [href]="'tickets/' + data.ticketId"><i nz-icon nzType="inbox"
                                                                            nzTheme="outline"></i> Ticket</a>
            </td>
          </tr>
          </tbody>
        </nz-table>

  </nz-spin>

</nz-card>

<button [disabled]="consumed?.length <= 0" nz-button nz-dropdown
        [nzDropdownMenu]="exportOptions"><i nz-icon
                                            nzType="cloud-download"
                                            nzTheme="outline"></i>
  Exportieren<i nz-icon nzType="down"></i></button>
<nz-dropdown-menu #exportOptions="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item>
      <a (click)="export('excel')">
        <i nz-icon nzType="file-excel" nzTheme="outline"></i> Excel (XLSX)
      </a>
    </li>
  </ul>
</nz-dropdown-menu>
