import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
	formatDate,
	MbscCalendarEvent,
	MbscDatepickerOptions,
	MbscEventcalendarOptions,
	MbscPopup,
	MbscPopupOptions,
	setOptions
	, localeDe, MbscRecurrenceRule, momentTimezone
} from '@mobiscroll/angular';
import moment from "moment";

setOptions({
	locale: localeDe,
	theme: 'material',
	themeVariant: 'light'
});


@Component({
	selector: 'app-recurring-editor',
	templateUrl: './recurring-editor.component.html',
	styleUrls: ['./recurring-editor.component.scss']
})
export class RecurringEditorComponent implements OnInit {

	private days = [
		{
			name: 'Mo',
			value: 'MO',
      longname: 'Montag',
			checked: false
		}, {
			name: 'Di',
      longname: 'Dienstag',
			value: 'TU'
		}, {
			name: 'Mi',
      longname: 'Mittwoch',
			value: 'WE'
		}, {
			name: 'Do',
      longname: 'Donnerstag',
			value: 'TH'
		}, {
			name: 'Fr',
      longname: 'Freitag',
			value: 'FR'
		}, {
			name: 'Sa',
      longname: 'Samstag',
			value: 'SA'
		},
		{
			name: 'So',
      longname: 'Sonntag',
			value: 'SU',
		}
	];

	weekDays = [...this.days];
	showCustomRepeat = false;

	monthlyDays = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
	months = [{
		value: 1,
		text: 'Januar'
	}, {
		value: 2,
		text: 'Februar'
	}, {
		value: 3,
		text: 'März'
	}, {
		value: 4,
		text: 'April'
	}, {
		value: 5,
		text: 'Mai'
	}, {
		value: 6,
		text: 'Juni'
	}, {
		value: 7,
		text: 'Juli'
	}, {
		value: 8,
		text: 'August'
	}, {
		value: 9,
		text: 'September'
	}, {
		value: 10,
		text: 'Oktober'
	}, {
		value: 11,
		text: 'November'
	}, {
		value: 12,
		text: 'Dezember'
	}];

	condition: 'never' | 'until' | 'count' = 'never';
	monthlyCondition: 'day' | 'weekday' = 'day';
  yearlyCondition: 'monthday' | 'weekmonthday' = 'monthday';

  bysepos = [
    {
      value: 1,
      text: 'Ersten'
    },
    {
      value: 2,
      text: 'Zweiten'
    },
    {
      value: 3,
      text: 'Dritten'
    },
    {
      value: 4,
      text: 'Vierten'
    },
    {
      value: -1,
      text: 'Letzten'
    }
  ]

	public momentPlugin = momentTimezone;


	private _recurringRule: MbscRecurrenceRule = {} as MbscRecurrenceRule;

	get recurringRule(): MbscRecurrenceRule {
		return this._recurringRule;
	}

	@Input()
	set recurringRule(value: MbscRecurrenceRule) {
		this._recurringRule = value;
		this.initRule();
	}

	@Output()
	public recurringRuleChange: EventEmitter<MbscRecurrenceRule> = new EventEmitter<MbscRecurrenceRule>();

	constructor() {
	}

	ngOnInit(): void {
		this.initRule();
	}

	public buildRule(): void {

    let tmpRule: MbscRecurrenceRule;

		switch (this.recurringRule.repeat) {
      case 'daily':
        tmpRule = {
          repeat: 'daily',
          interval: this.recurringRule.interval
        }
        break;
			case 'weekly':
        tmpRule = {
          repeat: 'weekly',
          interval: this.recurringRule.interval,
          weekDays: this.weekDays.filter(i => i.checked).map(i => i.value).join(',')
        }
       break;
			case 'monthly':
        tmpRule = {
          repeat: 'monthly',
          interval: this.recurringRule.interval,
          day: this.recurringRule.day || 1,

        }

        if (this.monthlyCondition === 'weekday') {
          tmpRule.pos = this.recurringRule.pos || this.bysepos[0].value;
          tmpRule.weekDays = this.recurringRule.weekDays || 'MO';
        } else {
          tmpRule.day = this.recurringRule.day || 1;
        }

				break;
			case 'yearly':
        tmpRule = {
          repeat: 'yearly',
          interval: this.recurringRule.interval,

          month: this.recurringRule.month || 1
        }

        if (this.yearlyCondition === 'weekmonthday') {
          tmpRule.pos = this.recurringRule.pos || this.bysepos[0].value;
          tmpRule.weekDays = this.recurringRule.weekDays || 'MO';
        } else {
          tmpRule.day = this.recurringRule.day || 1;
        }
				break;
		}

		switch (this.condition) {
			case 'until':
        tmpRule.until = moment(this.recurringRule.until).endOf('day')
				break;
			case 'count':
        tmpRule.count = this.recurringRule.count;
				break;
		}


		console.log(tmpRule);
		this.recurringRule = tmpRule;
		this.recurringRuleChange.emit(this.recurringRule);

	}

	public initRule(): void {
		if (this.recurringRule) {


			switch (this.recurringRule.repeat) {
				case 'daily':
					break;
				case 'weekly':
					const dayStrs = this.recurringRule.weekDays.split(',');
					dayStrs.map(value => {
						const index = this.weekDays.findIndex((obj => obj.value === value));
            if (index > -1) {
              this.weekDays[index].checked = true;
            }
					});
					break;
				case 'monthly':
          if (this.recurringRule.weekDays) {
            this.monthlyCondition = 'weekday';
          }
					break;
				case 'yearly':
          if (this.recurringRule.weekDays) {
            this.yearlyCondition = 'weekmonthday';
          }
					break;
        default:
          this.recurringRule = {
            repeat: 'daily',
            interval: 1
          }
          this.buildRule();
          break;
			}
			if (this.recurringRule.until) {
				this.condition = 'until';
			}
			if (this.recurringRule.count) {
				this.condition = 'count';
			}
		} else {
      this.recurringRule = {
        repeat: 'daily',
        interval: 1
      }
      this.buildRule();
		}


	}

  protected readonly JSON = JSON;
}
