import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Tag, Ticket, WorkLog, Task, Object} from '../../../../../../database-models';
import {ApiService} from 'src/app/services/api.service';
import {MessageService} from '../../services/message.service';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-entity-tag-selector',
  templateUrl: './entity-tag-selector.component.html',
  styleUrl: './entity-tag-selector.component.scss'
})
export class EntityTagSelectorComponent implements OnInit {

  public typeTranslation = {
    worklog: 'Arbeitsprotokolle',
    ticket: 'Tickets',
    task: 'Aufgaben',
    object: 'Objekte'
  }


  // @ts-ignore
  get entity(): WorkLog | Ticket | Task | Object {
    return this._entity;
  }


  @Input({required: true})
  set entity(value: WorkLog | Ticket | Task | Object) {
    this._entity = value;
    if (this.entityType) {
      this.api.getEntityTags(this.entityType, this._entity.id).subscribe(tags => this.entityTags = tags);
    }
  }



  private _entity: WorkLog | Ticket | Task | Object;

  @Input({required: true})
  entityType: 'task' | 'ticket' | 'worklog' | 'object';



  @Input()
  width: string = '250px';


  @Input()
  nzMaxTagCount: number = 3;

  @Input()
  readOnly: boolean = false;

  @Output()
  entityTagsChange: EventEmitter<Tag[]> = new EventEmitter<Tag[]>();

  entityTags: Tag[] = [];

  editMode = false;

  /**
   * tags available for this entity (company-wide)
   */
  companyTags: Tag[] = [];

  constructor(
    private api: ApiService,
    private message: MessageService,
    public authenticationService: AuthenticationService,
  ) {
  }

  public toggleEditMode(state: boolean): void {
    if (this.authenticationService.currentUserValue.isManager && !this.readOnly) {
      this.editMode = state;
    }
  }

  ngOnInit(): void {
    this.api.getCompanyTags().subscribe(tags => {
      this.companyTags = tags.filter(tag => tag.entity.includes(this.entityType))
      this.api.getEntityTags(this.entityType, this._entity.id).subscribe(tags => {
        this.entityTags = tags

        for (let tag of this.entityTags) {
          if (!this.companyTags.find(tg => tg.id === tag.id)) {
            this.companyTags.push(tag);
          }
        }
      });

    });


  }

  compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  save() {
    this._entity.tags = this.entityTags;
    this.api.assignTagsToEntity(this._entity.id, this.entityType, this.entityTags?.map(t => t.id))
      .subscribe(() => {
        this.message.success('Tags wurden aktualisiert');
        this.editMode = false;
      });
  }
}
