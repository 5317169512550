<h5>Betroffene Person am Objekt</h5>
<nz-select [compareWith]="compareById" [nzMode]="mode"
           class="w-100 m-b-10"
           [disabled]="isLoading"
           (ngModelChange)="emitUpdate($event)"
           nzShowSearch nzAllowClear [(ngModel)]="appusers"
           [nzPlaceHolder]="placeholder">
    <nz-option *ngFor="let appuser of appusersAvailable" nzCustomContent [nzLabel]="appuser?.user?.name"
               [nzValue]="appuser">
        {{appuser.user?.name}} <nz-tag [nzColor]="getTagColor(appuser?.appuser_type)">{{appuser?.appuser_type | titlecase}}</nz-tag>
        <div>
          <span *ngIf="appuser.blocked">Person ist gesperrt</span>
            <span class="display-block"
                  *ngIf="appuser?.house_number">Wohnung / Haus: <strong>{{appuser?.house_number}}</strong></span>
        </div>
    </nz-option>
</nz-select>

