import {Component, Injector, Input, OnChanges, OnInit, Pipe, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-master-table',
  templateUrl: './master-table.component.html',
  styleUrls: ['./master-table.component.css']
})
export class MasterTableComponent implements OnInit {

  @Input()
  public tableData: TableData = {
    items: [],
    data: []
  };


  constructor(private injector: Injector) {

  }

  public ngOnInit(): void {
  }


  public sort(sort: { key: string, value: string }): void {
    this.search(sort.key, sort.value);
  }

  public search(sortName: string, sortValue: string): void {
    /** sort data **/
    if (sortName && sortValue) {
      this.tableData.data = this.tableData.data.sort((a, b) => (sortValue === 'ascend') ?
        (a[sortName] > b[sortName] ? 1 : -1) : (b[sortName] > a[sortName] ? 1 : -1));
      console.log(this.tableData.data);
      this.tableData.data = JSON.parse(JSON.stringify(this.tableData.data));
    }
  }

  public getDescendantProp(obj, desc): any {
    const arr = desc.key.split('.');
    while (arr.length && obj) {
      obj = obj[arr.shift()];
    }
    if (desc.pipe) {
      const pipe = this.injector.get(desc.pipe.pipe);
      return pipe.transform(obj, desc.pipe.pipeArguments);
    }

    return obj;
  }

}

export interface TableData {
  items: TableDataItem[];
  data: any[];
}

export interface TableDataItem {
  key: string | string[];
  title: string;
  type?: 'string' | 'boolean';
  pipe?: {
    pipe: Pipe;
    pipeArguments: string
  };
}

