import {Component, inject, Input, OnInit} from '@angular/core';
import {CheckList, CheckListTemplate} from '../../../../../../../database-models';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

export interface ModalData {
	checkList: CheckList;
}

@Component({
  selector: 'app-checklist-details',
  templateUrl: './checklist-details.component.html',
  styleUrls: ['./checklist-details.component.scss']
})
export class ChecklistDetailsComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  @Input()
  public checkList: CheckList;

  constructor() { }

  ngOnInit(): void {
    this.checkList ??= this.data.checkList;
  }

}
