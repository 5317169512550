import {Component, EventEmitter, inject, Input, OnInit, Optional, Output} from '@angular/core';
import {
  Address,
  AddressTypes,
  Contact, ContactPerson, ContactType, ContactTypes,
  Email,
  EmailTypes,
  Phone,
  PhoneTypes,
  Salutation, Types
} from '../../../../../../database-models';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {AuthenticationService} from '../../services/authentication.service';
import {LexofficeContactListComponent, ModalData as LOContactListModalData} from '../lexoffice/lexoffice-contact-list/lexoffice-contact-list.component';
import {environment} from '../../../../environments/environment';

export interface ModalData {
	contact: Contact
}

@Component({
  selector: 'app-edit-contact-v2',
  templateUrl: './edit-contact-v2.component.html',
  styleUrls: ['./edit-contact-v2.component.scss']
})
export class EditContactV2Component implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  public lxuiUrl = environment.lexofficePartnerApi.uiUrl;

  public contact: Contact;

  @Output()
  public savedContact: EventEmitter<Contact> = new EventEmitter<Contact>();

  public disabled: boolean = false;

  public types: string[] = [];

  public salutations: Salutation[] = ['Herr', 'Frau', 'Firma'];
  public AddressTypes: AddressTypes[] = ['Adresse', 'Rechnungsadresse', 'Andere'];
  public PhoneTypes: PhoneTypes[] = ['Privat Mobil', 'Arbeit Mobil', 'Privat Festnetz', 'Arbeit Festnetz', 'Fax', 'Notfall', 'Andere'];
  public EmailTypes: EmailTypes[] = ['Privat', 'Arbeit', 'Rechnungsmailadresse', 'Andere'];
  public ContactTypes: ContactType[] = ['Kunde', 'Lieferant', 'Hausverwaltung', 'Kontakt', 'Dienstleister', 'Partner', 'Andere'];
  public title: string = 'Kontakt bearbeiten';

  public hideAdd: boolean = true;

  public index = 0;

  public contactForm: FormGroup;


  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    public authenticationService: AuthenticationService,
    @Optional() private modal: NzModalRef
  ) {
  }

  ngOnInit(): void {
    this.contact ??= this.data.contact;
    if (!this.contact) {
      this.title = 'Neuen Kontakt erstellen';
      this.contact = {
        type: 'Kunde',
        salutation: null,
        contact_emails: [],
        contact_phones: [],
        contact_addresses: [],
        contact_contactPeople: [],
        v2: true
      } as Contact;
      this.hideAdd = true;
    }
    this.contact.v2 = true;
    if (this.contact.id) {
      this.hideAdd = false;
    }
    this.contactForm = this.formBuilder.group({
      name: [this.contact.name, [Validators.required]],
      type: [this.contact.type, [Validators.required]],
      salutation: [this.contact.salutation],
      lexofficeId: [this.contact.lexofficeId],
      contact_emails: this.formBuilder.array([]),
      contact_phones: this.formBuilder.array([]),
      contact_addresses: this.formBuilder.array([]),
    });

    if (this.contact.contact_emails.length > 0) {
      for (let email of this.contact.contact_emails) {
        let controls: any = {
          type: [email.type, Validators.required],
          email: [email.email, Validators.required]
        };
        if (email.id) {
          controls.id = email.id;
        }
        let group = this.formBuilder.group(controls);
        // @ts-ignore
        this.contactForm.controls['contact_emails'].push(group);
      }
    }

    if (this.contact?.contact_phones?.length > 0) {
      for (let phone of this.contact?.contact_phones) {
        let controls: any = {
          type: [phone.type, Validators.required],
          phonenumber: [phone.phonenumber, Validators.required]
        };
        if (phone.id) {
          controls.id = phone.id;
        }
        let group = this.formBuilder.group(controls);
        // @ts-ignore
        this.contactForm.controls['contact_phones'].push(group);
      }
    }

    if (this.contact?.contact_addresses?.length > 0) {
      for (let address of this.contact?.contact_addresses) {
        let controls: any = {
          type: [address.type, Validators.required],
          address: [address.address, Validators.required],
          zip: [address.zip, Validators.required],
          city: [address.city, Validators.required],
        };
        if (address.id) {
          controls.id = address.id;
        }
        let group = this.formBuilder.group(controls);
        // @ts-ignore
        this.contactForm.controls['contact_addresses'].push(group);
      }
    }
    if (this.contact.lexofficeId) {
      this.disabled = true;
      this.hideAdd = true;
      this.contactForm.disable();
      this.contactForm.get('type').enable();
      this.contactAddress.controls.forEach(ctrl => ctrl.disable());
      this.contactPhones.controls.forEach(ctrl => ctrl.disable());
      this.contactEmails.controls.forEach(ctrl => ctrl.disable());
    } else {
      this.disabled = false;
    }
    this.getContactTypes();
  }

  public updateContact(keepOpen: boolean = false): void {
    const sendData: Contact = this.contactForm.getRawValue();
    this.contact.name = sendData.name;
    this.contact.type = sendData.type;
    this.contact.salutation = sendData.salutation;

    this.contact.contact_addresses = sendData.contact_addresses;
    this.contact.contact_emails = sendData.contact_emails;
    this.contact.contact_phones = sendData.contact_phones;

    if (this.contact.id) {
      this.api.updateContact(this.contact).subscribe((contact: Contact) => {
        this.message.success('Erfolgreich gespeichert');
        this.contactForm.markAsUntouched();
        if (!keepOpen) {
          this.modal.close();
        }
        this.hideAdd = false;
      }, error => {
        this.notification.create(
          'error',
          'Speichern fehlgeschlagen!',
          error.error
        );
        this.hideAdd = false;
      });
    } else {
      this.api.setContact(this.contact).subscribe((contact: Contact) => {
        this.message.success('Erfolgreich gespeichert');
        this.contactForm.markAsUntouched();
        this.hideAdd = false;
        this.savedContact.emit(contact);
        if (!keepOpen) {
          this.modal.close();
        }
      }, error => {
        this.notification.create(
          'error',
          'Speichern fehlgeschlagen!',
          error.error
        );
        this.hideAdd = true;
      });
    }

  }

  get contactEmails(): FormArray {
    return this.contactForm.controls['contact_emails'] as FormArray;
  }

  get contactAddress(): FormArray {
    return this.contactForm.controls['contact_addresses'] as FormArray;
  }

  get contactPhones(): FormArray {
    return this.contactForm.controls['contact_phones'] as FormArray;
  }

  public addEmail(): void {
    let newMail = this.formBuilder.group({
      type: ['Arbeit', Validators.required],
      email: ['', Validators.required]
    });
    this.contactEmails.push(newMail);
  }

  public addPhone(): void {
    let newPhone = this.formBuilder.group({
      type: ['Arbeit Mobil', Validators.required],
      phonenumber: ['', Validators.required]
    });
    this.contactPhones.push(newPhone);
  }

  public addAddress(): void {
    let newAddress = this.formBuilder.group({
      type: ['Adresse', Validators.required],
      address: ['', Validators.required],
      zip: ['', Validators.required],
      city: ['', Validators.required],
    });
    this.contactAddress.push(newAddress);
  }

  public delete(type: 'email' | 'phone' | 'address', index: number): void {
    if (type === 'email') {
      this.modalService.warning({
        nzTitle: '<i>E-Mail löschen</i>',
        nzContent:
          'Sind Sie sicher, dass Sie die E-Mail löschen möchten?',
        nzOnOk: () => this.deleteEmail(index),
        nzAutofocus: 'ok',
        nzCancelText: 'Abbrechen',
      });
    } else if (type === 'phone') {
      this.modalService.warning({
        nzTitle: '<i>Telefonummer löschen</i>',
        nzContent:
          'Sind Sie sicher, dass Sie die Telefonummer löschen möchten?',
        nzOnOk: () => this.deletePhone(index),
        nzAutofocus: 'ok',
        nzCancelText: 'Abbrechen',
      });
    } else if (type === 'address') {
      this.modalService.warning({
        nzTitle: '<i>Adresse löschen</i>',
        nzContent:
          'Sind Sie sicher, dass Sie die Adresse löschen möchten?',
        nzOnOk: () => this.deleteAddress(index),
        nzAutofocus: 'ok',
        nzCancelText: 'Abbrechen',
      });
    }
  }

  // Delete Email by ContactPerson
  public deleteEmail(index: number) {
    const emailFormGroup = this.contactEmails.at(index) as FormGroup;
    const emailId = emailFormGroup.value.id;
    if (!emailId) {
      this.contactEmails.removeAt(index);
    } else {
      this.api.deleteContactEmail(emailId).subscribe(value => {
        this.message.success('E-Mail wurde gelöscht');
        this.contactEmails.removeAt(index);
      });
    }
  }

  // Delete Phone by Contact
  deletePhone(index: number) {
    const phoneFormGroup = this.contactPhones.at(index) as FormGroup;
    const phoneId = phoneFormGroup.value.id;
    if (!phoneId) {
      this.contactPhones.removeAt(index);
    } else {
      this.api.deleteContactPhone(phoneId).subscribe(value => {
        this.message.success('Telefonnummer wurde gelöscht');
        this.contactPhones.removeAt(index);
      });
    }
  }

  public deleteAddress(index: number) {
    const addressFormGroup = this.contactAddress.at(index) as FormGroup;
    const addressId = addressFormGroup.value.id;
    if (!addressId) {
      this.contactAddress.removeAt(index);
    } else {
      this.api.deleteContactAddress(addressId).subscribe(value => {
        this.message.success('Adresse wurde gelöscht');
        this.contactAddress.removeAt(index);
      });
    }
  }

  public newTab(): void {
    this.contact.contact_contactPeople.push({} as ContactPerson);
    this.index = this.contact.contact_contactPeople.length + 1;
  }

  public cancel(): void {
    if (this.contactForm.touched) {
      this.modalService.confirm({
        nzTitle: 'Bearbeiten abbrechen',
        nzContent: 'Sind Sie sich sicher, dass Sie diesen Vorgang abbrechen möchten? Alle Änderungen gehen verloren.',
        nzOnOk: () => this.modal.close()
      });
    } else {
      this.modal.close();
    }

  }

  public getContactTypes(): void {
    this.api.getContactTypes().subscribe((types: string[]) => {
      this.types = [...new Set([...this.ContactTypes, ...types])].sort();
    }, onerror => {
      console.log(onerror);
      this.message.error('Kontakt Typen konnten nicht geladen werden');
    });
  }

  public selectLexofficeContact(): void {
    this.modalService.confirm({
      nzTitle: 'Achtung: Daten werden überschrieben',
      nzContent: 'Beim Verknüpfen mit einem bestehenden Lexware Office Kontakt, werden alle Kontakt-Informationen in der Hausmeisterapp.com überschrieben! Sind Sie sicher, dass Sie fortfahren möchten?',
      nzOkText: 'Ja, jetzt verknüpfen',
      nzOnOk: () => {
        const newModal = this.modalService.create<LexofficeContactListComponent, LOContactListModalData>({
          nzContent: LexofficeContactListComponent,
          nzData: {
            mode: 'select'
          },
          nzWidth: '1000px',
          nzFooter: null
        });
        newModal.afterClose.subscribe((data: any) => {
          console.log(data);
          if (data) {
            this.contact.lexofficeId = data.id;
            this.updateContact();
          }
        });
      }
    });
  }

  public deleteLexOfficeConnection(): void {
    this.modalService.confirm({
      nzTitle: 'Verknüpfung zu Lexware Office löschen',
      nzContent: 'Sind Sie sich sicher, dass Sie die Verbindung dieses Kontakts zu Lexware Office löschen möchten?',
      nzOnOk: () => {
        this.contact.lexofficeId = null;
        this.ngOnInit();
        this.updateContact(true);
      }
    });
  }

  public syncContactLexoffice(): void {
    this.api.webhookLexoffice('contact.changed', this.contact.lexofficeId).subscribe(res => {
      this.message.success('Die Synchronisation mit Lexware Office war erfolgreich');
      this.modal.close();
    }, error => {
      this.message.error('Kontakt konnte nicht mit Lexware Office synchronisiert werden!');
    });
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }
}
