import {Component, OnInit} from '@angular/core';
import {ActivityLog, RenewIntervals, User} from '../../../../../../database-models';
import {ApiService} from '../../../services/api.service';
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-active-users-with-prices',
  templateUrl: './active-users-with-prices.component.html',
  styleUrls: ['./active-users-with-prices.component.scss']
})
export class ActiveUsersWithPricesComponent implements OnInit {
  public loading = false;
  public users: User[];
  public intervals: RenewIntervals[];
  public valid: boolean = false;


  constructor(
    private api: ApiService,
    public auth: AuthenticationService,
    private message: NzMessageService,
  ) {
  }

  ngOnInit(): void {
    this.getUsers();
  }

  public getUsers(): void {
    this.loading = true;
    this.api.getAllUsers().subscribe((users: User[]) => {
      this.users = users;
      this.loading = false;
      this.getIntervals();
    }, onerror => {
      this.loading = false;
      console.log(onerror);
    });
  }

  public getIntervals(): void {
    this.loading = true;
    this.api.getRenewInterval().subscribe((intervals: RenewIntervals[]) => {
      this.intervals = intervals;
      this.loading = false;
      this.checkIntervals();
      this.validate();
    }, error => {
      this.loading = false;
      this.message.error('Optionen konnten nicht geladen werden');
    });
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  private checkIntervals(){
    for (let user of this.users) {
      if (this.intervals.findIndex(int => int.id === user.renewinterval.id) < 0) {
        user.renewinterval = this.intervals.find(int => int.default) || this.intervals[0];
      }
    }
  }

  public validate(): boolean {
    let valid = true;
    for (let user of this.users) {
      if (!user.renewinterval) {
        valid = false;
      } else {
        user.renew_interval_id = user.renewinterval.id;
      }
    }
    return this.valid = valid;
  }


}
