<div nz-col>
  <form nz-form [formGroup]="contactPersonForm">
    <div nz-row>
      <nz-form-item nz-col nzSpan="4" class="p-r-5">
        <nz-form-label nzFor="salutation">Anrede</nz-form-label>
        <nz-form-control>
          <nz-select style="width: 100%" formControlName="salutation" nzPlaceHolder="Anrede">
            <nz-option nzLabel="- keine Anrede -" nzValue=" "></nz-option>
            <nz-option *ngFor="let salutations of salutations" [nzValue]="salutations"
                       [nzLabel]="salutations"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item nz-col nzSpan="10" class="p-r-5">
        <nz-form-label nzFor="firstname">Vorname</nz-form-label>
        <nz-form-control nzErrorTip="Bitte geben Sie den Vornamen ein.">
          <input formControlName="firstname" nz-input placeholder="Vorname">
        </nz-form-control>
      </nz-form-item>

      <nz-form-item nz-col nzSpan="10" class="p-r-5">
        <nz-form-label nzRequired nzFor="lastname">Nachname</nz-form-label>
        <nz-form-control nzErrorTip="Bitte geben Sie den Nachnamen ein.">
          <input formControlName="lastname" nz-input placeholder="Nachname">
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-divider></nz-divider>
    <h4>E-Mail</h4>
    <div nz-row>
      <div nz-col nzSpan="24">
        <ng-container formArrayName="contact_emails">
          <nz-table #emailTable [nzData]="['']" [nzShowPagination]="false">

            <tbody>

            <ng-container *ngFor="let email of contactEmails.controls; let i = index">
              <tr [formGroup]="email">
                <td>
                  <nz-select style="width: 100%" formControlName="type"
                  >
                    <nz-option *ngFor="let types of EmailTypes" [nzValue]="types"
                               [nzLabel]="types"></nz-option>
                  </nz-select>
                </td>
                <td><input type="text" nz-input formControlName="email"/></td>
                <td style="width: 50px">
                  <button *ngIf="!disabled" (click)="delete('email', i)" nzSize="small"
                          nz-button nzType="link"
                  ><i nz-icon nzType="delete"></i></button>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </nz-table>
        </ng-container>
      </div>
    </div>
    <div class="m-t-10" *ngIf="!disabled">
      <a (click)="addEmail()" style="color: #ffaa00">+ E-Mail hinzufügen</a>
    </div>

    <nz-divider></nz-divider>
    <h4>Telefon</h4>
    <div nz-row>
      <div nz-col nzSpan="24">
        <ng-container formArrayName="contact_phones">
          <nz-table [nzData]="['']" [nzShowPagination]="false">

            <tbody>

            <ng-container *ngFor="let phones of contactPhones.controls; let i = index">

              <tr [formGroup]="phones">
                <td>
                  <nz-select style="width: 100%" formControlName="type"
                  >
                    <nz-option *ngFor="let types of PhoneTypes" [nzValue]="types"
                               [nzLabel]="types"></nz-option>
                  </nz-select>
                </td>
                <td><input type="tel" nz-input formControlName="phonenumber"/></td>
                <td style="width: 50px">
                  <button *ngIf="!disabled" (click)="delete('phone', i)"
                          nzSize="small"
                          nz-button nzType="link"
                  ><i nz-icon nzType="delete"></i></button>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </nz-table>
        </ng-container>
      </div>
    </div>
    <div class="m-t-10" *ngIf="!disabled">
      <a (click)="addPhone()" style="color: #ffaa00">+ Telefon hinzufügen</a>
    </div>

  </form>

  <nz-divider></nz-divider>
  <ng-container *appIsManager>
    <div class="m-t-15" *ngIf="!disabled" [hidden]="!contact.id">
      <button nz-button
              nzType="link"
              nzDanger
              (click)="delete('contactPerson',null,contactPerson.id)"
              type="submit">
        Ansprechpartner Löschen
      </button>
      <button
        nz-button
        class="pull-right"
        nzType="primary"
        [disabled]="contactPersonForm.invalid || !contactPersonForm.touched"
        (click)="setContactPerson()"
        type="submit">
        Ansprechpartner Speichern
      </button>
    </div>
  </ng-container>
</div>
