<ng-container *ngIf="editMode; else tagView">
  <div class="select-flex" [ngStyle]="{'width': width}">
    <nz-select [nzNotFoundContent]="noTagsFound" nzOptionHeightPx="35" class="w-100"  nzSize="small" nzMode="multiple" [(ngModel)]="entityTags" [nzMaxTagCount]="nzMaxTagCount"
      (ngModelChange)="entityTagsChange.emit($event)" [compareWith]="compareById" nzPlaceHolder="bitte wählen">
      <nz-option nzCustomContent *ngFor="let tag of companyTags" [nzValue]="tag" [nzLabel]="tag.name">
        <i [style.color]="tag.color" class="m-r-10" nz-icon nzType="tag" nzTheme="fill"></i> {{tag.name}}
      </nz-option>
    </nz-select>
    <button nz-button (click)="save()" nzType="link">
      <i nz-icon nzType="save"></i>
    </button>
  </div>
</ng-container>

<ng-template #tagView>
  <div [ngStyle]="{'width': width}">
    <div [ngClass]="{'hover-edit' : authenticationService?.currentUserValue?.isManager && !readOnly}" class="p-r-10" (click)="toggleEditMode(true)">
      <nz-tag *ngFor="let tag of entityTags" [nzColor]="tag.color" class="m-b-5">{{ tag.name }}</nz-tag>
      <p class="m-0" *ngIf="entityTags?.length <= 0"><i nz-icon nzType="tag" nzTheme="outline"></i> keine Tags gesetzt</p>
    </div>
  </div>

</ng-template>




<ng-template #noTagsFound>
  Für <strong>{{ typeTranslation[entityType] }}</strong> wurden noch keine Tags angelegt. Diese können <a href="company?tab=settings" target="_blank">hier</a> verwaltet werden.
</ng-template>
