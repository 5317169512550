
  <div nz-row class="text-center">
    <i *ngIf="false" style="cursor: pointer" nz-tooltip="zur Zeiterfassungs Übersicht" class="icon color-orange"
       [routerLink]="'/timetracking/' + currentUser?.id" nz-icon nzType="clock-circle" nzTheme="outline"></i>
    <div nz-col nzSpan="11" class="m-l-5 m-r-5">
      <h6>{{timeService.getLastType()?.name || 'Arbeitszeit'}}</h6>
      <h4>
        <strong><i nz-icon [nzType]="timeService.getLastType()?.icon_web || 'tool'" class="p-r-10"
                   nzTheme="outline"></i><span
          [ngClass]="{'color-orange' : timeService.getLastAction() === 'KOMMEN'}">{{ timeService.dutyTime | async }}</span></strong>
      </h4>
    </div>
    <nz-divider nzType="vertical" class="p-0 m-0"></nz-divider>
    <div nz-col nzSpan="12">
      <h6>Pausenzeit</h6>
      <h4>
        <strong><i nz-icon nzType="coffee" class="p-r-10"
                   nzTheme="outline"></i><span
          [ngClass]="{'color-blue' : timeService.getLastAction() === 'PAUSE'}">{{ timeService.breakTime | async }}</span></strong>
      </h4>
    </div>
  </div>
  <nz-dropdown-menu #timetrackingTypeMenu="nzDropdownMenu">
    <ul nz-menu nzSelectable>
      <li *ngFor="let type of timeService.timetrackingTypes"
          nz-menu-item (click)="timeService.stamp('KOMMEN', type)"
      [hidden]="type.id === timeService.getLastType()?.id">
        <i class="p-r-10" nz-icon [nzType]="type.icon_web" nzTheme="outline"></i> {{type.name}}
      </li>
    </ul>
  </nz-dropdown-menu>
  <div nz-row class="text-center">
    <button [disabled]="timeService.locked" nzBlock
            *ngIf="timeService.getLastAction() === 'KOMMEN'" nz-button
            nzType="link" nz-dropdown nzTrigger="click" [nzDropdownMenu]="timetrackingTypeMenu">
      <i nz-icon nzType="retweet" nzTheme="outline"></i> Tätigkeit wechseln
    </button>
    <div *ngIf="timeService.getLastAction() === 'GEHEN' || !timeService.getLastAction()" nz-col nzSpan="24"
         class="m-t-10">
      <button [disabled]="timeService.locked" nzShape="round" nzBlock
              *ngIf="timeService.getLastAction() === 'GEHEN' || !timeService.getLastAction()" nz-button
              nzType="primary" nz-dropdown nzTrigger="click" [nzDropdownMenu]="timetrackingTypeMenu">
        <i nz-icon nzType="login" nzTheme="outline"></i> Kommen
      </button>
    </div>

    <div nz-col nzSpan="12" class="m-t-10">
      <button [disabled]="timeService.locked" *ngIf="timeService.getLastAction() === 'KOMMEN'" nz-button
              nzType="primary" nzShape="round"
              (click)="timeService.stamp('PAUSE')">
        <i nz-icon nzType="coffee" nzTheme="outline"></i> Pause
      </button>

      <button [disabled]="timeService.locked" *ngIf="timeService.getLastAction() === 'PAUSE'" nz-button
              nzType="primary" nzShape="round"
              nz-dropdown nzTrigger="click" [nzDropdownMenu]="timetrackingTypeMenu">
        <i nz-icon nzType="coffee" nzTheme="outline"></i> Pause Ende
      </button>
    </div>
    <div nz-col nzSpan="12" class="m-t-10">
      <button [disabled]="timeService.locked" nzDanger
              *ngIf="timeService.getLastAction() === 'KOMMEN' || timeService.getLastAction() === 'PAUSE'"
              nz-button nzType="primary" nzShape="round"
              (click)="timeService.stamp('GEHEN')">
        <i nz-icon nzType="logout" nzTheme="outline"></i> Gehen
      </button>
    </div>
  </div>


<div nz-row *ngIf="timeService.timeStamps.length > 0" class="m-t-20">
  <div nz-col nzSpan="24">
    <nz-list nzBordered nzSize="small" style="height: 300px; max-height: 50vh; overflow: auto">
      <nz-list-header>Stempelzeiten</nz-list-header>
      <nz-list-item *ngFor="let item of timeService.timeStamps">
                    <span>
                        <i *ngIf="item?.action === 'KOMMEN'" nz-icon [nzType]="item?.timetracking_type?.icon_web || 'tool'" class="color-orange" nzTheme="outline"></i>
                        <i *ngIf="item?.action === 'PAUSE'" nz-icon nzType="coffee" class="color-blue" nzTheme="outline"></i>
                        <i *ngIf="item?.action === 'GEHEN'" nz-icon nzType="logout" class="color-red" nzTheme="outline"></i>
                      {{ item?.timetracking_type?.name || item?.action }}
                      <small>
                            <p class="m-0 p-0">{{ item?.timestamp | date: 'dd.MM.y HH:mm' }}</p>
                        </small>
                    </span>
        <span nz-typography>
                        {{ timeService.convertTime(item._average) }}
          <small><p class="m-0 p-0">Dauer</p></small>
                    </span>
      </nz-list-item>
    </nz-list>
  </div>
</div>

