<h2>{{ appuser.name }}</h2>
<form nz-form [formGroup]="form">

    <nz-form-item nz-col nzSpan="24">
      <nz-form-label nzFor="appuser_type">Typ</nz-form-label>
      <nz-form-control>
        <nz-select formControlName="appuser_type">
          <nz-option nzValue="mieter" nzLabel="Mieter"></nz-option>
          <nz-option nzValue="eigentuemer" nzLabel="Eigentümer"></nz-option>
          <nz-option nzValue="sonstige" nzLabel="Sonstige"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

  <nz-form-item nz-col nzSpan="24">
    <nz-form-label nzFor="house_number">Haus-/Wohnungsnr.</nz-form-label>
    <nz-form-control>
      <input formControlName="house_number" nz-input placeholder="Haus-/Wohnungsnr.">
    </nz-form-control>
  </nz-form-item>

  <div class="text-right">
    <button class="m-r-10" nz-button nzType="default" (click)="cancel()" type="button">Abbrechen
    </button>
    <button nz-button nzType="primary" type="submit" (click)="updateAppuser()"
            [disabled]="form.invalid || !form.touched">
      Speichern
    </button>
  </div>
</form>
