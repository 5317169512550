import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appversion-lower-exception',
  templateUrl: './appversion-lower-exception.component.html',
  styleUrls: ['./appversion-lower-exception.component.css']
})
export class AppversionLowerExceptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
