<div *minAppversion="2">
  <nz-steps class="m-t-20" [nzCurrent]="current">
    <nz-step nzTitle="Datei hochladen"></nz-step>
    <nz-step nzTitle="Vorauswahl"></nz-step>
    <nz-step nzTitle="Termine auswählen"></nz-step>
    <nz-step nzTitle="Fertig"></nz-step>
  </nz-steps>

  <nz-divider></nz-divider>

  <ng-container *ngIf="current === 0">
    <div class="m-t-15">
      <nz-upload
        nzType="drag"
        [nzMultiple]="false"
        [nzAccept]="'.ics'"
        (nzChange)="handleFileUpload($event)"
        [nzShowUploadList]="false"
      >
        <p class="ant-upload-drag-icon">
          <span nz-icon nzType="file"></span>
        </p>
        <p class="ant-upload-text">Klicken Sie hier oder ziehen Sie die ICS-Datei in diesen Bereich, um sie
          hochzuladen</p>
        <p class="ant-upload-hint">
          Es können nur ICS-Dateien hochgeladen werden.
        </p>
      </nz-upload>
      <nz-divider></nz-divider>

      <button nz-button nzSize="small" class="m-t-10" nzType="default"
              (click)="modal.triggerCancel()">Abbrechen
      </button>
    </div>
  </ng-container>

  <div [hidden]="current !== 1">
    <div style="margin-top: 15px;">
      <nz-table #preselectTable nzSize="small" nzPaginationType="default" [nzData]="groupedWasteplans"
                [nzPageSize]="6" (nzCurrentPageDataChange)="onCurrentPageDataChangeGroup($event)"
                [nzPaginationPosition]="'bottom'">
        <thead>
        <tr>
          <th [nzChecked]="checkedGroup" [nzIndeterminate]="indeterminateGroup"
              (nzCheckedChange)="onAllCheckedGroup($event)"></th>
          <th>Gruppe</th>
          <th>Raus- & Reinstellen</th>

          <th>Farben</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let groupedItem of groupedWasteplans">
          <tr>
            <td [nzChecked]="setOfCheckedIdGroup.has(groupedItem._originalName)" (nzCheckedChange)="onItemCheckedGroup(groupedItem._originalName, $event)"></td>
            <td>
              <input class="w-70" nz-input [style.color]="groupedItem._color" [(ngModel)]="groupedItem.name" /> <nz-tag class="m-l-10" [nzColor]="groupedItem._color">{{ groupedItem.wastePlans.length || 0 }}</nz-tag>
            </td>

            <td>
              <div>
                <i nz-icon nzType="logout" class="color-red" nzTheme="outline"></i>
                <nz-input-number nzSize="small"
                                 class="m-h-10"
                                 style="width: 50px"
                                 [nzDisabled]="groupedItem._out.day === 'same'"
                                 [(ngModel)]="groupedItem._out.count"
                                 [nzPrecision]="1"
                                 [nzMin]="0"
                                 [nzMax]="14"
                ></nz-input-number>
                <nz-radio-group [(ngModel)]="groupedItem._out.day" nzSize="small">

                  <label nz-radio-button nzValue="day">Tag(e) vorher</label>
                  <label nz-radio-button nzValue="workday">Werktag(e) vorher</label>
                  <label nz-radio-button nzValue="same">selber Tag</label>
                </nz-radio-group>
                <span class="m-l-10 color-red">Rausstellen</span>
              </div>

              <div class="m-t-10">
                <i nz-icon nzType="login" class="color-green" nzTheme="outline"></i>
                <nz-input-number nzSize="small"
                                 class="m-h-10"
                                 style="width: 50px"
                                 [nzDisabled]="groupedItem._in.day === 'same'"
                                 [(ngModel)]="groupedItem._in.count"
                                 [nzPrecision]="1"
                                 [nzMin]="0"
                                 [nzMax]="14"
                ></nz-input-number>
                <nz-radio-group [(ngModel)]="groupedItem._in.day" nzSize="small">
                  <label nz-radio-button nzValue="day">Tag(e) danach</label>
                  <label nz-radio-button nzValue="workday">Werktag(e) danach</label>
                  <label nz-radio-button nzValue="same">selber Tag</label>
                </nz-radio-group>
                <span class="m-l-10 color-green">Reinstellen</span>
              </div>
            </td>

            <td>
              <div>
                                    <span *ngFor="let color of wasteplanColors"
                                          [ngClass]="{'active' : color === groupedItem._color}"
                                          class="color-picker-btn"
                                          (click)="groupedItem._color = color"
                                          [style.background]="color">
                                    </span>

                <i nz-icon nzType="bg-colors" nzTheme="outline"
                   [ngClass]="{'active' : color === groupedItem._color}"
                   class="color-picker-btn color-picker-input"
                   [style.color]="groupedItem._color"
                   [(colorPicker)]="groupedItem._color">
                </i>
              </div>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </nz-table>

      <nz-divider></nz-divider>

      <button nz-button nzSize="small" class="m-t-10" nzType="default"
              (click)="pre()">Zurück
      </button>

      <button nz-button nzSize="small" class="m-t-10 pull-right" nzType="primary"
              [disabled]="loading" (click)="preSave()"> zum nächsten Schritt
      </button>
    </div>
  </div>


  <ng-container *ngIf="current === 2">
    <div style="margin-top: 15px;">
      <nz-table #eventTable nzSize="small" nzPaginationType="default" [nzData]="events" [nzPageSize]="6"
                [nzPaginationPosition]="'bottom'"
                (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
        <thead>
        <tr>
          <th [nzChecked]="checked" [nzIndeterminate]="indeterminate"
              (nzCheckedChange)="onAllChecked($event)"></th>
          <th>Gruppe</th>
          <th>Termin</th>
          <th>Raus- & Reinstellen</th>
          <th>Farben</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let eve of eventTable.data; let i = index">
          <td [nzChecked]="setOfCheckedId.has(eve.id)" (nzCheckedChange)="onItemChecked(eve.id, $event)"></td>
          <td>
            <nz-tag [nzColor]="eve.color">{{eve.title}}</nz-tag>
          </td>
          <td>
            <mbsc-datepicker
              tabindex="70"
              inputStyle="outline"
              [controls]="['date']"
              [dateFormat]="'DDDD - DD.MM.YYYY'"
              labelStyle="stacked"
              dataTimezone="utc"
              placeholder="Datum"
              displayTimezone="local"
              theme="ios"
              [timezonePlugin]="momentPlugin"
              [(ngModel)]="eve.time"
              [stepMinute]="1"
            >
            </mbsc-datepicker>
          </td>
          <td>
            <div>
              <i nz-icon nzType="logout" class="color-red" nzTheme="outline"></i>
              <mbsc-datepicker
                style="display: inline-block"
                nz-tooltip
                nzTooltipTitle="Rausstellen"
                nzTooltipPlacement="right"
                class="m-h-10"
                tabindex="70"
                inputStyle="outline"
                [controls]="['date']"
                [dateFormat]="'DDDD - DD.MM.YYYY'"
                labelStyle="stacked"
                dataTimezone="utc"
                placeholder="Datum (optional)"
                displayTimezone="local"
                theme="ios"
                [timezonePlugin]="momentPlugin"
                [(ngModel)]="eve.outTime"
                [stepMinute]="1"
              >
              </mbsc-datepicker>
            </div>
            <div class="m-t-10">
              <i nz-icon nzType="login" class="color-green" nzTheme="outline"></i>
              <mbsc-datepicker
                style="display: inline-block"
                nz-tooltip
                nzTooltipTitle="Reinstellen"
                nzTooltipPlacement="right"
                class="m-h-10"
                tabindex="70"
                inputStyle="outline"
                [controls]="['date']"
                labelStyle="stacked"
                [dateFormat]="'DDDD - DD.MM.YYYY'"
                placeholder="Datum (optional)"
                dataTimezone="utc"
                displayTimezone="local"
                theme="ios"
                [(ngModel)]="eve.inTime"
                [timezonePlugin]="momentPlugin"
                [stepMinute]="1"
              >
              </mbsc-datepicker>
            </div>

          </td>
          <td>
            <div>
                            <span *ngFor="let color of wasteplanColors"
                                  [ngClass]="{'active' : color === eve.color}" class="color-picker-btn"
                                  [style.background]="color"
                                  (click)="selectColor(eve,color)">
                            </span>
              <i nz-icon nzType="bg-colors" nzTheme="outline"
                 [ngClass]="{'active' : color === eve.color}"
                 class="color-picker-btn color-picker-input"
                 [style.color]="eve.color" [(colorPicker)]="eve.color"
                 (colorPickerChange)="selectColor(eve,eve.color)">
              </i>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>

      <nz-divider *ngIf="current === 2"></nz-divider>

      <button nz-button nzSize="small" class="m-t-10" nzType="default"
              (click)="pre()">Zurück
      </button>

      <button nz-button nzSize="small" class="m-t-10 pull-right" nzType="primary"
              [disabled]="loading || setOfCheckedId.size <= 0" (click)="save()">
        ({{setOfCheckedId.size}}) Ausgewählte Termine hinzufügen
      </button>
    </div>
  </ng-container>
</div>
