 <nz-table #contactTable [nzData]="contacts" [nzShowPagination]="true">
    <thead>
    <tr>
      <th></th>
      <th [nzSortFn]="orderFn.name" [(nzSortOrder)]="sortOrders.name"
          (nzSortOrderChange)="sortChanged($event)">Name
      </th>
      <th [nzSortFn]="orderFn.adresse" [(nzSortOrder)]="sortOrders.adresse"
          (nzSortOrderChange)="sortChanged($event)">Adresse
      </th>
      <th>Telefonnummer</th>
      <th style="width: 80px"></th>
    </tr>
    </thead>
    <tbody>
    <tr class="cursor-pointer" *ngFor="let dataItem of contactTable.data" (click)="editContact(dataItem)">
      <td>
        <nz-tag [nzColor]="getColorByType(dataItem.type)">  <lexoffice-icon type="icon" class="m-r-5" *ngIf="dataItem.lexofficeId"></lexoffice-icon> {{dataItem.type}}</nz-tag>

      </td>
      <td nzBreakWord>
        <strong>{{ dataItem.name }}</strong>

      </td>
      <td>
        <ng-container *ngIf="dataItem.v2">
          <div nz-tooltip nzTooltipTitle="{{add.type}}" class="m-v-5" *ngFor="let add of dataItem.contact_addresses; index as i">
            <span *ngIf="i === 0 || dataItem._showAdresse">{{ add.address ? add.address + ', ' + add.zip : '-' }}
              {{ add.city ? add.city : '-' }} <i (click)="getMapLink(add); $event.stopPropagation()" class="color-orange" nz-icon nzType="environment" nzTheme="outline"></i></span>
            <br *ngIf="i === 0 || dataItem._showAdresse">
          </div>
          <small *ngIf="dataItem.contact_addresses?.length > 1">
            <a (click)="toggleShowAddresses(dataItem); $event.stopPropagation()">{{ dataItem._showAdresse ? 'weniger anzeigen' : 'mehr anzeigen' }}</a></small>
        </ng-container>
        <ng-container *ngIf="!dataItem.v2">
          <div>
            <nz-tag [nzColor]="'red'">Veralteter Kontakt</nz-tag>
            <br>
            <button nz-button nzType="link" (click)="upgradeContact(dataItem); $event.stopPropagation()">In neuen
              Kontakt umwandeln
            </button>
          </div>
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="dataItem.v2">
          <div nz-tooltip nzTooltipTitle="{{phone.type}}" class="m-v-5" *ngFor="let phone of dataItem.contact_phones; index as i">
            <a (click)="startPhoneCall(phone.phonenumber); $event.stopPropagation()" style="color: black;"
               *ngIf="i === 0 || dataItem._showPhone">{{ phone.phonenumber }}</a>
            <br *ngIf="i === 0 || dataItem._showPhone">
          </div>
          <small *ngIf="dataItem.contact_phones?.length > 1">
            <a (click)="toggleShowPhones(dataItem); $event.stopPropagation()">{{ dataItem._showPhone ? 'weniger anzeigen' : 'mehr anzeigen' }}</a></small>
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="mode === 'default'">
        <button *appIsManager nz-button nzType="primary" nzGhost nzSize="small" nzShape="circle"
                (click)="deleteContact(dataItem.id); $event.stopPropagation()"><i nz-icon nzType="delete"></i>
        </button>
        </ng-container>
        <button  class="m-t-10" nz-button nzType="text" nzDanger nzGhost nzSize="small" *ngIf="mode === 'unlink'"
                 nzShape="circle" nz-tooltip nzTooltipTitle="Verknüpfung aufheben"
                 (click)="unset(dataItem.id); $event.stopPropagation()"><i nz-icon nzType="link"></i>
        </button>
        <button *ngIf="mode === 'link'" class="m-t-10" nzSize="small" nz-button nzType="primary"
                (click)="set(dataItem); $event.stopPropagation()"><i nz-icon nzType="plus"></i> verknüpfen</button>
      </td>
    </tr>
    </tbody>
  </nz-table>
