import {Component, inject, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {Counter, CounterStatus, File} from "../../../../../../../database-models";
import moment from "moment";
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {NzMessageService} from "ng-zorro-antd/message";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import {AuthenticationService} from "../../../services/authentication.service";
import * as uuid from "uuid";

export interface ModalData {
	counterId: string;
  counter: Counter;
}

class NzDatePickerDisabledDateFn {}

@Component({
  selector: 'app-counter-status-historie',
  templateUrl: './counter-status-historie.component.html',
  styleUrls: ['./counter-status-historie.component.css']
})
export class CounterStatusHistorieComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  public counterId: string;
  public counter: Counter;
  public userId: string;


  public isLoading = false;
  public isTouched = false;
  // public showWarning: boolean = false;
  public date: string = moment().format();
  public dateFormat = 'dd.MM.yyyy'
  public backendURL = environment.apiURL;

  public counterStatusForm: FormGroup;

  constructor(
      public api: ApiService,
      private modal: NzModalRef,
      private message: NzMessageService,
      public formBuilder: FormBuilder,
      private modalService: NzModalService,
      private authenticationService: AuthenticationService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.counterId ??= this.data.counterId;
    this.counter ??= this.data.counter;
    this.authenticationService.currentUser.subscribe(user => {
      this.userId = user.id
    });

    this.initForm();
    this.counterStatusForm.get('count').setValue(this.counter?.counterStatuses[0]?.count || 0);
  }

  public initForm(): void {
    this.counterStatusForm = this.formBuilder.group(
        {
          count: [null, [Validators.required, Validators.min(0)]],
          readingDate: [moment().format(), [Validators.required]],
          note: [''],
        }
    );
  }



  public setCounterStatus(): void {
    const toSend = this.counterStatusForm.getRawValue();

    toSend.id = uuid.v4()
    toSend.counterId = this.counterId;
    toSend.userId = this.userId;

    this.api.setCounterStatus(toSend).subscribe(value => {
      this.message.success('Neuer Zählerstand wurde gespeichert');
      this.modal.close();
    });
  }

  public close(): void {
    this.modal.destroy();
  }
}
