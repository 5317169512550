<div nz-popover [nzPopoverContent]="contentTemplate" (nzPopoverVisibleChange)="getTask()"
     nzPopoverTrigger="hover" (click)="editTask(task)" class="task-tag task-done" [ngClass]="{'task-done' : (!task.recurring && task.recurringDone?.length > 0)}" *ngIf="task">
  <span class="user" [style.background-color]="task.color">
    <ng-container *ngIf="task.backlog">
      <strong>BACKLOG</strong> <br> <small>Zeit nicht festgelegt</small>
    </ng-container>
      <ng-container *ngIf="!task.backlog">
       <strong> {{ task.start | date:'dd.MM.y' }}</strong>
       <br>
        {{ task.start | date:'HH:mm' }} - {{ task.end | date:'HH:mm' }}
        <ng-container
          *ngIf="moment(task.end).diff(task.start, 'day') > 0">(+{{ moment(task.end).diff(task.start, 'day') }}
          Tag)</ng-container>
    </ng-container>
  </span>
  <span class="info">
    <i *ngIf="(!task.recurring && task.recurringDone?.length > 0)" class="checked-task color-green" nz-icon nzType="check-circle" nzTheme="fill"></i>
    <span class="title">

    {{ task.title }}</span> <br>
    <nz-avatar *ngIf="task.user" nzSize="small" class="m-r-5"
               [nzSrc]="task.user.avatarPath ? backendURL + '/' + task.user.avatarPath : ''"
               [nzIcon]="task.user.avatarPath ? backendURL + '/' + task.user.avatarPath : 'user'"
               style="vertical-align: middle;"
    ></nz-avatar>
    {{ task.user?.name || 'Team: ' + task.team?.name }}</span>
</div>

<ng-template #contentTemplate>
  <app-task-overview [(task)]="task"></app-task-overview>
</ng-template>

