import {Component, Injector, OnInit} from '@angular/core';
import {MasterTableComponent} from '../../master-table/master-table.component';
import {environment} from '../../../../../environments/environment';
import {Abonnment, User} from '../../../../../../../database-models';
import {ApiService} from '../../../../services/api.service';
import {Router} from '@angular/router';
import {NzModalService} from 'ng-zorro-antd/modal';
import {TimetrackingService} from '../../../services/timetracking.service';
import {NzMessageService} from 'ng-zorro-antd/message';

@Component({
  selector: 'app-time-tracking-user-list',
  templateUrl: './time-tracking-user-list.component.html',
  styleUrls: ['./time-tracking-user-list.component.css']
})
export class TimeTrackingUserListComponent extends MasterTableComponent implements OnInit {
  public backendURL = environment.apiURL;

  public userOriginal: User[] = [];
  public users: User[] = [];


  public abonnement: Abonnment;

  public title = 'Mitarbeiter';
  public nameSearch: string;
  public userLoading = false;

  public sortOrders = {
    name: null,
    username: null,
    email: null
  };

  public constructor(
    injector: Injector,
    private api: ApiService,
    public router: Router,
    private modalService: NzModalService,
    public timeService: TimetrackingService,
    private message: NzMessageService) {
    super(injector);
  }

  public ngOnInit(): void {
    this.getUsers();
    const storedOrderFn = localStorage.getItem('tableheaderUser');
    if (storedOrderFn) {
      this.sortOrders = JSON.parse(storedOrderFn);
    }
  }

  public sortChanged(change: any) {
    localStorage.setItem('tableheaderUser', JSON.stringify(this.sortOrders));
  }

  public orderFn = {
    name: (a, b) => a.name?.localeCompare(b.name),
    username: (a, b) => a.username?.localeCompare(b.username),
    email: (a, b) => a.email?.localeCompare(b.email),
  }

  public getUsers(): void {
    this.userLoading = true;
    this.api.getUsers().subscribe((users: User[]) => {
      this.userOriginal = users;
      this.users = users;
      this.userLoading = false;
    }, onerror => {
      console.log(onerror);
      // TODO
    });
  }

  public filter() {
    if (this.nameSearch) {
      this.users = this.userOriginal.filter(users =>
        users.name.toLowerCase().indexOf(this.nameSearch.toLowerCase()) !== -1);
    } else {
      this.users = this.userOriginal;
    }
  }
}
