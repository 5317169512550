<h2>Paket & Abrechnung</h2>

<ng-container *ngIf="!this.authService.currentUserValue.company.billing.billingActive">
  <nz-alert class="m-b-10" nzType="success" nzMessage="Während der kostenlosen Testphase entstehen für Sie natürlich keine Kosten"></nz-alert>
</ng-container>

<p>Das aktuelle Abrechnungs-Paket für <strong>{{ user.name }}</strong> ist <strong>{{ currentInterval.name }}</strong>
  <ng-container *ngIf="user.nextRenew">und verlängert sich das nächste mal am {{ user.nextRenew | date: 'dd.MM.yyyy' }}.</ng-container></p>



<p>Sie können das Paket auf ein größeres Paket upgraden, die Restlaufzeit des aktuell gültigen Pakets wird anteilg angerechnet.</p>


<nz-spin [nzSpinning]="loading">

  <div *ngIf="intervals" class="m-b-20">

    <div>
      <div>
        <div class="select-item p-v-20"
             [ngClass]="{ 'active' : selectedInterval.id === interval.id, 'disabled': !upsellingPossible(interval) }"
             (click)="select(interval)" *ngFor="let interval of intervals">

          <div class="inline-block">
            <label>{{ interval.name }}</label>
            <nz-tag class="m-l-10" [nzColor]="'blue'">{{ interval.netto }} €</nz-tag>
            <nz-tag class="m-l-10" *ngIf="currentInterval.id === interval.id" [nzColor]="'red'">aktuelles Paket</nz-tag>
            <p>{{ interval.description }}</p>
          </div>

        </div>

      </div>

    </div>

  </div>

  <nz-divider></nz-divider>

  <ng-container *ngIf="authService.currentUserValue.company.billing.billingActive">

  <nz-table *ngIf="residualValue && selectedInterval.id !== currentInterval.id" [nzShowPagination]="false" [nzData]="['']">
    <tbody>
    <tr>
      <td>
        Neuer Preis Paket "{{selectedInterval.name}}"
      </td>
      <td>
        {{ selectedInterval.netto }} €
      </td>
    </tr>
    <tr>
      <td>
        Restlaufzeit Paket "{{currentInterval.name}}"<br>
        <small>Restlaufzeit: {{ residualValue.daysLeft }} Tage</small>
      </td>
      <td>
        - {{ residualValue.residualValue }} €
      </td>
    </tr>
    <tr>
      <td>
        <strong>SUMME</strong><br>
        <small>sofort fälliger Betrag (Alle Preise zzgl. gesetzliche MwSt.)</small>
      </td>
      <td>
        <strong>{{ (selectedInterval.netto - residualValue.residualValue).toFixed(2) }} €</strong>
      </td>
    </tr>
    </tbody>
  </nz-table>

  </ng-container>

  <div class="text-right m-t-20">
    <button class="button m-r-10" nz-button nzType="default" (click)="modal.triggerCancel()" type="button">Abbrechen
    </button>
    <button class="button" nz-button nzType="primary"
            [disabled]="!selectedInterval || selectedInterval.id === currentInterval.id" (click)="save()">
      {{ authService.currentUserValue.company.billing.billingActive ? 'Kostenpflichtig bestellen' : 'Ändern' }}
    </button>
  </div>

</nz-spin>
