import {Component, ElementRef, inject, Input, OnInit, Optional, QueryList, ViewChildren} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {
  ContactFull,
  InvoiceCreate,
  LineItem,
  OptionalFilters,
  PagingParameters
} from '../../../../../../../Shared/lexoffice-client-js-main/src';
import {Address, Consumable, Contact, Object, WorkLog} from '../../../../../../../database-models';
import moment from 'moment';
import Inputmask from 'inputmask';
import {environment} from '../../../../../environments/environment';
import {NzMessageService} from 'ng-zorro-antd/message';

export interface ModalData {
  worklogs: WorkLog[];
}

const settingsKey = 'lexwareoffice.invoice.config';

@Component({
  selector: 'app-lexoffice-worklog-to-invoice',
  templateUrl: './lexoffice-worklog-to-invoice.component.html',
  styleUrls: ['./lexoffice-worklog-to-invoice.component.css'],
})
export class LexofficeWorklogToInvoiceComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});


  @Input()
  public worklogs: WorkLog[];


  public config = {
    headlines: true,
    headlines_prefix: 'Arbeitsprotokoll',
    headlines_description: true,
    headlines_services: true,
    headlines_gps: true,
    times: true,
    times_calc: true,
    consumables: true,
    consumables_lexwareOnly: false,
    worktimeArticle: null
  };

  public allConsumablesLexoffice: Consumable[] = [];

  public objects: Object[] = [];
  public contacts: Contact[] = [];

  public contact: Contact;
  public object: Object;

  public lxadresses: any = {
    objects: [],
    contacts: []
  };

  public lxInvoice: InvoiceCreate = {
    archived: false,
    voucherDate: moment().toISOString(),
    address: undefined,
    lineItems: [],
    totalPrice: {
      currency: 'EUR'
    },
    taxConditions: {
      taxType: 'net'
    },
    shippingConditions: {
      shippingDate: moment().toISOString(),
      shippingEndDate: moment().toISOString(),
      shippingType: 'service'
    }
  };

  public loading = false;

  constructor(
    private api: ApiService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    private message: NzMessageService,
    @Optional() private modal: NzModalRef
  ) {
  }

  ngOnInit(): void {

    const tmpConfig = localStorage.getItem(settingsKey);
    if (tmpConfig) {
      this.config = JSON.parse(tmpConfig);
    }
    this.api.getConsumables().subscribe((consumables: Consumable[]) => {
      this.allConsumablesLexoffice = consumables.filter(con => con.lexofficeId);
      // Reset worktimeArticle if article not available
      if (this.config.worktimeArticle && !this.allConsumablesLexoffice.find(all => all.id  === this.config.worktimeArticle)) {
        this.config.worktimeArticle = null;
      }
    }, (onerror) => {
      this.message.error('Verbrauchsgegenstände konnten nicht geladen werden');
    });
    this.worklogs ??= this.data.worklogs;


    for (let wl of this.worklogs) {
      if (wl.object) {
        this.objects.push(wl.object);
      }
      if (wl.contact) {
        this.contacts.push(wl.contact);
      }
    }
    this.extractContacts();

    /*   if (this.worklog.contact?.contact_addresses?.length > 0) {
         this.selectedAddressId = this.worklog.contact.contact_addresses.find(address => address.type = 'Rechnungsadresse')?.id || this.worklog.contact.contact_addresses[0].id;
       }

       if (this.worklog.object?.id) {
         this.getContacts(this.worklog.object.id);
       }*/

  }

  private extractContacts(): void {

    this.lxadresses = {
      objects: [],
      contacts: []
    }

    for (let object of this.objects) {
      this.lxadresses.objects.push({
        name: object.name,
        street: object.address_line1,
        zip: object.zip,
        city: object.city,
        countryCode: 'DE'
      });
    }

    for (let contact of this.contacts) {
      for (let address of contact.contact_addresses) {

        this.lxadresses.contacts.push({
          contactId: contact.role !== 'vendor' ? contact.lexofficeId : null,
          name: contact.name,
          street: address.address,
          zip: address.zip,
          city: address.city,
          countryCode: address.countryCode || 'DE'
        });
      }
      if (contact.contact_addresses.length <= 0) {
        this.lxadresses.contacts.push({
          contactId: contact.lexofficeId,
          name: contact.name,
          countryCode: 'DE',
          _noaddress: true
        });
      }
    }
// remove duplicates
    this.lxadresses.contacts = this.lxaddressesRemoveDuplicates(this.lxadresses.contacts);
    this.lxadresses.objects = this.lxaddressesRemoveDuplicates(this.lxadresses.objects);


    if (this.lxadresses.contacts.length <= 0 && this.lxadresses.objects.length <= 0) {
      this.lxadresses.contacts.push({
        name: 'Keine Adresse',
        countryCode: 'DE',
        _noaddress: true
      });
    }
  }

  private lxaddressesRemoveDuplicates(addresses: any): any {
    addresses = addresses.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.contactId === value.contactId &&
          t.name === value.name &&
          t.street === value.street &&
          t.zip === value.zip &&
          t.city === value.city &&
          t.countryCode === value.countryCode
        ))
    );
    return addresses.sort((a, b) => a.name.localeCompare(b.name));;
  }

  public generatePreview(): void {
    localStorage.setItem(settingsKey, JSON.stringify(this.config));
    this.lxInvoice.lineItems = [];
    for (let wl of this.worklogs) {
      if (this.config.headlines) {
        this.lxInvoice.lineItems.push(this.getServices(wl));
      }
      if (this.config.times) {
        this.lxInvoice.lineItems.push(this.getWorktime(wl));
      }
      if (this.config.consumables) {
        this.lxInvoice.lineItems = this.lxInvoice.lineItems.concat(...this.getConsumables(wl));
      }
    }

    for (let li of this.lxInvoice.lineItems) {
      li._selected = true;
    }

    this.setMasks();
  }


  @ViewChildren('amountMask') amountMask: QueryList<ElementRef>;

  public setMasks(): void {
    setTimeout(() => {
      if (this.amountMask) {
        for (let mask of this.amountMask.toArray()) {
          Inputmask('decimal', {
            alias: 'currency',
            digits: 2,
            radixPoint: ',',
            clearMaskOnLostFocus: false,
            isComplete: function (buffer, opts) {

            }
          }).mask(mask.nativeElement);
        }
      }
    }, 100);


  }

  ngAfterViewInit(): void {
    this.setMasks();
  }

  public getContacts(objectId: string): void {
    this.api.getContacts({objectId}).subscribe(contacts => {
      //this.worklog.object.contacts = contacts;
    }, error => {
      this.notification.error('Fehler', 'Kontakte konnten nicht geladen werden');
    });
  }

  public getServices(worklog: WorkLog): any {
    let headline = {
      type: 'text',
      name: (this.config.headlines_prefix ? this.config.headlines_prefix + ' vom ' : '') + moment(worklog.createdAt).format('DD.MM.YYYY'),
      description: (this.config.headlines_description && worklog.description) ? worklog.description + '\n' : ''
    } as LineItem;
    if (this.config.headlines_services && worklog.completedServices?.length > 0) {
      headline.description += worklog.completedServices.map(service => '- ' + service.name).join('\n');
      headline.description += '\n'
    }
    if (this.config.headlines_gps) {
      for (let geo of worklog.geolocations) {
        headline.description += 'GPS-Standort vom ' + moment(geo.createdAt).format('DD.MM.YYYY HH:mm') + ' Uhr: ' + geo.latitude + ' / ' + geo.longitude + '\n';
      }
    }
    return headline;
  }

  public getWorktime(worklog: WorkLog): any {
    let quantity = 0;
    if (this.config.times_calc) {
      quantity = (moment(worklog.end).diff(worklog.start, 'minutes') / 60) || 0;
      quantity = quantity ? +(quantity.toFixed(2)) : 0;
    }


    let description = 'Beginn: ' + moment(worklog.start).format('DD.MM.YYYY HH:mm') + ' Uhr' + (worklog.end ? '\nEnde: ' + moment(worklog.end).format('DD.MM.YYYY HH:mm') + ' Uhr' : '');
    if (this.config.worktimeArticle) {
      let consumable = this.allConsumablesLexoffice.find(con => con.id === this.config.worktimeArticle);
      return {
        id: this.allConsumablesLexoffice.find(con => con.id === this.config.worktimeArticle).lexofficeId,
        type: consumable.lexofficeObject.type === 'PRODUCT' ? 'material' : 'service',
        name: consumable.name,
        description: (consumable.description ? consumable.description + '\n' : '') + description,
        quantity: quantity,
        unitName: consumable.lexofficeObject.unitName,
        unitPrice: {
          currency: 'EUR',
          netAmount: consumable.lexofficeObject.price.netPrice.toFixed(2).toString().replace('.', ','),
          taxRatePercentage: consumable.lexofficeObject.price.taxRate
        }
      } as LineItem;
    } else {
      return {
        type: 'custom',
        name: 'Arbeitszeit',
        description: description,
        quantity: quantity,
        unitName: 'Stunden',
        unitPrice: {
          currency: 'EUR',
          netAmount: 0,
          taxRatePercentage: 19
        }
      } as LineItem;
    }

  }

  public getConsumables(worklog: WorkLog): any {
    let lineItems: any[] = [];
    for (let consumable of worklog.consumed) {
      if (consumable.lexofficeId && consumable.lexofficeObject) {
        lineItems.push({
          id: consumable.lexofficeId,
          type: consumable.lexofficeObject.type === 'PRODUCT' ? 'material' : 'service',
          name: consumable.name,
          description: consumable.description,
          quantity: consumable.Consumable_Ticket?.number,
          unitName: consumable.lexofficeObject.unitName,
          unitPrice: {
            currency: 'EUR',
            netAmount: consumable.lexofficeObject.price.netPrice.toFixed(2).toString().replace('.', ','),
            taxRatePercentage: consumable.lexofficeObject.price.taxRate
          }
        });
      } else if (!this.config.consumables_lexwareOnly) {
        lineItems.push({
          type: 'custom',
          name: consumable.name,
          description: consumable.description,
          quantity: consumable.Consumable_Ticket?.number,
          unitName: consumable.unit,
          unitPrice: {
            currency: 'EUR',
            netAmount: 0,
            taxRatePercentage: 19
          }
        });
      }

    }
    return lineItems;

  }

  /* public deleteLineItem(index: number): void {
     this.lxInvoice.lineItems.splice(index, 1);
   }

   public cloneLineItem(index: number): void {
     this.lxInvoice.lineItems.splice(index, 0, JSON.parse((JSON.stringify(this.lxInvoice.lineItems[index]))));
     this.setMasks();
   }*/

  public sendToLexoffice(finalize: boolean = false): void {

    let invToSave = JSON.parse(JSON.stringify(this.lxInvoice));
    invToSave.lineItems = invToSave.lineItems.filter(item => item._selected);

    this.loading = true;


    // Amount String to Number
    for (let lineItem of invToSave.lineItems) {
      if (lineItem.unitPrice?.netAmount) {
        lineItem.unitPrice.netAmount = +lineItem.unitPrice.netAmount.replace(',', '.');
      }
    }

    let wlIds = this.worklogs.map(wl => wl.id);

    this.api.lexofficeCreateInvoice(invToSave, {worklogIds: wlIds, finalize}).subscribe(result => {
      this.notification.success('Erfolgreich', 'Die Rechnung wurde erfolgreich erstellt');
      setTimeout(() => {
        this.loading = false;
        window.open(environment.lexofficePartnerApi.uiUrl + '/voucher/#/' + result.id, '_blank');
        this.modal.close(result);
      }, 500);

    }, error => {
      this.loading = false;
      this.modalService.error({
        nzTitle: 'Fehler beim Senden an Lexware Office',
        nzContent: 'Die Rechnung konnte nicht erstellt werden!<br><br><strong>Status-Code: </strong> ' + error.status + '<br><strong>Lexware Office Fehlermeldung:</strong><br>' + error.error?.messageOrig
      });
    });
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

}
