import {Component, inject, Input, OnInit, Optional} from '@angular/core';
import {Object, PropertyManagement, PropertyManagementRequest} from '../../../../../../../database-models';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import {HttpParams} from '@angular/common/http';

export interface ModalData {
	object: Object;
}

@Component({
  selector: 'app-hv-object-connection',
  templateUrl: './hv-object-connection.component.html',
  styleUrls: ['./hv-object-connection.component.css']
})
export class HvObjectConnectionComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  public loading = false;

  @Input()
  public object: Object;

  public objectForm: FormGroup;

  public selectedPropertyManagement: PropertyManagement;
  public propertyManagements: PropertyManagement[] = [];
  public nzFilterOption = () => true;

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    private message: NzMessageService,
    private modalService: NzModalService,
    @Optional() private modal: NzModalRef
  ) {
  }

  ngOnInit(): void {
    this.object ??= this.data.object;
  }

  public search(value: string): void {
    if (value && value.length > 0) {
      let httpParams = new HttpParams();
      httpParams = httpParams.append('name', value);
      this.api.searchPropertyManagement(httpParams).subscribe((propertyManagements: PropertyManagement[]) => {
        this.propertyManagements = propertyManagements;
      }, error => {
        this.message.error('Hausverwaltungen konnten nicht geladen werden!');
      });
    }
  }

  public startRequest(): void {
    this.loading = true;
    const reqest = {
      object_id: this.object.id,
      property_management_id: this.selectedPropertyManagement.id
    } as PropertyManagementRequest;

    this.api.createPropertyManagementRequest(reqest).subscribe((request: PropertyManagementRequest) => {
      this.message.success('Die Anfrage wurde erfolgreich gesendet.');
      this.loading = false;
      this.modal.close('reload');
    }, error => {
      this.loading = false;
      this.message.error('Anfrage konnte nicht gestellt werden!  Bitte versuchen Sie es später erneut.');
    });
  }

  public removeConnection(): void {
    this.modalService.confirm({
      nzTitle: '<i>Hausverwaltung für dieses Objekt entfernen</i>',
      nzContent: 'Sind Sie sicher, dass Sie die Hausverwaltung von diesem Objekt entfernen möchten?<br>Die Hausverwaltung verliert damit jeglichen Zugriff auf das Objekt und die zugehörigen Tickets.',
      nzOnOk: () => {
        this.api.deletePropertyManagementConnection(this.object.id).subscribe(res => {
          this.object.property_management = null;
          this.object.property_management_id = null;
          this.modal.close('reload');

        }, error => {
          this.message.error('Die Aktion ist fehlgeschlagen, bitte versuchen Sie es später erneut!');
        });
      }
    });
  }

}
