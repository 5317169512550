<h3>Kontakte verknüpfen</h3>

<nz-tabset>
    <nz-tab [nzTitle]="header">
        <ng-template #header>
            <nz-badge [nzStyle]="{ backgroundColor: '#f58b0a' }"
                      [nzCount]="availableContacts?.length"><span>Bestehende Kontakte</span></nz-badge>
        </ng-template>
        <nz-spin [nzSpinning]="isLoading">
            <app-contact-select-list (setRelation)="setRelation($event)" [mode]="'link'"
                                     [contacts]="availableContacts"></app-contact-select-list>
        </nz-spin>
    </nz-tab>
    <nz-tab [nzTitle]="'Neuen Kontakt erstellen'">

        <app-edit-contact-v2 (savedContact)="setRelation($event)"></app-edit-contact-v2>

    </nz-tab>
</nz-tabset>
