<div nz-row>
  <div nz nz-col nzSpan="18">
    <h2>{{title}}</h2>
  </div>

  <div nz-col nzSpan="6">
    <!--        <button class="m-l-25" *ngIf="wasteplan.id" nz-button nzSize="small" nzType="default" (click)="editTask(wasteplan)"><i nz-icon
                                                                                                                    nzType="calendar"
                                                                                                                    nzTheme="outline"></i>
                zu Aufgabe
            </button>-->
  </div>
</div>
<nz-divider style="margin-top: 0px;"></nz-divider>
<form [formGroup]="wasteplanForm" *ngIf="wasteplanForm">
  <div class="m-b-10" nz-col nzSpan="24">
    <div nz-row>
      <nz-form-item nz-col nzSpan="12" class="p-r-10">
        <nz-form-label nzRequired nzFor="title">Titel</nz-form-label>
        <nz-form-control>
          <input style="font-weight: bold" formControlName="title"
                 [nzAutocomplete]="auto" placeholder="Titel" nz-input>
          <nz-autocomplete [nzDataSource]="wasteCategories" nzBackfill #auto></nz-autocomplete>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item nz-col nzSpan="12">
        <nz-form-label nzFor="color">Farben</nz-form-label>
        <nz-form-control>
          <div>
                        <span *ngFor="let color of wasteplanColors"
                              [ngClass]="{'active' : color === wasteplan.color}" class="color-picker-btn hover"
                              [style.background]="color"
                              (click)="selectColor(color)">
                        </span>
            <i nz-icon nzType="bg-colors" nzTheme="outline"
               [ngClass]="{'active' : color === wasteplan.color}"
               class="color-picker-btn color-picker-input"
               [style.color]="wasteplan.color" [(colorPicker)]="wasteplan.color"
               (colorPickerChange)="selectColor(wasteplan.color)">
            </i>

          </div>
        </nz-form-control>
      </nz-form-item>


    </div>

    <nz-form-item nz-col nzSpan="24">
      <nz-form-label nzRequired nzFor="time">Datum</nz-form-label>
      <nz-form-control>
        <mbsc-datepicker
          tabindex="70"
          inputStyle="outline"
          formControlName="time"
          [controls]="['date']"
          labelStyle="stacked"
          [dateFormat]="'DDDD - DD.MM.YYYY'"
          dataTimezone="utc"
          displayTimezone="local"
          theme="ios"
          [timezonePlugin]="momentPlugin"
          [stepMinute]="1"
        >
        </mbsc-datepicker>
      </nz-form-control>
    </nz-form-item>

    <div nz-row>
      <nz-form-item class="p-r-5" nz-col nzSpan="12">
        <nz-form-label nzFor="outTime">Zeitpunkt zum rausstellen</nz-form-label>
        <nz-form-control *ngIf="wasteplanForm.get('outTime').value">
          <mbsc-datepicker
            tabindex="70"
            inputStyle="outline"
            formControlName="outTime"
            [controls]="['date']"
            [dateFormat]="'DDDD - DD.MM.YYYY'"
            labelStyle="stacked"
            dataTimezone="utc"
            placeholder="Datum (optional)"
            displayTimezone="local"
            theme="ios"
            [timezonePlugin]="momentPlugin"
            [stepMinute]="1"
          >
          </mbsc-datepicker>
        </nz-form-control>
        <nz-form-control *ngIf="!wasteplanForm.get('outTime').value">
          <button nz-button nzSize="small" nzType="dashed"
                  (click)="selectDates('OUT', 0)">
            Selber Tag
          </button>
          <button style="margin-left: 10px;" nzSize="small" nz-button nzType="dashed"
                  (click)="selectDates('OUT', 1, true)">
            - 1 Werktag
          </button>
          <button style="margin-left: 10px;" nzSize="small" nz-button nzType="dashed"
                  (click)="selectDates('OUT', 1)">
            - 1 Tag
          </button>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item nz-col nzSpan="12">
        <nz-form-label nzFor="inTime">Zeitpunkt zum reinstellen</nz-form-label>
        <nz-form-control *ngIf="wasteplanForm.get('inTime').value">
          <mbsc-datepicker
            tabindex="70"
            inputStyle="outline"
            formControlName="inTime"
            [controls]="['date']"
            [dateFormat]="'DDDD - DD.MM.YYYY'"
            labelStyle="stacked"
            placeholder="Datum (optional)"
            dataTimezone="utc"
            displayTimezone="local"
            theme="ios"
            [timezonePlugin]="momentPlugin"
            [stepMinute]="1"
          >
          </mbsc-datepicker>
        </nz-form-control>
        <nz-form-control *ngIf="!wasteplanForm.get('inTime').value">
          <button nz-button nzSize="small" nzType="dashed"
                  (click)="selectDates('IN', 0)">
            Selber Tag
          </button>
          <button nz-button nzType="dashed" nzSize="small" style="margin-left: 10px;"
                  (click)="selectDates('IN', 1, true)">
            + 1 Werktag
          </button>
          <button nz-button nzType="dashed" nzSize="small" style="margin-left: 10px;"
                  (click)="selectDates('IN', 1)">
            + 1 Tag
          </button>
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-form-item nz-col nzSpan="24">
      <nz-form-label nzFor="note">Beschreibung</nz-form-label>
      <nz-form-control>
                    <textarea formControlName="description" [nzAutosize]="{ minRows: 4, maxRows: 6 }" nz-input
                              placeholder="Beschreibung schreiben (optional)" nzAutosize></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>
<button nzSize="small" nz-button nzType="link" (click)="close()">Abbrechen</button>


<button [disabled]="wasteplanForm.invalid || !wasteplanForm.touched || isLoading" class="pull-right"
        nzSize="small" nz-button
        nzType="primary" (click)="save()">Speichern
</button>
