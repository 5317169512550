<h2>Notiz bearbeiten</h2>
<div nz-row>
  <div nz-col nzSpan="24">
    <nz-comment>
      <nz-avatar nz-comment-avatar nzIcon="user" [nzSrc]="''"></nz-avatar>
      <nz-comment-content>
        <nz-form-item>
          <textarea [(ngModel)]="this.note.text" nz-input rows="4"></textarea>
        </nz-form-item>
        <nz-form-item>
          <button
                  nz-button
                  [nzType]="'primary'"
                  [nzLoading]="uploading"
                  (click)="updateObjectNote() "
          >
            {{ uploading ? 'Sendet' : 'Speichern' }}
          </button>

          <nz-upload [(nzFileList)]="imageUploads" [nzBeforeUpload]="beforeUpload" [nzSize]="10000"
                     [nzFileType]="'image/png,image/jpeg,image/jpg,image/gif,image/bmp'">
            <button nz-button class="m-l-10"><i nz-icon nzType="upload"></i>Bilder hochladen</button>
          </nz-upload>

          <label class="m-l-10" [(ngModel)]="note.managerOnly" nz-checkbox>Nur sichtbar für Manager und Admin</label>
        </nz-form-item>
      </nz-comment-content>
    </nz-comment>
  </div>
</div>

<nz-divider class="m-0 m-b-10 p-0" *ngIf="note.files.length > 0"></nz-divider>
<h4 *ngIf="note.files.length > 0">Dateien</h4>

<app-lightbox [images]="note.files" [nzSpan]="3" [maxHeight]="100"
    (edit)="editFile($event.file)" 
    (delete)="deleteImage($event.file.id, $event.index, note)">
</app-lightbox>