import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {Contact, ContactPerson, EmailTypes, PhoneTypes, Salutation} from '../../../../../../database-models';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-edit-contact-person',
  templateUrl: './edit-contact-person.component.html',
  styleUrls: ['./edit-contact-person.component.css']
})
export class EditContactPersonComponent implements OnInit {


  @Input() public contactPerson: ContactPerson;
  @Input() public disabled: boolean = true;
  @Input() public contact: Contact;


  @Output()
  public contactPersonDelete: EventEmitter<any> = new EventEmitter<any>();


  public salutations: Salutation[] = ['Herr', 'Frau'];
  public EmailTypes: EmailTypes[] = ['Privat', 'Arbeit', 'Rechnungsmailadresse', 'Andere'];
  public PhoneTypes: PhoneTypes[] = ['Privat Mobil', 'Arbeit Mobil', 'Privat Festnetz', 'Arbeit Festnetz', 'Fax', 'Notfall', 'Andere'];

  public contactPersonForm: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    @Optional() private modal: NzModalRef
  ) {
  }

  ngOnInit(): void {
    this.contactPersonForm = this.formBuilder.group({
      firstname: [this.contactPerson.firstname],
      lastname: [this.contactPerson.lastname, [Validators.required]],
      salutation: [this.contactPerson.salutation],

      // Formgroups
      contact_emails: this.formBuilder.array([]),
      contact_phones: this.formBuilder.array([]),
    });

    if (this.contactPerson?.contact_emails?.length > 0) {
      for (let email of this.contactPerson.contact_emails) {
        let group = this.formBuilder.group({
          id: email.id,
          type: [email.type, Validators.required],
          email: [email.email, Validators.required]
        });
        // @ts-ignore
        this.contactPersonForm.controls['contact_emails'].push(group);
      }
    }

    if (this.contactPerson?.contact_phones?.length > 0) {
      for (let phone of this.contactPerson?.contact_phones) {
        let group = this.formBuilder.group({
          id: phone.id,
          type: [phone.type, Validators.required],
          phonenumber: [phone.phonenumber, Validators.required]
        });
        // @ts-ignore
        this.contactPersonForm.controls['contact_phones'].push(group);
      }
    }

    if (this.contact.lexofficeId) {
      this.disabled = true;
      this.contactPersonForm.disable();
      this.contactPhones.controls.forEach(ctrl => ctrl.disable());
      this.contactEmails.controls.forEach(ctrl => ctrl.disable());
    }
  }

  public setContactPerson(): void {
    const sendConPerson = this.contactPersonForm.getRawValue();
    this.contactPerson.firstname = sendConPerson.firstname;
    this.contactPerson.lastname = sendConPerson.lastname;
    this.contactPerson.salutation = sendConPerson.salutation;

    this.contactPerson.contact_emails = sendConPerson.contact_emails;
    this.contactPerson.contact_phones = sendConPerson.contact_phones;

    this.contactPerson.contactId = this.contact.id;

    this.api.setContactPerson(this.contactPerson).subscribe(value => {
      this.message.success('Ansprechpartner wurde gespeichert');
      this.contactPersonForm.markAsUntouched();
    });
  }


  get contactPhones() {
    return this.contactPersonForm.controls['contact_phones'] as FormArray;
  }

  get contactEmails() {
    return this.contactPersonForm.controls['contact_emails'] as FormArray;
  }

  public addEmail(): void {
    let newMail = this.formBuilder.group({
      type: ['Arbeit', Validators.required],
      email: ['', Validators.required]
    });
    this.contactEmails.push(newMail);
  }

  public addPhone(): void {
    let newPhone = this.formBuilder.group({
      type: ['Arbeit Mobil', Validators.required],
      phonenumber: ['', Validators.required]
    });
    this.contactPhones.push(newPhone);
  }

  public delete(type: 'contactPerson' | 'email' | 'phone', index?: number, id?: string): void {
    if (type === 'email') {
      this.modalService.warning({
        nzTitle: '<i>E-Mail löschen</i>',
        nzContent:
          'Sind Sie sicher, dass Sie die E-Mail löschen möchten? Die E-Mail wird nur von dem Ansprechpartner gelöscht',
        nzOnOk: () => this.deleteEmail(index),
        nzAutofocus: 'ok',
        nzCancelText: 'Abbrechen',
      });
    } else if (type === 'phone') {
      this.modalService.warning({
        nzTitle: '<i>Telefonummer löschen</i>',
        nzContent:
          'Sind Sie sicher, dass Sie die Telefonummer löschen möchten? Die Telefonummer wird nur von dem Ansprechpartner gelöscht',
        nzOnOk: () => this.deletePhone(index),
        nzAutofocus: 'ok',
        nzCancelText: 'Abbrechen',
      });
    } else if (type === 'contactPerson') {
      this.modalService.warning({
        nzTitle: '<i>Ansprechpartner löschen</i>',
        nzContent:
          'Sind Sie sicher, dass Sie diesen Ansprechpartner löschen möchten? Alle Daten daran werden gelöscht',
        nzOnOk: () => this.deleteContactPerson(id),
        nzAutofocus: 'ok',
        nzCancelText: 'Abbrechen',
      });
    }
  }

  // DELETE CONTACT PERSON
  public deleteContactPerson(id: string) {
    if (!id) {
      this.contactPersonDelete.emit();
    } else {
      this.api.deleteContactPerson(id).subscribe(value => {
        this.message.success('Ansprechpartner wurde gelöscht');
        this.contactPersonDelete.emit();
      });
    }
  }

  // Delete Email by ContactPerson
  public deleteEmail(index: number) {
    const emailFormGroup = this.contactEmails.at(index) as FormGroup;
    const emailId = emailFormGroup.value.id;
    if (!emailId) {
      this.contactEmails.removeAt(index);
    } else {
      this.api.deleteContactPersonEmail(emailId).subscribe(value => {
        this.message.success('E-Mail wurde gelöscht');
        this.contactEmails.removeAt(index);
      });
    }
  }

  // Delete Phone by ContactPerson
  deletePhone(index: number) {
    const phoneFormGroup = this.contactPhones.at(index) as FormGroup;
    const phoneId = phoneFormGroup.value.id;
    if (phoneId) {
      this.contactPhones.removeAt(index);
    } else {
      this.api.deleteContactPersonPhone(phoneId).subscribe(value => {
        this.message.success('Telefonnummer wurde gelöscht');
        this.contactPhones.removeAt(index);
      });
    }
  }
}
