import {Component, Injector, Input, OnInit} from '@angular/core';
import {CheckListTemplate, Image, ObjectNote} from '../../../../../../../database-models';
import {MasterTableComponent} from '../../master-table/master-table.component';
import {ApiService} from '../../../../services/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import {Router} from '@angular/router';
import {EditChecklistTemplateComponent, ModalData as EditChecklistTemplateModalData} from '../edit-checklist-template/edit-checklist-template.component';

@Component({
  selector: 'app-check-list-templates-list',
  templateUrl: './check-list-templates-list.component.html',
  styleUrls: ['./check-list-templates-list.component.css']
})
export class CheckListTemplatesListComponent extends MasterTableComponent implements OnInit {

  @Input()
  public objectId: string;

  public checkListTemplates: CheckListTemplate[];

  constructor(
    injector: Injector,
    private api: ApiService,
    private message: NzMessageService,
    public router: Router,
    private modalService: NzModalService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.tableData = {
      items: [
        {
          title: 'Name',
          key: 'name'
        }
      ],
      data: []
    };

    if (this.objectId) {
      this.getTemplatesByObjectId();
    }

  }

  public getTemplatesByObjectId(): void {
    this.api.getCheckListTemplatesByObjectId(this.objectId).subscribe((templates: CheckListTemplate[]) => {
      this.checkListTemplates = templates;
      this.tableData.data = this.checkListTemplates;
    }, onerror => {
      this.message.error('Checklisten konnten nicht geladen werden');
    });
  }

  public editCheckListTemplate(tpl?: CheckListTemplate): void {
    const editModal = this.modalService.create<EditChecklistTemplateComponent, EditChecklistTemplateModalData>({
      nzContent: EditChecklistTemplateComponent,
      nzData: {
        objectId: this.objectId,
        checkListTemplate: tpl ? JSON.parse(JSON.stringify(tpl)) : null
      },
      nzFooter: null,
      nzWidth: 700,
    });
    editModal.afterClose.subscribe((data: any) => {
      console.log('data', data);
      this.getTemplatesByObjectId();
    });
  }

  public deleteCheckListTemplate(tpl: CheckListTemplate): void {
      this.modalService.confirm({
        nzTitle: '<i>Checkliste löschen</i>',
        nzContent: 'Sind Sie sicher, dass Sie die Checkliste unwiderruflich löschen möchten?',
        nzOnOk: () => {
          this.api.deleteCheckListTemplate(tpl.id).subscribe(res => {
            this.message.success('Checkliste wurde gelöscht');
            this.getTemplatesByObjectId();
          }, error => {
            this.message.error('Checkliste konnte nicht gelöscht werden');
          });
        }
      });
  }

}
