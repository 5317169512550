<nz-spin [nzSpinning]="isLoading">
    <ng-containern *ngIf="sharedTickets?.length > 0">
        <div style="max-height: 450px; overflow: auto">
            <nz-table nzSize="small" #sharedTicketsTable [nzData]="sharedTickets">
                <thead>
                <tr>
                    <th>Erstellt am:</th>
                    <th>Erstellt von:</th>
                    <th>Berechtigungen</th>
                    <th style="width: 120px"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of sharedTicketsTable.data">
                    <td>{{item.createdAt | date:'dd.MM.y H:mm'}}</td>
                    <td>{{item.user.name}}</td>
                    <td>
                        <i class="m-r-5" nz-icon nzType="info-circle" nz-tooltip [nzTooltipTrigger]="'hover'" nzTooltipTitle="Grundinformationen"></i>
                        <i class="m-r-5" *ngIf="item.comments" nz-icon nzType="message" nz-tooltip [nzTooltipTrigger]="'hover'" nzTooltipTitle="Kommentare sichtbar"></i>
                        <i class="m-r-5" *ngIf="item.files" nz-icon nzType="file" nz-tooltip [nzTooltipTrigger]="'hover'" nzTooltipTitle="Dateien sichtbar"></i>
                        <i  *ngIf="item.persons" nz-icon nzType="user" nz-tooltip [nzTooltipTrigger]="'hover'" nzTooltipTitle="Personen Information sichtbar"></i>
                    </td>
                    <td>
                        <button (click)="deleteSharedTicketLink(item)" nz-tooltip nzTooltipTitle="Geteilten Link löschen" nzSize="small" class="m-l-10" nz-button nzDanger
                                nzType="link"
                                nzShape="circle"><i nz-icon
                                                    nzType="delete"></i>
                        </button>
                        <button [cdkCopyToClipboard]="copyToClipboard" nz-tooltip nzTooltipTitle="Link kopieren" (mouseup)="clipboardCopy(item)" nzSize="small"
                                class="m-l-10" nz-button nzType="default" nzShape="circle"><i nz-icon
                                                                                              nzType="copy"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </nz-table>
        </div>
    </ng-containern>
</nz-spin>
