import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Company, MailImportConfig} from '../../../../../../../database-models';
import {ApiService} from '../../../../services/api.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import * as uuid from 'uuid';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-mail-import-config',
  templateUrl: './mail-import-config.component.html',
  styleUrls: ['./mail-import-config.component.css']
})
export class MailImportConfigComponent implements OnInit {

  public loading = false;

  @Input()
  public company: Company;

  public configForm: FormArray;

  public modalVisible: boolean = false;

  public mailImportConfigs: MailImportConfig[];

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private message: NzMessageService,
    private authenticationService: AuthenticationService,
    private notification: NzNotificationService
  ) {
  }


  ngOnInit(): void {
    this.getMailImportConfig();

  }

  public getMailImportConfig() {
    this.api.getMailImportConfig().subscribe(data => {
      this.mailImportConfigs = data;
      if (this.mailImportConfigs.length <= 0) {
        this.mailImportConfigs.push({} as MailImportConfig);
      }
      this.initForm();
    });
  }

  public initForm(): void {
    this.configForm = this.fb.array([]);
    for (let config of this.mailImportConfigs) {
      this.addConfig(config);
    }
  }

  private addConfig(config?: MailImportConfig): void {
    let newConfig = this.fb.group({
      id: [config.id || uuid.v4()],
      name: [config.name, [Validators.required]],
      host: [config.host, [Validators.required]],
      port: [config.port, [Validators.required]],
      user: [config.user, [Validators.required]],
      password: [config.password, [Validators.required]],
      tls: [config.tls],
      active: [config.active]
    });
    this.configForm.push(newConfig);
  }

  public checkConnection(config: MailImportConfig): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.notification.blank('Verbindung wird überprüft', 'Bitte warten Sie, bis die Verbindung überprüft wurde...', {
        nzKey: config.id,
        nzDuration: -1
      });
      this.api.getMailImport(config.id).subscribe(data => {
        this.notification.success('Verbindung erfolgreich', 'Die Verbindung zum IMAP Postfach ' + config.name + ' war erfolgreich.<br><br>Damit die Einstellung abgeschlossen werden kann, aktualisieren Sie ggf. das Browserfenster.', {
          nzKey: config.id,
          nzDuration: 2000
        });

        config._error = false;
        resolve(true);
        this.loading = false;
      }, error => {
        console.error(error);
        this.notification.error('Verbindung fehlgeschlagen', 'Die Verbindung zum IMAP Postfach ' + config.name + ' war nicht erfolgreich.', {
          nzKey: config.id,
          nzDuration: -1
        });
        config._error = true;
        resolve(false);
        this.initForm();
        this.loading = false;
      });
    });

  }

  public save(): void {
    this.mailImportConfigs = this.configForm.getRawValue();
    this.configForm.markAsUntouched();

    this.api.setMailImportConfig(this.mailImportConfigs).subscribe(async result => {
      console.log(result);
      this.mailImportConfigs = result;
      let checkResults = true;
      for (let config of this.mailImportConfigs) {
        if (config.active) {
          if (!await this.checkConnection(config)) {
            checkResults = false;
          }
        }
      }
      if (checkResults) {
        this.message.success('Erfolgreich aktualisiert');
        this.modalVisible = false;
        this.authenticationService.loadCurrentUser();
      }

    }, error => {
      this.message.error('Es ist ein Fehler aufgetreten!');
    });
  }

}
