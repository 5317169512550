import {Component, Injector, Input, OnInit} from '@angular/core';
import {Session, User} from "../../../../../../database-models";
import {ApiService} from "../../../services/api.service";
import { NzModalService} from "ng-zorro-antd/modal";
import {FormBuilder} from "@angular/forms";
import {NzMessageService} from "ng-zorro-antd/message";
import {SuperadminApiService} from '../../../super-admin/services/superadmin-api.service';

@Component({
  selector: 'app-session-list',
  templateUrl: './session-list.component.html',
  styleUrls: ['./session-list.component.css']
})
export class SessionListComponent implements OnInit {

  @Input() public userId: string;

  @Input()
  public api: ApiService | SuperadminApiService;


  public sessions: Session[];

  public isLoading = false;

  constructor(
    injector: Injector,
      public formBuilder: FormBuilder,
      private message: NzMessageService,
      private modalService: NzModalService,
  ) {
    if (!this.api) {
      this.api = injector.get(ApiService);
    }
  }

  ngOnInit(): void {
    if (this.userId !== 'new') {
      this.getSessionByUser();
    }
  }

  public getSessionByUser(): void {
    this.isLoading = true;
    this.api.getSessionsByUser(this.userId).subscribe(sessions => {
      this.sessions = sessions;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  public deleteSession(id: string, index: number): void {
    this.modalService.warning({
      nzTitle: '<i>Aktives Gerät entfernen?</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie dieses aktive Gerät entfernen möchten? Dadurch erfolgt automatisch eine Abmeldung von diesem aktiven Gerät.',
      nzOnOk: () => this.delete(id, index),
      nzCancelText: 'Abbrechen'
    });
  }

  public delete(id: string, index: number): void {
    this.isLoading = true;
    this.api.deleteSession(id).subscribe(value => {
      this.sessions.splice(index, 1);
      this.message.info('Das Gerät wurde entfernt')
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

}
