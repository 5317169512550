import {Component, Input, OnInit} from '@angular/core';
import {Ticket} from '../../../../../../../database-models';

@Component({
  selector: 'app-ticket-issuer',
  templateUrl: './ticket-issuer.component.html',
  styleUrls: ['./ticket-issuer.component.css']
})
export class TicketIssuerComponent implements OnInit {

  @Input()
  public ticket: Ticket;

  constructor() { }

  ngOnInit(): void {
  }

}
