import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TimeTrackingMonth} from '../../../../../../../database-models';

import moment from 'moment';
import {ApiService} from '../../../../services/api.service';
import {MessageService} from '../../../services/message.service';

@Component({
  selector: 'app-timetracking-lock-month',
  templateUrl: './timetracking-lock-month.component.html',
  styleUrls: ['./timetracking-lock-month.component.css']
})
export class TimetrackingLockMonthComponent implements OnInit {

  @Input()
  public timeTrackingMonth: TimeTrackingMonth;

  @Output()
  public monthLocked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private api: ApiService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
  }

  public lock(): void {
    this.api.lockTimetrackingMonth(this.timeTrackingMonth.id).subscribe(res => {
      this.messageService.success('Der Monat wurde abgeschlossen');
      this.monthLocked.emit(true);
    }, error => {
      this.messageService.error('Es ist ein Fehler aufgetreten!');
    });
  }


  protected readonly moment = moment;
}
