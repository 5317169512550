import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {LoginCredentials, SuperAdmin, User} from '../../../../../database-models';
import {Router} from '@angular/router';

@Injectable()
export class SuperadminAuthenticationService {
  private currentSuperAdminSubject: BehaviorSubject<SuperAdmin>;
  public currentSuperAdmin: Observable<SuperAdmin>;

  private apiURL = environment.apiURL;

  constructor(private http: HttpClient, private router: Router) {
    this.currentSuperAdminSubject = new BehaviorSubject<SuperAdmin>(JSON.parse(localStorage.getItem('superadmin')));
    this.currentSuperAdmin = this.currentSuperAdminSubject.asObservable();
  }

  public get currentSuperAdminValue(): SuperAdmin {
    return this.currentSuperAdminSubject.value;
  }

  public login(data: LoginCredentials) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return this.http.post(this.apiURL + '/super-admin-login/', data, {headers}).pipe(map((user: SuperAdmin) => {
      if (user && user.api_key) {
        localStorage.setItem('superadmin', JSON.stringify(user));
        this.currentSuperAdminSubject.next(user);
      }
      return user;
    }));
  }


  public logout(): void {
    localStorage.removeItem('superadmin');
    this.currentSuperAdminSubject.next(null);
    this.router.navigate(['login']);
  }
}
