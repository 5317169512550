<nz-card *ngIf="ticket?.creator">
  <nz-list [nzItemLayout]="'horizontal'">
    <nz-list-item class="border-bottom-0">
      <nz-list-item-meta
        [nzTitle]="ticket.creator?.name"
        [nzAvatar]=""
        [nzDescription]="'Melder / Melderin'">
      </nz-list-item-meta>
    </nz-list-item>
  </nz-list>

</nz-card>
