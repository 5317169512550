import {Component, inject, Input, OnInit, Optional} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {ContactFull, OptionalFilters, PagingParameters} from '../../../../../../../Shared/lexoffice-client-js-main/src';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {Contact, ContactPerson, Email, Phone} from '../../../../../../../database-models';
import {EditContactComponent} from '../../edit-contact/edit-contact.component';
import {EditContactV2Component} from '../../edit-contact-v2/edit-contact-v2.component';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';

export interface ModalData {
	mode: 'import' | 'select'
}

@Component({
  selector: 'app-lexoffice-contact-list',
  templateUrl: './lexoffice-contact-list.component.html',
  styleUrls: ['./lexoffice-contact-list.component.css']
})
export class LexofficeContactListComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  @Input()
  public mode: 'import' | 'select';

  public loading: boolean = false;
  public lexContacts: ContactFull[];
  public lexResult: any & Partial<PagingParameters>;

  public alreadyImportedIds: string[] = [];

  public pagingParameters: PagingParameters = {
    page: 0,
    size: 10,
  };

  public search: string = '';

  constructor(
    private api: ApiService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    @Optional() private modal: NzModalRef
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.mode ??= this.data.mode;
  }

  public getContacts(params?: NzTableQueryParams): void {
    this.loading = true;



    let pagingParameters = this.pagingParameters;
    if (params) {
      const {pageSize, pageIndex, sort, filter} = params;
      pagingParameters.page = pageIndex - 1;
    }

    if (this.search?.length > 3) {
      let filters: OptionalFilters = {
        name: this.search,
      }

      pagingParameters = {...filters, ...pagingParameters};
    }

    this.api.getLexofficeContacts(pagingParameters).subscribe((result) => {

        this.alreadyImportedIds = result.alreadyImportedIds;
        console.log(result);
        this.lexContacts = result.content;
        this.lexResult = result;
        this.loading = false;

    }, error => {
      this.notification.create(
        'error',
        'Fehler',
        error.error
      );
      this.loading = false;
    });
  }

  timeout = null;

  public triggerSearch(skipTimeout: boolean = false): void {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (this.search?.length >= 3 || this.search?.length <= 0) {
        this.pagingParameters.page = 0;
        this.getContacts();
      }

    }, skipTimeout ? 0 : 1000);
  }

  public select(lexofficeContact: ContactFull): void {
    switch (this.mode) {
      case 'import':
        this.editContact(lexofficeContact);
        break;
      case 'select':
        this.modal.close(lexofficeContact);
        break;
    }
  }

  public editContact(lexofficeContact: ContactFull): void {

   let contact: Contact = {
     lexofficeId: lexofficeContact.id
   } as Contact

    this.api.setContact(contact).subscribe((contact: Contact) => {
      this.notification.create(
        'success',
        'Kontakt wurde importiert',
        'Der Kontakt wurde erfolgreich importiert.'
      );

        this.modal.close();

    }, error => {
      this.notification.create(
        'error',
        'Speichern fehlgeschlagen!',
        error.error
      );
      this.modal.close();
    });
  }

}
