<!--NOTE LIST && PREVIEW-->
<ng-container *ngIf="!selectedNote">
    <nz-spin [nzSpinning]="isLoading">
        <div nz-row>

            <!--NOTE TABLE-->
            <div nz-col nzSpan="10" class="m-t-10 noteList">

                <div style="display: flex">
                    <input [(ngModel)]="searchInput" (ngModelChange)="searchFilter()" style="width: 60%"
                           nzSize="small"
                           class="m-b-10 m-r-15"
                           placeholder="Notizen durchsuchen..." nz-input/>
                    <button *ngIf="!selectedNote" nz-button style="margin-top: 2px;" nzSize="small"
                            nzType="primary"
                            (click)="selecteNote()">
                        <i nz-icon nzType="plus-circle" nzTheme="outline"></i> Erstellen
                    </button>
                </div>

                <nz-table *ngIf="objectnotes?.length > 0" #noteTable [nzData]="objectnotes"
                          nzPaginationType="small" nzSize="small" [nzShowPagination]="false">
                    <tbody>
                    <tr *ngFor="let item of noteTable.data" (dblclick)="selecteNote(item)"
                        [ngClass]="{ 'row-active' : preSelectedNote?.id === item?.id}"
                        (click)="preSelecte(item)" style="cursor: pointer">
                        <td style="width: 5%;">
                            <nz-avatar
                                    [nzSrc]="isCurrentUser(item.user?.id) ? (currentUser.avatarPath ? backendURL + '/' + currentUser.avatarPath : '') : (item.user?.avatarPath ? backendURL + '/' + item.user?.avatarPath : '')"
                                    [nzIcon]="isCurrentUser(item.user?.id) ? (currentUser.avatarPath ? backendURL + '/' + currentUser.avatarPath : 'user') : 'user'"
                            ></nz-avatar>
                        </td>
                        <td>
                            <h6 class="m-0">
                                <i nz-icon class="m-r-5 color-red" *ngIf="item.managerOnly"
                                   [nz-tooltip]="'Nur sichtbar für Manager und Admin'"
                                   [nzType]="'lock'"
                                   nzTheme="outline">
                                </i>{{item.titel ? item.titel : '- kein Titel -'}}</h6>
                            <small><p class="m-0 p-0">{{item.user?.name}}</p></small>
                            <small class="m-0 p-0"
                                   style="font-size: x-small;">{{item.createdAt | date: 'dd.MM.yyyy'}}</small>
                        </td>
                        <td style="width: 15%;">
                            <nz-tag class="m-0" *ngIf="item.files.length > 0" [nzColor]="'geekblue'">
                                <i nz-icon
                                   [nzType]="'camera'"
                                   nzTheme="outline">
                                </i> {{item?.files?.length}}
                            </nz-tag>
                        </td>
                        <!--                        <td style="width: 15%;">
                                                    <nz-tag class="m-0" *ngIf="item.managerOnly" [nzColor]="'red'">
                                                        <i nz-icon
                                                           [nz-tooltip]="item?.managerOnly ? 'Nur sichtbar für Manager und Admin' : 'Für alle sichtbar'"
                                                           [nzType]="item.managerOnly ? 'lock' : 'unlock'"
                                                           nzTheme="outline">
                                                        </i>
                                                    </nz-tag>
                                                </td>-->
                    </tr>
                    </tbody>
                </nz-table>

                <ng-container *ngIf="objectnotes?.length <= 0 && !selectedNote">
                    <p class="m-10 m-t-15">{{object ? 'Für dieses Objekt liegen keine Notizen vor.' : 'Für diesen Raum liegen keine Notizen vor.'}}</p>
                </ng-container>
            </div>

            <!--PREVIEW-->
            <div nz-col nzSpan="14" class="m-t-15 preview">
                <small style="text-align: center; display: block; margin: 0 auto;">
                    <p>{{preSelectedNote?.createdAt | date: 'dd.MM.yyyy HH:mm'}}</p>
                </small>

                <!--PREVIEW BTNS-->
                <div style="display: flex" nz-row>
                    <div nz-col nzSpan="12">
                        <h4 style="margin-top: 2px;" *ngIf="preSelectedNote?.titel" nz-tooltip="Titel der Notiz">
                            {{preSelectedNote?.titel}}
                        </h4>
                    </div>

                    <div nz-col nzSpan="12">
                        <button *ngIf="preSelectedNote" nz-button nzSize="small" class="pull-right m-r-5"
                                nz-tooltip="Notiz löschen"
                                nzType="link" nzDanger (click)="deleteObjectNote(preSelectedNote.id)">
                            <i nz-icon nzType="delete" nzTheme="outline"></i>
                        </button>

                        <button *ngIf="preSelectedNote" nz-button nzSize="small" class="pull-right m-r-5"
                                nz-tooltip="Notiz bearbeiten"
                                nzType="link" (click)="selecteNote(preSelectedNote)">
                            <i nz-icon nzType="edit" nzTheme="outline"></i> Bearbeiten
                        </button>
                    </div>
                </div>
                <p [innerHTML]="preSelectedNote?.text"></p>

                <div *ngIf="preSelectedNote?.files?.length > 0">
                    <app-lightbox [images]="preSelectedNote?.files" [nzSpan]="4"
                        (edit)="editFile($event.file)" 
                        (delete)="deleteImage($event.file.id, $event.index, preSelectedNote)">
                    </app-lightbox>
                </div>
            </div>
        </div>
    </nz-spin>
</ng-container>


<!--EDIT NOTE-->
<ng-container *ngIf="selectedNote">
    <div nz-row>
        <div nz-col nzSpan="2">
            <button nz-button [disabled]="isTouched" class="m-t-10" nzType="default" nzSize="default"
                    (click)="selectedNote = null"><i nz-icon
                                                     nzType="double-left"
                                                     nzTheme="outline"
                                                     class="m-r-5"></i>
            </button>
        </div>
        <div nz-col nzSpan="22">
            <nz-form-item class="m-t-10">
                <nz-form-label>Titel</nz-form-label>
                <nz-form-control>
                    <input class="w-100" nzSize="default" placeholder="Titel der Notiz" nz-input
                           (ngModelChange)="noteIsTouched()"
                           [(ngModel)]="selectedNote.titel"/>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>

    <quill-editor [(ngModel)]="selectedNote.text" (ngModelChange)="noteIsTouched()" class="w-100"
                  [styles]="{height: '450px'}" placeholder=""></quill-editor>

    <nz-collapse>
        <nz-collapse-panel [nzActive]="selectedNote?.files?.length > 0" [nzDisabled]="!selectedNote?.files?.length > 0"
                           [nzHeader]="imgHeader"
                           [nzExtra]="extraTpl">
            <ng-template #imgHeader>
                Bilder
                <nz-badge class="m-l-10" [nzStyle]="{ backgroundColor: '#f58b0a' }"
                          *ngIf="selectedNote?.files?.length > 0"
                          [nzCount]="selectedNote?.files?.length"></nz-badge>
            </ng-template>
            <ng-template #extraTpl>
                <nz-upload [nzMultiple]="true" [(nzFileList)]="imageUploads"
                           [nzBeforeUpload]="beforeUpload" [nzSize]="10000"
                           [nzFileType]="'image/png,image/jpeg,image/jpg,image/gif,image/bmp'">
                    <a><i nz-icon nzType="upload" class="m-r-10"></i>Bilder hochladen</a>
                </nz-upload>
            </ng-template>
            <app-lightbox [images]="selectedNote.files" [nzSpan]="3" [maxHeight]="100"
                (edit)="editFile($event.file)" 
                (delete)="deleteImage($event.file.id, $event.index, selectedNote)">
            </app-lightbox>
        </nz-collapse-panel>
    </nz-collapse>

    <button nz-button class="m-t-20 pull-right" nzSize="small" nzType="primary" [disabled]="isLoading"
            (click)="updateObjectNote()">
        Speichern
    </button>

    <button nz-button [nzDanger]="selectedNote.managerOnly" nzSize="small"
            [nz-tooltip]="selectedNote.managerOnly ? 'Nur sichtbar für Manager und Admin' : 'Für alle sichtbar'"
            [nzType]="selectedNote.managerOnly ? 'primary' : 'default'" *appIsManager (click)="toggleManagerOnly()"
            class=" m-t-20 m-r-10 pull-right"
            [(ngModel)]="selectedNote.managerOnly">
        <i *ngIf="selectedNote.managerOnly" nz-icon nzType="lock" nzTheme="outline"></i>
        <i *ngIf="!selectedNote.managerOnly" nz-icon nzType="unlock" nzTheme="outline"></i>
    </button>


    <button [disabled]="!isTouched" nz-button class="m-t-20" nzSize="small" nzType="default" (click)="close()">
        Abbrechen
    </button>
</ng-container>
