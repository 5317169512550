
    <h3>{{appuser?.name}}</h3>
    <nz-divider></nz-divider>
    <nz-table [nzData]="['']" nzShowPagination="false" >
        <thead>
        <tr>
            <th>Objekt</th>
            <th>Adresse</th>
            <th>Beitritt</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let obj of appuser.objects">
            <td>{{obj.name}} {{' (' + capitalize(obj.Appuser_Users_Objects.appuser_type) + ')'}}</td>
            <td>{{obj.address_line1 + ' ' + obj.zip + ' ' + obj.city}}</td>
            <td>{{appuser.createdAt | date: 'dd.MM.yyyy'}}</td>
            <td style="width: 120px">
                <ng-container *appIsManager>
                    <button *ngIf="!obj.Appuser_Users_Objects.blocked"
                            (click)="editAppuser(obj)"
                            class="m-r-5"
                            [nz-tooltip]="'Bearbeiten'"
                            nz-button nzSize="small" nzShape="circle"><i nz-icon nzType="edit"></i>
                    </button><button *ngIf="!obj.Appuser_Users_Objects.blocked"
                            (click)="setBlockUser(obj.Appuser_Users_Objects, true)"
                            [nz-tooltip]="'Benutzer blockieren'"
                            nz-button nzDanger nzSize="small" nzShape="circle"><i nz-icon nzType="user-delete"></i>
                    </button>
                    <button *ngIf="obj.Appuser_Users_Objects.blocked"
                            (click)="setBlockUser(obj.Appuser_Users_Objects, false)"
                            [nz-tooltip]="'Benutzer wieder freischalten'" nz-button nzSize="small" nzType="default"
                            nzShape="circle"><i nz-icon nzType="user-add"></i>
                    </button>
                </ng-container>
            </td>
        </tr>
        </tbody>
    </nz-table>
