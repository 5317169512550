<div class="overview-container">
  <div *ngIf="activeTicket">
    <strong class="display-block">Titel</strong>
    {{ activeTicket.name }}
  </div>
  <div *ngIf="activeTicket" class="m-t-10">
    <strong class="display-block">Beschreibung</strong>
    <div [innerHTML]="activeTicket.description"></div>
  </div>
  <div class="m-t-10">
    <strong class="display-block">Ticket-Nr.</strong>
    {{ activeTicket.ticketNumber }}
  </div>
  <ng-container *ngIf="activeTicket.worklogs">
    <div class="m-t-10">
      <strong class="display-block" *ngIf="activeTicket.worklogs.length > 0">Verknüpftes
        Arbeitsprotokoll</strong>
      <p class="m-0 p-0" *ngFor="let wl of activeTicket.worklogs">
        <a class="m-r-5" nz-tooltip="Arbeitsprotokoll öffnen" nzTooltipPlacement="left" (click)="editWorklog(wl)">Arbeitsprotokoll</a>
        <small>{{ wl.createdAt | date:'dd.MM.y HH:mm' }}</small>
        <a class="m-l-5" nz-tooltip="PDF runterladen" nzTooltipPlacement="right"
           (click)="downloadPDF(wl, activeTicket)">
          <i nz-icon nzType="file-pdf" nzTheme="outline"></i>
        </a>
      </p>
    </div>
  </ng-container>
  <div *ngIf="activeTicket.object" class="m-t-10">
    <strong class="display-block">Objekt</strong>
    <a [routerLink]="'/objects/' + activeTicket.object.id">{{ activeTicket.object?.name }}</a>
  </div>
  <div *ngIf="activeTicket.customer" class="m-t-10">
    <strong class="display-block">Kunde</strong>
    {{
      activeTicket.customer?.companyname ||
      activeTicket.customer.firstname + ' '
      + activeTicket.customer.firstname
    }}
  </div>
  <div *ngIf="activeTicket.user" class="m-t-10">
    <strong class="display-block">Bearbeitender Manager</strong>
    <nz-avatar nzSize="small" class="m-r-5"
               [nzSrc]="activeTicket.user.avatarPath ? backendURL + '/' + activeTicket.user.avatarPath : ''"
               [nzIcon]="activeTicket.user.avatarPath ? backendURL + '/' + activeTicket.user.avatarPath : 'user'"
               style="vertical-align: middle;"
    ></nz-avatar>
    {{ activeTicket.user.name }}
  </div>
  <div *ngIf="activeTicket.employee" class="m-t-10">
    <strong class="display-block">Zugewiesener Mitarbeiter</strong>
    <nz-avatar nzSize="small" class="m-r-5"
               [nzSrc]="activeTicket.employee.avatarPath ? backendURL + '/' + activeTicket.employee.avatarPath : ''"
               [nzIcon]="activeTicket.employee.avatarPath ? backendURL + '/' + activeTicket.employee.avatarPath : 'user'"
               style="vertical-align: middle;"
    ></nz-avatar>
    {{ activeTicket.employee.name }}
  </div>
  <div *ngIf="activeTicket.createdAt" class="m-t-10">
    <strong class="display-block">Erstellt</strong>
    {{ activeTicket.createdAt | date: 'dd.MM.yyyy HH:mm' }} Uhr
  </div>
  <div *ngIf="activeTicket.updatedAt" class="m-t-10">
    <strong class="display-block">Zuletzt bearbeitet</strong>
    {{ activeTicket.updatedAt | date: 'dd.MM.yyyy HH:mm' }} Uhr
  </div>
  <div *ngIf="activeTicket.doUntil" [ngClass]="{'ticket-doUntil-alert' : isOverdue(activeTicket)}"
       class="m-t-10">
    <strong class="display-block">Zu erledigen am oder bis</strong>
    {{ activeTicket.doUntil | date: 'dd.MM.yyyy HH:mm' }}
  </div>
  <div class="m-t-10">
    <app-entity-tag-selector [readOnly]="!embeddedMode" nzMaxTagCount="1" width="100%" [entity]="activeTicket"
                              [entityType]="'ticket'"></app-entity-tag-selector>
  </div>
  <div *ngIf="activeTicket.done" class="m-t-10">
    <i class="text-success" nz-icon nzType="check-circle" nzTheme="fill"></i> Alle Arbeiten wurden
    erledigt
  </div>
  <div *ngIf="activeTicket.billed" class="m-t-10">
    <i class="text-info" nz-icon nzType="euro-circle" nzTheme="fill"></i> Abgerechnet
  </div>
  <div *ngIf="activeTicket.private" class="m-t-10">
    <strong class="text-primary m-r-5">P</strong> Privates Ticket
  </div>


</div>

