import {ComponentFactoryResolver, Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {AppversionLowerExceptionComponent} from '../template/appversion-lower-exception/appversion-lower-exception.component';

@Directive({
  selector: '[minAppversion]'
})
export class MinAppversionDirective implements OnInit {

  @Input()
  public minAppversion: number;

  constructor(
    private authService: AuthenticationService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {

  }

  ngOnInit() {
    if (this.authService.currentUserValue.renewinterval.appversion >= this.minAppversion) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.createComponent(AppversionLowerExceptionComponent);
    }

  }

}
