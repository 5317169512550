import {AfterViewInit, Component, inject, Input, OnInit, Optional} from '@angular/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {ApiService} from '../../../../services/api.service';
import {Object} from '../../../../../../../database-models';
import * as L from 'leaflet';
import {LatLngExpression, marker, Marker} from 'leaflet';
import {position} from 'html2canvas/dist/types/css/property-descriptors/position';
import {MapMarker} from '../../maps/map-marker';
import {AuthenticationService} from '../../../services/authentication.service';
import {HttpClient} from '@angular/common/http';

export interface ModalData {
	object: Object;
}

@Component({
  selector: 'app-edit-object-meta',
  templateUrl: './edit-object-meta.component.html',
  styleUrls: ['./edit-object-meta.component.scss'],
})
export class EditObjectMetaComponent implements OnInit, AfterViewInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});

  public object: Object;

  public title = 'Objekt bearbeiten';

  public objectForm: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private auth: AuthenticationService,
    public api: ApiService,
    private message: NzMessageService,
    private modalService: NzModalService,
    private mapMarker: MapMarker,
    @Optional() private modal: NzModalRef
  ) {
  }

  public ngOnInit() {
    this.object ??= this.data.object;
    if (!this.object) {
      this.title = 'Neues Objekt anlegen';
      this.object = {} as Object;
    }
    this.objectForm = this.formBuilder.group({
      name: [this.object.name, [Validators.required]],
      address_line1: [this.object.address_line1, [Validators.required]],
      zip: [this.object.zip, [Validators.required]],
      city: [this.object.city, [Validators.required]],
      einheiten: [this.object.einheiten],
      schluessel_anzahl: [this.object.schluessel_anzahl],
      tiefgaragen: [this.object.tiefgaragen],
      objectNumber: [this.object.objectNumber],
      lat: [this.object.lat],
      long: [this.object.long],
    });

  }

  public updateObject(): void {
    const sendData = this.objectForm.getRawValue();
    this.object.name = sendData.name;
    this.object.address_line1 = sendData.address_line1;
    this.object.zip = sendData.zip;
    this.object.city = sendData.city;
    this.object.einheiten = sendData.einheiten;
    this.object.schluessel_anzahl = sendData.schluessel_anzahl;
    this.object.tiefgaragen = sendData.tiefgaragen;
    this.object.objectNumber = sendData.objectNumber;
    this.object.lat = sendData.lat;
    this.object.long = sendData.long;

    this.api.updateObject(this.object).subscribe((object: Object) => {
      this.message.success('Erfolgreich gespeichert');
      this.modal.close(object);
    });
  }


  public cancel(): void {
    if (this.objectForm.touched) {
      this.modalService.confirm({
        nzTitle: '<i>Bearbeiten abbrechen</i>',
        nzContent: 'Sind Sie sicher, dass Sie abbrechen möchten? Alle Änderungen gehen verloren.',
        nzOnOk: () => {
          this.modal.close();
        }
      });
    } else {
      this.modal.close();
    }
  }


  public map;
  public markers;

  private initMap(center: LatLngExpression = [51.1020, 10.2832], zoom: number = 4): void {


    this.map = L.map('mapedit', {
      center,
      zoom
    });
    this.markers = new L.LayerGroup().addTo(this.map);

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
    });

    tiles.addTo(this.map);


    try {
      if (this.object.lat && this.object.long) {
        var marker = L.marker([this.object.lat, this.object.long], {
          icon: this.mapMarker.object
        }).addTo(this.markers);
      }

      // @ts-ignore
      var group = new L.featureGroup([marker]);

      this.map.fitBounds(group.getBounds());
    } catch (e) {

    }

    this.map.on('click', (map) => {
      console.log(map);
      this.markers.clearLayers();
      // @ts-ignore
      this.marker = new L.marker(map.latlng, {
        icon: this.mapMarker.object
      }).addTo(this.markers);
      this.objectForm.get('lat').setValue(map.latlng.lat);
      this.objectForm.get('long').setValue(map.latlng.lng);
    });
  }

  public resetMap(): void {
    this.objectForm.get('lat').setValue(null);
    this.objectForm.get('long').setValue(null);
    this.markers.clearLayers();
  }

  async ngAfterViewInit(): Promise<void> {
    try {
      if (!this.objectForm.get('lat').value) {

        let companyPos: LatLngExpression = [this.auth.currentUserValue?.company?.lat, this.auth.currentUserValue?.company?.long];
        if (companyPos[0] && companyPos[1]) {
          this.initMap(companyPos, 12);
        } else {
          let position: GeolocationPosition = await this.getCurrentLocation();
          if (position?.coords) {
            this.initMap([position.coords.latitude, position.coords.longitude], 10);
          } else {
            this.initMap();
          }
        }
      } else {
        this.initMap();
      }
    } catch (e) {
      this.initMap();
    }


  }

  getCurrentLocation(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(position => {
        resolve(position);
      }, positionError => {
        reject(positionError);
      });
    });
  }


  public LatLongUpdated(): void {
    this.markers.clearLayers();
    // @ts-ignore
    this.marker = new L.marker([this.objectForm.get('lat').value, this.objectForm.get('long').value], {
      icon: this.mapMarker.object
    }).addTo(this.markers);
    // @ts-ignore
    var group = new L.featureGroup([this.marker]);

    this.map.fitBounds(group.getBounds());
  }



  public findAddressGeoDisabled = false;
  public findAddressGeo(): void {

    this.findAddressGeoDisabled = true;
    setTimeout(() => {
      this.findAddressGeoDisabled = false;
    }, 5000);

    let formData = this.objectForm.getRawValue();
    let address = formData.address_line1 + '+' +  formData.zip + '+' + formData.city;
    this.http.get('https://geocode.maps.co/search?q=' + address + '&&api_key=6717cdefb6473915700653egscf4b54').subscribe((result: any) => {
      console.log(result);

      let lat: number;
      let lon: number;

      if (result.length > 0) {
        lat = result[0].lat;
        lon = result[0].lon;
        this.objectForm.get('lat').setValue(lat);
        this.objectForm.get('long').setValue(lon);
        this.markers.clearLayers();
        // @ts-ignore
        this.marker = new L.marker([lat, lon], {
          icon: this.mapMarker.object
        }).addTo(this.markers);
        // @ts-ignore
        var group = new L.featureGroup([this.marker]);

        this.map.fitBounds(group.getBounds());
      } else {
        this.message.error('Es konnten keine Koordinaten zu der angegebenen Adresse ermittelt werden.')
      }

    });
   //
  }

}
