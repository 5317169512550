import {Component, ElementRef, EventEmitter, HostListener, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Contact, Customer, Object, State, Task, Ticket} from '../../../../../../../database-models';
import {ApiService} from '../../../../services/api.service';
import {EditTaskComponent} from '../../task/edit-task/edit-task.component';
import {NZ_MODAL_DATA, NzModalService} from 'ng-zorro-antd/modal';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {EditCustomerComponent} from '../../customer/edit-customer/edit-customer.component';
import {HttpParams} from '@angular/common/http';
import {NzMessageService} from 'ng-zorro-antd/message';

export interface ModalData {
  hideLinkTaskBtn: boolean;
  hideLinkTicketBtn: boolean;
  showInfotxt: boolean;
  showSearchFilter: boolean;
  searchPlaceholder: string;
  searchCategories?: string;
}

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  @ViewChild('resultDiv', {read: ElementRef}) resultDiv?: ElementRef;
  @ViewChild('searchInput', {read: ElementRef}) searchInput?: ElementRef;

  public searchResult: {
    tasks: Task[],
    tickets: Ticket[]
  };

  @Input() public hideLinkTaskBtn: boolean = false;
  @Input() public hideLinkTicketBtn: boolean = false;
  @Input() public showInfotxt: boolean = false;
  @Input() public showSearchFilter: boolean = false;
  @Input() public searchPlaceholder: string = '';

  /*Filtert vorhandene Tasks*/
  @Input() public filterTask: boolean = false;
  @Input() public disabledUUIDS: string[];


  @Input() public hideClose: boolean = true;

  @Input() public searchCategories: string = '';
  @Input() public infoTooltip: string = '';

  @Output() public linkTaskToWorklog: EventEmitter<Task> = new EventEmitter<Task>();
  @Output() public linkTicketToWorklog: EventEmitter<Ticket> = new EventEmitter<Ticket>();

  @Output() public showsearchChange = new EventEmitter<boolean>();

  private date: Date = new Date();

  public filter = {
    search: null,
    searchCategories: null,
    reference: [],
    user: null
  };

  public showResults: boolean = false;
  public isResultVisible: boolean = false;
  public isLoading: boolean = false;
  public isSearchEmpty: boolean = false;

  constructor(
    public api: ApiService,
    public route: ActivatedRoute,
    public authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    this.hideLinkTaskBtn ??= this.data.hideLinkTaskBtn;
    this.hideLinkTicketBtn ??= this.data.hideLinkTicketBtn;
    this.showInfotxt ??= this.data.showInfotxt;
    this.showSearchFilter ??= this.data.showSearchFilter;
    this.searchPlaceholder ??= this.data.searchPlaceholder;
    this.searchCategories ??= this.data.searchCategories;
  }

  public keyEnter(e: KeyboardEvent): void {
    setTimeout(() => {
      const trimmedSearch = this.filter?.search ? this.filter.search.trim() : '';
      if ((e.key === 'Enter' || e.key === 'Backspace') && trimmedSearch !== '') {
        setTimeout(() => {
          this.globalSearchFilter();
          this.isResultVisible = true;
          this.showResults = true;
        }, 1);
      }
    }, 1);
  }

  public globalSearchFilter(): void {
    this.isLoading = true;
    let httpParams = new HttpParams();

    if (this.searchCategories) {
      httpParams = httpParams.append('searchCategories', this.searchCategories);
    }

    if (this.filter.search) {
      httpParams = httpParams.append('search', this.filter.search);
    }

    this.api.globalSearch(httpParams).subscribe(result => {
      this.searchResult = result;

      if (this.searchCategories === 'TASKS' && this.filterTask) {
        this.searchResult.tasks = this.searchResult?.tasks.filter(task => !this.disabledUUIDS.includes(task.id));
      }

      this.isLoading = false;
      if (!this.checkResult(this.searchResult)) {
        this.showResults = false;
      }
    });
  }

  public taskWorklogLink(task: Task): void {
    this.linkTaskToWorklog.emit(task);
  }

  public ticketWorklogLink(ticket: Ticket): void {
    this.linkTicketToWorklog.emit(ticket);
  }

  public isOverdue(ticket: Ticket, state?: State): boolean {
    if (!ticket.doUntil || ticket.done || state?.isClosed) {
      return false;
    } else {
      const convDate = new Date(ticket.doUntil);
      return convDate < this.date;
    }
  }

  public onResultClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.resultDiv && !this.resultDiv?.nativeElement.contains(event.target)) {
      this.isResultVisible = false;
    }
  }

  public checkResult(searchResult: any): boolean {
    const result = (searchResult && (searchResult.tasks?.length > 0 ||
        searchResult.tickets?.length > 0)
    );
    this.isSearchEmpty = !result;
    return result;
  }

  public searchFocus(): void {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 100);
  }


  public hideSearch(value: boolean): void {
    this.showsearchChange.emit(value);
  }
}
