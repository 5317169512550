<nz-spin [nzSpinning]="isLoading">
    <nz-table #wasteplanTable [nzData]="wastePlans" nzPaginationType="small" nzSize="small" [nzPageSize]="10">
        <thead>
        <tr>
            <th>Abholdatum</th>
            <th>Title</th>
            <th>Rausstellen</th>
            <th>Reinstellen</th>
            <th style="width: 90px"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let wp of wasteplanTable.data; index as i">
            <td>
                <strong class="p-0 m-0"> {{wp.time | date: 'dd.MM.yyyy'}}</strong>
            </td>
            <td>
                <p class="p-0 m-0">
                    {{wp.title}} <i *ngIf="wp.description" nz-icon nzType="info-circle" nzTheme="outline" class="m-l-5"
                                    nz-tooltip
                                    [nzTooltipTitle]="wp.description"></i>
                </p>
            </td>
            <td>
                <nz-tag *ngIf="wp.outTime" class="m-l-5" [nzColor]="'volcano'"><i nz-icon nzType="logout"
                                                                                  nzTheme="outline"
                                                                                  class="p-r-5"></i>{{wp.outTime | date: 'dd.MM.yyyy'}}
                </nz-tag>

                <nz-tag *ngIf="wp.task_out" [nzColor]="'orange'">
                    <i nz-tooltip nzTooltipTitle="Aufgabe wurde erstellt" nz-icon nzType="schedule" nzTheme="outline"></i>
                </nz-tag>
            </td>
            <td>
                <nz-tag *ngIf="wp.inTime" [nzColor]="'green'"><i nz-icon nzType="login" nzTheme="outline"
                                                                 class="p-r-5"></i>{{wp.inTime | date: 'dd.MM.yyyy'}}
                </nz-tag>

              <nz-tag *ngIf="wp.task_in" [nzColor]="'orange'">
                <i nz-tooltip nzTooltipTitle="Aufgabe wurde erstellt" nz-icon nzType="schedule" nzTheme="outline"></i>
              </nz-tag>
            </td>
            <td>
                <button nzSize="small" class="m-r-10" nz-button nzType="default" nzShape="circle"
                        (click)="editWasteplan(wp); $event.stopPropagation()"><i nz-icon nzType="edit"></i>
                </button>

                <button nzSize="small" nz-button nzType="default" nzShape="circle" nzDanger [disabled]="isLoading"
                        (click)="deleteWasteplan(wp.id, i); $event.stopPropagation()"><i nz-icon nzType="delete"></i>
                </button>
            </td>
        </tr>
        </tbody>
    </nz-table>
</nz-spin>

<button *appIsManager class="m-l-10 m-t-20" nz-button nzSize="small" nzType="primary" (click)="editWasteplan()" [disabled]="isLoading">
    <i nz-icon nzType="plus-circle"></i> Hinzufügen
</button>

<button *appIsManager class="m-l-10 m-t-20" nz-button nzSize="small" nzType="default" (click)="import()" [disabled]="isLoading">
    <i nz-icon nzType="upload"></i> Importieren
</button>

<button *appIsManager class="m-l-10 m-t-20" nz-button nzSize="small" nzDanger nzType="link" (click)="deleteWasteplansByObjectId()" [disabled]="isLoading">
    <i nz-icon nzType="delete"></i> Alle Termine löschen
</button>

<a class="pull-right m-t-20" nz-button nzSize="small" nzType="default" target="_blank"
        href="https://support.netbrick.de/hc/hausmeisterapp/articles/1730105026-integration-neuer-abfuhrplane-durch-importieren-nur-weboberflache">
  <i nz-icon nzType="question" class="m-r-5"></i> Hilfe
</a>
