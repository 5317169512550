<nz-spin [nzSpinning]="loading">


  <div *ngIf="intervals">
    <nz-table #userTable [nzData]="users">
      <thead>
      <tr>
        <th>Aktiv</th>
        <th>Name</th>
        <th>Abrechnung</th>
        <th>Betrag</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let user of userTable.data" [ngClass]="{ 'inactive' : !user.isActive }">
        <td>
          <nz-switch [(ngModel)]="user.isActive" nzSize="small" [nzDisabled]="user.id === auth.currentUserValue.id"
                     (ngModelChange)="validate()"></nz-switch>
        </td>
        <td>
          {{ user.name }}
        </td>
        <td>
          <nz-select [(ngModel)]="user.renewinterval" class="w-100" [compareWith]="compareById"
                     [nzDisabled]="!user.isActive" (ngModelChange)="validate()">
            <nz-option *ngFor="let interval of intervals" [nzValue]="interval" [nzLabel]="interval.name"></nz-option>
          </nz-select>
        </td>
        <td>
          {{ user.renewinterval?.netto }} €
        </td>
      </tr>
      </tbody>
    </nz-table>
    <small>Alle Preise zzgl. MwSt.</small><br>
    <small>Dies ist eine Momentaufnahme Ihrer Benutzer. Beim Hinzufügen oder Entfernen von Benutzern ändert sich
      die Kostenaufstellung.</small>

  </div>
</nz-spin>
