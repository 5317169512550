
<nz-spin [nzSpinning]="isLoading">
    <ng-container>
        <div style="max-height: 450px; overflow: auto">
            <nz-table nzSize="small" #sharedWorkLogsTable [nzData]="sharedWorklog">
                <thead>
                <tr>
                    <th>Erstellt am:</th>
                    <th style="width: 120px"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of sharedWorkLogsTable.data">
                    <td>{{item.createdAt | date:'dd.MM.y H:mm'}}</td>
                    <td>
                        <button (click)="deleteSharedWorkLogLink(item)" nzSize="small" class="m-l-10" nz-button nzType="default"
                                nzShape="circle"><i nz-icon
                                                    nzType="delete"></i>
                        </button>
                        <button [cdkCopyToClipboard]="copyToClipboard" (mouseup)="clipboardCopy(item)" nzSize="small"
                                class="m-l-10" nz-button nzType="default" nzShape="circle"><i nz-icon
                                                                                              nzType="copy"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </nz-table>
        </div>
    </ng-container>
</nz-spin>
