<div #globalSearchBackdrop id="global-search-backdrop" (click)="toggleSearch(false)" class="backdrop"
     [ngClass]="{ 'active' : active }"></div>

<div class="search-area">
    <nz-input-group style="margin-top: 15px;" [nzSuffix]="suffixIconSearch">
        <input style="width: 100%" (keydown)="keyEnter($event)"
               (focus)="toggleSearch(true)" id="blurInput"
               [(ngModel)]="filter.search" (ngModelChange)="onChange()" nz-input
               [placeholder]="active ? 'Geben Sie Ihren Suchbegriff ein und bestätigen Sie mit ENTER'  : 'Globale suche'"
               nzSize="default"/>
    </nz-input-group>
    <ng-template #suffixIconSearch>
        <a *ngIf="isTouched" (click)="globalSearchFilter()">Suchen</a>
    </ng-template>


    <nz-spin [nzSpinning]="isLoading" nzSize="small">
        <ng-container *ngIf="showContentDiv">
            <div #resultDiv class="result-div">
                <!--FilterTags-->
                <div class="sticky-filter">
                    <nz-tag nzMode="checkable" class="tags" [(nzChecked)]="allChecked"
                            (nzCheckedChange)="updateAllChecked()">
                        Alle <i
                            *ngIf="allChecked" nz-icon nzType="check" nzTheme="outline"></i></nz-tag>
                    <nz-tag class="tags"
                            *ngFor="let tag of selectedCategories"
                            nzMode="checkable"
                            [nzChecked]="tag.isChecked"
                            (nzCheckedChange)="onChangeCheckBox(tag)"
                    >
                        {{ tag.name }} <i *ngIf="tag.isChecked" nz-icon nzType="check"
                                          nzTheme="outline"></i>
                    </nz-tag>
                </div>

                <ng-container *ngIf="!checkIfAtLeastOneCategorySelected()">
                    <p class="p-20">Sie müssen mindestens eine Kategorie auswählen.</p>
                </ng-container>

                <ng-container *ngIf="!showResults && checkIfAtLeastOneCategorySelected()">
                    <p class="p-20">Es wurden keine Ergebnisse gefunden.</p>
                </ng-container>

                <ng-container *ngIf="showResults">
                    <!--TICKETS-->
                    <ng-container *ngIf="searchCategories.includes('TICKETS') && searchResult?.tickets?.length > 0">
                        <h6>
                            Tickets
                        </h6>
                        <ul>
                            <li *ngFor="let ticket of searchResult?.tickets; let i = index"
                                [ngClass]="{'isOverdue' : isOverdue(ticket)}"
                                (click)="navigateToTicketPage(ticket.id)">
                                <i nz-icon nzType="tag" nzTheme="fill" class="m-r-5"
                                   [nz-tooltip]="ticket.priority?.name"
                                   [style.color]="ticket.priority?.background_color"></i> <span>{{ ticket.name }}</span>
                                <small><p class="m-0 p-0">{{ ticket.user?.name }}</p></small>
                                <small><p *ngIf="ticket.state.name" class="m-0 p-0">{{ ticket.state.name }}</p>
                                </small>
                                <small><p *ngIf="ticket.object" class="m-0 p-0">{{ ticket.object?.name }}</p></small>
                                <small><p *ngIf="ticket.ticketNumber" class="m-0 p-0">{{ ticket.ticketNumber }}</p></small>
                            </li>
                        </ul>
                        <div class="m-b-10 m-t-10 m-l-20">
                            <a *ngIf="searchResult?.tickets?.length === 10"
                               (click)="globalSearchFilter('TICKETS', null)">Alle
                                Ergebnisse anzeigen</a>
                        </div>
                    </ng-container>

                    <!--TASKS-->
                    <ng-container *ngIf="searchCategories.includes('TASKS') && searchResult?.tasks?.length > 0">
                        <h6>Aufgaben
                        </h6>
                        <ul>
                            <li *ngFor="let task of searchResult?.tasks; let i = index" (click)="editTask(task)">
                                <span>{{ task.title }}</span>
                                <small><p class="m-0 p-0">{{ task.user?.name }}</p></small>
                                <small><p *ngIf="task.start"
                                          class="m-0 p-0">{{ task.start | date: 'dd.MM.yyyy HH:mm' }}</p>
                                </small>
                                <small><p *ngIf="task.object" class="m-0 p-0">{{ task.object?.name }}</p></small>
                            </li>
                        </ul>
                        <div class="m-b-10 m-t-10 m-l-20" *ngIf="searchResult?.tasks?.length === 10">
                            <a (click)="globalSearchFilter('TASKS', null)">Alle
                                Ergebnisse anzeigen</a>
                        </div>
                    </ng-container>

                    <!--OBJECTS-->
                    <ng-container *ngIf="searchCategories.includes('OBJECTS') && searchResult?.objects?.length > 0">
                        <h6>
                            Objekte
                        </h6>
                        <ul>
                            <li *ngFor="let obj of searchResult?.objects; let i = index"
                                (click)="navigateToObjectPage(obj.id)">
                                {{ obj.name }}
                                <small><p *ngIf="obj.key" class="m-0 p-0">{{ obj.key }}</p></small>
                            </li>
                        </ul>
                        <div *ngIf="searchResult?.objects?.length === 10" class="m-b-10 m-t-10 m-l-20">
                            <a *ngIf="searchResult?.objects?.length === 10"
                               (click)="globalSearchFilter('OBJECTS', null)">Alle
                                Ergebnisse anzeigen</a>
                        </div>
                    </ng-container>

                    <!--USERS-->
                    <ng-container
                            *ngIf="searchCategories.includes('USERS') && searchResult?.users?.length > 0 && authenticationService.currentUserValue.isManager && authenticationService.currentUserValue.isAdmin">
                        <h6>
                            Mitarbeiter
                        </h6>
                        <ul>

                            <li *ngFor="let user of searchResult?.users; let i = index"
                                (click)="navigateToUser(user.id)">
                                <nz-avatar class="m-r-5"
                                           [nzSrc]="user?.avatarPath ? backendURL + '/' + user?.avatarPath : ''"
                                           [nzIcon]="user.avatarPath ? backendURL + '/' + user.avatarPath : 'user'"
                                ></nz-avatar>
                                <span>{{ user.name }}</span>
                                <button (click)="navigateToTimeTracking(user.id); $event.stopPropagation()"
                                        nzSize="default" nz-tooltip="Zur Zeiterfassung"
                                        class="pull-right" nz-button nzShape="circle" nzType="link">
                                    <i nz-icon nzType="clock-circle" nzTheme="outline"></i>
                                </button>
                            </li>
                        </ul>
                        <div *ngIf="searchResult?.users?.length === 10" class="m-b-10 m-t-10 m-l-20">
                            <a *ngIf="searchResult?.users?.length === 10"
                               (click)="globalSearchFilter('USERS', null)">Alle Ergebnisse anzeigen</a>
                        </div>
                    </ng-container>

                    <!--CONTACTS-->
                    <ng-container *ngIf="searchCategories.includes('CONTACTS') && searchResult?.contacts?.length > 0">
                        <h6>
                            Kontakte
                        </h6>
                        <ul>
                            <li *ngFor="let contact of searchResult?.contacts; let i = index"
                                (click)="editContact(contact)">
                                <span>{{ contact.name }}</span> <br>
                                <small *ngIf="contact.phone"><a class="m-0 p-0"
                                                                (click)="startCall(contact.phone); $event.stopPropagation();">{{ contact.phone }}</a></small>
                                <br>
                                <small *ngIf="contact.email"><a class="m-0 p-0"
                                                                (click)="sendEmail(contact.email); $event.stopPropagation();">{{ contact.email }}</a></small>
                            </li>
                        </ul>
                        <div *ngIf="searchResult?.contacts?.length === 10" class="m-b-10 m-t-10 m-l-20">
                            <a *ngIf="searchResult?.contacts?.length === 10"
                               (click)="globalSearchFilter('CONTACTS', null)">Alle
                                Ergebnisse anzeigen</a>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </nz-spin>
</div>
