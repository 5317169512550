<ng-container *ngIf="!company?.billing?.timetrackingmodule">

  <div class="container">
    <div class="text-center m-t-30 m-b-40">
      <h2>Zeiterfassung</h2>
      <p class="w-45 m-h-auto m-b-30">
        Das Modul <strong>Zeiterfassung</strong> ist für Ihren Account aktuell nicht verfügbar.
      </p>
      <h5>Warum sehe ich das Modul nicht?</h5>
      <p>
        Aktuell ist das Modul in einer <strong>geschlossenen Testphase</strong> nur für ausgewählte Kunden verfügbar.
      </p><br>

      <hr>
      <div nz-row nzJustify="space-around">
        <div nz-col nzSpan="12">
          <nz-card>
            <h3>Sie möchten am Test teilnehmen?</h3>
            <p>Schreiben Sie uns und vereinbaren Sie einen Beratungstermin um am geschlossenen Test teilzunehmen.</p>

            <button nz-button nzType="primary" (click)="openSupport()"><i nz-icon nzType="comment"
                                                                          nzTheme="outline"></i>
              Anfragen
            </button>
          </nz-card>
        </div>
      </div>


    </div>

  </div>


</ng-container>


<ng-container *ngIf="!user">
  <app-users [timeTrackingView]="true"></app-users>
</ng-container>

<ng-container *ngIf="user">
  <nz-spin [nzSpinning]="isLoading">


    <button [disabled]="!user" class="m-r-10" nz-button nzType="default" nzSize="small"
            [routerLink]="'/timetracking/'"><i
      nz-icon nzType="arrow-left" nzTheme="outline"></i>
    </button>

    <nz-select style="width: 250px" [(ngModel)]="user" (ngModelChange)="userChanged()"
               nzShowSearch
               nzPlaceHolder="Benutzer wählen" nzSize="small"
    >
      <nz-option nzCustomContent *ngFor="let employee of employeeList" [nzLabel]="employee.name"
                 [nzValue]="employee">
        <nz-badge [nzStatus]="employee.renewinterval?.module_timetracking ? 'success' : 'error'"></nz-badge>
        {{ employee.name }}
      </nz-option>
    </nz-select>


    <nz-tag class="m-l-10"
            *ngIf="user && !user?.renewinterval?.module_timetracking"
            [nzColor]="'red'">Die Zeiterfassung ist für
      den gewählten Mitarbeiter nicht lizenziert
    </nz-tag>

    <ng-container *ngIf="user?.renewinterval?.module_timetracking">

      <nz-badge class="m-l-10" [nzCount]="userAbsentsNotApproved?.length">
        <button [disabled]="!user" (click)="absentEditor()" nz-button nzType="link"
                nzSize="small">
          <i nz-icon nzType="user-delete" nzTheme="outline"></i> Abwesenheiten
        </button>
      </nz-badge>


      <div *ngIf="user?.renewinterval?.module_timetracking"
           class="pull-right">


        <ng-container *appIsManager>

          <a nz-button nzType="default"
             nzSize="small" target="_blank" class="m-r-10"
             [href]="'/users/' + user.id"><i nz-icon nzType="user" nzTheme="outline"></i></a>

          <button nzSize="small" nz-button nz-dropdown [nzDropdownMenu]="menu4">
            Exportieren
            <span nz-icon nzType="down"></span>
          </button>
          <nz-dropdown-menu #menu4="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item>
                <a (click)="export('excel')">
                  <i nz-icon nzType="file-excel" nzTheme="outline"></i> Excel (XLSX)
                </a>
              </li>
              <li nz-menu-item>
                <a (click)="export('csv')">
                  <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Kommaseparierte Liste (CSV)
                </a>
              </li>
              <li nz-menu-item>
                <a (click)="printPDF()">
                  <i nz-icon nzType="file-pdf" nzTheme="outline"></i> Ansicht exportieren (PDF)
                </a>
              </li>
            </ul>
          </nz-dropdown-menu>

        </ng-container>


        <button [disabled]="!user" class="m-l-10" nz-button nzType="default" nzSize="small" (click)="getTimestamps()"><i
          nz-icon nzType="reload" nzTheme="outline"></i> aktualisieren
        </button>
      </div>


      <nz-alert
        class="m-t-10"
        *ngIf="user && !user.timeTrackingWorkWeek"
        nzType="warning"
        [nzMessage]="timesNotDefined"
      ></nz-alert>
      <ng-template #timesNotDefined>
        Für {{ user.name }} sind noch keine Soll-Arbeitszeiten hinterlegt. Klicken Sie <a target="_blank"
                                                                                          [href]="'/users/' + user.id">hier</a>
        und fügen Sie Arbeitszeiten hinzu. Bereits hinterlegt? Jetzt <a (click)="getUsers()">aktualisieren</a>.
      </ng-template>


      <div class="m-v-10">
        Ansicht:
        <nz-radio-group nzSize="small" class="m-l-10" [(ngModel)]="activeView">
          <label nz-radio-button nzValue="details">Listenansicht</label>
          <label nz-radio-button nzValue="months">Monatsübersicht</label>

        </nz-radio-group>
        <nz-date-picker nzMode="month"
                        nzSize="small"
                        class="m-l-10"
                        style="width: 150px"
                        [hidden]="activeView !== 'details'"
                        [disabled]="!user"
                        [nzAllowClear]="false"
                        (nzOnOk)="getTimestamps()"
                        [nzDisabledDate]="disabledDate"
                        nzFormat="MMMM yyyy"
                        [(ngModel)]="filter.date"
                        (ngModelChange)="getTimestamps()">
        </nz-date-picker>

        <div class="pull-right m-v-10">
          <strong class="p-r-10">Zeitkonto:</strong>
          <strong
            [ngClass]="{'color-green' : timeTrackingTotalSaldo > 0, 'color-red' : timeTrackingTotalSaldo < 0}">{{ timetracking.convertTime(timeTrackingTotalSaldo, true) }}</strong>

        </div>

      </div>

      <ng-container *ngIf="activeView === 'months'">
        <app-timestamp-list-months [userId]="userId" (selectMonth)="getTimestamps($event)" (updated)="getTimestamps()"></app-timestamp-list-months>
      </ng-container>

      <ng-container *ngIf="activeView === 'details'">
        <nz-row class="m-t-10" [nzGutter]="[16, 24]">
          <nz-col nzXs="12" nzMd="6" nzLg="6" nzXl="4" *ngFor="let month of timeTrackingMonths">
            <div class="month-card cursor-pointer m-b-0"
                 [ngClass]="{'active' : isMonthActive(month), 'in-future': isMonthFuture(month)}"
                 (click)="getTimestamps(moment(month.year + (month.month.toString().padStart(2, '0'))).toISOString())">
              <h6>{{ moment(month.year + (month.month.toString().padStart(2, '0'))).locale('de').format('MMMM YYYY') }} </h6>
              <span [hidden]="isMonthFuture(month)"
                    [ngClass]="{'color-green' : month.saldo > 0, 'color-red' : month.saldo < 0}">{{ timetracking.convertTime(month.saldo, true) }}</span>
              <i *ngIf="month.locked" class="m-l-10" nz-icon nzType="lock" nzTheme="outline"></i>
              <i *ngIf="month._canLock" nz-tooltip nzTooltipTitle="Kann abgeschlossen werden" class="m-l-10 pulse"
                 nz-icon
                 nzType="unlock" nzTheme="outline"></i>
              <i *ngIf="month.ignored" class="m-l-5" nz-icon nzType="minus-circle" nzTheme="outline" nz-tooltip [nzTooltipTitle]="'Der Monat wurde aus der Zeitkonto-Berechnung ausgeschlossen'"></i>

              <span nz-tooltip nzTooltipTitle="bis zur Berechnung"
                    *ngIf="isMonthFuture(month)">noch {{ moment(month.start).diff(now(), 'days') }} Tage</span>

              <nz-progress [nzShowInfo]="false" [nzPercent]="calcPercent(month)" nzSize="small"></nz-progress>
            </div>
          </nz-col>
        </nz-row>

        <app-timetracking-lock-month [timeTrackingMonth]="timeTrackingMonth"
                                     (monthLocked)="getTimestamps()"></app-timetracking-lock-month>
        <nz-card class="m-t-10 card-no-padding"
                 *ngIf="user?.renewinterval?.module_timetracking">

          <nz-table nzTableLayout="fixed" nzSize="default" [nzShowPagination]="false" #stampTable
                    [nzData]="day_Timestamps">
            <thead>
            <tr>
              <th nzWidth="60px"></th>
              <th>Tag</th>
              <th>Arbeitszeit</th>
              <th>Pausenzeit</th>
              <th nz-tooltip [nzTooltipTitle]="'Bezahlte / Entschuldigte Abwesenheit'">Abwesenheit</th>
              <th colSpan="2"></th>
              <th style="width: 80px">Soll</th>
              <th style="width: 205px">Summe</th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let day_stamps of day_Timestamps; let dsI = index">
              <tr class="day-row"
                  [ngClass]="{
              'background-gray' : expandSet.has(day_stamps.id),
              'weekend' : timetracking.isWeekend(day_stamps),
              'today' : moment(day_stamps.date).isSame(moment(), 'day'),
              'disabled' : day_stamps._disabled}">
                <td [nzExpand]="expandSet.has(day_stamps.id)" [nzShowExpand]="!day_stamps._disabled"
                    (nzExpandChange)="onExpandChange(day_stamps.id, $event)"></td>

                <td (click)="onExpandChange(day_stamps.id, true)">
                  {{ day_stamps.date | date:  'E, dd.MM.y' :'local' : 'de-DE' }}
                  <i nz-tooltip
                     [nzTooltipTitle]="timetracking.combineErrors(day_stamps)"
                     class="font-size-20 p-l-5 color-time-orange"
                     *ngIf="day_stamps._errorCount > 0"
                     nz-icon nzType="warning" nzTheme="outline"></i>
                </td>
                <td (click)="onExpandChange(day_stamps.id, true)">
                  <ng-container *ngIf="!day_stamps.worktime"><p>-</p></ng-container>
                  {{ timetracking.convertTime(day_stamps.worktime) }}
                </td>
                <td (click)="onExpandChange(day_stamps.id, true)">
                  <ng-container *ngIf="!day_stamps.breaktime"><p>-</p></ng-container>
                  {{ timetracking.convertTime(day_stamps.breaktime) }}

                  <i *ngIf="day_stamps.prescribedBreakTimeDiff" class="color-red m-l-10" nz-popover
                     nzPopoverTitle="Automatische Anpassung nach §4 ARBZG" [nzPopoverContent]="arbzgContent" nz-icon
                     nzType="exclamation-circle" nzTheme="fill"></i>
                  <ng-template #arbzgContent>
                    Aufgrund der gesetzlichen Pausenregelung nach §4 ARBZG<br>
                    wurde die Pausenzeit entsprechend geändert.<br>
                    <br>
                    <table>
                      <tr>
                        <td style="width: 160px">Gestempelt:</td>
                        <td>{{ timetracking.convertTime(day_stamps.breaktime - day_stamps.prescribedBreakTimeDiff, true) }}</td>
                      </tr>
                      <tr>
                        <td style="width: 160px">Angepasst:</td>
                        <td>{{ timetracking.convertTime(day_stamps.prescribedBreakTimeDiff) }}</td>
                      </tr>
                      <tr class="bold">
                        <td style="width: 160px">Summe:</td>
                        <td>{{ timetracking.convertTime(day_stamps.breaktime) }}</td>
                      </tr>
                    </table>
                  </ng-template>

                </td>
                <td (click)="onExpandChange(day_stamps.id, true)">
                  <ng-container *ngIf="!day_stamps.absenttime"><p>-</p></ng-container>
                  {{ timetracking.convertTime(day_stamps.absenttime) }}


                </td>
                <td (click)="onExpandChange(day_stamps.id, true)" colspan="2"
                    [ngClass]="{ 'background-red' : day_stamps._errorCount > 0 }">

                  <nz-tag *ngFor="let absent of day_stamps.absents" [ngClass]="{'not-approved' : !absent.approved}"
                          [nzColor]="absent.absentType?.color" nz-popover [nzPopoverContent]="absent.note">
                    {{ absent.halfDay ? absent?.absentType?.name + ' &#189; Tag' : absent?.absentType?.name }}
                    <small *ngIf="!absent.approved">(beantragt)</small>
                  </nz-tag>

                  <nz-tag *ngIf="day_stamps.holiday" nz-popover [nzPopoverContent]="content"
                          [nzColor]="'red'">{{ day_stamps.holiday?.name }}
                  </nz-tag>
                  <nz-tag *ngIf="timetracking.isWeekend(day_stamps)" [nzColor]="'orange'">Wochenende</nz-tag>

                  <ng-template #content>
                    <div class="m-15">
                      <ul>
                        <li *ngFor="let name of timetracking.bundeslaender(day_stamps.holiday)">{{ name }}</li>
                      </ul>
                    </div>
                  </ng-template>


                  <ng-container *ngIf="day_stamps._errorCount > 0">
                    <ul class="p-l-10 m-0">
                      <li *ngFor="let error of timetracking.combineErrors(day_stamps, true)"> {{ error }}</li>
                    </ul>
                  </ng-container>

                </td>
                <td (click)="onExpandChange(day_stamps.id, true)">
                  {{ timetracking.convertTime(day_stamps.needed) }}
                </td>
                <td (click)="onExpandChange(day_stamps.id, true)">
                  <strong>{{ timetracking.convertTime(day_stamps.total, true) }}</strong>
                  <small class="p-l-20 color-green"
                         *ngIf="day_stamps.saldo > 0">{{ day_stamps.saldo ? '+' + timetracking.convertTime(day_stamps.saldo) : '' }}</small>
                  <small class="p-l-20 color-light-grey" *ngIf="day_stamps.saldo === 0">00:00</small>
                  <small class="p-l-20 color-red"
                         *ngIf="day_stamps.saldo < 0">{{ day_stamps.saldo ? timetracking.convertTime(day_stamps.saldo) : '' }}</small>

                  <i *ngIf="timeTrackingMonth.locked" class="m-l-10 pull-right" nz-icon nzType="lock"
                     nzTheme="outline"></i>
                  <strong *ngIf="day_stamps.manualEntry" nz-tooltip
                          nzTooltipTitle="Tag manuell berechnet"
                          class="m-l-10 pull-right color-orange cursor-pointer">M</strong>

                  <nz-progress [nzShowInfo]="false" [nzPercent]="calcPercent(day_stamps)" nzSize="small"></nz-progress>
                </td>
              </tr>

              <!-- Sub-Table -->
              <ng-container *ngIf="expandSet.has(day_stamps.id) && !day_stamps._disabled">

                <ng-container *ngIf="!day_stamps.manualEntry">
                  <tr style="border: 3px" class="background-gray sub-table">

                    <td colspan="3" class="text-bold">Aktion</td>
                    <td class="text-bold">Zeitpunkt</td>
                    <td class="text-bold">Dauer</td>
                    <td class="text-bold">Status</td>
                    <td>
                      <button [disabled]="isDayInEditMode(day_stamps) || timeTrackingMonth.locked" nz-tooltip
                              nzTooltipTitle="Zeiteintrag nachtragen"
                              nzSize="small"
                              class="m-l-10" nz-button nzType="primary"
                              (click)="newTimeStamp(day_stamps, $event)"><i nz-icon nzType="plus"></i> Eintrag
                      </button>
                    </td>
                    <td>
                    </td>
                    <td></td>
                  </tr>
                  <tr *ngIf="day_stamps.timetracking_timestamps.length <= 0" class="sub-table">
                    <td class="text-center" colspan="9">
                      <i>Keine Zeiteinträge</i><br>
                      <label class="m-t-10" *ngIf="!timeTrackingMonth.locked" nz-checkbox
                             [(ngModel)]="day_stamps.manualEntry">Tag manuell
                        Berechnen</label>
                    </td>
                  </tr>
                </ng-container>

                <tr manualTimes [hidden]="!day_stamps.manualEntry" [(timestamps)]="day_Timestamps[dsI]"
                    (timestampsChange)="getTimestamps()"></tr>


                <tr class="background-light-gray sub-table"
                    [id]="item?.id"
                    [ngClass]="{'background-red' : item?._error }"
                    *ngFor="let item of day_stamps.timetracking_timestamps; let tsI = index">
                  <ng-container *ngIf="!editCache[item?.id]?.edit; else editTemplate">
                    <td>
                      <i *ngIf="item?.action  === 'PAUSE'" nz-icon nzType="coffee" style="font-size: 20px"
                         nzTheme="outline"
                         class="p-r-10 color-blue"></i>
                      <i *ngIf="item?.action  === 'KOMMEN'" nz-icon [nzType]="item?.timetracking_type?.icon_web || 'tool'" style="font-size: 20px"
                         nzTheme="outline"
                         class="p-r-10 color-orange"></i>
                      <i *ngIf="item?.action  === 'GEHEN'" nz-icon nzType="logout" style="font-size: 20px"
                         nzTheme="outline"
                         class="p-r-10 color-red"></i>
                    </td>
                    <td colspan="2">
                      {{ item?.timetracking_type?.name || item?.action }}
                    </td>
                    <td>
                      <strong>{{ item?.timestamp | date: 'HH:mm' }}</strong>
                    </td>

                    <td>{{ timetracking.convertTime(item?.average) }}</td>

                    <td>
                      <nz-tag *ngIf="item?.manipulated" [nzColor]="'red'" nz-tooltip
                              nzTooltipTitle="Dieser Eintrag wurde manuell bearbeitet">Bearbeitet
                      </nz-tag>
                    </td>

                 

                    <td>
                      <button nz-tooltip nzTooltipTitle="Zeiteintrag bearbeiten" nzSize="small"
                              class="m-l-10" nz-button nzType="default" [disabled]="timeTrackingMonth.locked"
                              (click)="editTimestamp(item)"><i nz-icon nzType="edit"></i> Ändern
                      </button>
                    </td>
                    <td>

                    </td>
                    <td>
                      <a *ngIf="item.geolocation"
                         [href]="'https://www.google.com/maps/search/?api=1&query=' + item.geolocation.latitude + '%2C' + item.geolocation.longitude"
                         target="_blank"> <i nz-icon nzType="compass" nzTheme="outline"></i> GPS-Standort</a>
                      <i nz-tooltip
                         [nzTooltipTitle]="item?._error?.reason"
                         class="font-size-20 p-l-5 color-time-orange pull-right"
                         *ngIf="item?._error"
                         nz-icon nzType="warning" nzTheme="outline"></i>
                    </td>
                  </ng-container>

                  <!--Edit Sub-Table-->
                  <ng-template #editTemplate>

                    <td colspan="3">
                      <nz-select style="width: 50%" nzPlaceHolder="Aktion"
                                 [(ngModel)]="editCache[item?.id].data.action" (ngModelChange)="cacheActionChanged(editCache[item?.id].data)">
                        <nz-option [nzValue]="'KOMMEN'" nzLabel="KOMMEN"></nz-option>
                        <nz-option [nzValue]="'PAUSE'" nzLabel="PAUSE"></nz-option>
                        <nz-option [nzValue]="'GEHEN'" nzLabel="GEHEN"></nz-option>
                      </nz-select>
                      <nz-select *ngIf="editCache[item?.id].data.action === 'KOMMEN'" class="p-l-5" style="width: 50%" nzPlaceHolder="Typ"
                                 [(ngModel)]="editCache[item?.id].data.type">
                        <nz-option *ngFor="let type of timetracking.timetrackingTypes" [nzValue]="type.id" [nzLabel]="type.name"></nz-option>
                      </nz-select>
                    </td>
                    <td>
                      <mbsc-datepicker
                        [(ngModel)]="editCache[item?.id].data.timestamp"
                        [controls]="['time']"
                        [timezonePlugin]="momentPlugin"
                        [stepMinute]="1"
                        headerText="Ausgewählte Stempelzeit: {value}"
                        timeFormat="HH:mm"
                        inputStyle="outline"
                        labelStyle="stacked"
                        dataTimezone="utc"
                        displayTimezone="local"
                        theme="ios"
                      ></mbsc-datepicker>
                    </td>
                    <td nz-tooltip nzTooltipTitle="Dieser Eintrag berechnet sich automatisch">
                      <input type="text" nz-input disabled
                             [placeholder]="'null' ? 'wird berechnet' : timetracking.convertTime(item?._average)"/>
                    </td>
                    <td colspan="3">
                      <button nzSize="small" class="m-l-10" nz-button nzType="default"
                              [disabled]="!editCache[item?.id].data.action" (click)="saveEdit(item?.id, dsI, tsI)"><i
                        nz-icon nzType="save"></i> Speichern
                      </button>

                      <button nzSize="small" class="m-l-10" nz-button nzType="default"
                              (click)="cancelEdit(item.id, dsI, tsI)">Abbrechen
                      </button>

                    </td>

                    <td>
                      <button *ngIf="!hide" nzSize="small" class="m-l-10" nz-button nzType="link" nzDanger
                              (click)="deleteTimestamp(item?.id)"><i nz-icon nzType="delete"></i> Löschen
                      </button>

                    </td>
                  </ng-template>

                </tr>
              </ng-container>


            </ng-container>
            <tr *ngIf="timeTrackingMonth">
              <td></td>
              <td class="text-bold">Gesamt:</td>
              <td class="text-bold">{{ timetracking.convertTime(timeTrackingMonth.worktime) }}</td>
              <td class="text-bold">{{ timetracking.convertTime(timeTrackingMonth.breaktime) }}</td>
              <td class="text-bold">{{ timetracking.convertTime(timeTrackingMonth.absenttime) }}</td>
              <td></td>
              <td></td>
              <td class="text-bold">{{ timetracking.convertTime(timeTrackingMonth.needed, true) }}</td>
              <td class="text-bold">{{ timetracking.convertTime(timeTrackingMonth.total, true) }}</td>
            </tr>
            </tbody>
          </nz-table>

        </nz-card>
      </ng-container>
    </ng-container>
  </nz-spin>
</ng-container>
