import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from "../services/authentication.service";
import {User} from "../../../../../database-models";

@Directive({
  selector: '[appIsManager]'
})
export class IsManagerDirective implements OnInit {

  constructor(
      private authService: AuthenticationService,
      private viewContainer: ViewContainerRef,
      private templateRef: TemplateRef<any>
  ) { }

  ngOnInit() {


      if (this.authService.currentUserValue.isManager) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }

  }

}
