<nz-table #table [nzData]="tableData.data">
  <thead >
  <tr>
    <th *ngFor="let item of tableData.items" >{{item.title}}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let dataItem of table.data">
    <td *ngFor="let item of tableData.items">{{dataItem[item.key]}}</td>
  </tr>
  </tbody>
</nz-table>

