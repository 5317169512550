<div class="p-b-10">
  <div class="text-right">


    <button nz-button class="m-r-5" nzType="link" nzDanger *ngIf="checkForActiveFilters()"
            (click)="resetFilter()"> alle Filter zurücksetzen
    </button>
    <button class="m-r-5" nz-button nzType="text"
            (click)="openTab('https://support.netbrick.de/hc/hausmeisterapp/articles/1730104335-arbeitsprotokolle-auswerten')">
      <i
        nz-icon nzType="question"></i> Hilfe
    </button>

    <button class="m-r-5" nz-button nzType="primary" (click)="newWorklog()"><i nz-icon
                                                                               nzType="plus-circle"></i>Neu
    </button>

    <nz-input-group nz-tooltip style="width: 250px"
                    nzTooltipTitle="Sie können hier nach einer Tätigkeit suchen"
                    [nzPrefix]="prefiXTemplate" [nzSuffix]="inputClearTpl">
      <input [(ngModel)]="filter.search" (keyup)="triggerSearch()" (keyup.enter)="triggerSearch(true)" nz-input
             placeholder="Tätigkeit suchen"
             nzSize="small">
    </nz-input-group>
    <ng-template #inputClearTpl>
      <span
        nz-icon
        class="ant-input-clear-icon"
        nzTheme="fill"
        nzType="close-circle"
        *ngIf="filter.search"
        (click)="filter.search = null;triggerSearch(true)"
      ></span>
    </ng-template>
    <ng-template #prefiXTemplate>
      <i nz-icon nzType="search" nzTheme="outline"></i>
    </ng-template>
    <nz-badge [nzCount]="checkForActiveFilters()">
      <button class="m-l-5" nz-button nzType="text" (click)="toogleFilter()"><i nz-icon
                                                                                [nzTheme]="filter.show ? 'outline' : 'fill'"
                                                                                nzType="filter"></i>
      </button>
    </nz-badge>
  </div>
</div>

<div class="m-b-10" *ngIf="filter.show">
  <nz-row [nzGutter]="[8, 8]">
    <div nz-col nzSpan="5">
      <nz-range-picker class="w-100" [nzRenderExtraFooter]="rangePickerFooter" [nzAllowClear]="false"
                       (nzOnOk)="getWorklogs()"
                       nzFormat="dd.MM.yyyy" [(ngModel)]="filter.dateRange"
                       (ngModelChange)="getWorklogs(null, true)"></nz-range-picker>


      <ng-template #rangePickerFooter>
        <button nz-button nzType="link" nzSize="small" (click)="setRange('today')">Heute</button>
        <button nz-button nzType="link" nzSize="small" (click)="setRange('tw')">diese Woche</button>
        <button nz-button nzType="link" nzSize="small" (click)="setRange('lw')">letzte Woche</button>
        <button nz-button nzType="link" nzSize="small" (click)="setRange('tm')">dieser Monat</button>
        <button nz-button nzType="link" nzSize="small" (click)="setRange('lm')">letzter Monat</button>
        <button nz-button nzType="link" nzSize="small" (click)="setRange('tj')">dieses Jahr</button>
        <button nz-button nzType="link" nzSize="small" (click)="setRange('lj')">letztes Jahr</button>
      </ng-template>
    </div>
    <div nz-col nzSpan="11" class="inline-block">
      <app-object-customer-select [multiple]="true" [(reference)]="filter.reference"
                                  (referenceChange)="getWorklogs(null, true)"
                                  [hideLabel]="true" [allowClear]="true"></app-object-customer-select>
    </div>
    <nz-select nz-col nzSpan="8" *appIsManager [(ngModel)]="filter.user"
               (ngModelChange)="getWorklogs(null, true)"
               nzShowSearch [nzAllowClear]="true"
               nzPlaceHolder="Nach Mitarbeiter filtern"
               [compareWith]="compareById">
      <nz-option nzCustomContent *ngFor="let employee of users" [nzLabel]="employee.name"
                 [nzValue]="employee">
        {{ employee.name }}
      </nz-option>
    </nz-select>

    <nz-select nz-col nzSpan="8" nzAllowClear nzMode="multiple" [nzMaxTagCount]="3" nzPlaceHolder="nach Tags filtern"
               [(ngModel)]="filter.tags" (ngModelChange)="getWorklogs(null, true)" [compareWith]="compareById"
               [ngClass]="{'border-primary' : filter.tags?.length > 0}">
      <nz-option nzCustomContent *ngFor="let tag of availableTags" [nzLabel]="tag.name" [nzValue]="tag">
        <i [style.color]="tag.color" class="m-r-10" nz-icon nzType="tag" nzTheme="fill"></i> {{tag.name}}
      </nz-option>
    </nz-select>
    <div nz-col nzSpan="4">
      <label *appIsManager class="m-t-10 align-top" nz-checkbox [(ngModel)]="filter.billed"
             (ngModelChange)="getWorklogs(null, true)">inkl. Abgerechnet</label>
    </div>

    <div nz-col nzSpan="4">
      <label *appIsManager nz-tooltip="nur Arbeitsprotokolle anzeigen, welche mit einem Ticket verknüpft sind"
             class="m-t-10 align-top" nz-checkbox [(ngModel)]="filter.linkedTicket"
             (ngModelChange)="getWorklogs(null, true)">mit Ticket verknüpft</label>
    </div>
  </nz-row>
</div>

<nz-card>

  <nz-alert class="m-b-10" *ngIf="timeIsPast()" nzType="warning"
            nzMessage="Der gewählte Zeitraum liegt in der Vergangenheit" nzShowIcon></nz-alert>


  <nz-table [nzFrontPagination]="false" (nzQueryParams)="getWorklogs($event)" [nzLoading]="worklogsLoading"
            #worklogTable [nzData]="worklogs"
            [nzTotal]="tableState.total" [nzPaginationPosition]="'both'"
            [(nzPageSize)]="tableState.pageSize"
            [(nzPageIndex)]="tableState.pageIndex"
            [nzShowSizeChanger]="true"
            [nzPageSizeOptions]="[20, 50, 100]"
            (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
    <thead>
    <tr>
      <th [nzChecked]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)">
      </th>
      <th>
        Details
      </th>
      <th>
        Tätigkeit
      </th>
      <th nzColumnKey="start" [nzSortFn]="true">
        Zeiten
      </th>


      <th style="width: 100px"></th>
    </tr>
    </thead>
    <tbody>


    <tr class="cursor-pointer" *ngFor="let dataItem of worklogTable.data" (click)="editWorklog(dataItem)">
      <td
        [nzChecked]="setOfCheckedId.has(dataItem.id)"
        (nzCheckedChange)="onItemChecked(dataItem.id, $event)"
        (dblclick)="$event.stopPropagation()"
      ></td>

      <td>
        <div class="font-weight-bold" *ngIf="dataItem.object"><i class="m-r-5" nz-icon nzType="home"
                                                                 nzTheme="outline"></i> {{ dataItem.object?.name }}
          <ng-container *ngIf="dataItem.room"><i nz-icon nzType="right"
                                                 nzTheme="outline"></i>  {{ dataItem.room?.name }}
          </ng-container>
        </div>

        <ng-container *ngIf="dataItem.ticket && dataItem.ticket_id">
          <p class="p-0 m-0 font-weight-bold">
            <small nz-tooltip="Verknüpftes Ticket" nzTooltipPlacement="right">
              <i class="p-r-5" nz-icon nzType="inbox" nzTheme="outline"></i>{{ dataItem.ticket.ticketNumber }}
            </small>
          </p>
        </ng-container>

        <div class="font-weight-bold" *ngIf="dataItem.contact"><i class="m-r-5" nz-icon nzType="idcard"
                                                                  nzTheme="outline"></i>{{ dataItem.contact.name }}
        </div>


      </td>

      <td style="max-width: 300px">
        <p nz-typography *ngIf="dataItem.description" nzEllipsis [nzEllipsisRows]="2">{{ dataItem.description }}</p>
        <p nz-typography *ngIf="!dataItem.description">-</p>
        <span *ngIf="dataItem.creator" nz-tooltip nzTooltipPlacement="top"
              nzTooltipTitle="Ersteller des Protokolls">
            <app-username-avatar [user]="dataItem.creator"></app-username-avatar>
          </span>
        <nz-tag class="m-l-5" nzColor="geekblue" *ngIf="dataItem.involved_users?.length > 0">
          +{{ dataItem.involved_users?.length }}
        </nz-tag>
      </td>

      <td>
        <div>
          <ng-container *ngIf="dataItem.end">
            <ng-container *ngIf="moment(dataItem.start).isSame(dataItem.end, 'day')">
              <i nz-icon nzType="calendar" nzTheme="outline"></i> {{ dataItem.start | date:'dd.MM.y' }}<br>
              <i nz-icon nzType="clock-circle" nzTheme="outline"></i> {{ dataItem.start | date:'H:mm' }}
              - {{ dataItem.end | date:'H:mm' }}
            </ng-container>
            <ng-container *ngIf="!moment(dataItem.start).isSame(dataItem.end, 'day')">
              <i nz-icon nzType="calendar" nzTheme="outline"></i> {{ dataItem.start | date:'dd.MM.y' }}<br>
              <i nz-icon nzType="clock-circle" nzTheme="outline"></i> {{ dataItem.start | date:'H:mm' }}
              - {{ dataItem.end | date:'H:mm' }} (+{{ moment(dataItem.end).diff(dataItem.start, 'day') }} Tag)
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!dataItem.end">
            <i nz-icon nzType="calendar" nzTheme="outline"></i> {{ dataItem.start | date:'dd.MM.y' }}<br>
            <i nz-icon nzType="clock-circle" nzTheme="outline"></i> {{ dataItem.start | date:'H:mm' }}
          </ng-container>
          <nz-tag class="m-l-10" *ngIf="dataItem.end" [nzColor]="'green'">{{ convertTime(dataItem.sum) }}</nz-tag>

        </div>
      </td>


      <td>
        <nz-tag nz-dropdown [nzDropdownMenu]="dataItem.lexofficeInvoices?.length > 0 ? optionmenu : null"
                nzPlacement="bottomRight" *ngIf="dataItem.billed" [nzColor]="'green'">
          <lexoffice-icon *ngIf="dataItem.lexofficeInvoices?.length > 0"></lexoffice-icon>
          Abgerechnet
        </nz-tag>
        <nz-dropdown-menu #optionmenu="nzDropdownMenu">
          <ul nz-menu>
            <ng-container *ngFor="let lxInvoice of dataItem.lexofficeInvoices; let i = index">
              <li (click)="openTab(environment.lexofficePartnerApi.uiUrl + '/voucher/#/' + lxInvoice.id)" nz-menu-item>
                <a>
                  Rechnung {{ lxInvoice.voucherNumber || i + 1 }} öffnen <i nz-icon nzType="link" nzTheme="outline"></i><br>
                  <small *ngIf="lxInvoice.createdDate">Erstellt am {{ lxInvoice.createdDate | date:'dd.MM.y H:mm'  }} Uhr</small>
                </a>
              </li>
            </ng-container>
          </ul>
        </nz-dropdown-menu>
        <nz-tag *ngIf="dataItem.finalized" [nzColor]="'blue'">Finalisiert</nz-tag>
        <br>
        <!-- Worklog tags -->
        <i *ngFor="let tag of dataItem.tags" nz-tooltip
                                  [nzTooltipTitle]="tag.name"
                                  class="p-r-5" [style.color]="tag.color" nz-icon nzType="tag" nzTheme="fill"></i>
      </td>
    </tr>

    </tbody>
  </nz-table>




<div [hidden]="setOfCheckedId.size <= 0" [ngClass]="{ 'fixed-footer' : setOfCheckedId.size > 0 }">

  <span class="color-orange">{{ setOfCheckedId.size }} selektiert</span>

  <button  class="m-l-10" [disabled]="setOfCheckedId.size <= 0" nz-button nz-dropdown [nzDropdownMenu]="billingOptions"  *appIsManager>Abrechnung
   <i nz-icon
                                     nzType="down"></i>
  </button>


  <nz-dropdown-menu #billingOptions="nzDropdownMenu">
    <ul nz-menu>
      <ng-container *ngIf="authenticationService.lexofficeApiActive">
        <li (click)="sendToLexOffice()" nz-menu-item>
          <a>
            <lexoffice-icon></lexoffice-icon>
            Neue Rechnung in <strong>Lexware Office</strong> erzeugen
          </a>
        </li>
        <li nz-menu-divider></li>
      </ng-container>
      <li nz-menu-item>
        <a (click)="bulkUpdateBilled(true)">
          <i class="text-info" nz-icon nzType="euro-circle" nzTheme="fill"></i>
          Arbeitsprotokolle als abgerechnet markieren
        </a>
      </li>
      <li nz-menu-item (click)="bulkUpdateBilled(false)">
        <a>
          <i class="text-danger" nz-icon nzType="euro-circle" nzTheme="fill"></i>
          Arbeitsprotokolle als <u>nicht</u> abgerechnet markieren
        </a>
      </li>
    </ul>
  </nz-dropdown-menu>


  <button class="m-l-10" [disabled]="setOfCheckedId.size <= 0" nz-button nz-dropdown [nzDropdownMenu]="exportOptions">Exportieren <i nz-icon
                                     nzType="down"></i>
  </button>

  <nz-dropdown-menu #exportOptions="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item (click)="export('excel')">
        <a>
          <i nz-icon nzType="file-excel" nzTheme="outline"></i> Excel (XLSX)
        </a>
      </li>
      <li nz-menu-item (click)="export('csv')">
        <a>
          <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Kommaseparierte Liste (CSV)
        </a>
      </li>
      <li nz-menu-item (click)="exportReport()">
        <a>
          <i nz-icon nzType="file-pdf" nzTheme="outline"></i> PDF
        </a>
      </li>
    </ul>
  </nz-dropdown-menu>


  <button class="m-l-10" [disabled]="setOfCheckedId.size <= 0" nz-button nz-dropdown [nzDropdownMenu]="bulkOptions">Stapelverarbeitung <i nz-icon
                                                                                                                      nzType="down"></i>
  </button>
  <nz-dropdown-menu #bulkOptions="nzDropdownMenu">
    <ul nz-menu>
      <li *appIsManager nz-menu-item [nzDisabled]="isFinalizedInArray()"
          [ngClass]="{ 'background-light-red' : isFinalizedInArray() }">
        <a (click)="bulkUpdateFinalized()"> <i nz-icon nzType="snippets" nzTheme="outline"></i>
          Arbeitsprotokolle finalisieren <small *ngIf="isFinalizedInArray()">(nur Möglich wenn die Auswahl aus nicht
            finalisierten Protokollen besteht)</small>
        </a>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>


</nz-card>
