<ng-container *ngIf="wastePlans">
  <mbsc-eventcalendar [scheduleEventTemplate]="eventTemplate"
                      [resources]="objectResources"
                      [data]="myEvents"
                      [options]="eventSettings"
                      (onPageChange)="getWastePlans($event)"
  ></mbsc-eventcalendar>

  <!-- Event -->
  <ng-template #eventTemplate let-data>
    <div class="waste-event" [ngClass]="{ 'done' : data.original.done }" [ngStyle]="{borderColor: data.color, background: data.color}">
      <div class="waste-event-cont">
        <i *ngIf="data.original.type === 'out'" class="icon-out" nz-icon [nzType]="data.original.done ? 'check' : 'logout'"
           nzTheme="outline"></i>
        <i *ngIf="data.original.type === 'in'" class="icon-in" nz-icon [nzType]="data.original.done ? 'check' : 'login'"
           nzTheme="outline"></i>
        {{data.original.title}}
      </div>
    </div>

  </ng-template>
</ng-container>


