import {Component, ElementRef, inject, Input, OnInit, ViewChild} from '@angular/core';
import {
  Appuser_Users_Objects,
  Company,
  Share_Worklog_Users,
  SharedTicket,
  SharedWorkLog, User,
  WorkLog, WorklogBulUpdate
} from '../../../../../../../database-models';
import {AuthenticationService} from "../../../services/authentication.service";
import {ApiService} from "../../../../services/api.service";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {environment} from "../../../../../environments/environment";
import {NzMessageService} from "ng-zorro-antd/message";
import {DomSanitizer} from "@angular/platform-browser";
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

export interface ModalData {
	worklog: WorkLog;
  HvId?: string;
}

@Component({
  selector: 'app-print-worklog',
  templateUrl: './print-worklog.component.html',
  styleUrls: ['./print-worklog.component.scss']
})
export class PrintWorklogComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  @ViewChild('downloadSection')
  private downloadSection: ElementRef;
  public backendURL = environment.apiURL;

  @Input() public worklog: WorkLog;
  @Input() public HvId: string;

  public company: Company;
  public sharedWorkLog: SharedWorkLog = {} as SharedWorkLog;
  public sharedWithUser: Appuser_Users_Objects[];

  public copyToClipboard: string;

  constructor(
      public authenticationService: AuthenticationService,
      public api: ApiService,
      private message: NzMessageService,
      public sanitizer: DomSanitizer
  ) {
    this.authenticationService.currentUser.subscribe(user => {
      this.company = user.company;
    });
  }

  ngOnInit(): void {
    this.worklog ??= this.data.worklog;
    this.HvId ??= this.data.HvId;
    if (this.worklog?.Appuser_Users_Objects) {
      this.sharedWithUser = this.worklog.Appuser_Users_Objects
    }
  }

  public generateLink(): void {
    const data = this.worklog
    this.api.setSharedWorkLog(data).subscribe((sharedWorkLog: SharedWorkLog) => {
      this.sharedWorkLog = sharedWorkLog;
      this.message.success('Link wurde erfolgreich generiert');
    })
  }

  public clipboardCopy(): void {
    this.copyToClipboard = this.sharedWorkLog?.link;
    this.message.success('Link wurde in die Zwischenablage kopiert!')
  }

  public setShareWorklogUsers(): void {
    let shareWlUsers = this.sharedWithUser.map(appUser => {
      return {
        worklogId: this.worklog.id,
        AppuserUsersObjectId: appUser.id
      }
    });

    this.api.setShareWorklogUsers(this.worklog.id, shareWlUsers).subscribe(value => {
      this.message.success('Den ausgewählten Bewohnern wurde das Arbeitsprotokoll zur Verfügung gestellt.')
    });
  }

  public changePublicHV(value: any): void {
    const bulk: WorklogBulUpdate = {
      ids: this.worklog.id,
      field: 'HV',
      value: value
    } as unknown as WorklogBulUpdate;

    this.api.updateWorkLogBulk(bulk).subscribe(res => {
      this.message.success('Arbeitsprotokoll wurde der Hausverwaltung zur verfügung gestellt');

    }, error => {
      this.message.error('Die Bereitstellung war nicht erfolgreich!');
    });
  }

  // public setShareWorkLogHV(): void {
  //
  //   const send = {
  //     HvId: this.HvId
  //   } as Share_Worklog_Users
  //
  //   this.api.setShareWorklogHV(this.worklog.id, send).subscribe(value => {
  //     this.message.success('Den ausgewählten Bewohnern wurde das Arbeitsprotokoll zur Verfügung gestellt.')
  //   });
  // }
}
