<h5>Firmenstandort</h5>

<p><i nz-icon nzType="info-circle" nzTheme="outline"></i> Klicken Sie auf die Karte, um die Position zu wählen.</p>
<div style="height: 350px">
  <div id="mapedit"></div>
</div>

<div class="m-t-10 text-right">
  <button class="m-r-10" nz-button nzType="default" nzDanger (click)="resetMap()" [disabled]="!company?.lat">
    Löschen
  </button>
  <button nz-button nzType="primary" (click)="setCompany()" [disabled]="!company?.lat">
    Speichern
  </button>
</div>

