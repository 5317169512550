<nz-card nzHoverable="true" nzSize="small" [nzTitle]="Title"
         [nzLoading]="isLoading">

  <ng-template #Title>
    {{ contact.name }}
    <small>{{ '(' + contact.type + ')' }}</small>
  </ng-template>

  <nz-tabset nzSize="small">
    <nz-tab [nzTitle]="tabOneTitle">
      <div class="m-t-10">

        <span nz-tooltip [nzTooltipTitle]="contact?.type">{{ contact?.name }}</span>

        <ng-container
          *ngIf="contact?.contact_phones?.length === 0 && contact?.contact_emails?.length === 0 && contact?.contact_addresses?.length === 0">
          <div class="m-10">
            <p>Keine Daten vorhanden <i nz-icon nzType="frown" nzTheme="outline"></i></p>
          </div>
        </ng-container>

        <ng-container *ngIf="contact?.contact_phones?.length > 0">
          <details class="m-t-10">
            <summary>Telefon ({{ contact.contact_phones?.length }})</summary>
            <ng-container *ngFor="let c_Phone of contact.contact_phones">
              <div class="m-t-10">
                <a style="color: black" href="tel:{{c_Phone.phonenumber}}" nz-tooltip [nzTooltipTitle]="c_Phone.type"><i nz-icon
                                     nzType="phone"
                                     nzTheme="outline"></i>{{ c_Phone.phonenumber ? (' ' + c_Phone.phonenumber) : ' - ' }}
                </a>
              </div>


            </ng-container>
          </details>
        </ng-container>

        <ng-container *ngIf="contact?.contact_emails?.length > 0">
          <details class="m-t-10">
            <summary>E-Mail ({{ contact?.contact_emails?.length }})</summary>
            <ng-container *ngFor="let c_Email of contact?.contact_emails">
              <div nz-tooltip [nzTooltipTitle]="c_Email.type" class="m-t-10">
                <a style="color: black;" href="mailto:{{c_Email.email}}">
                  <!--                                    <i nz-icon nzType="mail" nzTheme="outline"></i>-->
                  {{ c_Email.email ? (' ' + c_Email.email) : ' - ' }}
                </a></div>

            </ng-container>
          </details>
        </ng-container>

        <ng-container *ngIf="contact?.contact_addresses?.length > 0">
          <details class="m-t-10">
            <summary>Adresse ({{ contact?.contact_addresses?.length }})</summary>
            <ng-container *ngFor="let c_Address of contact?.contact_addresses">
              <div nz-tooltip [nzTooltipTitle]="c_Address.type" class="m-t-10"><i nz-icon
                                                                                  nzType="compass"
                                                                                  nzTheme="outline"></i>
                <a style="color: black;"
                   (click)="getMapLink(c_Address)">{{ c_Address.address ? (' ' + c_Address.address + ' ' + c_Address.city + ' ' + c_Address.zip) : ' - ' }}</a>
              </div>

            </ng-container>
          </details>
        </ng-container>
      </div>

      <ng-template #tabOneTitle>
        <span nz-tooltip nzTooltipTitle="Kontakt Übersicht"> Info</span>

        <!--                    <i nz-icon nzType="contacts" nzTheme="outline"></i>-->
      </ng-template>
    </nz-tab>

    <ng-container *ngFor="let cP of contact?.contact_contactPeople">
      <nz-tab [nzTitle]="(cP.firstname + cP.lastname) ? getInitials(cP.firstname, cP.lastname) : ' '">

        <div class="m-t-10">
          <span>{{ cP.firstname + ' ' + cP.lastname }}</span>

          <ng-container *ngIf="cP.contact_phones?.length === 0 && cP.contact_emails?.length === 0">
            <div class="m-10">
              <p>Keine Daten vorhanden <i nz-icon nzType="frown" nzTheme="outline"></i></p>
            </div>
          </ng-container>

          <ng-container *ngIf="cP.contact_phones?.length > 0">
            <details class="m-t-10">
              <summary>Telefon ({{ cP.contact_phones?.length }})</summary>
              <ng-container *ngFor="let c_Phone of cP.contact_phones">
                <small>
                  <a style="color: black" href="tel:{{c_Phone.phonenumber}}" nz-tooltip [nzTooltipTitle]="c_Phone.type"
                     class="m-l-10"><i nz-icon
                                       nzType="phone"
                                       nzTheme="outline"></i>{{ c_Phone.phonenumber ? (' ' + c_Phone.phonenumber) : ' - ' }}
                  </a> <br>
                </small>
              </ng-container>
            </details>
          </ng-container>


          <ng-container *ngIf="cP.contact_emails?.length > 0">
            <details class="m-t-10">
              <summary>E-Mail ({{ cP.contact_emails?.length }})</summary>
              <ng-container *ngFor="let c_Email of cP.contact_emails">
                <small nz-tooltip [nzTooltipTitle]="c_Email.type" class="m-l-10">
                  <a style="color: black;" href="mailto:{{c_Email.email}}">
                    <!--                                    <i nz-icon nzType="mail" nzTheme="outline"></i>-->
                    {{ c_Email.email ? (' ' + c_Email.email) : ' - ' }}
                  </a></small> <br>
              </ng-container>
            </details>
          </ng-container>
        </div>
      </nz-tab>
    </ng-container>

  </nz-tabset>
</nz-card>

