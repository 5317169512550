import {Component, inject, Input, OnInit} from '@angular/core';
import {File, Image} from '../../../../../../database-models';
import {ThemeConstantService} from '../../services/theme-constant.service';
import {ApiService} from '../../../services/api.service';
import {environment} from '../../../../environments/environment';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';

export interface ModalData {
	ticketId?: string;
  objectId?: string;
	worklogId?: string;
}

@Component({
  selector: 'app-file-browser',
  templateUrl: './file-browser.component.html',
  styleUrls: ['./file-browser.component.scss']
})
export class FileBrowserComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  public themeColors = this.colorConfig.get().colors;

  public fileTypes = {

    'application/pdf': {
      twoToneColor: this.themeColors.red,
      icon: 'file-pdf'
    },
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      twoToneColor: this.themeColors.blue,
      icon: 'file-word'
    },
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      twoToneColor: this.themeColors.cyan,
      icon: 'file-excel'
    },
    'application/vnd.ms-powerpoint': {
      twoToneColor: this.themeColors.volcano,
      icon: 'file-ppt'
    },
    'application/txt': {
      twoToneColor: this.themeColors.purple,
      icon: 'file-text'
    },
    'image/gif': {
      twoToneColor: this.themeColors.gold,
      icon: 'file-gif'
    },
    'image/png': {
      twoToneColor: this.themeColors.gold,
      icon: 'file-image'
    },
    'image/jpeg': {
      twoToneColor: this.themeColors.gold,
      icon: 'file-image'
    },
    'application/zip': {
      twoToneColor: this.themeColors.orange,
      icon: 'file-zip'
    }
  };

  @Input()
  public ticketId = '';

  @Input()
  public objectId = '';

  @Input()
  public worklogId = '';

  public files: File[];
  public selectedFiles: File[] = [];

  private imageTypes = ['image/jpeg', 'image/png', 'image/gif'];

  public backendURL = environment.apiURL;

  constructor(
    private colorConfig: ThemeConstantService,
    private api: ApiService,
    private modalRef: NzModalRef
  ) { }

  ngOnInit(): void {
    this.ticketId ??= this.data.ticketId;
    this.objectId ??= this.data.objectId;
    this.worklogId ??= this.data.worklogId;
    this.getFiles();
  }

  public fileIsImage(type: string): boolean {
    return this.imageTypes.includes(type);
  }

  public getFiletype(type: string): any {
    let fileResponse;

    fileResponse = this.fileTypes[type];
    if (!fileResponse) {
      fileResponse = {
        twoToneColor: this.themeColors.grey,
        icon: 'file'
      };
    }

    return fileResponse;
  }

  public getFiles(): void {
    this.files = [];
    let where:any = {};

    if (this.ticketId) {
      where.ticket_id = this.ticketId;
    }

    if (this.worklogId) {
      where.worklog_id = this.worklogId;
    }

    if (this.objectId) {
      where.object_id = this.objectId;
    }

    this.api.getFiles(where).subscribe((files: File[]) => {
      this.files = files;
    });
  }

  public toggleFile(file: File): void {
    const index = this.selectedFiles.findIndex(f => f.id === file.id);
    if (index > -1) {
      this.selectedFiles.splice(index, 1);
    } else {
      this.selectedFiles.push(file);
    }

  }

  public ok(): void {
    this.modalRef.close(this.selectedFiles);
  }

}
