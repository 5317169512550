import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {Object, User, Users_Objects} from '../../../../../../database-models';
import {NzMessageService} from 'ng-zorro-antd/message';

@Component({
  selector: 'app-user-object-permission',
  templateUrl: './user-object-permission.component.html',
  styleUrls: ['./user-object-permission.component.scss']
})
export class UserObjectPermissionComponent implements OnInit {

  public users: User[];
  public objects: Object[];

  public isLoading: boolean = false;

  constructor(
    public api: ApiService,
    private message: NzMessageService,
  ) {
  }

  ngOnInit(): void {
    this.getMatrixData();
  }

  public getMatrixData(): void {
    this.isLoading = true;
    this.api.getObjects().subscribe((objs: Object[]) => {
      this.objects = objs;
      this.api.getAllUsers().subscribe((users: User[]) => {
        this.users = users;

        for (let user of this.users) {
          // @ts-ignore
          user._objects = {};
          for (let object of this.objects) {
            // @ts-ignore
            user._objects[object.id] = user.User_Objects?.some(obj => obj.id === object.id);
          }
        }
        this.isLoading = false;

      });
    });
  }

  public toggleAll(user: User, value: boolean) {

    // @ts-ignore
    user._objects = {};

    for (let object of this.objects) {
      // @ts-ignore
      user._objects[object.id] = value;
    }
  }

  public save(): void {
    this.isLoading = true;
    const toSend: Users_Objects[] = [];

    this.users.forEach(user => {
      // @ts-ignore
      Object.keys(user._objects).forEach(objectsId => {
        // @ts-ignore
        if (user._objects[objectsId]) {
          toSend.push({userId: user.id, objectId: objectsId});
        }
      });
    });
    this.api.setUsersObjectAssociation(toSend).subscribe(value => {
      this.message.success('Berechtigungen wurden gespeichert');
      this.isLoading = false;
    }, error => {
      this.message.error('Berechtigungen konnten nicht gespeichert werden!');
    });
  }
}
