<nz-spin [nzSpinning]="isLoading">
    <div style="height: 300px">
        <canvas ngChartjs *ngIf="chart.data.datasets.length > 0"
                [datasets]="chart.data.datasets"
                [labels]="chart.data.labels"
                [options]="options"
                [legend]="true"
                [colors]="lineChartColors"
                [chartType]="'line'"
                width="100%" height="300">
        </canvas>
    </div>
</nz-spin>
