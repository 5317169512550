import {Component, Injector, Input, OnInit} from '@angular/core';
import {MasterTableComponent} from '../../master-table/master-table.component';
import {CheckList, CheckListTemplate, WorkLog} from '../../../../../../../database-models';
import {ApiService} from '../../../../services/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import {Router} from '@angular/router';
import {EditChecklistTemplateComponent} from '../edit-checklist-template/edit-checklist-template.component';
import {DatePipe} from '@angular/common';
import {ChecklistDetailsComponent, ModalData as ChecklistDetailsModalData} from '../checklist-details/checklist-details.component';

@Component({
  selector: 'app-check-list-list',
  templateUrl: './check-list-list.component.html',
  styleUrls: ['./check-list-list.component.scss']
})
export class CheckListListComponent implements OnInit {

  @Input()
  public checkLists: CheckList[] = [];

  @Input()
  public worklog: WorkLog;

  constructor(
    private api: ApiService,
    private message: NzMessageService,
    public router: Router,
    private modalService: NzModalService
  ) {}

  public ngOnInit(): void {}



  public openChecklist(list?: CheckList): void {
    const editModal = this.modalService.create<ChecklistDetailsComponent, ChecklistDetailsModalData>({
      nzContent: ChecklistDetailsComponent,
      nzData: {
        checkList: list
      },
      nzFooter: null,
      nzWidth: 600,
    });
    editModal.afterClose.subscribe((data: any) => {
      console.log('data', data);
    });
  }

  public deleteCheckList(checkList: CheckList, index: number): void {
    this.modalService.confirm({
      nzTitle: '<i>Checkliste löschen</i>',
      nzContent: 'Sind Sie sicher, dass Sie die Checkliste unwiderruflich löschen möchten?',
      nzOnOk: () => {
        this.api.deleteCheckList(checkList.id).subscribe(res => {
          this.message.success('Checkliste wurde gelöscht');
          this.checkLists.splice(index, 1);
        }, error => {
          this.message.error('Checkliste konnte nicht gelöscht werden');
        });
      }
    });
  }

}
