import {Component, inject, Input, OnInit} from '@angular/core';
import {GroupedWasteplan, Task, WasteCategory, Wasteplan, wasteplanColor} from '../../../../../../../database-models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {NzMessageService} from 'ng-zorro-antd/message';

// @ts-ignore
import moment from 'moment-timezone';
import {momentTimezone} from '@mobiscroll/angular';
import {color} from 'chart.js/helpers';
import {EditTaskComponent, ModalData as EditTaskModalData} from '../../task/edit-task/edit-task.component';
import {group} from '@angular/animations';

export interface ModalData {
	wasteplan: Wasteplan;
  objId: string;
}

@Component({
  selector: 'app-edit-wasteplan',
  templateUrl: './edit-wasteplan.component.html',
  styleUrls: ['./edit-wasteplan.component.scss']
})
export class EditWasteplanComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  public momentPlugin = momentTimezone;

  @Input() public wasteplan: Wasteplan;
  @Input() public objId: string;

  public wasteplanColors: string[] = wasteplanColor;
  public groupedWasteplans: GroupedWasteplan[] = [];

  public title: string = 'Abfuhrplan erstellen';


  public wasteCategories: WasteCategory[] = [
    'Papier',
    'Gelber Sack',
    'Biomüll',
    'Restmüll',
    'Glas',
    'Altpapier',
    'Elektroschrott',
    'Sperrmüll',
    'Schadstoffe',
    'Altmetall',
    'Batterien',
    'Kunststoffe',
    'Textilien',
    'Gartenabfälle',
    'Altglas',
    'Holz',
    'Sonstiges'
  ];


  public isLoading = false;

  public wasteplanForm: FormGroup;

  constructor(
    public api: ApiService,
    private modalService: NzModalService,
    private modal: NzModalRef,
    public formBuilder: FormBuilder,
    private message: NzMessageService,
  ) {
  }

  ngOnInit(): void {
    this.wasteplan ??= this.data.wasteplan;
    this.objId ??= this.data.objId;
    if (this.wasteplan) {
      this.title = 'Abfuhrplan bearbeiten';
      this.wasteplanForm = this.formBuilder.group(
        {
          title: [this.wasteplan.title, [Validators.required]],
          time: [this.wasteplan.time, [Validators.required]],
          color: [this.wasteplan.color],
          description: [this.wasteplan.description],
          inTime: [this.wasteplan.inTime],
          outTime: [this.wasteplan.outTime],
          object_id: [this.objId]
        }
      );
    } else {
      this.title = 'Abfuhrplan erstellen';
      this.wasteplan = {} as Wasteplan;
      this.wasteplanForm = this.formBuilder.group(
        {
          title: ['', [Validators.required]],
          time: [moment().format(), [Validators.required]],
          color: [null],
          description: [''],
          inTime: [null],
          outTime: [null]
        }
      );
    }
  }

  public selectDates(key: 'IN' | 'OUT', count: number, workDay?: boolean): void {
    let time = moment(this.wasteplanForm.get('time').value);

    if (key === 'OUT') {
      time = time.subtract(count, 'day');
      while (workDay && this.checkWeekend(time)) {
        time = time.subtract(1, 'day');
      }
      this.wasteplanForm.get('outTime').setValue(time.format());
    }

    if (key === 'IN') {
      time = time.add(count, 'day');
      while (workDay && this.checkWeekend(time)) {
        time = time.add(1, 'day');
      }

      this.wasteplanForm.get('inTime').setValue(time.format());
    }


    this.wasteplanForm.markAsTouched();
  }


  public selectColor(color?: string): void {
    this.wasteplan.color = color;
    this.wasteplanForm.get('color').setValue(color);
    this.wasteplanForm.markAsTouched();
  }

  public checkWeekend(date: moment.Moment): boolean {
    const weekday: number = date.day();
    return weekday === 0 || weekday === 6;
  }

  public save(): void {
    this.isLoading = true;
    const toSend = this.wasteplanForm.getRawValue();
    this.wasteplan.title = toSend.title;
    this.wasteplan.description = toSend.description;
    this.wasteplan.time = toSend.time;
    this.wasteplan.inTime = toSend.inTime;
    this.wasteplan.outTime = toSend.outTime;
    this.wasteplan.color = toSend.color;

    this.wasteplan.object_id = this.objId;

    console.log('toSend', this.wasteplan);
    this.api.setWasteplan(this.wasteplan).subscribe(value => {
      console.log(value);
      this.modal.close(value);
      this.message.success('Abfuhrplan wurde erstellt');
      this.isLoading = false;
    }, onerror => {
      console.log(onerror);
      this.isLoading = false;
    });
  }

  public editTask(wasteplan?: Wasteplan): void {
    const taskModal = this.modalService.create<EditTaskComponent, EditTaskModalData>({
      nzContent: EditTaskComponent,
      nzData: {
        wasteplan: wasteplan
      },
      nzWidth: '1300px',
      nzFooter: null,
      nzClosable: false,
    });
    taskModal.afterClose.subscribe((data: any) => {
      this.modal.close();
    });
  }

  public close(): void {
    if (this.wasteplanForm.touched
    ) {
      this.modalService.confirm({
        nzTitle: '<i>Bearbeiten abbrechen</i>',
        nzContent: 'Sind Sie sich sicher, dass Sie diesen Vorgang abbrechen möchten? Alle Änderungen gehen verloren.',
        nzOnOk: () => this.modal.close()
      });
    } else {
      this.modal.close();
    }
  }

  protected readonly;
  momentTimezone = momentTimezone;
  protected readonly color = color;
}
