import {Component, Input, OnInit} from '@angular/core';
import {SharedTicket, SharedWorkLog, WorkLog} from "../../../../../../../../database-models";
import {ApiService} from "../../../../../services/api.service";
import {MessageService} from "../../../../services/message.service";
import {NzModalService} from "ng-zorro-antd/modal";
import {Router} from "@angular/router";

@Component({
  selector: 'app-worklog-share-list',
  templateUrl: './worklog-share-list.component.html',
  styleUrls: ['./worklog-share-list.component.css']
})
export class WorklogShareListComponent implements OnInit {

  @Input() public worklog: WorkLog;

  @Input()
  public sharedWorklog: SharedWorkLog[];

  public copyToClipboard: string;
  public isLoading = false;

  constructor(
      public api: ApiService,
      private message: MessageService,
      private modalService: NzModalService,
      public router: Router,
  ) { }

  ngOnInit(): void {
    this.getSharedWorklogs();
  }


  public getSharedWorklogs(): void {
    this.isLoading = true
    this.api.getSharedWorklog(this.worklog.id).subscribe((sharedWorklogs: SharedWorkLog[]) => {
      this.sharedWorklog = sharedWorklogs
      this.isLoading = false;
    })
  }

  public deleteSharedWorkLogLink(sharedWorklog: SharedWorkLog): void {
    this.modalService.warning({
      nzTitle: '<i>Link löschen</i>',
      nzContent: 'Sind Sie sicher, dass Sie diesen geteilten Link löschen möchten?',
      nzOnOk: () => {
        this.api.deleteSharedWorkLog(sharedWorklog.id).subscribe(() => {
          this.message.success('Erfolgreich gelöscht');
          this.getSharedWorklogs();
        })
      },
      nzCancelText: 'Abbrechen',
    });
  }

  public clipboardCopy(sharedTicket: SharedTicket): void {
    this.copyToClipboard = sharedTicket.link
    this.message.success('Link wurde in die Zwischenablage kopiert!')
  }
}
