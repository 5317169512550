import {Component, OnInit, ViewChild} from '@angular/core';
import {Signature} from "../../../../../../database-models";
import SignaturePad from "signature_pad";
import {ApiService} from "../../../services/api.service";
import {NzModalRef} from "ng-zorro-antd/modal";
import {NzMessageService} from "ng-zorro-antd/message";

@Component({
  selector: 'app-signature-worklog',
  templateUrl: './signature-worklog.component.html',
  styleUrls: ['./signature-worklog.component.scss']
})
export class SignatureWorklogComponent implements OnInit {

  public signature: Signature;
  public signaturePad: SignaturePad;

  @ViewChild('canvas', {static: true})
  public signaturePadElement: any;


  constructor(
    public api: ApiService,
    private modal: NzModalRef,
  ) {}

  ngOnInit(): void {

    this.signature = {
      createdAt: new Date().toISOString()
    } as Signature;

    this.signaturePad = new SignaturePad(this.signaturePadElement.nativeElement);

  }

  public save(): void {
    this.signature.canvas = this.signaturePad.toDataURL();
    this.modal.triggerOk();
  }
}
