import {Component, Input, OnInit} from '@angular/core';
import {SharedTicket, Ticket} from "../../../../../../../database-models";
import {ApiService} from "../../../../services/api.service";
import {MessageService} from "../../../services/message.service";
import {NzModalService} from "ng-zorro-antd/modal";
import {Router} from "@angular/router";

@Component({
	selector: 'app-ticket-share-list',
	templateUrl: './ticket-share-list.component.html',
	styleUrls: ['./ticket-share-list.component.css']
})
export class TicketShareListComponent implements OnInit {

	@Input() ticket: Ticket;

	public sharedTickets: SharedTicket[] = [];
	public isLoading = false;
	public copyToClipboard: string;

	constructor(
		public api: ApiService,
		private message: MessageService,
		private modalService: NzModalService,
		public router: Router,
	) { }


	ngOnInit(): void {
		this.getSharedTickets();
	}


	public getSharedTickets(): void {
		this.isLoading = true
		this.api.getSharedTicket(this.ticket.id).subscribe((sharedTicket: SharedTicket[]) => {
			this.sharedTickets = sharedTicket
			this.isLoading = false;
		})
	}

	public deleteSharedTicketLink(sharedTicket: SharedTicket): void {
		this.modalService.warning({
			nzTitle: '<i>Link löschen</i>',
			nzContent: 'Sind Sie sicher, dass Sie diesen geteilten Link löschen möchten?',
			nzOnOk: () => {
				this.api.deleteSharedTicket(sharedTicket.id).subscribe(() => {
					this.message.success('Erfolgreich gelöscht');
					this.getSharedTickets();
				})
			},
			nzCancelText: 'Abbrechen',
		});
	}


	public clipboardCopy(sharedTicket: SharedTicket): void {
		this.copyToClipboard = sharedTicket.link
		this.message.success('Link wurde in die Zwischenablage kopiert!')
	}
}
