import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TimeTrackingWorkWeek, User} from '../../../../../../../database-models';
import {ApiService} from '../../../../services/api.service';

@Component({
  selector: 'app-time-tracking-work-week',
  templateUrl: './time-tracking-work-week.component.html',
  styleUrls: ['./time-tracking-work-week.component.css']
})
export class TimeTrackingWorkWeekComponent implements OnInit {

  formatterHour = (value: number): string => `${value} h`;
  parserHour = (value: string): string => value.replace(' h', '');

  public tmpWorkWeek: TimeTrackingWorkWeek = {
    monday: 8,
    tuesday: 8,
    wednesday: 8,
    thursday: 8,
    friday: 8,
    saturday: 0,
    sunday: 0,
  };

  public translation = {
    monday: 'MO',
    tuesday: 'DI',
    wednesday: 'MI',
    thursday: 'DO',
    friday: 'FR',
    saturday: 'SA',
    sunday: 'SO',
  }

  @Input()
  public timeTrackingWorkWeek: TimeTrackingWorkWeek;

  @Output()
  public timeTrackingWorkWeekChange: EventEmitter<TimeTrackingWorkWeek> = new EventEmitter<TimeTrackingWorkWeek>();

  constructor(
    private api: ApiService
  ) {
  }

  ngOnInit(): void {
    console.log(this.tmpWorkWeek);
    if (this.timeTrackingWorkWeek) {
      this.tmpWorkWeek = this.workWeekToHours(this.timeTrackingWorkWeek);
    }
    this.save();
  }

  public save(): void {
    this.timeTrackingWorkWeek = this.workWeekToSeconds(this.tmpWorkWeek);
    this.timeTrackingWorkWeekChange.emit(this.timeTrackingWorkWeek);
  }

  public sum(): number {
    let sum = 0;
    for (let key in this.tmpWorkWeek) {
      sum += this.tmpWorkWeek[key];
    }
    return sum;
  }

  private workWeekToHours(ww: TimeTrackingWorkWeek): TimeTrackingWorkWeek {
    return {
      monday: ww.monday / 3600,
      tuesday: ww.tuesday / 3600,
      wednesday: ww.wednesday / 3600,
      thursday: ww.thursday / 3600,
      friday: ww.friday / 3600,
      saturday: ww.saturday / 3600,
      sunday: ww.sunday / 3600,
    }
  }

  private workWeekToSeconds(ww: TimeTrackingWorkWeek): TimeTrackingWorkWeek {
    return {
      monday: ww.monday * 3600,
      tuesday: ww.tuesday * 3600,
      wednesday: ww.wednesday * 3600,
      thursday: ww.thursday * 3600,
      friday: ww.friday * 3600,
      saturday: ww.saturday * 3600,
      sunday: ww.sunday * 3600,
    }
  }


  asIsOrder(a, b) {
    return 0;
  }

}
