import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {Object, WorkLog, WorklogBulUpdate} from "../../../../../../database-models";
import moment from 'moment';
import {HttpParams} from "@angular/common/http";
import {EditWorklogComponent, ModalData as EditWorklogModalData} from "../edit-worklog/edit-worklog.component";
import {NzModalService} from "ng-zorro-antd/modal";
import {NzMessageService} from "ng-zorro-antd/message";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {DatePipe} from "@angular/common";
import {NewWorklogComponent, ModalData as NewWorklogModalData} from "../new-worklog/new-worklog.component";
import {ExportService} from "../../services/export.service";

@Component({
	selector: 'app-object-worklog-list',
	templateUrl: './object-worklog-list.component.html',
	styleUrls: ['./object-worklog-list.component.css']
})
export class ObjectWorklogListComponent implements OnInit {

	@Input() public object: Object;

	public worklogs: WorkLog[];

	public checked = false;
	public indeterminate = false;
	public listOfCurrentPageData: WorkLog[] = [];
	public setOfCheckedId = new Set<string>();

	public isLoading = false;

	public sortOrders = {
		details: null,
		task: null,
		time: null
	};

	public filter = {
		objectIds: [],
		billed: null,
	};

	constructor(
		public api: ApiService,
		private modalService: NzModalService,
		private message: NzMessageService,
		private datePipe: DatePipe,
		public exportService: ExportService,
	) {
	}

	ngOnInit(): void {
		if (this.object.id) {
			this.filter.objectIds.push(this.object.id)
		}

		this.getWorkLogsByObject();
	}

	public getWorkLogsByObject(): void {
		this.isLoading = true;
		let httpParams = new HttpParams();

		httpParams = httpParams.append('objectIds', JSON.stringify(this.filter.objectIds));

		if (this.filter.billed) {
			httpParams = httpParams.append('billed', 'true');
		}

		this.api.getWorklogs(httpParams).subscribe((worklogs: WorkLog[]) => {
			this.worklogs = worklogs;
			this.isLoading = false;
			console.log(worklogs);
		}, onerror => {
			console.log(onerror);
			this.message.error('Failed')
		});
	}

	public bulkUpdateFinalized(): void {
		this.modalService.confirm({
			nzTitle: '<i>Finalisierung aller ' + this.setOfCheckedId.size + ' Arbeitsprotokolle?</i>',
			nzContent: 'Sind Sie sicher, dass Sie die Finalisierung dieser Arbeitsprotokolle vornehmen möchten? Dies ist nicht mehr änderbar.',
			nzOnOk: () => {
				const bulk: WorklogBulUpdate = {
					ids: Array.from(this.setOfCheckedId),
					field: 'finalized',
					value: new Date().toISOString()
				} as WorklogBulUpdate;

				this.api.updateWorkLogBulk(bulk).subscribe(res => {
					this.message.success('Das Update war erfolgreich!');
					this.getWorkLogsByObject();
					this.setOfCheckedId.clear();
				}, error => {
					this.message.error('Das Update war nicht erfolgreich!');
				});
			}
		});
	}

	public bulkUpdateBilled(value: any): void {
		this.modalService.confirm({
			nzTitle: '<i>Abrechnung aller ' + this.setOfCheckedId.size + ' Arbeitsprotokolle ändern</i>',
			nzContent: 'Sind Sie sicher, dass Sie die Abrechnung dieser Arbeitsprotokolle vornehmen möchten?',
			nzOnOk: () => {
				const bulk: WorklogBulUpdate = {
					ids: Array.from(this.setOfCheckedId),
					field: 'billed',
					value: value
				} as WorklogBulUpdate;

				this.api.updateWorkLogBulk(bulk).subscribe(res => {
					this.message.success('Das Update war erfolgreich!');
					this.getWorkLogsByObject();
					this.setOfCheckedId.clear();
				}, error => {
					this.message.error('Das Update war nicht erfolgreich!');
				});
			}
		});
	}

	public orderFn = {
		details: (a, b) => a.object?.name?.localeCompare(b.object?.name) || a.customer?.firstname?.localeCompare(b.customer?.firstname) && a.customer?.lastname?.localeCompare(b.customer?.lastname) || a.customer?.companyname?.localeCompare(b.customer?.companyname),
		task: (a, b) => a.description?.localeCompare(b.description),
		time: (a, b) => a.end?.localeCompare(b.end) || a.finalized?.localeCompare(b.finalized)
	};

	public sortChanged(change: any) {
		localStorage.setItem('tableheaderWorklog', JSON.stringify(this.sortOrders));
	}

	public convertTime(milliseconds: number): string {
		if (!milliseconds) {
			return null;
		}

		let seconds = milliseconds / 1000;

		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds - (hours * 3600)) / 60);
		seconds = Math.floor(seconds - (hours * 3600) - (minutes * 60));

		const formattedHours = hours.toString().padStart(2, '0');
		const formattedMinutes = minutes.toString().padStart(2, '0');

		return `${formattedHours}:${formattedMinutes}`;
	}

	public editWorklog(worklog?: WorkLog, isNew?: boolean): void {
		if (isNew) {
			const newModal = this.modalService.create<NewWorklogComponent, NewWorklogModalData>({
				nzContent: NewWorklogComponent,
				nzData: {
					object: this.object
				},
				nzWidth: '1000px',
				nzFooter: null
			});
			newModal.afterClose.subscribe(data => {
				this.getWorkLogsByObject();
			});

		} else {
			const newModal = this.modalService.create<EditWorklogComponent, EditWorklogModalData>({
				nzContent: EditWorklogComponent,
				nzData: {
					worklogId: worklog.id
				},
				nzWidth: '1500px',
				nzFooter: null,
				nzMaskClosable: true,
			});

			newModal.afterClose.subscribe((data: any) => {
				this.getWorkLogsByObject();
			});
		}
	}

	public onAllChecked(checked: boolean): void {
		this.listOfCurrentPageData.forEach(({id}) => this.updateCheckedSet(id, checked));
		this.refreshCheckedStatus();
	}

	public refreshCheckedStatus(): void {
		this.checked = this.listOfCurrentPageData.every(({id}) => this.setOfCheckedId.has(id));
		this.indeterminate = this.listOfCurrentPageData.some(({id}) => this.setOfCheckedId.has(id)) && !this.checked;
	}

	public updateCheckedSet(id: string, checked: boolean): void {
		if (checked) {
			this.setOfCheckedId.add(id);
		} else {
			this.setOfCheckedId.delete(id);
		}
	}

	public onItemChecked(id: string, checked: boolean): void {
		this.updateCheckedSet(id, checked);
		this.refreshCheckedStatus();
	}

	public onCurrentPageDataChange(listOfCurrentPageData: WorkLog[]): void {
		this.listOfCurrentPageData = listOfCurrentPageData;
		this.refreshCheckedStatus();
	}

	public exportReport(): void {
		const exportJSON: any[] = [];
		const selectedWorklogs = [];
		for (let id of this.setOfCheckedId) {
			let item = this.worklogs.find(wl => wl.id === id);
			selectedWorklogs.push(item);
		}
		for (const wl of selectedWorklogs) {
			const row = {
				'Datum': this.datePipe.transform(wl.start, 'dd.MM.YY'),
				'Start / Ende': {
					content: this.datePipe.transform(wl.start, 'dd.MM.YY hh:mm') + '\n' + (wl.end ? (this.datePipe.transform(wl.end, 'dd.MM.YY hh:mm')) : '-'),
					styles: {
						fontSize: 8
					}
				},
				'Objekt/Kunde': wl.object ? (wl.object.name + (wl.room ? ' (' + wl.room.name + ')' : '')) : (wl.customer?.firstname ? (wl.customer?.firstname + ' ' + wl.customer?.lastname) : wl.customer?.companyname),
				Mitarbeiter: wl.user ? wl.user.name : '',
				Taetigkeit: wl.description,


				'Erbrachte Leistungen': function () {
					let returnString = '';
					if (wl.completedServices && Array.isArray(wl.completedServices)) {
						let index = 1;
						for (const item of wl.completedServices) {
							returnString += item.name;
							if (index < wl.completedServices.length) {
								returnString += '\n';
							}
							index++;
						}
					}
					return returnString;
				}()
			};


			exportJSON.push(row);
		}

		var doc = new jsPDF();

		doc.text('Arbeitsprotokolle', 15, 10);


		autoTable(doc, {
			head: [Object.keys(exportJSON[0])],
			body: exportJSON.map(obj => Object.values(obj)),
			headStyles: {
				fillColor: [255, 143, 0]
			}
		})

		doc.save('Arbeitsprotokolle' + '.pdf')


	}

	public getDuration(worklog: WorkLog | any): any {
		const end = moment(new Date(worklog.end));
		const start = moment(new Date(worklog.start));
		return moment.duration(end.diff(start));
	}

	public export(type: 'excel' | 'csv' | 'pdf'): void {
		const exportJSON: any[] = [];
		const selectedWorklogs = [];
		for (let id of this.setOfCheckedId) {
			let item = this.worklogs.find(wl => wl.id === id);
			selectedWorklogs.push(item);
		}
		for (const wl of selectedWorklogs) {
			const row = {
				Start: this.datePipe.transform(wl.start, 'dd.MM.y H:mm'),
				Ende: this.datePipe.transform(wl.end, 'dd.MM.y H:mm'),
				'Objekt/Kunde': wl.object ? (wl.object.name + (wl.room ? ' (' + wl.room.name + ')' : '')) : (wl.customer?.firstname ? (wl.customer?.firstname + ' ' + wl.customer?.lastname) : wl.customer?.companyname),
				Taetigkeit: wl.description,
				Mitarbeiter: wl.user ? wl.user.name : '',
				Tage: wl.end ? this.getDuration(wl).days() : '',
				Stunden: wl.end ? this.getDuration(wl).hours() : '',
				Minuten: wl.end ? this.getDuration(wl).minutes() : '',
				Ticket: wl.ticket ? wl.ticket.number : '',
				Aufgabe: wl.task ? wl.task.title : '',
				Finalisiert: this.datePipe.transform(wl.finalized, 'dd.MM.y H:mm'),
				Material: function () {
					let returnString = '';
					let index = 1;
					for (const con of wl.consumed) {
						returnString += con.Consumable_Ticket.number + ' ' + con.name;
						if (index < wl.consumed.length) {
							returnString += '\n';
						}
						index++;
					}
					return returnString;
				}(),
				'Erbrachte Leistungen': function () {
					let returnString = '';
					if (wl.completedServices && Array.isArray(wl.completedServices)) {
						let index = 1;
						for (const item of wl.completedServices) {
							returnString += item.name;
							if (index < wl.completedServices.length) {
								returnString += '\n';
							}
							index++;
						}
					}
					return returnString;
				}()
			};


			exportJSON.push(row);
		}

		switch (type) {
			case 'excel':
				this.exportService.exportJsonToExcel(exportJSON, 'Arbeitsprotokoll');
				break;
			case 'csv':
				this.exportService.exportToCsv(exportJSON, 'Arbeitsprotokoll');
				break;
			case 'pdf':
				this.exportService.exportToPDF(exportJSON, 'Arbeitsprotokolle');
				break;
		}


	}

	public isFinalizedInArray(): boolean {
		for (let id of this.setOfCheckedId) {
			const worklog = this.worklogs.find(wl => wl.id === id);
			if (worklog?.finalized) {
				return true;
			}
		}
		return false;
	}

	protected readonly moment = moment;
}
