import {Component, inject, Input, OnInit} from '@angular/core';
import {ServiceCatalog, ServiceCatalogItem, WorkLog} from "../../../../../../database-models";
import {ApiService} from "../../../services/api.service";
import {NZ_MODAL_DATA, NzModalModule, NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {MbscCalendarEvent} from "@mobiscroll/angular";

export interface ModalData {
	propergate?: boolean;
  withoutId?: boolean;
  objId?: string;
}

@Component({
  selector: 'app-servicecatalog-checklist',
  templateUrl: './servicecatalog-checklist.component.html',
  styleUrls: ['./servicecatalog-checklist.component.scss']
})
export class ServicecatalogChecklistComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  public serviceCatalogs: ServiceCatalog[];

  public checkedServices: ServiceCatalogItem[] = [];
  public checkedServicesCapsuled: ServiceCatalogItem[] = [];

  @Input() public objId: string

  @Input() public propergate: boolean = false;

  @Input() public withoutId: boolean = false;



  public isLoading = false;
  public showAllCatalogs = false;

  constructor(
      public api: ApiService,
      private modal: NzModalRef,
  ) {
  }

  ngOnInit(): void {
    this.objId ??= this.data.objId;
    this.propergate ??= this.data.propergate;
    this.withoutId ??= this.data.withoutId;
    this.getServiceCatalogs(this.objId);
  }


  public switchChanged(): void {
    if (this.showAllCatalogs) {
      this.getServiceCatalogs();
    } else {
      this.getServiceCatalogs(this.objId);
    }
  }

  public getServiceCatalogs(objId?: string): void {
    if (objId) {
      this.isLoading = true;
      this.api.getCatalogbyObject(objId).subscribe((serv: ServiceCatalog[]) => {
        this.serviceCatalogs = serv;
        this.isLoading = false;
      });
    } else {
      this.isLoading = true;
      this.api.getAllServiceCatalog().subscribe((serv: ServiceCatalog[]) => {
        this.serviceCatalogs = serv;
        this.isLoading = false;
      });
    }
  }

  public updateSelectedServices(clicked: ServiceCatalogItem, parent: ServiceCatalogItem) {
    this.checkedServices = [];
    this.checkedServicesCapsuled = [];

    if (this.propergate) {
      this.updateCheckedRecusive(clicked.childservices, clicked._checked);
    }

    for (let catalog of this.serviceCatalogs) {
      this.generateItemsList(catalog.service_catalog_items);
      this.checkedServicesCapsuled = [...this.checkedServicesCapsuled, ...this.generateItemsListCapsuled(catalog.service_catalog_items)];
    }
  }

  private generateItemsList(items: ServiceCatalogItem[]): void {
    for (let itm of items) {
      if (itm._checked) {
        this.checkedServices.push({
          name: itm.name,
          id: itm.id,
          index: itm.index,
          order: itm.order
        });
      }
      if (itm.childservices) {
        this.generateItemsList(itm.childservices);
      }
    }
  }

  private generateItemsListCapsuled(items: ServiceCatalogItem[]): ServiceCatalogItem[] {
    let tmpItems: ServiceCatalogItem[] = [];
    for (let itm of items) {
      if (itm._checked) {
        tmpItems.push({
          name: itm.name,
          id: this.withoutId ? undefined : itm.id,
          index: itm.index,
          order: itm.order,
          childservices: this.generateItemsListCapsuled(itm.childservices)
        });
      }

    }
    return tmpItems;
  }

  private updateCheckedRecusive(items: ServiceCatalogItem[], checked: boolean): void {
    for (let itm of items) {
      itm._checked = checked;
      if (itm.childservices) {
        this.updateCheckedRecusive(itm.childservices, checked);
      }
    }
  }

  public addToTask(): void {
    this.modal.close({flat: this.checkedServices, capsuled: this.checkedServicesCapsuled});
  }
}
