import {Component, OnInit} from '@angular/core';
import {localeDe, MbscCalendarEvent, MbscEventcalendarOptions, MbscPageChangeEvent, MbscResource} from '@mobiscroll/angular';
import {ApiService} from '../../../../services/api.service';
import {Object, Wasteplan} from '../../../../../../../database-models';
import {EventcalendarBase} from '@mobiscroll/angular/dist/js/core/components/eventcalendar/eventcalendar';
import moment from 'moment';

@Component({
  selector: 'app-wasteplan-calendar',
  templateUrl: './wasteplan-calendar.component.html',
  styleUrls: ['./wasteplan-calendar.component.scss']
})
export class WasteplanCalendarComponent implements OnInit {

  public wastePlans: Wasteplan[];
  public objects: Object[];
  public objectResources: MbscResource[] = [];

  public myEvents: MbscCalendarEvent[] = [];
  public timeOut: MbscCalendarEvent[] = [];
  public timeIn: MbscCalendarEvent[] = [];

  public filter = {
    start: moment().startOf('week').toISOString(),
    end: moment().endOf('week').toISOString()
  }

  public eventSettings: MbscEventcalendarOptions = {
    locale: localeDe,
    theme: 'ios',
    themeVariant: 'light',
    clickToCreate: false,
    dragToCreate: false,
    dragToMove: false,
    dragToResize: false,
    eventDelete: false,
    view: {
      timeline: {
        type: 'week',
        resolutionHorizontal: 'day',}
    },
    onEventClick: (event, inst) => {

    }
  };





  constructor(
    private api: ApiService
  ) {
  }

  ngOnInit(): void {
    this.getAllObjects();
    this.getWastePlans();
  }

  public getWastePlans(event?: MbscPageChangeEvent): void {

    if (event) {
      this.filter.start = event.viewStart.toISOString();
      this.filter.end = event.viewEnd.toISOString();
    }

    this.api.getWasteplan(this.filter).subscribe((plans: Wasteplan[]) => {
      this.wastePlans = plans;
      this.myEvents = [];
      this.timeOut = [];
      this.timeIn = [];

      for (let wasteplan of plans) {
        const plan = {
          id: wasteplan.id,
          allDay: true,
          start: wasteplan.time,
          color: wasteplan.color,
          title: wasteplan.title,
          resource: wasteplan.object_id
        } as MbscCalendarEvent;
        //this.myEvents.push(plan);

        const outPlan = Object.assign({}, plan);
        outPlan.start = wasteplan.outTime;
        outPlan.type = 'out';
        outPlan.done = !!wasteplan.out_user_id;
        this.timeOut.push(outPlan);

        if (wasteplan.inTime) {
          const inPlan = Object.assign({}, plan);
          inPlan.start = wasteplan.inTime;
          inPlan.type = 'in';
          inPlan.done = !!wasteplan.in_user_id;
          this.timeIn.push(inPlan);
        }

      }
      this.myEvents = [...this.timeOut, ...this.timeIn];
      console.log(this.myEvents)
    });


  }

  public getAllObjects(): void {
    this.api.getObjects().subscribe(objects => {
      this.objects = objects;
      this.objectResources = this.objects.map(object => {
        return {
          id: object.id,
          name: object.name
        } as MbscResource;
      })
    });
  }

}
