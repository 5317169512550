import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor() { }

  prepareForPrint(selector: string, customStyles: string) {
    const printContent = document.querySelector(selector).innerHTML;
    const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <style>
            * {
              font-family: "Poppins", "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
            }
            @media print {
              @page { margin: 0; }
            }
            ${customStyles}
          </style>
        </head>
        <body onload="window.print(); window.close();">${printContent}</body>
      </html>`
    );
    popupWin.document.close();
  }
}
