<h2>E-Mail Senden</h2>
<nz-spin [nzSpinning]="loading">
  <form nz-form nzLayout="vertical" [formGroup]="mailForm">

    <nz-form-item *ngIf="mailTemplates">
      <nz-form-label nzFor="template" nzRequired>Template</nz-form-label>
      <nz-form-control>
        <nz-select [(ngModel)]="mailTemplate" (ngModelChange)="setTemplate()" [ngModelOptions]="{standalone: true}" [compareWith]="compareById"
                   nzShowSearch
                   nzPlaceHolder="bitte wählen"
        >
          <nz-option nzCustomContent *ngFor="let template of mailTemplates" [nzLabel]="template.name"
                     [nzValue]="template">{{template.name}}
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="to" nzRequired>An</nz-form-label>
      <nz-form-control>
        <input formControlName="to" nz-input placeholder="to"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzFor="summary" nzRequired>Summary</nz-form-label>
      <nz-form-control>
        <input formControlName="summary" nz-input placeholder="summary"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzFor="text" nzRequired>Text</nz-form-label>
      <nz-form-control>
        <quill-editor class="w-100" formControlName="text" [styles]="{height: '150px'}" placeholder=""></quill-editor>
      </nz-form-control>
    </nz-form-item>

  </form>
  <div class="text-right">
    <button class="button" nz-button nzType="default" (click)="cancel()" type="button">Abbrechen</button>
    <button class="button" nz-button nzType="primary" type="submit" (click)="send()"
            [disabled]="mailForm.invalid">
      Senden
    </button>
  </div>

</nz-spin>
