import {Component, inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivityLog, Appuser, Company, Roles, Team, Ticket, User} from '../../../../../../database-models';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {AuthenticationService} from '../../services/authentication.service';
import {EditUserBillingIntervalComponent, ModalData as EditUserBillingIntervalModalData} from '../edit-user-billing-interval/edit-user-billing-interval.component';
import {environment} from "../../../../environments/environment";

export interface ModalData {
	user: User;
}

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});

  public user: User;

  public userForm: FormGroup;
  public passwordForm: FormGroup;

  public teams: Team[];
  public team: Team;
  public userLoading = true;
  public isTouched = false;
  public userId: string;
  public userRole: Roles = 'employee';

  public backendURL = environment.apiURL;

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    public authService: AuthenticationService
  ) {
    this.route.params.subscribe(params => {
        this.userId = params['id'] || null;
        if (!this.userId) {
          this.router.navigate(['users']);
        }
        if (this.userId === 'new') {
          this.user = {
            isActive: true,
            user_type: 'employee',
            prescribeBreakTime: true,
            emailNotification: false,
            default_ticket_contributor: false
          } as User;
          this.user.isAdmin = false;
          this.user.isManager = true;
          this.userLoading = false;
          this.initForm();
        } else {
          this.getUser();
        }
      });
    this.getTeams();
  }

  ngOnInit(): void {

  }

  public getUser(): void {
    this.api.getUsers(this.userId).subscribe((user: User) => {
      this.user = user;
      this.getRole();
      this.initForm();
      this.userLoading = false;
    }, onerror => {
      console.log(onerror);
      this.message.error('Manager konnte nicht geladen werden');
    });
  }

  private getRole(): void {
    if (this.user.isManager) {
      this.userRole = 'manager';
      if (this.user.isAdmin) {
        this.userRole = 'admin';
      }
    } else {
      this.userRole = 'employee';
    }
  }

  private initForm(): void {
    if (this.user?.username) {
      this.user.username = this.user.username.replace(/\s/g, '');
    }
    this.userForm = this.formBuilder.group({
      name: [this.user.name, [Validators.required, Validators.minLength(3)]],
      email: [this.user.email, [Validators.required, Validators.email]],
      username: [this.user.username, [Validators.required, Validators.minLength(5), this.noWhitespaceValidator]],
      phone: [this.user.phone],
      userTeams: [this.user.teams],
      userRole: [this.userRole],
      prescribeBreakTime: [this.user.prescribeBreakTime],
      ticket_contributor: [this.user.default_ticket_contributor],
      emailNotification: [this.user.emailNotification]
    });
    this.passwordForm = this.formBuilder.group({
      password: [this.user.password],
      passwordChangeMessage: [null]
    });
  }

  noWhitespaceValidator(control: FormControl) {
    const hasWhitespace = (control && control.value && control.value.toString() || '').indexOf(' ') >= 0;
    return hasWhitespace ? {whitespace: {value: control.value}} : null;
  }

  public updateUser(): void {
    this.userLoading = true;
    const sendData = this.userForm.getRawValue();
    this.user.name = sendData.name.trim();
    this.user.email = sendData.email.trim();
    this.user.phone = sendData.phone;
    this.user.username = sendData.username.trim();
    this.user.teams = sendData.userTeams;
    this.user.prescribeBreakTime = sendData.prescribeBreakTime;
    this.user.default_ticket_contributor = sendData.ticket_contributor;
    this.user.emailNotification = sendData.emailNotification;

    this.userRole = sendData.userRole;
    this.user.isManager = (this.userRole === 'manager' || this.userRole === 'admin');
    this.user.isAdmin = (this.userRole === 'admin');



    console.log('User to send: ', this.user);
    this.api.setUser(this.user).subscribe((user: User) => {
      this.message.success('Erfolgreich gespeichert');
      if (user.id) {
        this.createUserNotification();
        this.router.navigate(['users/' + user.id]);
        this.writeCreateActvityLog(user.id);
      } else {
        this.getUser();
      }
      this.userLoading = false;
    }, error => {
      this.notification.create(
        'error',
        'Speichern fehlgeschlagen!',
        error.error
      );
      this.userLoading = false;
    });
  }

  public createUserNotification(): void {
    this.notification.blank(
        'Neuer Mitarbeiter wurde angelegt',
        'Der Mitarbeiter wurde mit den persönlichen Zugangsdaten per E-Mail informiert, ' +
        'sollte es Probleme mit der E-mail-Adresse geben, generieren Sie bitte manuell ein Passwort.',
        {  }
    );
  }

  public getTeams(): void {
    this.api.getTeam().subscribe((teams: Team[]) => {
      this.teams = teams;
    }, onerror => {
      console.log(onerror);
      this.message.error('Teams konnten nicht geladen werden');
    });
  }

  public updatePassword(): void {
    const sendData = this.passwordForm.getRawValue();
    this.user.password = sendData.password;
    this.api.changePassword(sendData, this.user.id).subscribe((user: User) => {
      this.message.success('Passwort erfolgreich geändert.');
      this.passwordForm.reset();
      // User Benachrichtigung
      if (sendData.passwordChangeMessage) {
        const mailData = {
          'to': this.user.email,
          'subject': 'Ihr Passwort wurde geändert.',
          'text': 'Ihr neuse Passwort lautet: ' + sendData.password
        };
        this.api.sendMail(mailData).subscribe(() => {
          this.message.success('User erfolgreich benachrichtigt.');
        });
      }
    }, error => {
      this.message.error('Speichern fehlgeschlagen!');
    });
  }

  public cancel(): void {
    if (this.userForm.touched) {
      this.modalService.confirm({
        nzTitle: '<i>Bearbeiten abbrechen</i>',
        nzContent: 'Sind Sie sich sicher, dass Sie diesen Vorgang abbrechen möchten? Alle Änderungen gehen verloren.',
        nzOnOk: () => this.router.navigate(['users'])
      });
    } else {
      this.router.navigate(['users']);
    }
  }

  public passwordCancel(): void {
    this.modalService.warning({
      nzTitle: '<i>Passwort ändern?</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie dieses Passwort ändern möchten?',
      nzOnOk: () => this.updatePassword(),
      nzCancelText: 'Abbrechen',
    });
  }

  public disableUser(): void {
    this.modalService.warning({
      nzTitle: '<i>Benutzer deaktivieren</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie diesen Benutzer deaktivieren möchten?<br><br> Wenn ein Benutzer deaktiviert ist, wird der Zugang für den gewählten Benutzer' +
        '            gesperrt. Ein deaktivierter Benutzer wird bei zukünftigen Abrechnungen <strong>nicht</strong> mehr' +
        '            automatisch verlängert.',
      nzOnOk: () => {
        this.user.isActive = false;
        this.updateUser();
        this.writeActvityLog('DEACTIVATE_EMPLOYEE');
      },
      nzCancelText: 'Abbrechen'
    });
  }

  //Schreibt eine Log wenn ein Mitarbeiter Deaktiviert wird.
  public writeActvityLog(action: 'DEACTIVATE_EMPLOYEE' | 'ACTIVATE_EMPLOYEE'): void {
    const Log: ActivityLog = {
      activity: action,
      description: 'Der Aktivitätsstatus von "' + this.user.name + '" wurde geändert',
      user_id: this.userId
    } as ActivityLog
    this.api.writeActivityLog(Log).subscribe(value => {});
  }

  //Schreibt eine Log wenn ein Mitarbeiter Deaktiviert wird.
  public writeCreateActvityLog(userId: string): void {
    const Log: ActivityLog = {
      activity: 'CREATE_EMPLOYEE',
      description: 'Der Benutzer ' + this.user.name + ' wurde erstellt',
      user_id: userId
    } as ActivityLog
    this.api.writeActivityLog(Log).subscribe(value => {});
  }

  public enableUser(): void {
    this.modalService.success({
      nzTitle: '<i>Benutzer reaktivieren</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie diesen Benutzer reaktivieren möchten? Die Abrechnung für diesen Benutzer wird dadurch fortgesetzt. Es entstehen Kosten, falls der Benutzer für den laufenden Zeitraum noch nicht abgerechnet wurde!',
      nzOnOk: () => {
        this.user.isActive = true;
        this.updateUser();
        this.writeActvityLog('ACTIVATE_EMPLOYEE');
      },
      nzCancelText: 'Abbrechen'
    });
  }

  public editRenewInterval(): void {
    this.modalService.create<EditUserBillingIntervalComponent, EditUserBillingIntervalModalData>({
      nzContent: EditUserBillingIntervalComponent,
      nzData: {
        user: this.user
      },
      nzWidth: 800,
      nzClosable: false,
      nzFooter: null,
      nzOnOk: instance => {
        this.getUser();
      }
    });
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  public deleteAvatar(): void {
    this.modalService.warning({
      nzTitle: "<i>Profilbild löschen?</i>",
      nzContent:
          "Sind Sie sich sicher, dass Sie das Profilbild löschen möchten?",
      nzOnOk: () => this.delete(),
      nzCancelText: "Abbrechen",
    });
  }

  public delete(): void {
    this.api.deleteAvatar(this.user.id).subscribe(value => {
      console.log(value);
      this.getUser();
    });
  }

  public onChange(event: any): void {
    console.log(event);
    if (event.type === 'success') {
      this.message.success('Profilbild wurde hochgeladen/geändert')
      this.getUser();
    } else if (event.type === 'error') {
      this.message.error(event.file.error.error);
      this.getUser();
    }
  }


}
