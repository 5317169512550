<h2>Ticket teilen</h2>
<nz-divider></nz-divider>

    <div nz-col>
        <nz-card style="box-shadow: rgba(136,136,136,0.05) 0px 2px 8px 0px; margin: 0">
            <div nz-col class="m-b-20">
                <form nz-form [formGroup]="sharedForm">
                    <div>
                        <label nz-checkbox [nzChecked]="true" [nzDisabled]="true">Standard Freigaben</label>
                        <p>
                            Titel, Beschreibung und alle anderen Grundinformationen
                        </p>
                    </div>
                    <div>
                        <label nz-checkbox formControlName="files">Dateien</label>
                        <p>
                            Zugriff und download auf alle angehängten Dateien & Bilder des Tickets
                        </p>
                    </div>
                    <div>
                        <label nz-checkbox formControlName="persons">Personen Infos</label>
                        <p>
                            Übersicht der Personen
                        </p>
                    </div>
                    <div>
                        <label nz-checkbox formControlName="comments">Kommentare</label>
                        <p>
                            Übersicht aller Kommentare des Tickets
                        </p>
                    </div>
                </form>
            </div>
            <div nz-col>
                <nz-form-label>Link zum teilen erstellen</nz-form-label>
                <nz-form-item>
                    <nz-form-control>
                        <div nz-row>
                            <ng-container *ngIf="sharedTicket.link">
                                <nz-input-group nzSearch [nzSuffix]="suffixTemplateInfo">
                                    <input [disabled]="!sharedTicket.link" nz-input readonly
                                           [(ngModel)]="sharedTicket.link"/>

                                    <ng-template #suffixTemplateInfo>
                                        <a href="{{ sharedTicket.link }}" target="_blank">
                                            <i nz-tooltip nzTooltipTitle="Link öffnen" nz-icon nzType="select" nzTheme="outline"></i>
                                        </a>

                                    </ng-template>
                                </nz-input-group>
                            </ng-container>


                            <button *ngIf="!sharedTicket.link" [nzLoading]="isLoading" nzBlock class="m-t-10"
                                    (click)="generateLink()"
                                    nz-button nzType="primary" nzSearch>Erstellen
                            </button>
                            <button class="copybtn m-t-10" nzBlock *ngIf="sharedTicket.link"
                                    [cdkCopyToClipboard]="copyToClipboard"
                                    (mouseup)="clipboardCopy()" nz-button nzType="primary" nzSearch><span nz-icon
                                                                                                          nzType="copy"></span>
                            </button>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </nz-card>
    </div>

