import {Component, Input, OnInit} from '@angular/core';
import {
	Contributors_Users_Tickets,
	Hausverwaltungsansprechpartner,
	Ticket,
	User
} from "../../../../../../../database-models";
import {ApiService} from "../../../../services/api.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
	selector: 'app-contributer-select-list',
	templateUrl: './contributer-select-list.component.html',
	styleUrls: ['./contributer-select-list.component.css']
})
export class ContributerSelectListComponent implements OnInit {

	@Input() public ticket: Ticket;

	public currentContributors: User[];
	public availableContributors: User[];
	public availableAppUser: User[];

	public currentUser: User;

	public availableHVContributors: Hausverwaltungsansprechpartner[];

	public isLoading = false;

	public selected = {
		user: null
	}

	constructor(
		public api: ApiService,
		private message: NzMessageService,
		private autenticationService: AuthenticationService,
	) {
		this.autenticationService.currentUser.subscribe(user => {
			this.currentUser = user;
		});
	}

	ngOnInit(): void {
		this.getAvailableContributors();
		if (this.ticket) {
			this.getCurrentContributors();
			this.getAvailableContributors();
			this.getAvailableAppUsers();
			if (this.ticket?.object?.property_management_id) {
				this.getAvailableHVContributors();
				this.getAvailableContributors();
				this.getAvailableAppUsers();
			}
		}
	}

	public addContributor(): void {
		const sendData = {
			ticketId: this.ticket.id,
			userId: this.selected.user.id
		} as Contributors_Users_Tickets;

		this.api.updateContributorForTicket(sendData).subscribe((contributor: Contributors_Users_Tickets) => {
			this.selected.user = null
			this.getCurrentContributors();
		}, error => {
			this.message.error('Es ist ein Fehler aufgetreten!');
		});
	}

	public getCurrentContributors(): void {
		this.isLoading = true;
		this.api.getTicketContributors(this.ticket.id).subscribe(value => {
			this.currentContributors = value;
			this.getAvailableContributors();
			this.getAvailableHVContributors();
			this.getAvailableAppUsers();
			this.isLoading = false;
		});
	}

	public getAvailableContributors(): void {
		this.api.getUsers().subscribe(allUsers => {
			this.availableContributors = allUsers.filter(user =>
				!this.currentContributors?.some(current => current.id === user.id)
			);
		});
	}

	public getAvailableHVContributors(): void {
    if (this.ticket.object?.property_management_id) {
      this.api.getPropertyManagementContactPerson(this.ticket.object.property_management_id).subscribe(value => {
        this.availableHVContributors = value.filter(user =>
          !this.currentContributors?.some(current => current.id === user.id)
        );
      });
    }

	}

	public getAvailableAppUsers(): void {
    if (this.ticket.object?.id) {
      this.api.getObjectAppusers(this.ticket.object.id).subscribe(value => {
        this.availableAppUser = value.filter(user =>
          !this.currentContributors?.some(current => current.id === user.user.id)
        );
      });
    }
	}

	public deleteContributor(id: string): void {
		this.isLoading = true;
		this.api.deleteContributor(id).subscribe(value => {
			if (value) {
				console.log('Contributor deleted');
				this.getCurrentContributors();
				this.getAvailableContributors();
				this.getAvailableHVContributors();
				this.getAvailableAppUsers();
				this.isLoading = false;
			}
		}, error => {
			console.log('Error', error);
			this.isLoading = false;
		});
	}

	public cancel(): void {

	}
}
