<ng-container *ngIf="!contact.v2">
  <nz-alert
          class="m-b-15"
          nzType="warning"
          nzShowIcon="true"
          [nzMessage]="alertHeader">

    <ng-template #alertHeader>
      Hinweis: Dieser Kontakt ist veraltet. <a class="pull-right"
                                                       (click)="upgradeContact(contact); $event.stopPropagation()">In
      einen neuen Kontakt umwandeln</a>
    </ng-template>
  </nz-alert>
</ng-container>

<nz-card *ngIf="contact && contactForm">
  <div>
    <nz-spin [nzSpinning]="contactLoading">

      <div class="box">
        <div class="box-header">

          <div>
            <h3 class="title">Kontakt</h3>
          </div>

          <form nz-form nzLayout="vertical" [formGroup]="contactForm">
            <nz-form-item>
              <nz-form-label nzFor="name" nzRequired>Firma / Name*</nz-form-label>
              <nz-form-control>
                <input formControlName="name" nz-input placeholder="Name">
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label nzFor="type" nzRequired>Typ*</nz-form-label>
              <nz-form-control>
                <input formControlName="type" nz-input placeholder="Kontakt Typ z.B. Notdienst"
                       (ngModelChange)="onChange($event)" [nzAutocomplete]="auto"/>
                <nz-autocomplete [nzDataSource]="filterTypes" nzBackfill #auto></nz-autocomplete>
              </nz-form-control>
            </nz-form-item>

            <div nz-row>

              <div nz-col nzSpan="8" class="p-5">
                <nz-form-item>
                  <nz-form-label nzFor="phone">Telefonnummer</nz-form-label>
                  <nz-form-control>
                    <input formControlName="phone" nz-input placeholder="Telefonnummer">
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8" class="p-5">
                <nz-form-item>
                  <nz-form-label nzFor="mobile">Mobil</nz-form-label>
                  <nz-form-control>
                    <input formControlName="mobile" nz-input placeholder="Mobil">
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8" class="p-5">
                <nz-form-item>
                  <nz-form-label nzFor="emergency">Notfallnummer</nz-form-label>
                  <nz-form-control>
                    <input formControlName="emergency" nz-input placeholder="Notfallnummer">
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row>
              <div nz-col nzSpan="12" class="p-5">
                <nz-form-item>
                  <nz-form-label nzFor="email">E-Mailadresse</nz-form-label>
                  <nz-form-control>
                    <input formControlName="email" nz-input placeholder="mustermann@musterfirma.de">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="12" class="p-5">
                <nz-form-item>
                  <nz-form-label nzFor="fax">Fax</nz-form-label>
                  <nz-form-control>
                    <input formControlName="fax" nz-input placeholder="Fax">
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>


            <nz-form-item>
              <nz-form-label nzFor="address">Adresse</nz-form-label>
              <nz-form-control>
                <input formControlName="address" nz-input placeholder="Adresse">
              </nz-form-control>
            </nz-form-item>

            <div nz-row>
              <div nz-col nzSpan="12" class="p-5">
                <nz-form-item>
                  <nz-form-label nzFor="zip">PLZ</nz-form-label>
                  <nz-form-control>
                    <input formControlName="zip" nz-input placeholder="PLZ">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="12" class="p-5">
                <nz-form-item>
                  <nz-form-label nzFor="city">Ort</nz-form-label>
                  <nz-form-control>
                    <input formControlName="city" nz-input placeholder="Ort">
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>


          </form>

        </div>
      </div>
    </nz-spin>
  </div>
</nz-card>
