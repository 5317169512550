<nz-spin [nzSpinning]="uploadInProgress">
  <div nz-row [nzGutter]="nzGutter">
    <div nz-col [nzSpan]="nzSpan" *ngIf="uploadEnabled">
      <nz-upload
              nzType="drag"
              [nzName]="'upload-file'"
              [nzAction]="backendURL + '/file'"
              [nzAccept]="'.jpg, .jpeg, .png, .gif, .bmp, .pdf, .doc, .docx, .pages, .xls, .xlsx, .numbers'"
              [nzData]="{ticket_id: ticketId, object_id: objectId, worklog_id: worklogId, onlyManager: fileUploadManagerOnly}"
              [nzHeaders]="{ apiKey: autenticationService.session | async }"
              [nzMultiple]="true"
              (nzChange)="uploadChange($event)"
              [nzShowUploadList]="false"
      >

        <p class="ant-upload-drag-icon"><i nz-icon nzType="plus"></i></p>
        <p>Dateien hochladen</p>

        <div class="p-5">
          <label *appIsManager [(ngModel)]="fileUploadManagerOnly" nz-checkbox>Nur für Manager/Admin</label>
        </div>
        <ng-container *ngIf="fileUploadManagerOnly">
          <i style="font-size: 70px" class="p-10" nz-icon nzType="lock" nzTheme="outline" nz-tooltip
             nzTooltipTitle="Diese Datei wird nur für Manager & Admin sichtbar sein"></i>
        </ng-container>
      </nz-upload>
    </div>

    <div nz-col class="p-5" [nzSpan]="nzSpan" *ngFor="let item of files">
      <ng-template #coverTemplate>
        <img #realImg [hidden]="true" lightbox
          [src]="backendURL + '/' + item.url"
          [imageSrc]="item.url"
          [imageName]="item.name"
          [imageDescription]="item.description"
          [thumbnails]="files"
          [disableControls]="true"/>
        <div>
          <div class="preview-img" *ngIf="fileIsImage(item.type)" (click)="realImg.click()"
               [style.background-image]="'url(' + backendURL + '/' + item.url + ')'">
            <nz-tag *ngIf="item.onlyManager" style="position: absolute; bottom: 0; right: 0; margin: 5px"
                    [nzColor]="'rgba(0,168,255,0.8)'"><i nz-icon nzType="lock" nzTheme="outline" nz-tooltip
                                                         nzTooltipTitle="Diese Dateien sind nur für Manager & Admin sichtbar"></i>
            </nz-tag>
          </div>
          <div class="preview-img" *ngIf="!fileIsImage(item.type)">
            <div class="font-size-100">
              <i class="m-t-20" [nzTwotoneColor]="getFiletype(item.type).twoToneColor" nz-icon [nzType]="getFiletype(item.type).icon"
                 nzTheme="twotone"></i>
            </div>
            <nz-tag *ngIf="item.onlyManager" style="position: absolute; bottom: 0; right: 0; margin: 5px"
                    [nzColor]="'rgba(0,168,255,0.8)'"><i nz-icon nzType="lock" nzTheme="outline" nz-tooltip
                                                         nzTooltipTitle="Diese Dateien sind nur für Manager & Admin sichtbar"></i>
            </nz-tag>
          </div>
        </div>


      </ng-template>
      <nz-card class="text-center file-container m-0" [nzTitle]="title" [nzActions]="[actionDownload, actionEdit]"
               [nzCover]="coverTemplate">

        <ng-template #title>
          <span style="font-size: 14px;">{{item.name}}</span><br>
            <p>
              <small>
                <i nz-icon nzType="user"></i>
                {{item.user?.name}}
              <br>
                {{item.createdAt | date:'dd.MM.y HH:mm'}} Uhr
              </small>
            </p>

        </ng-template>


        <div>
          <p *ngIf="!item.description"><small>Für diese Datei gibt es keine Beschreibung</small></p>
          <p class="m-0" *ngIf="item.description && !item._showFullDescription && item.description.length > 30">
            {{item.description | slice:0:30}}... <a *ngIf="!item._showFullDescription"
                                                    (click)="toggleDescriptionView(item)">Mehr anzeigen</a>
          </p>
          <p class="m-0" *ngIf="item.description && (item._showFullDescription || item.description.length <= 30)">
            {{item.description}} <a *ngIf="item._showFullDescription" (click)="toggleDescriptionView(item)">weniger
            anzeigen</a>
          </p>

        </div>

        <ng-template #actionDownload>
          <i nz-icon nzType="download" (click)="download(item)"></i>
        </ng-template>
        <ng-template #actionEdit>
          <ng-container *ngIf="editEnabled">
            <i (click)="editFile(item)" nz-icon nzType="edit"></i>
          </ng-container>
        </ng-template>
      </nz-card>
    </div>
  </div>
</nz-spin>
