<h2>Übersicht</h2>
<mbsc-datepicker [controls]="['calendar']"
                 [colors]="color"
                 [labels]="label"
                 [invalid]="rangeSelected"
                 [calendarSize]="12"
                 [showRangeLabels]="false"
                 [inRangeInvalid]="false"
                 [rangeEndInvalid]="true"
                 class="no-border"
                 display="inline"
                 calendarType="month"
                 theme="ios"
></mbsc-datepicker>

