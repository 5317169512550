import {Component, inject, Input, OnInit} from '@angular/core';
import {RenewIntervals, User} from '../../../../../../database-models';
import {ApiService} from '../../../services/api.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {AuthenticationService} from '../../services/authentication.service';

export interface ModalData {
	user: User;
}

@Component({
  selector: 'app-edit-user-billing-interval',
  templateUrl: './edit-user-billing-interval.component.html',
  styleUrls: ['./edit-user-billing-interval.component.scss']
})
export class EditUserBillingIntervalComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  @Input()
  public user: User;

  public loading = false;

  public intervals: RenewIntervals[];
  public currentInterval: RenewIntervals;
  public selectedInterval: RenewIntervals;
  public residualValue: any;

  constructor(
    private api: ApiService,
    private message: NzMessageService,
    public modal: NzModalRef,
    public authService: AuthenticationService
  ) {

  }

  ngOnInit(): void {
    this.user ??= this.data.user;
    this.getIntervals();
    this.getUserResidualValue();
    this.selectedInterval = this.currentInterval = this.user.renewinterval;
  }

  public select(interval: RenewIntervals): void {
    if (this.upsellingPossible(interval)) {
      this.selectedInterval = interval;
    }
  }

  public getIntervals(): void {
    this.loading = true;
    this.api.getRenewInterval().subscribe((intervals: RenewIntervals[]) => {
      this.intervals = intervals;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.message.error('Optionen konnten nicht geladen werden');
    });
  }

  public getUserResidualValue(): void {

    this.api.getUserResidualValue(this.user.id).subscribe((residual: any) => {
      this.residualValue = residual;
      console.log(residual);
    }, error => {

      this.message.error('Restwert konnte nicht ermittelt werden');
    });
  }

  public save(): void {
    this.loading = true;
    this.user.renew_interval_id = this.selectedInterval.id;
    let upgradeData = {
      user_id: this.user.id,
      renew_interval_id: this.selectedInterval.id
    };
    this.api.upgradeUserRenewInterval(upgradeData).subscribe((res: any) => {
      this.modal.triggerOk();
      this.message.success('Das Abrechnungsinterval wurde gespeichert.');
      this.loading = false;
    }, error => {
      this.message.error('Das Abrechnungsinterval konnte nicht gespeichert werden!');
      this.loading = false;
    });
  }

  public upsellingPossible(interval: RenewIntervals): boolean {
    if (this.authService.currentUserValue.company.billing.billingActive) {
      if (interval.id === this.currentInterval.id) {
        return true;
      } else {
        return this.currentInterval.upselling_index <= interval.upselling_index;
      }
    } else {
      return true;
    }


  }

}
