<nz-spin [nzSpinning]="isLoading">
  <ng-container *ngIf="sessions?.length <= 0">
    <p>Aktuell sind noch keine Geräte angemeldet</p>
  </ng-container>

  <ng-container *ngIf="sessions?.length > 0">
    <nz-table [nzData]="['']" nzShowPagination="false" nzSize="small">
      <thead>
      <tr>
        <th></th>
        <th>Aktiv seit</th>
        <th>IP-Adresse</th>
        <th>OSP-ID</th>
        <th style="width: 200px">Gerätetyp</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let session of sessions; index as i">
        <td>
          <i *ngIf="session.type === 'app'" nz-icon nzType="mobile" nzTheme="outline"></i>
          <i *ngIf="session.type === 'browser'" nz-icon nzType="desktop" nzTheme="outline"></i>
        </td>
        <td>{{session.createdAt | date:'dd.MM.y H:mm'}}</td>
        <td>{{session.ip}}</td>
        <td>{{session.oneSignalPlayerID}}</td>
        <td >
          <div style="width: 500px" >{{session.device}}</div>
        </td>
        <td>
          <button nzSize="small" nz-button nzType="link" nzDanger nzShape="circle"
                  (click)="deleteSession(session.id, i)">
            <i nz-icon nzType="delete"></i></button>
        </td>
      </tr>
      </tbody>
    </nz-table>
  </ng-container>
</nz-spin>

