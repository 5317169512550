<nz-table nzSize="small" #newsTable [nzData]="checkLists" [nzPageSize]="10" [nzShowPagination]="false">
  <thead >
  <tr>
    <th></th>
    <th>Name</th>
    <th>Datum</th>
    <th style="width: 120px"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let dataItem of checkLists; let i = index">
    <td>
      <i class="color-red" nz-icon nzType="exclamation-circle" nzTheme="outline" *ngIf="dataItem.items_not_ok !== 0"></i>
      <i class="color-green" nz-icon nzType="check-circle" nzTheme="outline" *ngIf="dataItem.items_not_ok === 0"></i>
    </td>
    <td>{{dataItem.name}}</td>
    <td>{{dataItem.createdAt | date:'dd.MM.y H:mm'}}</td>
    <td>
      <button nzSize="small" class="m-l-10" nz-button nzType="default" nzShape="circle"
              (click)="openChecklist(dataItem)"><i nz-icon nzType="eye"></i></button>
      <button *ngIf="!worklog.finalized" nzSize="small" class="m-l-10" nz-button nzType="default" nzShape="circle"
              (click)="deleteCheckList(dataItem, i)"><i nz-icon nzType="delete"></i></button>
    </td>

  </tr>
  </tbody>
</nz-table>
