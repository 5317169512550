<nz-spin [nzSpinning]="isLoading">

  <div class="box-header d-flex align-items-center justify-content-between p-b-10">

    <nz-tag class="m-t-10" *ngIf="residents" [nzColor]="">{{residents?.length}} Bewohner</nz-tag>

    <div class="d-flex">

      <label *appIsManager style="width: 260px" nz-checkbox [(ngModel)]="includeBlocked" (ngModelChange)="getObjectResidents()">inkl. Blockiert</label>

      <i style="font-size: 20px;" class="m-r-15 p-10" nz-icon nzType="info-circle" nzTheme="outline"
         nz-tooltip [nzTooltipTitle]="tooltip" ></i>
      <ng-template #tooltip>
        <span style="color: indianred">Rot = Mieter </span><br>
        <span style="color: cornflowerblue">Blau = Eigentümer</span> <br>
        <span style="color: goldenrod">Gelb = Sonstige</span>
      </ng-template>

      <nz-input-group nz-tooltip nzTooltipTitle="" [nzPrefix]="prefiXTemplate">
        <input nz-input placeholder="Bewohner suchen" nzSize="small" (ngModelChange)="filter()" [(ngModel)]="nameSearch">
      </nz-input-group>
      <ng-template #prefiXTemplate>
        <i nz-icon nzType="search" nzTheme="outline"></i>
      </ng-template>
    </div>
  </div>

    <nz-card>

        <nz-table #table class="m-t-10" [nzData]="residents" [nzSize]="'small'">
            <thead>
            <tr>
                <th></th>
                <th [nzSortFn]="orderFn.name" [(nzSortOrder)]="sortOrders.name"
                    (nzSortOrderChange)="sortChanged($event)">Name</th>
                <th>Objekte</th>
            </tr>
            </thead>
            <tbody>
            <tr (click)="residentDetail(resident)"
                class="cursor-pointer"
                *ngFor="let resident of table.data">
                <td></td>
                <td>{{resident.name}}</td>
                <td>
                    <nz-tag *ngFor="let obj of resident.objects" style="cursor: pointer" nz-tooltip
                            nzTooltipTitle="{{capitalize(obj.Appuser_Users_Objects.appuser_type) + (obj.Appuser_Users_Objects.blocked ? ' (gesperrt)' : '')}}"
                            [nzColor]="getTagColor(obj.Appuser_Users_Objects.appuser_type)"
                            [routerLink]="'/objects/' + obj.id"><i *ngIf="obj.Appuser_Users_Objects.blocked" nz-icon nzType="lock" nzTheme="outline"></i>  {{obj.name}}</nz-tag>
                </td>
            </tr>
            </tbody>
        </nz-table>
    </nz-card>
</nz-spin>


