<nz-table #basicTable [nzData]="transactions">
  <thead>
  <tr>
    <th>Zeitpunkt</th>

    <th>Netto</th>
    <th>Brutto</th>
    <th>MwSt</th>
    <th>Referenz</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let data of basicTable.data" [ngClass]="{ 'background-green' : data.amount > 0 }">
    <td>{{data.createdAt | date:'dd.MM.y H:mm'}}</td>

    <td [ngClass]="{ 'color-red' : data.amount < 0 }">{{data.amount}} €</td>
    <td [ngClass]="{ 'color-red' : data.amount < 0 }">{{data.amount_brutto}} €</td>
    <td>{{data.mwst_percent}} %</td>
    <td>
      <div>{{data.reference}}</div>
      <small>{{data.description}}</small>
    </td>
    <td>
      <a *ngIf="data.lex_invoice?.path" (click)="download(data.lex_invoice)"><i nz-icon nzType="file-pdf" nzTheme="outline"></i> Rechnung</a>

    </td>
  </tr>
  </tbody>
</nz-table>

