import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contact} from "../../../../../../database-models";
import {EditContactV2Component, ModalData as EditContactV2ModalData} from "../edit-contact-v2/edit-contact-v2.component";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {ApiService} from "../../../services/api.service";
import {FormBuilder} from "@angular/forms";
import {NzMessageService} from "ng-zorro-antd/message";

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.css']
})
export class ContactCardComponent implements OnInit {

  @Input() public contact: Contact;

  @Output() public deleteContactbyObject: EventEmitter<string> = new EventEmitter<string>();

  public isLoading = true;
  @Input() public hvPermission = false;


  constructor(
      private modalService: NzModalService,
  ) { }

  ngOnInit(): void {
    if (this.contact) {
      this.isLoading = false;
    }
  }

  public delete(): void {
   this.deleteContactbyObject.emit(this.contact.id)
  }

  public editContact(contact: Contact): void {
      const newModal = this.modalService.create<EditContactV2Component, EditContactV2ModalData>({
        nzContent: EditContactV2Component,
        nzData: {
          contact
        },
        nzWidth: '1000px',
        nzFooter: null,
        nzMaskClosable: false
      });
      newModal.afterClose.subscribe((data: any) => {
      });
    }

  public getMapLink(address: any): void {
    const query = encodeURIComponent(`${address.address} ${address.city} ${address.zip}`);
    const url = `https://www.google.com/maps/search/?api=1&query=${query}`;

    window.open(url, '_blank');
  }

  public getInitials(firstName: string, lastName: string): string {
    const initials = firstName?.charAt(0) + lastName?.charAt(0);
    return initials.toUpperCase();
  }
}
