<h2>Checkliste</h2>

<nz-select class="w-100"
           [(ngModel)]="selectedCheckListTemplate"
           (ngModelChange)="selectTemplate($event)"
           nzPlaceHolder="Vorlage wählen"
>
  <nz-option *ngFor="let template of checkListTemplates" [nzValue]="template" [nzLabel]="template.name"></nz-option>
</nz-select>

<ng-container *ngIf="checkList?.list">
  <nz-table [nzData]="checkList.list" [nzShowPagination]="false">
    <thead>
    <tr>
      <th>Zu prüfen</th>
      <th>Status</th>
      <th>Kommentar</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of checkList.list" class="stop-hover" [ngClass]="{ 'background-red' : item.state === 'not_ok', 'background-green' : item.state === 'ok'}">
      <td>{{item.description}}</td>
      <td>
        <nz-radio-group class="check-item"  [(ngModel)]="item.state">
          <label nz-radio nzValue="ok">Ok</label>
          <label nz-radio nzValue="not_ok">Nicht Ok</label>
        </nz-radio-group>
      </td>
      <td>
        <nz-form-control *ngIf="item.state === 'not_ok'">
          <textarea [disabled]="readOnly"
                    [(ngModel)]="item.comment"
                    nz-input
                    placeholder="Kommentar hinzufügen" >
          </textarea>
        </nz-form-control>
      </td>
    </tr>
    </tbody>
  </nz-table>

  <p class="center-text" *ngIf="checkListTemplates?.length <= 0 && !readOnly">
    Für dieses Objekt wurden noch keine Vorlagen angelegt. Bitte fügen Sie zuerst eine Vorlage über die
    Administration hinzu.
  </p>

</ng-container>


<div nz-row  class="text-right m-v-10">
  <button class="m-r-10" nz-button nzType="default" (click)="modal.destroy()" type="button">Abbrechen
  </button>
  <button [disabled]="!selectedCheckListTemplate" nz-button nzType="primary" type="submit" (click)="save()"
    >Speichern
  </button>
</div>
