<ng-container *ngIf="!hideLabel">
  <nz-form-label nzFor="object" >{{ this.entities.includes('Object') ? 'Objekt' : '' }} {{ this.entities.length > 1 ? 'oder' : '' }} {{ this.entities.includes('Contact') ? 'Kontakt' : '' }}</nz-form-label>
</ng-container>


<nz-select style="width: 100%"
           [disabled]="disabled"

           [(ngModel)]="reference"
           (ngModelChange)="select(reference); $event.stopPropagation()"
           nzShowSearch
           [nzMaxTagCount]="3"
           [nzMode]="multiple ? 'multiple' : 'default'"
           [nzPlaceHolder]="(this.entities.includes('Object') ? 'Objekt' : '') + (this.entities.length > 1 ? 'oder' : '') + (this.entities.includes('Contact') ? 'Kontakt' : '') + ' wählen' + (optional ? ' (optional)' : '')"
           [nzAllowClear]="allowClear"
           [compareWith]="compareById">
  <nz-option-group [nzLabel]="objectLabel" *ngIf="this.entities.includes('Object')">
    <ng-template #objectLabel>
      <i class="m-r-10" nz-icon nzType="home" nzTheme="fill"></i> <strong>Objekte</strong>
    </ng-template>
    <nz-option *ngIf="optional" nzCustomContent [nzLabel]="'- kein Objekt wählen -'" [nzValue]="null">- kein Objekt wählen -</nz-option>
    <nz-option nzCustomContent *ngFor="let object of objects" [nzLabel]="object.name" [nzValue]="object">
      <i class="m-r-10" nz-icon nzType="home" nzTheme="outline"></i> <strong>{{object.name}}</strong><br>
      <small><span *ngIf="object.objectNumber"> {{object.objectNumber}} - </span> {{object.address_line1}}, {{object.zip}} {{object.city}}</small>
    </nz-option>
  </nz-option-group>


  <nz-option-group [nzLabel]="customerLabel" *ngIf="this.entities.includes('Customer')">
    <ng-template #customerLabel>
      <i class="m-r-10" nz-icon nzType="idcard" nzTheme="fill"></i> <strong>Kontakte</strong>
    </ng-template>
    <nz-option *ngIf="optional" nzCustomContent [nzLabel]="'- keinen Kontakt wählen-'" [nzValue]="null">- keinen Kontakt wählen-</nz-option>
    <nz-option nzCustomContent *ngFor="let contact of contacts"
               [nzLabel]="contact.name" [nzValue]="contact">
      <i class="m-r-10" nz-icon nzType="idcard" nzTheme="outline"></i>
      <strong>{{ contact.name }} </strong>
      <nz-tag class="pull-right m-t-5" [nzColor]="getColorByType(contact.type)"><lexoffice-icon class="m-r-5" *ngIf="contact.lexofficeId"></lexoffice-icon>{{ contact.type }}</nz-tag>
    <br>
      <small>{{contact.contact_addresses[0]?.zip}} {{contact.contact_addresses[0]?.city}}</small>
    </nz-option>
  </nz-option-group>

</nz-select>

<div class="m-t-15" *ngIf="roomSelect && rooms">
  <nz-form-label>Raum</nz-form-label>
  <nz-form-control>
    <nz-select nzSize="small" nzShowSearch [nzDropdownRender]="renderTemplate" [disabled]="!!worklog?.finalized" [nzAllowClear]="allowClear" [(ngModel)]="room" (ngModelChange)="roomChange.emit(room)" [compareWith]="compareById" nzPlaceHolder="Raum wählen (optional)">
      <nz-option *ngFor="let item of rooms" [nzValue]="item" [nzLabel]="item?.name"></nz-option>
    </nz-select>
    <ng-template #renderTemplate>
      <button nz-button nzType="link" nzSize="small" nzBlock (click)="addRoom()">Raum anlegen</button>
    </ng-template>
  </nz-form-control>
</div>
