<h2>{{ checkList.name }}</h2>
<p>vom {{ checkList.createdAt | date:'dd.MM.y H:mm' }}</p>
<div>Geprüft: <strong>{{checkList.items}}</strong> / OK: <strong>{{checkList.items_ok}}</strong> / Nicht OK:
  <strong>{{checkList.items_not_ok}}</strong></div>
<div class="m-t-20">
  <div class="check-item" *ngFor="let item of checkList.list" [ngClass]="{ 'red' : item.state === 'not_ok', 'green' : item.state === 'ok' }">
    <div>
      <i [ngClass]="{ 'color-red' : item.state === 'not_ok', 'color-green' : item.state === 'ok', 'color-orange' : !item.state }" nz-icon [nzType]="item.state ? (item.state === 'ok' ? 'check' : 'close-circle') : 'question-circle'" nzTheme="outline"></i>
      <strong>{{ item.description }}</strong>
    </div>
    <div class="check-comment" *ngIf="item.comment">
      {{ item.comment }}
    </div>
  </div>
</div>



