import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Appuser_Users_Objects, AppUserTypes} from "../../../../../../../database-models";
import {ApiService} from "../../../../services/api.service";

@Component({
  selector: 'app-resident-select',
  templateUrl: './resident-select.component.html',
  styleUrls: ['./resident-select.component.scss']
})
export class ResidentSelectComponent implements OnInit {

  public appusersAvailable: Appuser_Users_Objects[];

  @Input() public appusers: Appuser_Users_Objects[] | Appuser_Users_Objects | any;

  @Input() public objectId: string;
  @Input() public mode: any = 'default';
  @Input() public placeholder: string = 'Bewohner wählen (optional)';
  @Input() public provideBtn: boolean = false;

  @Output() public appusersChange: EventEmitter<Appuser_Users_Objects[] | Appuser_Users_Objects> = new EventEmitter<Appuser_Users_Objects[] | Appuser_Users_Objects>();

  public isLoading = false;

  constructor(
      public api: ApiService,
  ) {
  }

  ngOnInit(): void {
    if (this.objectId) {
      this.getObjectResidents();
    }

  }

  public getObjectResidents(): void {
    this.isLoading = true;
    this.api.getObjectAppusers(this.objectId).subscribe((ObjectAppusers: Appuser_Users_Objects[]) => {
      this.appusersAvailable = ObjectAppusers;
      if (this.appusers?.userId && !this.appusersAvailable.find(appusr => appusr.userId === this.appusers.userId)) {
        this.appusersAvailable.push(this.appusers);
      }
      this.isLoading = false;
    }, error => {
      console.error(error)
    });
  }

  public emitUpdate(value: any): void {
    this.appusersChange.emit(value);
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  public getTagColor(appuserType: AppUserTypes): string {
    switch (appuserType) {
      case 'mieter':
        return 'red';
      case 'eigentuemer':
        return 'blue';
      default:
        return 'gold';
    }
  }

  public focus(value: any): void {
      console.log(value)
  }
}
