import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Company, RenewIntervals, User} from '../../../../../../database-models';
import {ApiService} from '../../../services/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-select-user-billing-interval',
  templateUrl: './select-user-billing-interval.component.html',
  styleUrls: ['./select-user-billing-interval.component.scss']
})
export class SelectUserBillingIntervalComponent implements OnInit {

  public loading = false;

  public intervals: RenewIntervals[];

  public company: Company;

  @Input()
  public renewIntervalId: string;

  @Output()
  public renewIntervalIdChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private api: ApiService,
    private message: NzMessageService,
    public authenticationService: AuthenticationService,
  ) {

  }

  ngOnInit(): void {
    this.getIntervals();
    this.authenticationService.currentUser.subscribe(user => {
      this.company = user.company;
    });
  }


  public select(interval: RenewIntervals): void {
    this.renewIntervalId = interval.id;
    this.renewIntervalIdChange.emit(interval.id);
  }

  public getIntervals(): void {
    this.loading = true;
    this.api.getRenewInterval().subscribe((intervals: RenewIntervals[]) => {
      this.intervals = intervals;
      this.loading = false;
      if (!this.renewIntervalId) {
        this.renewIntervalId = this.intervals.find(int => { return int.default })?.id;
        if (!this.renewIntervalId) {
          this.renewIntervalId = this.intervals[0]?.id;
        }
      }
      this.renewIntervalIdChange.emit(this.renewIntervalId);
    }, error => {
      this.loading = false;
      this.message.error('Optionen konnten nicht geladen werden');
    });
  }


}
