<h2>Artikel aus Lexware Office importieren </h2>
<div class="box-header d-flex align-items-center justify-content-between p-b-10">
  <div><lexoffice-tag></lexoffice-tag></div>
  <div class="d-flex">


    <ng-template #prefiXTemplate>
      <i nz-icon nzType="search" nzTheme="outline"></i>
    </ng-template>
  </div>

</div>
<nz-table class="m-v-15" #contactTable
          [nzData]="lexItems"
          [nzFrontPagination]="false"
          [nzLoading]="loading"
          [nzTotal]="lexResult?.totalElements"
          [(nzPageSize)]="pagingParameters.size"
          [nzPageIndex]="pagingParameters.page+1"
          (nzQueryParams)="getItems($event)"
          nzSize="small">
  <thead>
  <tr>
    <th>Typ</th>
    <th>Titel</th>
    <th>Beschreibung</th>
    <th>Einheit</th>
    <th>Artikelnummer</th>

    <th style="width: 80px"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let dataItem of contactTable.data">
    <td>
      {{ dataItem.type === 'PRODUCT' ? 'Produkt' : 'Service' }}
    </td>
    <td>
      <strong>{{ dataItem.title }}</strong>
    </td>
    <td>
      {{ dataItem.description }}
    </td>
    <td>
      {{ dataItem.unitName }}
    </td>
    <td>
      {{ dataItem.articleNumber }}
    </td>
    <td>
      <nz-tag class="m-t-5" *ngIf="alreadyImportedIds.includes(dataItem.id)" nzColor="green"><i nz-icon nzType="check-circle" nzTheme="outline"></i> importiert</nz-tag>
      <button *ngIf="!alreadyImportedIds.includes(dataItem.id)" (click)="select(dataItem)" nz-button nzType="link" nzSize="small">wählen</button>
    </td>
  </tr>
  </tbody>
</nz-table>

<p><strong>Produkte</strong> werden als Verbrauchsgegenstand importiert, <strong>Services</strong> als Dienstleistung. Sie können die Zuordnung später noch anpassen.</p>
