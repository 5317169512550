<h2>Vorlage Checkliste bearbeiten</h2>

<div nz-row>
  <nz-form-item nz-col nzSpan="24">
    <nz-form-label nzFor="name">Name</nz-form-label>
    <nz-form-control>
      <input [(ngModel)]="checkListTemplate.name" nz-input placeholder="Name">
    </nz-form-control>
  </nz-form-item>
</div>

<h4>Positionen</h4>

<div nz-row *ngFor="let item of checkListTemplate.list; let i = index">
  <div nz-col nzSpan="21">
    <nz-form-item nz-col nzSpan="24">
      <nz-form-control>
        <input [(ngModel)]="item.description" nz-input placeholder="Beschreibung">
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col nzSpan="3">
    <button class="m-l-10" nz-button nzShape="circle" nzDanger [disabled]="checkListTemplate.list.length <= 1"
            (click)="deleteListItem(i)"><i nz-icon nzType="minus"></i></button>
  </div>
</div>
<div nz-row>
  <button (click)="addListItem()" class="w-100" nz-button nzType="primary"><i nz-icon nzType="plus"></i> Position Hinzufügen</button>
</div>
<div nz-row class="m-t-20">
  <div nz-col nzSpan="12">
    <label [disabled]="checkListTemplate.id && !checkListTemplate.object_id" class="m-t-10" nz-checkbox [(ngModel)]="isGlobalTemplate">Globale Vorlage für alle Objekte</label>
  </div>
  <div nz-col nzSpan="12" class="text-right">
    <button class="m-r-10" nz-button nzType="default" (click)="modal.destroy()" type="button">Abbrechen
    </button>
    <button nz-button nzType="primary" type="submit" [disabled]="isInvalid()" (click)="save()"
    >
      Speichern
    </button>
  </div>

  <nz-alert class="m-t-10" nz-col nzSpan="24" *ngIf="checkListTemplate.id && checkListTemplate.object_id && isGlobalTemplate" nzType="warning" nzMessage="Sind Sie sicher, dass Sie diese Vorlage von diesem Objekt trennen möchten und für alle Objekte anwenden möchten? Diese Einstellung lässt sich nach dem Speichern nicht rückgängig machen." nzShowIcon></nz-alert>

</div>
