<nz-table class="m-t-10" nzSize="small" #newsTable [nzData]="tableData.data" [nzPageSize]="10">
  <thead >
  <tr>
    <th *ngFor="let item of tableData.items" >{{item.title}}</th>
    <th nzWidth="10%"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let dataItem of newsTable.data">
    <td *ngFor="let item of tableData.items" (click)="editCheckListTemplate(dataItem)" style="cursor: pointer">
      {{dataItem[item.key]}} <i *ngIf="!dataItem.object_id" nz-icon nzType="global" nzTheme="outline" nz-tooltip
                                nzTooltipTitle="Globale Vorlage"></i>
    </td>
    <td>
      <button nzSize="small" nz-button nzType="link" nzGhost nzDanger nzShape="circle"
              (click)="deleteCheckListTemplate(dataItem)">
        <i nz-icon nzType="delete"></i></button>
    </td>

  </tr>
  </tbody>
</nz-table>
<button class="m-t-10" nz-button nzSize="small" nzBlock nzType="default" (click)="editCheckListTemplate()"><i nz-icon
                                                                                                              nzType="plus-circle"></i>Vorlage
  hinzufügen
</button>
