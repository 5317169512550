import {Component, OnInit} from '@angular/core';
import moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzMessageService} from 'ng-zorro-antd/message';
import {DatePipe} from '@angular/common';
import {HttpParams} from '@angular/common/http';
import * as XLSX from 'xlsx';
import {ApiService} from '../../../../services/api.service';
import {ExportService} from '../../../services/export.service';
import {WorkLog, Object, Consumable_Ticket} from '../../../../../../../database-models';

@Component({
  selector: 'app-consumables-by-object',
  templateUrl: './consumables-by-object.component.html',
  styleUrls: ['./consumables-by-object.component.css']
})
export class ConsumablesByObjectComponent implements OnInit {
  public objectsForFilter: Object[];

  public consumed: Consumable_Ticket[];

  public consumablesLoading = false;

  public filter = {
    object: null,
    dateRange: [moment().startOf('week').toISOString(), moment().endOf('day').toISOString()]
  };


  public constructor(
    private api: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    private modalService: NzModalService,
    private message: NzMessageService,
    public exportService: ExportService,
    private datePipe: DatePipe
  ) {
    const storageFilter = localStorage.getItem('consumableListFilter');
    if (storageFilter) {
      this.filter = JSON.parse(storageFilter);
    }

    this.getObjects();
  }

  public ngOnInit(): void {
    this.getConsumablesStats();
  }


  public getConsumablesStats(): void {

    if (this.filter.object) {
      this.consumablesLoading = true;
      const start = moment(this.filter.dateRange[0]).startOf('day').toISOString();
      const end = moment(this.filter.dateRange[1]).endOf('day').toISOString();

      let httpParams = new HttpParams();

      httpParams = httpParams.append('objectId', this.filter.object.id);

      if (this.filter.dateRange) {
        httpParams = httpParams.append('from', start);
        httpParams = httpParams.append('to', end);
      }

      this.api.geConsumablesStats(httpParams).subscribe((consumed: Consumable_Ticket[]) => {
        this.consumed = consumed;
        this.consumablesLoading = false;
      }, onerror => {
        console.log(onerror);
        this.consumablesLoading = false;
        // TODO
      });

      localStorage.setItem('consumableListFilter', JSON.stringify(this.filter));
    }
  }

  public getObjects(): void {
    this.api.getObjects().subscribe((objects: Object[]) => {
      this.objectsForFilter = objects;
      // this.initForm();
    }, onerror => {
      console.log(onerror);
      this.message.error('Objekte konnten nicht geladen werden');
    });
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  public getDuration(worklog: WorkLog | any): any {
    const end = moment(new Date(worklog.end));
    const start = moment(new Date(worklog.start));
    return moment.duration(end.diff(start));
  }

  public export(type: 'excel'): void {

       if (type === 'excel') {
         const workbook = XLSX.utils.book_new();

           const exportJSON: any[] = [];
           for (const consumable of this.consumed) {

             const row = {
               Bezeichnung: consumable.consumable.name,
               Anzahl: consumable.number,
               Einheit: consumable.consumable.unit,
               Datum: this.datePipe.transform(consumable.createdAt, 'dd.MM.y H:mm'),
               Ticket_Name: consumable.ticket ? consumable.ticket.name : '',
               Ticket_Nummer: consumable.ticket ? consumable.ticket.ticketNumber : '',
               Ticket_Datum: consumable.ticket ? this.datePipe.transform(consumable.ticket.createdAt, 'dd.MM.y H:mm') : '',
               Ticket_Beschreibung: consumable.ticket ? consumable.ticket.description : ''
             };
             exportJSON.push(row);
           }
           // inserting first blank row
           const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportJSON);
           XLSX.utils.book_append_sheet(workbook, worksheet, this.filter.object.name.replace(' ', '_'));


         // save to file
         XLSX.writeFile(workbook, 'Verbrauchsmaterial-' + this.filter.object.name.replace(' ', '_') + '.xlsx', {type: 'file'});
       }


  }

  public setRange(period: 'today' | 'tw' | 'lw' | 'tm' | 'lm' | 'tj' | 'lj'): void {
    switch (period) {
      case 'today':
        this.filter.dateRange = [moment().startOf('day').toISOString(), moment().endOf('day').toISOString()];
        break;
      case 'tw':
        this.filter.dateRange = [moment().startOf('week').toISOString(), moment().endOf('week').toISOString()];
        break;
      case 'lw':
        this.filter.dateRange = [moment().subtract(1, 'week').startOf('week').toISOString(), moment().subtract(1, 'week').endOf('week').toISOString()];
        break;
      case 'tm':
        this.filter.dateRange = [moment().startOf('month').toISOString(), moment().endOf('month').toISOString()];
        break;
      case 'lm':
        this.filter.dateRange = [moment().subtract(1, 'month').startOf('month').toISOString(), moment().subtract(1, 'month').endOf('month').toISOString()];
        break;
      case 'tj':
        this.filter.dateRange = [moment().startOf('year').toISOString(), moment().endOf('year').toISOString()];
        break;
      case 'lj':
        this.filter.dateRange = [moment().subtract(1, 'year').startOf('year').toISOString(), moment().subtract(1, 'year').endOf('year').toISOString()];
        break;
    }
  }
}
