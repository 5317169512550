import { Injectable } from '@angular/core';
import {ApiService} from '../../services/api.service';
import {AuthenticationService} from './authentication.service';
import {Company} from '../../../../../database-models';
import moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  public showLicenseBanner = false;
  public expired = false;
  public company: Company;

  constructor(
    public authenticationService: AuthenticationService,
  ) {
    this.authenticationService.currentUser.subscribe(user => {
      this.company = user.company;
      this.checkLicense();
    });
  }


  public checkLicense(): void {
    if (this.company) {
      if (this.company.billing.billingActive) {
        this.showLicenseBanner = false;
      } else {
        if (this.company.billing.testPeriodEnd) {
          this.checkTestExpired();
          this.showLicenseBanner = true;
        }
      }
    }
  }

  public checkTestExpired(): void {
    const now = moment();
    if (moment(this.company.billing.testPeriodEnd) < now) {
      this.expired = true;
    } else {
      this.expired = false;
    }

  }
}
