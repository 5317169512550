<h3>Leistungskataloge</h3>
<nz-divider></nz-divider>
<nz-spin [nzSpinning]="isLoading">
    <nz-collapse *ngFor="let sC of serviceCatalogs">
        <nz-collapse-panel [nzHeader]="header">
            <ng-template #header>
                <span><i nz-icon nzType="book" nzTheme="outline"></i>{{' ' + sC.name}}</span>
            </ng-template>
            <div class="tree-list first">
                <ng-template #recursiveList let-items="items" let-parent="parent">
                    <div *ngFor="let child of items">
                        <label (nzCheckedChange)="updateSelectedServices(child, parent)" nz-checkbox [disabled]="propergate && !parent?._checked && parent"
                               [(ngModel)]="child._checked"> <span style="color: #72849a; font-weight: lighter">{{child.index}} </span> {{' ' + child.name}}</label>
                        <ng-container *ngIf="child.childservices?.length > 0">
                            <ng-container
                                    *ngTemplateOutlet="recursiveList; context:{ items: child.childservices, parent: child }"></ng-container>
                        </ng-container>
                    </div>
                </ng-template>

                <ng-container
                        *ngTemplateOutlet="recursiveList; context:{ items: sC.service_catalog_items }"></ng-container>
            </div>

        </nz-collapse-panel>
    </nz-collapse>

    <ng-container *ngIf="!!objId">
        <nz-switch class="p-t-15" nzSize="small" [(ngModel)]="showAllCatalogs" (ngModelChange)="switchChanged()"></nz-switch> <small class="p-l-10 p-t-15">Es werden nur Objekt gebundene Kataloge angezeigt, Sie können auch alle anzeigen</small>
    </ng-container>
    <nz-divider></nz-divider>
    <div class="p-t-10">
        <button nz-button
                nzSize="small"
                (click)="addToTask()"
                nzType="primary">
            Hinzufügen
        </button>
    </div>
</nz-spin>





