<div nz-row>
  <div nz-col nzSpan="16" class="p-5">
    <nz-card *ngIf="user">
      <nz-spin [nzSpinning]="userLoading">

        <div>
          <h3 class="title">
            Mitarbeiter-Daten
          </h3>

        </div>

        <form nz-form nzLayout="vertical" [formGroup]="userForm" (ngSubmit)="updateUser()">

          <nz-form-item>
            <nz-form-label nzFor="name" nzRequired>Vorname Nachname</nz-form-label>
            <nz-form-control nzErrorTip="Vor- und Nachname angeben (min. drei Zeichen)">
              <input formControlName="name" nz-input placeholder="Name">
            </nz-form-control>
          </nz-form-item>

          <nz-row>
            <div nz-col nzSpan="12" class="p-r-10">
              <nz-form-item>
                <nz-form-label nzFor="email" nzRequired>E-Mailadresse</nz-form-label>
                <nz-form-control nzErrorTip="Bitte geben Sie eine gültige E-Mailadresse an">
                  <input formControlName="email" type="email" nz-input placeholder="mustermann@musterfirma.de">
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="12">
              <nz-form-item>
                <nz-form-label nzFor="username" nzRequired>Benutzername</nz-form-label>
                <nz-form-control
                  nzErrorTip="Bitte geben Sie einen gültigen Benutzernamen an. Dieser muss mindestens aus 5 Zeichen bestehen und darf keine Leerzeichen beinhalten.">
                  <input formControlName="username" nz-input placeholder="mustermann123">
                </nz-form-control>
              </nz-form-item>
            </div>
          </nz-row>


          <nz-form-item>
            <nz-form-label nzFor="phone">Telefonnummer</nz-form-label>
            <nz-form-control>
              <input formControlName="phone" nz-input placeholder="Telefonnummer">
            </nz-form-control>
          </nz-form-item>

          <div nz-row>
            <div nz-col nzSpan="16" class="p-r-10">
              <nz-form-item>
                <nz-form-control>
                  <nz-form-label>Teams</nz-form-label>
                  <nz-select formControlName="userTeams"
                             nzMode="multiple"
                             nzPlaceHolder="Teams"
                             [compareWith]="compareById"
                  >
                    <nz-option *ngFor="let item of teams" [nzLabel]="item.name" [nzValue]="item"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8">
              <nz-form-item>
                <nz-form-label>Farbe</nz-form-label>
                <nz-form-control>

                  <input nz-input [(colorPicker)]="user.color" [style.background]="user.color"/>
                </nz-form-control>
              </nz-form-item>

            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-control>
                  <nz-form-label>Rolle des Mitarbeiters</nz-form-label>
                  <br>
                  <nz-radio-group formControlName="userRole" [nzDisabled]="user.id === authService.currentUserValue.id">
                    <label nz-radio [nzValue]="'employee'" nz-tooltip
                           nzTooltipTitle="Diese Rolle erlaubt dem Mitarbeiter
                      das Einloggen in die App mit allen Funktionen">Mitarbeiter</label>
                    <label nz-radio [nzValue]="'manager'" nz-tooltip
                           nzTooltipTitle="Diese Rolle erlaubt dem Mitarbeiter
                      das Einloggen in die App und in die Weboberfläche, die Verwaltung von Tickets, News, Objekte und
                      Hausverwaltungen">Manager</label>
                    <label nz-radio [nzValue]="'admin'" nz-tooltip nzTooltipTitle="Diese Rolle gewährt dem Mitarbeiter
                      alle Berechtigungen eines Managers und darüber hinaus die Verwaltung von Personal und Account">Administrator</label>
                  </nz-radio-group>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-control>
                  <nz-form-label>Benachrichtigungen</nz-form-label>
                  <br>
                  <label nz-checkbox nz-tooltip nzTooltipTitle="Bei jedem Ticket, das neu erstellt wird, egal ob von einem anderen Mitarbeiter oder Bewohner, wird dieser
                    Mitarbeiter benachrichtigt. Darüber hinaus, erhält man über alle Updates des Tickets
                    Benachrichtigungen. Diese Einstellung ist daher nicht für jeden Benutzer sinnvoll. Vor allem aber für Manager oder Administratoren, kann diese Option Vorteile schaffen."
                         formControlName="ticket_contributor">
                    Benachrichtigungen automatisch für neu erstellte Tickets aktivieren

                  </label>
                  <!-- <br>
                   <label nz-checkbox formControlName="emailNotification">E-Mail Benachrichtigung bei jeder änderung an einem Ticket</label>-->
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>


          <div *ngIf="user && user.renewinterval?.module_timetracking">
            <nz-divider></nz-divider>
            <h3>Zeiterfassung</h3>
            <div nz-row>

              <div nz-col nzSpan="24">
                <nz-form-label class="m-t-20">Arbeitszeiten in Stunden</nz-form-label>
                <app-time-tracking-work-week
                  [(timeTrackingWorkWeek)]="this.user.timeTrackingWorkWeek"></app-time-tracking-work-week>
                <label class="m-t-20" nz-checkbox formControlName="prescribeBreakTime">Automatische Pausenberechnung
                  nach
                  §4
                  ARBZG</label>
              </div>
            </div>
          </div>

          <div class="text-right m-t-20">
            <button class="m-r-10" nz-button nzType="default" (click)="cancel()" type="button">Abbrechen</button>
            <button nz-button nzType="primary" type="submit" [disabled]="userForm.invalid || !user.renew_interval_id">
              Speichern
            </button>
          </div>
        </form>


      </nz-spin>
    </nz-card>


  </div>
  <div nz-col nzSpan="8" class="p-5">
    <ng-container *ngIf="userId === 'new' && !user.id">
      <app-select-user-billing-interval [(renewIntervalId)]="user.renew_interval_id"></app-select-user-billing-interval>
    </ng-container>

    <nz-card *ngIf="user && user.id && !user.isActive">


      <div>
        <h3 class="title color-red">
          <i nz-icon nzType="warning" nzTheme="outline"></i> Benutzer ist deaktiviert
        </h3>
      </div>

      <p>Wenn ein Benutzer deaktiviert ist, wird der Zugang für den gewählten Benutzer zur Administration und zur App
        gesperrt. Ein deaktivierter Benutzer wird in zukünftigen Abrechnungen <strong>nicht</strong> berücksichtigt.</p>

      <p *ngIf="user.nextRenew">
        Bezahlt bis:
        <strong> {{ user.nextRenew | date: 'dd.MM.yyyy' }}</strong>
      </p>
      <div class="text-right">
        <button nz-button nzType="primary" (click)="enableUser()">Benutzer reaktivieren</button>
      </div>

    </nz-card>

    <ng-container *ngIf="userId !== 'new'">
      <nz-card *ngIf="user">
        <div nz-row>
          <div nz-col nzSpan="24">
            <img class="rounded-profile-img m-h-30"
                 [src]="user.avatarPath ? backendURL + '/' + user?.avatarPath : '../../../../assets/user.png'">

            <nz-upload
              [nzName]="'file'"
              [nzAction]="backendURL + '/admin/avatar'"
              [nzData]="{user_id: user.id}"
              [nzFileType]="'image/png,image/jpeg,image/gif'"
              [nzAccept]="'.png, .jpeg, .jpg, .gif'"
              [nzHeaders]="{ apiKey: authService.session | async }"
              (nzChange)="onChange($event)"
              [nzShowUploadList]="false"
            >
              <button *ngIf="!user.avatarPath" nzSize="small" nzType="primary" nzGhost class="m-l-15" nz-button>
                Profilbild hochladen
              </button>
              <button *ngIf="user.avatarPath" (click)="deleteAvatar(); $event.stopPropagation()" nzSize="small"
                      nzType="default" nzGhost nzDanger class="m-l-15" nz-button>
                Profilbild löschen
              </button>
            </nz-upload>
            <div style="opacity: .69" class="m-t-30 m-l-15">
              <p class="m-0">Erlaubte Dateiformate '*.jpg, *.jpeg, *.png, *.gif'</p>
            </div>
          </div>
        </div>
      </nz-card>

    </ng-container>

    <nz-card *ngIf="user && user.id">


      <h3 class="title">
        Informationen
      </h3>

      <nz-descriptions nzSize="small" nzBordered>
        <nz-descriptions-item nzTitle="Erstellt" [nzSpan]="3">
          {{ user.createdAt | date: 'dd.MM.yyyy' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Status" [nzSpan]="3">
          <nz-badge *ngIf="user.isActive" nzStatus="success" nzText="Aktiv"></nz-badge>
          <nz-badge *ngIf="!user.isActive" nzStatus="error" nzText="Inaktiv"></nz-badge>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Paket & Abrechnung" [nzSpan]="3">
          <a *ngIf="user.isActive" (click)="editRenewInterval()">{{ user.renewinterval?.name }} <i nz-icon nzType="edit"
                                                                                                   nzTheme="outline"></i></a>
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="user.isActive" nzTitle="Nächste Abrechnung" [nzSpan]="3">
          {{ user.nextRenew | date: 'dd.MM.yyyy' }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Zeiterfassung" [nzSpan]="3">
          <nz-badge *ngIf="user.renewinterval?.module_timetracking" nzStatus="success" nzText="Lizenziert"></nz-badge>
          <nz-badge *ngIf="!user.renewinterval?.module_timetracking" nzStatus="error"
                    nzText="Nicht Lizenziert"></nz-badge>
        </nz-descriptions-item>
      </nz-descriptions>

      <ng-container *ngIf="user.isActive && (authService.currentUserValue.id !== user.id)">
        <button nz-button nzBlock  nzType="link" class="m-t-10" nzDanger (click)="disableUser()">Benutzer deaktivieren</button>
      </ng-container>
    </nz-card>





    <nz-card *ngIf="user && user.id && user.isActive">
      <div>

        <div>
          <h3 class="title">
            Passwort
          </h3>
        </div>

        <form nz-form nzLayout="vertical" [formGroup]="passwordForm">

          <nz-form-item>
            <nz-form-label nzFor="password">Neues Passwort</nz-form-label>
            <nz-form-control>
              <input (ngModelChange)="isTouched = true" formControlName="password" nz-input
                     placeholder="Neues Passwort">
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <label nz-checkbox formControlName="passwordChangeMessage">Benutzer benachrichtigen?</label>
            </nz-form-control>
          </nz-form-item>

        </form>
        <div class="text-right">
          <button nz-button nzType="primary" type="submit" [disabled]="!isTouched" (click)="passwordCancel()">Passwort
            ändern
          </button>
        </div>
      </div>
    </nz-card>

  </div>
  <nz-collapse class="w-100" *ngIf="user?.id">
    <nz-collapse-panel *appIsManager [nzHeader]="'Angemeldete Geräte von ' + user?.name">
      <app-session-list *appIsManager [userId]="userId"></app-session-list>
    </nz-collapse-panel>
  </nz-collapse>
</div>

