<nz-select (ngModelChange)="userChange.emit($event); $event.stopPropagation()"
           style="width: 100%"
           [(ngModel)]="user"
           nzShowSearch
           [nzAllowClear]="allowClear"
           nzPlaceHolder="- niemandem zugewiesen -"
           [nzSize]="size"
           [compareWith]="compareById"
           [nzDisabled]="readonly"
>
    <nz-option *ngIf="allowClear" nzLabel="- niemandem zugewiesen -" nzValue=" "></nz-option>
    <nz-option *ngFor="let user of users" [nzLabel]="user.name" [nzValue]="user"></nz-option>
</nz-select>
