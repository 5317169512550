<div nz-row [nzGutter]="nzGutter">
    <div *ngFor="let image of images; index as i" nz-col [nzSpan]="nzSpan" [ngClass]="showDateAsCaption ? 'p-5 p-b-20':'p-5'" nz-tooltip [nzTooltipTitle]="image.description" nzTooltipPlacement="top">
        <img [src]="backendURL + image.url" lightbox
             [imageSrc]="image.url"
             [imageName]="image.name"
             [imageDescription]="image.description"
             [thumbnails]="images"
             [disableControls]="disableControls"
             (edit)="editImage($event)" 
             (delete)="deleteImage($event)" 
             class="thumbnail" 
             [style]="{'max-height': maxHeight + 'px'}">
        <span class="p-t-5 display-block text-center" *ngIf="showDateAsCaption">{{image.createdAt | date:'dd.MM.y H:mm'}}</span>
    </div>
</div>