<div class="container">
  <div class="text-center m-t-30 m-b-40">

    <i style="font-size: 60px" class="m-b-50" nz-icon nzType="lock" nzTheme="outline"></i>


    <h2>Diese Funktion steht Ihnen aktuell leider nicht zur Verfügung</h2>
    <p class="w-80 m-h-auto m-t-30 m-b-30">
      In Anbetracht der aktuellen wirtschaftlichen Rahmenbedingungen, steigender Betriebskosten und Investitionen in die
      Qualität und Verbesserung unserer Produkte, ist es für uns unvermeidbar, eine Preiserhöhung vorzunehmen. Als
      Bestandskunde profitierten Sie lange Zeit noch von unseren günstigen Einstiegspreisen. Wie Sie sicher mitbekommen
      haben, hat sich aber seit Projektbeginn einiges getan und unser Service wird stetig erweitert. Wir hoffen daher
      auf Ihr Verständnis für diesen Schritt.
    </p>
    <h5>Wie kann ich meinen Account upgraden?</h5>
    <p>
      Bitte wenden Sie sich schriftlich oder telefonisch an unseren Kundenservice, mit der Bitte um ein <strong>Account-Upgrade</strong>.
      Anschließend erhalten Sie von uns eine Auskunft über die für Sie geltenden neuen Preise. Diese unterscheiden sich
      in der Regel nur minimal von Ihrem aktuellen Preis. Nach Ihrer Zustimmung, wird das Upgrade an Ihrem Account wirksam.
    </p><br>

    <a href=" https://hausmeisterapp.com/kontakt/" target="_blank" nz-button nzType="primary">
      Kontakt
    </a>


  </div>

</div>
