<nz-select *appIsManager nzPlaceHolder="Nutzer wählen" style="width: 100%" [(ngModel)]="selected.user"
           (ngModelChange)="addContributor()">
    <nz-option-group [nzLabel]="userHeader">
        <ng-template #userHeader>
            <strong>Mitarbeiter</strong>
        </ng-template>
        <nz-option *ngFor="let contributor of availableContributors"
                   [nzValue]="contributor"
                   [nzLabel]="contributor.name"
        ></nz-option>
    </nz-option-group>
    <nz-option-group [nzLabel]="appuserHeader">
        <ng-template #appuserHeader>
            <strong>Bewohner</strong>
        </ng-template>
        <nz-option *ngFor="let appUserContributor of availableAppUser"
                   [nzValue]="appUserContributor.user"
                   [nzLabel]="appUserContributor.user.name"
        ></nz-option>
    </nz-option-group>
    <nz-option-group *ngIf="availableHVContributors?.length > 0" [nzLabel]="hvHeader">
        <ng-template #hvHeader>
            <strong>Hausverwaltungsansprechpartner</strong>
        </ng-template>
        <nz-option *ngFor="let hvContributor of availableHVContributors"
                   [nzValue]="hvContributor"
                   [nzLabel]="hvContributor.name"
        ></nz-option>
    </nz-option-group>
</nz-select>


<ng-container>
    <nz-spin [nzSpinning]="isLoading">
        <nz-list style="overscroll-behavior-x: auto" [nzSplit]="true" class="m-t-10" nzBordered
                 [nzHeader]="currentContributors?.length > 0  ? header : ''" nzSize="small">
            <ng-template #noResult>
                <p class="p-0 m-0">Aktuell werden keine Nutzer benachrichtigt</p>
            </ng-template>
            <ng-template #header>
                <p class="p-0 m-0">
                    <strong>{{ currentContributors?.length }} ausgewählte Nutzer</strong>
                </p>
            </ng-template>
            <nz-list-empty *ngIf="currentContributors?.length <= 0" [nzNoResult]="noResult">

            </nz-list-empty>
            <ng-container *ngIf="currentContributors?.length > 0">
                <nz-list-item *ngFor="let item of currentContributors">
                    <span>
                        {{ item.name }}
                        <small><p
                                class="m-0 p-0">{{ item.user_type === 'customer' && !item.property_management_id ? 'Mieter' : (item.property_management_id ? 'Hausverwaltung' : 'Mitarbeiter') }}</p></small>
                    </span>
                    <ul nz-list-item-actions>
                        <nz-list-item-action *appIsManager>
                            <a nz-tooltip="Nutzer nicht mehr benachrichtigen"
                               nzTooltipPlacement="right"
                               nz-popconfirm
                               nzPopconfirmTitle="Sind Sie sich sicher?"
                               nzPopconfirmPlacement="top"
                               nzOkText="Ja"
                               nzCancelText="Nein"
                               (nzOnConfirm)="deleteContributor(item.Contributors_Users_Tickets.id)"
                               (nzOnCancel)="cancel()">
                                Entfernen
                            </a>
                        </nz-list-item-action>
                    </ul>
                </nz-list-item>
            </ng-container>
        </nz-list>
    </nz-spin>
</ng-container>
