<td colspan="2">
  <strong>Manueller Eintrag</strong>
  <p class="p-0 m-0">für den ganzen Tag</p>
</td>
<td>
  <mbsc-datepicker
    label="Arbeitszeit"
    [(ngModel)]="valuesAsTime.worktime"
    [controls]="['time']"
    [stepMinute]="1"
    [timezonePlugin]="momentPlugin"
    headerText="Arbeitszeit in Stunden und Minuten"
    timeFormat="HH:mm"
    inputStyle="outline"
    labelStyle="stacked"
    returnFormat="moment"
    theme="ios"
  ></mbsc-datepicker>
</td>
<td>
  <mbsc-datepicker
    label="Pausenzeit"
    [(ngModel)]="valuesAsTime.breaktime"
    [controls]="['time']"
    [stepMinute]="1"
    [timezonePlugin]="momentPlugin"
    headerText="Pausenzeit in Stunden und Minuten"
    timeFormat="HH:mm"
    inputStyle="outline"
    labelStyle="stacked"
    returnFormat="moment"
    theme="ios"
  ></mbsc-datepicker>
</td>
<td>
  <mbsc-datepicker
    label="Abwesenheit"
    [(ngModel)]="valuesAsTime.absenttime"
    [controls]="['time']"
    [stepMinute]="1"
    [timezonePlugin]="momentPlugin"
    headerText="Abwesenheiten in Stunden und Minuten"
    timeFormat="HH:mm"
    returnFormat="moment"
    inputStyle="outline"
    labelStyle="stacked"
    theme="ios"
  ></mbsc-datepicker>
</td>
<td></td>

<td colspan="2">
  <mbsc-datepicker
    label="Soll"
    class="pull-right"
    [(ngModel)]="valuesAsTime.needed"
    [controls]="['time']"
    [stepMinute]="1"
    headerText="Soll in Stunden und Minuten"
    timeFormat="HH:mm"
    inputStyle="outline"
    labelStyle="stacked"
    theme="ios"
  ></mbsc-datepicker>
</td>
<td>
  <button nzSize="small" nz-button nzType="primary"
          (click)="save()"><i
    nz-icon nzType="save"></i> Speichern
  </button>

  <button nzSize="small" nz-button nzDanger nzType="link"
          (click)="reset()"><i
    nz-icon nzType="delete"></i>
  </button>
</td>

