import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {DatePipe, NgForOf} from '@angular/common';
import {NzSpinComponent} from 'ng-zorro-antd/spin';
import {MessageService} from '../../../services/message.service';
import {NzTableComponent} from 'ng-zorro-antd/table';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {MailImportConfig} from '../../../../../../../database-models';

@Component({
  selector: 'app-mail-import',
  templateUrl: './mail-import.component.html',
  styleUrl: './mail-import.component.css'
})
export class MailImportComponent implements OnInit {

  public loading: boolean = false;
  public imapData = {};

  public configs: MailImportConfig[];

  constructor(
    public api: ApiService,
    private message: MessageService,
    private modal: NzModalRef
  ) {
  }


  ngOnInit(): void {
    this.loading = true;

    this.api.getMailImportConfig().subscribe(data => {
      this.configs = data;
      if (this.configs?.length > 0) {
        this.loadImapInbox(this.configs[0]);
      } else {
        this.loading = false;
      }
    }, error => {
      this.message.error('Fehler beim laden der E-Mail-Postfächer');
      this.loading = false;
    });

  }

  public loadImapInbox(config: MailImportConfig): void {
    if (!this.imapData[config.id]) {
      this.loading = true;
      this.api.getMailImport(config.id).subscribe(data => {
        this.imapData[config.id] = data;
        this.loading = false;
      }, error => {
        this.message.error('Fehler beim laden der E-Mails');
        this.loading = false;
      });
    }
  }

  public select(inboxId: string, messageUid: any): void {
    this.loading = true;
    this.api.getMailImportFullMessage(inboxId, messageUid).subscribe(message => {

      this.modal.close(message);
      this.loading = false;
    }, error => {
      this.message.error('Fehler beim laden der E-Mail vom IMAP Server');
      this.loading = false;
    });

  }


}
