import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Company} from '../../../../../../database-models';
import {ApiService} from '../../../services/api.service';
import moment from 'moment';
import {LicenseService} from '../../services/license.service';

@Component({
  selector: 'app-test-period-banner',
  templateUrl: './test-period-banner.component.html',
  styleUrls: ['./test-period-banner.component.scss']
})
export class TestPeriodBannerComponent implements OnInit {

  constructor(
    public licenseService: LicenseService,
    private api: ApiService
  ) {
  }


  ngOnInit(): void {

  }



}
