<h3>Zählerstand erfassen</h3>
<nz-spin [nzSpinning]="isLoading">
    <form [formGroup]="counterStatusForm" *ngIf="counterStatusForm">
        <div nz-row>
            <nz-form-item nz-col nzSpan="12" class="p-r-5">
                <nz-form-label nzRequired>Zählerstand</nz-form-label>
                <nz-form-control>
                    <input nz-input
                           [placeholder]="'Zählerstand erfassen'"
                           type="number" formControlName="count">
                </nz-form-control>
            </nz-form-item>

            <nz-form-item nz-col nzSpan="12">
                <nz-form-label nzRequired>Abgelesen am</nz-form-label>
                <nz-form-control>
                    <nz-date-picker
                            class="w-100"
                            [nzFormat]="dateFormat"
                            formControlName="readingDate"
                    ></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </div>

        <div nz-row>
            <nz-form-item nz-col nzSpan="24">
                <nz-form-label nzFor="note">Notiz</nz-form-label>
                <nz-form-control>
                <textarea formControlName="note" [nzAutosize]="{ minRows: 2, maxRows: 6 }" nz-input
                          placeholder="Notiz schreiben (optional)" nzAutosize></textarea>
                </nz-form-control>
            </nz-form-item>
        </div>
    </form>

        <nz-divider></nz-divider>
        <button class="m-t-10" nzSize="small" nz-button nzType="link" (click)="close()">Abbrechen</button>

        <button class="m-t-10 pull-right" nzSize="small" [disabled]="counterStatusForm.invalid"
                nz-button nzType="primary" (click)="setCounterStatus()">Speichern
        </button>
</nz-spin>
