import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import {BillingTransactions, File, LexInvoice} from '../../../../../../database-models';
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.css']
})
export class TransactionsListComponent implements OnInit {

  public transactions: BillingTransactions[];

  constructor(
    private api: ApiService,
    private modalService: NzModalService,
    private message: NzMessageService,
  ) {
  }

  ngOnInit(): void {
    this.getTransactions();
  }

  public getTransactions(): void {
    this.api.getTransaction().subscribe((transactions: BillingTransactions[]) => {
      this.transactions = transactions;
    }, error => {
      this.message.error('Fehler beim laden der Transaktionen!');
    });
  }

  public download(lexInvoice: LexInvoice): void {
    this.api.downloadInvoice(lexInvoice.id).subscribe(res => {
      saveAs(res, (lexInvoice.voucherNumber ? lexInvoice.voucherNumber : lexInvoice.documentFileId) + '.pdf');
    });
  }

}
