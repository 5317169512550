<h2>Kontakt
</h2>

<nz-alert class="m-v-20" *ngIf="contact?.lexofficeId" nzType="warning" [nzMessage]="lxMsg"></nz-alert>
<ng-template #lxMsg>
  Dieser Kontakt wurde aus <strong>Lexware Office</strong> importiert. Sie können hier daher nur wenige Felder bearbeiten. Bearbeiten Sie den Kontakt in Lexware Office oder <a
  class="color-red" (click)="deleteLexOfficeConnection()">löschen Sie die Verknüpfung</a> zu Lexware Office. Alle Änderungen werden automatisch übertragen.<br>
  <div class="m-t-10">
    <a target="_blank" [href]="lxuiUrl + '/contacts/' + contact.lexofficeId">in Lexware Office ansehen <i nz-icon
                                                                                                     nzType="select"
                                                                                                     nzTheme="outline"></i></a>
  </div>

</ng-template>
<nz-tabset [nzHideAdd]="hideAdd" (nzAdd)="newTab()" nzType="editable-card" [(nzSelectedIndex)]="index">
  <nz-tab [nzTitle]="contactTitle">
    <ng-template #contactTitle>
      <i nz-icon nzType="contacts" nzTheme="outline"></i> Übersicht
    </ng-template>

    <div nz-col>
      <form nz-form [formGroup]="contactForm">
        <div nz-row>
          <nz-form-item nz-col nzSpan="6" class="p-r-5">
            <nz-form-label nzFor="type">Kontaktart</nz-form-label>
            <nz-form-control>
              <input formControlName="type" nz-input placeholder="Kontakt Typ z.B. Notdienst"
                     [nzAutocomplete]="auto"/>
              <nz-autocomplete nzBackfill #auto>
                <nz-auto-option *ngFor="let type of types" [nzValue]="type">
                  {{ type }}  <i [hidden]="ContactTypes.includes(type)" class="pull-right m-t-5" nz-icon nzType="tag"
                                 nzTheme="outline"></i>
                </nz-auto-option>
              </nz-autocomplete>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-col nzSpan="3" class="p-r-5">
            <nz-form-label nzFor="salutation">Anrede</nz-form-label>
            <nz-form-control>
              <nz-select style="width: 100%" [nzAllowClear]="true" formControlName="salutation" nzPlaceHolder="Anrede">
                <nz-option *ngFor="let salutations of salutations" [nzValue]="salutations"
                           [nzLabel]="salutations"></nz-option>

              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-col nzSpan="15" class="p-r-5">
            <nz-form-label nzRequired nzFor="name">Vor & Nachname oder Firmenname</nz-form-label>
            <nz-form-control nzErrorTip="Bitte geben Sie den Vor & Nachnamen oder Firmennamen ein.">
              <input formControlName="name" nz-input placeholder="Max Mustermann">
            </nz-form-control>
          </nz-form-item>


        </div>


        <nz-divider></nz-divider>
        <h4>Adressen</h4>
        <div nz-row>
          <div nz-col nzSpan="24">
            <ng-container formArrayName="contact_addresses">

              <ng-container *ngFor="let addresses of contactAddress.controls; let i = index">
                <div nz-row [formGroup]="addresses">
                  <nz-form-item nz-col nzSpan="4" class="p-r-5">
                    <nz-form-label nzFor="type" nzRequired>
                      Typ
                    </nz-form-label>
                    <nz-form-control nzErrorTip="Bitte geben Sie einen Typ an">
                    <nz-select class="w-100" formControlName="type" nzPlaceHolder="Typ">
                      <nz-option *ngFor="let types of AddressTypes" [nzValue]="types"
                                 [nzLabel]="types"></nz-option>
                    </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item nz-col nzSpan="8" class="p-r-5">
                    <nz-form-label nzFor="address" nzRequired>
                      Straße
                    </nz-form-label>
                    <nz-form-control nzErrorTip="Bitte geben Sie eine Straße an">
                    <input placeholder="Musterstrasse 1" type="text" nz-input formControlName="address"/>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item nz-col nzSpan="4" class="p-r-5">
                    <nz-form-label nzFor="zip" nzRequired>
                      Postleitzahl
                    </nz-form-label>
                    <nz-form-control nzErrorTip="Bitte geben Sie eine Postleitzahl an">
                    <input placeholder="12345" type="number" nz-input
                           formControlName="zip"/>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item nz-col nzSpan="6" class="p-r-5">
                    <nz-form-label nzFor="city" nzRequired>
                      Ort
                    </nz-form-label>
                    <nz-form-control nzErrorTip="Bitte geben Sie einen Ort an">
                    <input
                      placeholder="Musterstadt"
                      type="text" nz-input
                      formControlName="city"/>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item nz-col nzSpan="2" class="p-r-5">
                    <button *ngIf="!disabled" (click)="delete('address', i)"
                            nz-button nzType="link" class="m-t-40"
                    ><i nz-icon nzType="delete"></i></button>
                  </nz-form-item>
                </div>
              </ng-container>

            </ng-container>
          </div>
        </div>

        <div class="m-t-10" *ngIf="!disabled">
          <a *appIsManager (click)="addAddress()" style="color: #ffaa00">+ Adresse hinzufügen</a>
        </div>

        <nz-divider></nz-divider>
        <h4>E-Mail</h4>
        <div nz-row>
          <div nz-col nzSpan="24">
            <ng-container formArrayName="contact_emails">
              <nz-table #emailTable [nzData]="['']" [nzShowPagination]="false">

                <tbody>

                <ng-container *ngFor="let email of contactEmails.controls; let i = index">
                  <tr [formGroup]="email">
                    <td>
                      <nz-select style="width: 100%" formControlName="type" nzPlaceHolder="Typ wählen">
                        <nz-option *ngFor="let types of EmailTypes" [nzValue]="types"
                                   [nzLabel]="types"></nz-option>
                      </nz-select>
                    </td>
                    <td>
                      <input placeholder="VornameNachname@mustermail.de" [required]="true" type="text" nz-input
                             formControlName="email"/>
                    </td>
                    <td style="width: 50px">
                      <button *ngIf="!disabled" (click)="delete('email', i)" nzSize="small"
                              nz-button nzType="link"
                      ><i nz-icon nzType="delete"></i></button>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </nz-table>
            </ng-container>

          </div>
        </div>

        <div class="m-t-10" *ngIf="!disabled">
          <a *appIsManager (click)="addEmail()" style="color: #ffaa00">+ E-Mail hinzufügen</a>
        </div>

        <nz-divider></nz-divider>
        <h4>Telefon</h4>
        <div nz-row>
          <div nz-col nzSpan="24">
            <ng-container formArrayName="contact_phones">
              <nz-table [nzData]="['']" [nzShowPagination]="false">

                <tbody>

                <ng-container *ngFor="let phones of contactPhones.controls; let i = index">

                  <tr [formGroup]="phones">
                    <td>
                      <nz-select style="width: 100%" formControlName="type" nzPlaceHolder="Typ wählen">
                        <nz-option *ngFor="let types of PhoneTypes" [nzValue]="types"
                                   [nzLabel]="types"></nz-option>
                      </nz-select>
                    </td>
                    <td><input placeholder="+49 172 12345678" type="tel" nz-input formControlName="phonenumber"/></td>
                    <td style="width: 50px">
                      <button *ngIf="!disabled" (click)="delete('phone', i)"
                              nzSize="small"
                              nz-button nzType="link"
                      ><i nz-icon nzType="delete"></i></button>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </nz-table>
            </ng-container>
          </div>
        </div>

        <div class="m-t-10" *ngIf="!disabled">
          <a *appIsManager (click)="addPhone()" style="color: #ffaa00">+ Telefon hinzufügen</a>
        </div>
      </form>
      <!--Buttons-->
      <ng-container *ngIf="index <= 0">
        <div class="m-t-30">



          <ng-container *appIsManager>
            <button nz-dropdown [nzDropdownMenu]="menu" *ngIf="contact.id"
                    class="m-r-10"
                    nz-button
                    nzType="default"
                    type="button"><lexoffice-icon class="m-r-5"></lexoffice-icon> Lexware Office
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item *ngIf="contact.lexofficeId">
                  <a target="_blank" [href]="lxuiUrl + '/contacts/' + contact.lexofficeId"><i nz-icon nzType="select"
                                                                                              nzTheme="outline"></i> in Lexware Office
                    ansehen </a>
                </li>
                <li (click)="deleteLexOfficeConnection()" nz-menu-item *ngIf="contact.lexofficeId">
                  <a><i nz-icon
                        nzType="delete"></i> Verknüpfung aufheben</a>
                </li>
                <li (click)="syncContactLexoffice()" nz-menu-item *ngIf="contact.lexofficeId">
                  <a><i nz-icon
                        nzType="reload"></i> Mit Lexware Office synchronisieren</a>
                </li>
                <li (click)="selectLexofficeContact()" nz-menu-item *ngIf="!contact.lexofficeId">
                  <a><i nz-icon
                        nzType="link"></i> Mit Kontakt verknüpfen</a>
                </li>
              </ul>
            </nz-dropdown-menu>

            <div class="pull-right">



              <button (click)="cancel()"
                      class="m-r-10"
                      nz-button
                      nzType="default"
                      type="button">Abbrechen
              </button>
              <button nz-button
                      [disabled]="contactForm.invalid"
                      nzType="primary"
                      (click)="updateContact()"
                      type="submit">
                Speichern
              </button>
            </div>

          </ng-container>
        </div>
      </ng-container>
    </div>

  </nz-tab>

  <!--Ansprechpartner TAB-->
  <ng-container *ngFor="let contactPerson of contact.contact_contactPeople; let i = index">
    <nz-tab [nzTitle]="contactPersonTitle">
      <ng-template #contactPersonTitle>
        <i nz-icon nzType="user"
           nzTheme="outline"></i> {{ (contactPerson.firstname || contactPerson.lastname) ? ((contactPerson.firstname ? contactPerson.firstname + ' ' : '') + contactPerson.lastname) : 'Ansprechpartner' }}
      </ng-template>
      <app-edit-contact-person [contactPerson]="contactPerson" [contact]="contact" [disabled]="!contact.id || contact.lexofficeId"
                               (contactPersonDelete)="contact.contact_contactPeople.splice(i, 1)"></app-edit-contact-person>
    </nz-tab>
  </ng-container>
</nz-tabset>


