import {AfterViewInit, Component, OnInit} from '@angular/core';
import {LatLngExpression} from 'leaflet';
import * as L from 'leaflet';
import {Company} from '../../../../../../../database-models';
import {ApiService} from '../../../../services/api.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {MapMarker} from '../map-marker';

@Component({
  selector: 'app-map-set-company-location',
  templateUrl: './map-set-company-location.component.html',
  styleUrls: ['./map-set-company-location.component.css']
})
export class MapSetCompanyLocationComponent implements OnInit, AfterViewInit {

  public company: Company;

  constructor(
    private api: ApiService,
    private message: NzMessageService,
    private mapMarker: MapMarker
  ) {
  }

  ngOnInit(): void {

  }

  public getCompany(): Promise<unknown> {
    return new Promise(resolve => {
      this.api.getCompanyData().subscribe(company => {
        this.company = company;
        resolve(company);
      });
    })

  }

  public map;
  public markers;

  private initMap(position?: GeolocationPosition): void {

    let center: LatLngExpression = [51.1020, 10.2832];
    let zoom = 4;
    if (position?.coords) {
      center = [position.coords.latitude, position.coords.longitude];
      zoom = 10;
    }

    this.map = L.map('mapedit', {
      center,
      zoom
    });
    this.markers = new L.LayerGroup().addTo(this.map);

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
    });

    tiles.addTo(this.map);



    try {
      if (this.company?.lat && this.company?.long) {
        var marker = L.marker([this.company.lat, this.company.long], {
          icon: this.mapMarker.company,
        }).addTo(this.markers);
      }

      // @ts-ignore
      var group = new L.featureGroup([marker]);

      this.map.fitBounds(group.getBounds());
    } catch (e) {

    }

    this.map.on('click', (map) => {
      console.log(map);
      this.markers.clearLayers();
      // @ts-ignore
      this.marker = new L.marker(map.latlng, {
        icon: this.mapMarker.company
      }).addTo(this.markers);
      this.company.lat = map.latlng.lat;
      this.company.long = map.latlng.lng;
    });
  }

  public resetMap(): void {
    this.markers.clearLayers();
    this.company.lat = null;
    this.company.long = null;
    this.setCompany();
  }

  async ngAfterViewInit(): Promise<void> {
    await this.getCompany();
    try {
      if (!this.company?.lat) {
        let position: GeolocationPosition = await this.getCurrentLocation();
        this.initMap(position);
      } else {
        this.initMap();
      }
    } catch (e) {
      this.initMap();
    }
  }

  getCurrentLocation(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(position => {
        resolve(position);
      }, positionError => {
        reject(positionError);
      });
    });

  }

  public setCompany(): void {
    this.api.setCompanyData(this.company).subscribe((company: Company) => {
      this.message.success('Erfolgreich gespeichert');
    }, error => {
      this.message.error('Speichern fehlgeschlagen!');
    });
  }

}
