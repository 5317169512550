import {Injectable, TemplateRef} from '@angular/core';
import { NzMessageDataOptions, NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private message: NzMessageService
  ) {
  }

  public success(message: string, options?: NzMessageDataOptions): void {
    this.message.success(message, options);
  }

  public error(message: string, options?: NzMessageDataOptions): void {
    this.message.error(message, options);
  }

}
