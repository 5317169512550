import {Component, inject, Input, OnInit, Optional} from '@angular/core';
import {SharedTicket, Ticket, User} from '../../../../../../../database-models';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {PreviewSharedTicketComponent} from '../preview-shared-ticket/preview-shared-ticket.component';

export interface ModalData {
  ticket: Ticket;
}

@Component({
  selector: 'app-ticket-share',
  templateUrl: './ticket-share.component.html',
  styleUrls: ['./ticket-share.component.scss']
})
export class TicketShareComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  public copyToClipboard: string;

  @Input()
  public ticket: Ticket;


  public ticketid: string;

  public isLoading = false;

  public sharedTicket: SharedTicket = {
    files: false,
    persons: false,
    comments: false,
  } as SharedTicket;
  public sharedForm: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    private message: NzMessageService,
    private modalService: NzModalService,
    @Optional() private modal: NzModalRef
  ) {
  }

  ngOnInit(): void {
    this.ticket ??= this.data.ticket;
    this.sharedForm = this.formBuilder.group({
      files: [this.sharedTicket.files],
      persons: [this.sharedTicket.persons],
      comments: [this.sharedTicket.comments],
    });
  }

  public generateLink(): void {
    this.isLoading = true;
    const data = this.sharedForm.getRawValue();
    this.sharedTicket.files = data.files;
    this.sharedTicket.persons = data.persons;
    this.sharedTicket.comments = data.comments;
    this.sharedTicket.ticket = this.ticket;


    this.api.setSharedTicket(this.sharedTicket).subscribe((sharedTicket: SharedTicket) => {
      this.sharedTicket = sharedTicket;
      this.message.success('Erfolgreich gespeichert');
      this.isLoading = false;
    });


  }

  public cancel(): void {
    if (this.sharedForm.touched) {
      this.modalService.confirm({
        nzTitle: '<i>Bearbeiten abbrechen</i>',
        nzContent: 'Sind Sie sicher, dass Sie abbrechen möchten? Alle Änderungen gehen verloren.',
        nzOnOk: () => {
          this.modal.close();
        }
      });
    } else {
      this.modal.close();
    }
  }

  public showPreview(): void {
    const newObjectModal = this.modalService.create<PreviewSharedTicketComponent>({
      nzContent: PreviewSharedTicketComponent,
      nzData: {},
      nzWidth: '700px',
      nzFooter: null
    });
    newObjectModal.afterClose.subscribe((data: any) => {

    });
  }

  public clipboardCopy(): void {
    this.copyToClipboard = this.sharedTicket.link;
    this.message.success('Link wurde in die Zwischenablage kopiert!');
  }
}
