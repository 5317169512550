<nz-table [nzData]="[1]" nzShowPagination="false">
  <tbody>
  <tr>
    <td *ngFor="let day of tmpWorkWeek | keyvalue: asIsOrder">
      {{ translation[day.key] }}
    </td>
    <td>Summe</td>
  </tr>
  <tr>
    <td *ngFor="let day of translation | keyvalue: asIsOrder"
       >
      <nz-input-number
        style="width: 60px"  [ngClass]="{ 'background-green' : tmpWorkWeek[day.key] > 0 }"
        [(ngModel)]="tmpWorkWeek[day.key]"
        (ngModelChange)="save()"
        [nzMin]="0"
        [nzMax]="24"
        [nzStep]="0.5"
      ></nz-input-number>
    </td>
    <td class="text-bold">
      <div style="width: 70px">{{ sum() }} Std.</div>
    </td>
  </tr>

  </tbody>
</nz-table>

