<h3>Dateien</h3>
<div nz-row [nzGutter]="8">
  <div nz-col class="p-5" [nzSpan]="6" *ngFor="let item of files" (click)="toggleFile(item)" >

<div class="file-container" [ngClass]="{ 'active' : selectedFiles.includes(item) }">
  <div class="preview-img" *ngIf="fileIsImage(item.type)"
       [style.background-image]="'url(' + backendURL + '/' + item.url + ')'">
    <nz-tag *ngIf="item.onlyManager" style="position: absolute; bottom: 0; right: 0; margin: 5px"
            [nzColor]="'rgba(0,168,255,0.8)'"><i nz-icon nzType="lock" nzTheme="outline" nz-tooltip
                                                 nzTooltipTitle="Diese Dateien sind nur für Manager & Admin sichtbar"></i>
    </nz-tag>
  </div>
  <div class="preview-img text-center" *ngIf="!fileIsImage(item.type)">
    <div class="font-size-50">
      <i class="m-t-20" [nzTwotoneColor]="getFiletype(item.type).twoToneColor" nz-icon
         [nzType]="getFiletype(item.type).icon"
         nzTheme="twotone"></i>
    </div>
    <small>{{item.name}}</small>
    <nz-tag *ngIf="item.onlyManager" style="position: absolute; bottom: 0; right: 0; margin: 5px"
            [nzColor]="'rgba(0,168,255,0.8)'"><i nz-icon nzType="lock" nzTheme="outline" nz-tooltip
                                                 nzTooltipTitle="Diese Dateien sind nur für Manager & Admin sichtbar"></i>
    </nz-tag>
  </div>
</div>


  </div>
</div>

<div class="text-right m-t-20">
  <button nzType="primary" (click)="ok()" nz-button [disabled]="selectedFiles.length <= 0">{{ selectedFiles.length }} Dateien wählen</button>
</div>

