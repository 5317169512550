<div class="text-center">
  <nz-tag *ngIf="company?.lexoffice?.active" [nzColor]="'green'">Anbindung Aktiv</nz-tag>
  <nz-tag *ngIf="!company?.lexoffice?.active">Anbindung Inaktiv</nz-tag>



<img alt="Lexware Office" class="p-30 w-100"
     src="assets/Lexware-Office-Logo.png"/>
  <small>
    Lexware Office macht unternehmerische Büropflichten sowie die
    gesamte Buchhaltung für Freiberufler, Selbständige, Freelancer
    und (Klein)Unternehmer ganz einfach. Mit Lexware Office kannst
    du auch ohne Vorkenntnisse Angebote erstellen, Rechnungen
    schreiben, Kosten erfassen, Belege mit dem Smartphone einscannen, automatisch die Buchhaltung und Lohnabrechnung erledigen
    und deine Steuererklärung vorbereiten. Und das alles in einem
    System.
  </small>
<nz-divider></nz-divider>

</div>

  <button nzDanger nzBlock nzSize="small"  *ngIf="company?.lexoffice?.active" (click)="revokeLexoffice()" nz-button>Lexware Office trennen</button>
  <button *ngIf="!company?.lexoffice?.active" nzGhost nzBlock (click)="lexofficeService.startOauth()" nz-button nzType="primary">Lexware Office verbinden</button>
  <button nzSize="small" nzType="primary" nzGhost class="m-t-10" nzBlock *ngIf="company?.lexoffice?.active" (click)="lexofficeService.startOauth()" nz-button>Lexware Office erneut verbinden</button>
