<nz-spin [nzSpinning]="isLoading">
    <nz-table #worklogTable [nzData]="worklogs" nzPaginationType="small"
              (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
        <thead>
        <tr>
            <th [nzChecked]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)">
            </th>

            <th [nzSortFn]="orderFn.details" [(nzSortOrder)]="sortOrders.details"
                (nzSortOrderChange)="sortChanged($event)">
                Details
            </th>
            <th [nzSortFn]="orderFn.task" [(nzSortOrder)]="sortOrders.task" (nzSortOrderChange)="sortChanged($event)">
                Tätigkeit
            </th>
            <th [nzSortFn]="orderFn.time" [(nzSortOrder)]="sortOrders.time" (nzSortOrderChange)="sortChanged($event)">
                Zeiten
            </th>
            <th></th>
        </tr>
        </thead>

        <tbody>
        <tr class="cursor-pointer" *ngFor="let dataItem of worklogTable.data" (click)="editWorklog(dataItem, false)">

            <td [nzChecked]="setOfCheckedId.has(dataItem.id)"
                (nzCheckedChange)="onItemChecked(dataItem.id, $event)"
                (dblclick)="$event.stopPropagation()"
            ></td>

            <td>
                <div class="font-weight-bold" *ngIf="dataItem.object"><i class="m-r-5" nz-icon nzType="home"
                                                                         nzTheme="outline"></i> {{ dataItem.object?.name }}
                    <ng-container *ngIf="dataItem.room"><i nz-icon nzType="right"
                                                           nzTheme="outline"></i>  {{ dataItem.room?.name }}
                    </ng-container>
                </div>

                <ng-container *ngIf="dataItem.ticket && dataItem.ticket_id">
                    <p class="p-0 m-0 font-weight-bold">
                        <small nz-tooltip="Verknüpftes Ticket" nzTooltipPlacement="right">
                            <i class="p-r-5" nz-icon nzType="inbox"
                               nzTheme="outline"></i>{{ dataItem.ticket.ticketNumber }}
                        </small>
                    </p>
                </ng-container>

                <div class="font-weight-bold" *ngIf="dataItem.customer"><i class="m-r-5" nz-icon nzType="idcard"
                                                                           nzTheme="outline"></i>{{ dataItem.customer.companyname || dataItem.customer.salutation + ' ' + dataItem.customer.firstname + ' ' + dataItem.customer.lastname }}
                </div>


            </td>

            <td style="max-width: 300px">
                <p nz-typography nzEllipsis [nzEllipsisRows]="2">{{ dataItem.description }}</p>

                <span *ngIf="dataItem.user" nz-tooltip nzTooltipPlacement="top"
                      nzTooltipTitle="Ersteller des Protokolls">
                <app-username-avatar [user]="dataItem.user"></app-username-avatar>
            </span>
            </td>

            <td>
                <div>
                    <ng-container *ngIf="dataItem.end">
                        <ng-container *ngIf="moment(dataItem.start).isSame(dataItem.end, 'day')">
                            <i nz-icon nzType="calendar" nzTheme="outline"></i> {{ dataItem.start | date:'dd.MM.y' }}
                            <br>
                            <i nz-icon nzType="clock-circle" nzTheme="outline"></i> {{ dataItem.start | date:'H:mm' }}
                            - {{ dataItem.end | date:'H:mm' }}
                        </ng-container>
                        <ng-container *ngIf="!moment(dataItem.start).isSame(dataItem.end, 'day')">
                            <i nz-icon nzType="calendar" nzTheme="outline"></i> {{ dataItem.start | date:'dd.MM.y' }}
                            <br>
                            <i nz-icon nzType="clock-circle" nzTheme="outline"></i> {{ dataItem.start | date:'H:mm' }}
                            - {{ dataItem.end | date:'H:mm' }} (+{{ moment(dataItem.end).diff(dataItem.start, 'day') }}
                            Tag)
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="!dataItem.end">
                        <i nz-icon nzType="calendar" nzTheme="outline"></i> {{ dataItem.start | date:'dd.MM.y' }}<br>
                        <i nz-icon nzType="clock-circle" nzTheme="outline"></i> {{ dataItem.start | date:'H:mm' }}
                    </ng-container>
                    <nz-tag class="m-l-10" *ngIf="dataItem.end" [nzColor]="'green'">{{ convertTime(dataItem.sum) }}
                    </nz-tag>

                </div>
            </td>


            <td>
                <i *ngIf="dataItem.billed" nz-tooltip
                   nzTooltipTitle="Abgerechnet"
                   class="text-info" nz-icon nzType="euro-circle" nzTheme="fill"></i>
                <i *ngIf="dataItem.finalized" nz-tooltip
                   nzTooltipTitle="Finalisiert"
                   class="text-info" nz-icon nzType="lock" nzTheme="fill"></i>
            </td>
        </tr>

        </tbody>
    </nz-table>

    <button class="m-r-5" (click)="editWorklog(null, true)" nz-button nzSize="small" nzType="primary">
        <i nz-icon nzType="plus-circle" nzTheme="outline"></i> Arbeitsprotokoll erstellen
    </button>

    <button nzSize="small" [disabled]="setOfCheckedId.size <= 0" nz-button nz-dropdown [nzDropdownMenu]="exportOptions">
        Massenaktion
        ( {{ setOfCheckedId.size }} ) <i nz-icon
                                         nzType="down"></i>
    </button>

    <label *appIsManager style="top: 5px; position: relative;" class="m-l-10" nz-checkbox [(ngModel)]="filter.billed"
           (ngModelChange)="getWorkLogsByObject()">inkl. Abgerechnet</label>

    <nz-dropdown-menu #exportOptions="nzDropdownMenu">
        <ul nz-menu>
            <li *appIsManager nz-menu-item [nzDisabled]="isFinalizedInArray()"
                [ngClass]="{ 'background-light-red' : isFinalizedInArray() }">
                <a (click)="bulkUpdateFinalized()"> <i nz-icon nzType="snippets" nzTheme="outline"></i>
                    Arbeitsprotokolle finalisieren <small *ngIf="isFinalizedInArray()">(nur Möglich wenn die Auswahl aus
                        nicht
                        finalisierten Protokollen besteht)</small>
                </a>
            </li>
            <li *appIsManager nz-submenu nzTitle="Abrechnung">
                <ul>
                    <li nz-menu-item>
                        <a (click)="bulkUpdateBilled(true)">
                            <i class="text-info" nz-icon nzType="euro-circle" nzTheme="fill"></i>
                            Arbeitsprotokolle abrechnen
                        </a>
                    </li>
                    <li nz-menu-item>
                        <a (click)="bulkUpdateBilled(false)">
                            <i class="text-danger" nz-icon nzType="euro-circle" nzTheme="fill"></i>
                            Nicht Abgerechnet
                        </a>
                    </li>
                </ul>
            </li>

            <li nz-submenu nzTitle="Exportieren">
                <ul>
                    <li nz-menu-item (click)="export('excel')">
                        <a>
                            <i nz-icon nzType="file-excel" nzTheme="outline"></i> Excel (XLSX)
                        </a>
                    </li>
                    <li nz-menu-item (click)="export('csv')">
                        <a>
                            <i nz-icon nzType="unordered-list" nzTheme="outline"></i> Kommaseparierte Liste (CSV)
                        </a>
                    </li>
                    <li nz-menu-item (click)="exportReport()">
                        <a>
                            <i nz-icon nzType="file-pdf" nzTheme="outline"></i> PDF
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </nz-dropdown-menu>
</nz-spin>
