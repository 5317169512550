<div class="m-b-10">
  Dauer pro Termin: <strong>{{ taskDuration }} Minuten</strong>
  <nz-slider (nzOnAfterChange)="getWastePlans()" [(ngModel)]="taskDuration" [nzStep]="5" [nzMin]="5" [nzMax]="60"></nz-slider>
</div>
<div class="m-b-10">
  <nz-switch nzSize="small" [(ngModel)]="combineEvents" (ngModelChange)="this.getWastePlans()"></nz-switch>
  Termine nach Objekt gruppieren
</div>
<nz-divider></nz-divider>
<ng-container *ngIf="combineEvents">
  <div class="wasteplan-scheduler">
    <span *ngIf="myEvents.length <= 0">Für den {{ filter.start | date: 'dd.MM.yyyy' }} sind keine Abfuhrtermine zu verplanen</span>

    <div class="m-b-20" *ngFor="let object of objects | keyvalue">

      <h5>{{ object.key }}</h5>

      <div mbsc-draggable [dragData]="object.value">
        <ng-container *ngFor="let event of object.value.children;let i = index">
          <div class="waste-event combined" [ngClass]="{ 'done' : event.done }"
               [ngStyle]="{borderColor: event.color, background: event.color}">
            <div class="waste-event-cont">
              <i *ngIf="event.type === 'out'" class="icon-out" nz-icon [nzType]="event.done ? 'check' : 'logout'"
                 nzTheme="outline"></i>
              <i *ngIf="event.type === 'in'" class="icon-in" nz-icon [nzType]="event.done ? 'check' : 'login'"
                 nzTheme="outline"></i>
              {{ event.title }}
            </div>
          </div>
        </ng-container>
      </div>

    </div>
  </div>
</ng-container>

<ng-container *ngIf="!combineEvents">
  <div class="wasteplan-scheduler">
    <span *ngIf="myEvents.length <= 0">Für den {{ filter.start | date: 'dd.MM.yyyy' }} sind keine Abfuhrtermine zu verplanen</span>
    <div class="m-b-20" *ngFor="let object of objects | keyvalue">

      <h5>{{ object.key }}</h5>


      <ng-container *ngFor="let event of object.value;let i = index">
        <div class="waste-event" mbsc-draggable [dragData]="object.value[i]" [ngClass]="{ 'done' : event.done }"
             [ngStyle]="{borderColor: event.color, background: event.color}">
          <div class="waste-event-cont">
            <i *ngIf="event.type === 'out'" class="icon-out" nz-icon [nzType]="event.done ? 'check' : 'logout'"
               nzTheme="outline"></i>
            <i *ngIf="event.type === 'in'" class="icon-in" nz-icon [nzType]="event.done ? 'check' : 'login'"
               nzTheme="outline"></i>
            {{ event.title }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

