export const environment = {
  production: false,
  webURL: 'https://dev.hausmeisterapp.com',
  apiURL: 'https://dev-api.hausmeisterapp.com',
  lexApiUrl: '/lexofficeapi',
  googleApiKey: 'AIzaSyAByxN6OqklAULsFBuDxu4r1rXwOAv0BKA',
  lexofficePartnerApi: {
    authorizationurl: 'https://lexoffice-sandbox.grld.eu/oauth2/authorize',
    resourceurl: 'https://lexoffice-sandbox.grld.eu',
    uiUrl: 'https://lexoffice-sandbox.grld.eu',
    redirectUri: 'https://dev.hausmeisterapp.com/company',
    clientId: '079ad2cb-4d9a-4fcd-bd43-b8c4c6814dbb',
    responseType: 'code',
    oidc: false,
    scope: 'articles.read articles.write profile.read contacts.read contacts.write invoices.write invoices.read credit-notes.write credit-notes.read files.read payments.read',
    showDebugInformation: true,
    customQueryParams: {
      connection_name: 'Hausmeisterapp.com',
      grant_type: 'authorization_code'
    }
  }
};
