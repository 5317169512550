<h2>Objekt bearbeiten</h2>
<form nz-form [formGroup]="objectForm">

  <div nz-row>
    <div nz-col nzSpan="15" class="p-r-20">
      <div nz-row>
        <nz-form-item nz-col nzSpan="18" class="p-r-5">
          <nz-form-label nzFor="name" nzRequired>Objektname</nz-form-label>
          <nz-form-control nzErrorTip="Bitte geben Sie einen Namen für das Objekt ein">
            <input formControlName="name" nz-input placeholder="Geben Sie dem Objekt einen Namen">
          </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="6">
          <nz-form-label nzFor="objectNumber">Objektnummer</nz-form-label>
          <nz-form-control>
            <input formControlName="objectNumber" nz-input placeholder="Objektnummer (optional)">
          </nz-form-control>
        </nz-form-item>

        <nz-form-item nz-col nzSpan="24">
          <nz-form-label nzFor="address_line1" nzRequired>
            Adresse
          </nz-form-label>
          <nz-form-control nzErrorTip="Bitte geben Sie eine Adresse für das Objekt ein">
            <input formControlName="address_line1" nz-input placeholder="Adresse">
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-row>
        <nz-form-item nz-col nzSpan="12" class="p-r-5">
          <nz-form-label nzFor="zip" nzRequired>PLZ</nz-form-label>
          <nz-form-control nzErrorTip="Postleitzahl eingeben">
            <input formControlName="zip" nz-input placeholder="PLZ">
          </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="12">
          <nz-form-label nzFor="city" nzRequired>Ort</nz-form-label>
          <nz-form-control nzErrorTip="Ort eingeben">
            <input formControlName="city" nz-input placeholder="Ort">
          </nz-form-control>
        </nz-form-item>
      </div>
      <nz-divider nzText="Optionale Angaben"></nz-divider>
      <div nz-row>
        <nz-form-item nz-col nzSpan="8" class="p-r-15">
          <nz-form-label nzFor="einheiten">Einheiten
          </nz-form-label>
          <nz-form-control>
            <input type="number" formControlName="einheiten" nz-input placeholder="Einheiten">
          </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="8" class="p-r-15">
          <nz-form-label nzFor="schluessel_anzahl">Anzahl Schlüssel
          </nz-form-label>
          <nz-form-control>
            <input type="number" formControlName="schluessel_anzahl" nz-input placeholder="Anzahl">
          </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="8" class="p-r-15">
          <nz-form-label nzFor="tiefgaragen">Anzahl
            Tiefgaragen
          </nz-form-label>
          <nz-form-control>
            <input type="number" formControlName="tiefgaragen" nz-input placeholder="Anzahl">
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-col nzSpan="9">

      <div nz-row>

        <nz-form-item nz-col nzSpan="10" class="p-r-5">
          <nz-form-label nzFor="lat">Lat</nz-form-label>
          <nz-form-control>
            <input formControlName="lat" (blur)="LatLongUpdated()" nz-input placeholder="klicken Sie auf die Karte">
          </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="10">
          <nz-form-label nzFor="long">Long</nz-form-label>
          <nz-form-control>
            <input formControlName="long" (blur)="LatLongUpdated()" nz-input placeholder="klicken Sie auf die Karte">
          </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-col nzSpan="2">
         <button [disabled]="!objectForm.get('lat').value" class="m-l-5 m-t-40" nz-button nzDanger (click)="resetMap()"><i nz-icon nzType="delete" nzTheme="outline"></i></button>
        </nz-form-item>

        <button nzBlock nzType="link" [hidden]="objectForm.get('lat').value" [disabled]="objectForm.invalid || findAddressGeoDisabled" (click)="findAddressGeo()" nz-button>Adresse automatisch ermitteln</button>
      </div>

      <small><i nz-icon nzType="info-circle" nzTheme="outline"></i> Klicken Sie auf die Karte, um die Position zu wählen</small>
      <div style="height: 350px">
        <div id="mapedit"></div>
      </div>

    </div>
  </div>


  <div class="text-right m-t-10">
    <button class="m-r-10" nz-button nzType="default" (click)="cancel()" type="button">Abbrechen
    </button>
    <button nz-button nzType="primary" type="submit" (click)="updateObject()"
            [disabled]="objectForm.invalid">
      Speichern
    </button>
  </div>
</form>
