<div>
    <div nz-row>
        <div nz-col nzSpan="24" [ngClass]="{'search-empty': isSearchEmpty}">
            <nz-input-group [nzSuffix]="hideClose ? '' : suffix">
            <input #searchInput [nz-tooltip]="infoTooltip" style="width: 100%;" (keydown)="keyEnter($event)" (click)="onResultClick($event)"
                   [(ngModel)]="filter.search" nz-input
                   [placeholder]="searchPlaceholder ? searchPlaceholder : 'Suche'"
                   nzSize="default"
            />
            </nz-input-group>
            <ng-template #suffix>
                <a (click)="hideSearch(false)" nz-tooltip="Suche schließen"><i nz-icon nzType="close" nzTheme="outline"></i></a>
            </ng-template>
        </div>
    </div>

    <ng-container *ngIf="isResultVisible" (click)="onResultClick($event)">
        <div #resultDiv class="result-div">
            <ng-container *ngIf="!showResults">
                <p class="p-10">Es wurden keine Ergebnisse gefunden</p>
            </ng-container>

            <ng-container *ngIf="showResults">
                <nz-spin [nzSpinning]="isLoading" nzSize="small">
                    <!--TASKS-->
                    <ng-container *ngIf="searchCategories.includes('TASKS') && searchResult?.tasks?.length > 0">
                        <h6>Aufgaben
                            <nz-badge *ngIf="searchResult?.tasks?.length > 0" [nzStyle]="{ backgroundColor: '#f58b0a' }"
                                      [nzCount]="searchResult?.tasks?.length"></nz-badge>
                        </h6>
                        <ul style="list-style: none; padding: 0;">
                            <li *ngFor="let task of searchResult?.tasks; let i = index"
                                (click)="taskWorklogLink(task); $event.stopPropagation();"
                                style="display: flex; align-items: center; justify-content: space-between;">
                                <div style="flex: 1;">
                                    <span>{{ task.title }}</span>
                                    <small><p class="m-0 p-0">{{ task.user?.name }}</p></small>
                                    <small><p *ngIf="task.start"
                                              class="m-0 p-0">{{ task.start | date: 'dd.MM.yyyy HH:mm' }}</p></small>
                                    <small><p *ngIf="task.object" class="m-0 p-0">{{ task.object?.name }}</p></small>
                                </div>
                            </li>
                        </ul>

                    </ng-container>

                    <!--TICKETS-->
                    <ng-container *ngIf="searchCategories.includes('TICKETS') && searchResult?.tickets?.length > 0">
                        <h6>
                            Tickets
                            <nz-badge *ngIf="searchResult?.tickets?.length > 0"
                                      [nzStyle]="{ backgroundColor: '#f58b0a' }"
                                      [nzCount]="searchResult?.tickets?.length"></nz-badge>
                        </h6>
                        <ul style="list-style: none; padding: 0;">
                            <li *ngFor="let ticket of searchResult?.tickets; let i = index"
                                [ngClass]="{'isOverdue' : isOverdue(ticket)}"
                                style="display: flex; align-items: center; justify-content: space-between;"
                                (click)="ticketWorklogLink(ticket)">
                                <div style="flex: 1;">
                                    <i nz-icon nzType="tag" nzTheme="fill" class="m-r-5"
                                       [nz-tooltip]="ticket.priority?.name"
                                       [style.color]="ticket.priority?.background_color"></i>
                                    <span>{{ ticket.name }}</span>
                                    <small><p class="m-0 p-0">{{ ticket.user?.name }}</p></small>
                                    <small><p *ngIf="ticket.state.name" class="m-0 p-0">{{ ticket.state.name }}</p>
                                    </small>
                                    <small><p *ngIf="ticket.object" class="m-0 p-0">{{ ticket.object?.name }}</p>
                                    </small>
                                </div>
                            </li>
                        </ul>
                    </ng-container>
                </nz-spin>
            </ng-container>
        </div>
    </ng-container>
</div>
