import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import moment from 'moment';
import * as Chart from 'chart.js';
import {ChartOptions} from 'chart.js';

@Component({
  selector: 'tickets-year-chart',
  templateUrl: './tickets-year-chart.component.html',
  styleUrls: ['./tickets-year-chart.component.css']
})
export class TicketsYearChartComponent implements OnInit {

  @ViewChild('chartCanvas') private chartCanvas: ElementRef;

  public options: ChartOptions = {
    maintainAspectRatio: false,
    scales: {
      yAxis: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        }
      },


    }
  };

  public lineChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(255,143,0,0.65)',
      borderColor: 'rgba(255,143,0)',
      pointBackgroundColor: 'rgba(255,143,0)',
      pointBorderColor: 'rgba(255,143,0)',
      pointHoverBackgroundColor: 'rgba(255,143,0)',
      pointHoverBorderColor: 'rgba(255,143,0)'
    },
  ];

  // Chart
  public chart = {
    type: 'line',
    data: {
      labels: [],
      datasets: []
    }
  };


  constructor(
    private api: ApiService
  ) {
  }

  ngOnInit(): void {


    this.api.getTicketsChartData().subscribe(data => {
      this.chart.data.labels = data[0].labels;
      this.chart.data.datasets.push({
        data: data[0].data,
        label: 'Aktuelles Jahr',
        borderColor: 'rgb(255,143,0)',
        backgroundColor: 'rgba(255,143,0,0.65)',
      });
      this.chart.data.datasets.push({
        data: data[1].data,
        label: 'Vorjahr',
        borderColor: 'rgb(0,149,255)',
        backgroundColor: 'rgba(60,150,224,0.65)',
      });
      console.log(this.chart);
      //const chart: Chart = new Chart(this.chartCanvas.nativeElement, this.chart);


    });
  }

}
