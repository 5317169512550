import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-first-steps',
  templateUrl: './first-steps.component.html',
  styleUrls: ['./first-steps.component.scss']
})
export class FirstStepsComponent implements OnInit {

  @Input()
  public objects: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
