import {Component, EventEmitter, inject, Input, OnInit, Optional, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../services/api.service";
import {Contact, Customer, Object, Salutation} from "../../../../../../../database-models";
import {NzMessageService} from "ng-zorro-antd/message";
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {ContactComponent, ModalData as ContactModalData} from "../../contact/contact.component";
import {Router} from "@angular/router";

export interface ModalData {
	customer: Customer;
}

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.css']
})
export class EditCustomerComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  public salutations: Salutation[] = ['Herr', 'Frau'];

  public customerForm: FormGroup;

  @Input() public customer: Customer;
  @Input() public objects: Object[];
  @Input() public showHeader: boolean = true;

  public customer_Contacts: Contact[];

  @Output() public savedCustomer: EventEmitter<Customer> = new EventEmitter<Customer>();


  public title = 'Kunde bearbeiten';

  public loading = false;

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    private message: NzMessageService,
    public router: Router,
    private modalService: NzModalService,
    @Optional() private modal: NzModalRef
  ) {

  }

  ngOnInit(): void {
    if (this.customer) {
      this.getContactsByCustomer();
    }
    if(!this.customer) {
      this.title = 'Neuen Kunden anlegen'
      this.customer = {
        type: 'person',
        salutation: 'Herr'
      } as Customer;
    }
    this.customerForm = this.formBuilder.group({
      companyname: [this.customer.companyname],
      salutation: [this.customer.salutation],
      firstname: [this.customer.firstname, [Validators.required]],
      lastname: [this.customer.lastname, [Validators.required]],
      address: [this.customer.address],
      email: [this.customer.email],
      phone: [this.customer.phone],
      zip: [this.customer.zip],
      city: [this.customer.city],
    });
  }

  public customerTypeRadioChange(): void {
    if (this.customer.type === 'company') {
      this.customerForm.get('companyname').addValidators(Validators.required);
      this.customerForm.get('firstname').clearValidators();
      this.customerForm.get('lastname').clearValidators();
    } else {
      this.customerForm.get('companyname').clearValidators();
      this.customerForm.get('firstname').addValidators(Validators.required);
      this.customerForm.get('lastname').addValidators(Validators.required);
    }
    this.customerForm.get('companyname').updateValueAndValidity();
    this.customerForm.get('firstname').updateValueAndValidity();
    this.customerForm.get('lastname').updateValueAndValidity();
  }

  public getObjects(): void {
    this.api.getObjects().subscribe((objects: Object[]) => {
      this.objects = objects;
      // this.initForm();
    }, onerror => {
      console.log(onerror);
      this.message.error('Objekte konnten nicht geladen werden');
    });
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  public updateCustomer(): void {
    this.loading = true;
    const sendData = this.customerForm.getRawValue();
    this.customer.companyname = sendData.companyname;
    this.customer.salutation = sendData.salutation;
    this.customer.firstname = sendData.firstname;
    this.customer.lastname = sendData.lastname;
    this.customer.address = sendData.address;
    this.customer.email = sendData.email;
    this.customer.phone = sendData.phone;
    this.customer.zip = sendData.zip;
    this.customer.city = sendData.city;

    if (this.customer.type !== 'company') {
      this.customer.companyname = null;
    }


    this.api.setCustomer(this.customer).subscribe((customer: Customer) => {
      this.message.success('Erfolgreich gespeichert');
      this.modal.close(customer);
      this.savedCustomer.emit(customer);
      this.loading = false;
    }, error => {
      this.message.error('Dieser Eintrag konnte nicht gespeichert werden!');
      this.loading = false;
    });
  }

  public cancel(): void {
    if (this.customerForm.touched) {
      this.modalService.confirm({
        nzTitle: '<i>Bearbeiten abbrechen</i>',
        nzContent: 'Sind Sie sich sicher, dass Sie diesen Vorgang abbrechen möchten? Alle Änderungen gehen verloren.',
        nzOnOk: () => {
          this.modal.close();
        }
      });
    } else {
      this.modal.close();
    }
  }

  public newContactByCustomer(id: string): void {
    this.modal = this.modalService.create<ContactComponent, ContactModalData>({
      nzContent: ContactComponent,
      nzData: {
        customerId: id
      },
      nzFooter: null,
      nzWidth: 950,
    });
    this.modal.afterClose.subscribe((data: any) => {
      this.getContactsByCustomer();
    });
  }

  public getContactsByCustomer(): void {
    this.api.getContactsbyCustomer(this.customer.id).subscribe(customer_Contacts => {
      this.customer_Contacts = customer_Contacts;
    });
  }

  public deleteRelation(contactId: string): void {
    this.modalService.warning({
      nzTitle: '<i>Kontakt von diesem Kunden entfernen</i>',
      nzContent: 'Sind Sie sich sicher, dass Sie die Verknüpfung zu diesem Kontakt aufheben möchten? Der Kontakt wird dabei nicht gelöscht.',
      nzOnOk: () => {
        this.delete(contactId, this.customer.id);
      },
      nzCancelText: 'Abbrechen',
      nzOnCancel: () => this.router.navigate(['customers/']),
    });
  }

  private delete(contactId: string, customerId: string): void {
    this.api.deleteContactCustomerAssociation(contactId, customerId).subscribe((customer: Customer) => {
      this.message.success('Verknüpfung erfolgreich aufgelöst.');
      this.getContactsByCustomer();
    });
    this.router.navigate(['customers/'])
  }
}
