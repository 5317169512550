<nz-alert *ngIf="timeTrackingMonth?._canLock"
  class="m-t-10"
  nzType="warning"
  [nzMessage]="moment(timeTrackingMonth.start).locale('de').format('MMMM YYYY') + ' kann abgeschlossen werden'"
  [nzDescription]="desc"
  nzShowIcon
></nz-alert>
<ng-template #desc>
  Bitte überprüfen Sie die einzelnen Einträge auf ihre Richtigkeit und schließen Sie den Monat anschließend ab.<br>
  Beachten Sie, dass ein abgeschlossener Monat weder bearbeitet, noch neu berechnet werden kann. Alle Inhalte werden somit
  festgesetzt.
  <br>
  <button (click)="lock()" class="m-t-10" nzType="primary" nzSize="small" nz-button><i nz-icon nzType="lock" nzTheme="outline"></i> Jetzt abschließen</button>
</ng-template>
