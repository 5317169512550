import {ChangeDetectorRef, Component, inject, Input, OnInit} from '@angular/core';
import {GroupedWasteplan, Wasteplan, wasteplanColor, WasteplanGroupAction} from '../../../../../../../database-models';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {NzMessageService} from 'ng-zorro-antd/message';
import {ApiService} from '../../../../services/api.service';
import {NzUploadChangeParam, NzUploadFile} from 'ng-zorro-antd/upload';
import * as ICAL from 'ical.js';
import * as uuid from 'uuid';
import {groupBy} from '../../../../../../../helper-functions';
// @ts-ignore
import moment from 'moment-timezone';
import {momentTimezone, options} from '@mobiscroll/angular';
import {color} from 'chart.js/helpers';

export interface ModalData {
	objectId: string;
}

@Component({
  selector: 'app-wasteplan-import',
  templateUrl: './wasteplan-import.component.html',
  styleUrls: ['./wasteplan-import.component.scss']
})
export class WasteplanImportComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  @Input() public objectId: string;

  public momentPlugin = momentTimezone;

  public wasteplanColors: string[] = wasteplanColor;

  public current: number = 0;
  public checked = true;
  public checkedGroup = true;
  public indeterminate = true;
  public indeterminateGroup = true;
  public uploadCompleted: boolean = false;
  public loading: boolean = false;
  public setOfCheckedId = new Set<string>();
  public setOfCheckedIdGroup = new Set<string>();

  public listOfCurrentPageData: any[] = [];
  public listOfCurrentPageDataGroup: any[] = [];
  public events: Wasteplan[] = [];
  public groupedWasteplans: GroupedWasteplan[] = [];
  public selectedEvents: Wasteplan[] = [];

  constructor(
    public api: ApiService,
    private modalService: NzModalService,
    private modal: NzModalRef,
    private message: NzMessageService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.objectId ??= this.data.objectId;
  }

  public ics(calendarData: string): ICAL.Event[] {
    const jCalData = ICAL.parse(calendarData);
    const comp = new ICAL.Component(jCalData);
    const vevents = comp.getAllSubcomponents('vevent');

    return vevents.map(vevent => {
      const event = new ICAL.Event(vevent);
      event.title = vevent.getFirstPropertyValue('summary');
      try {
        event.description = vevent.getFirstPropertyValue('location');
      } catch (e) {
      }

      const date = vevent.getFirstPropertyValue('dtstart');
      event.time = moment(date.toJSDate()).format('YYYY-MM-DD');
      return event;
    });
  }

  public preSave(): void {
    this.events = [];
    for (let group of this.groupedWasteplans) {
      console.log(group);
      if (this.setOfCheckedIdGroup.has(group._originalName)) {
        for (let item of group.wastePlans) {
          item.title = group.name;
          const time = item.time;

          item._inTime = moment(time);
          if (group._in.day !== 'same') {
            item._inTime = moment(time).add(group._in.count, 'day');
            while (group._in.day === 'workday' && this.checkWeekend(item._inTime)) {
              item._inTime = item._inTime.add(1, 'day');
            }
          }
          item.inTime = item._inTime.format();

          item._outTime = moment(time);
          if (group._out.day !== 'same') {
            item._outTime = moment(time).subtract(group._out.count, 'day');
            while (group._out.day === 'workday' && this.checkWeekend(item._outTime)) {
              item._outTime = item._outTime.subtract(1, 'day');
            }
          }
          item.outTime = item._outTime.format();

          // COLOR

          item.color = group._color;

        }
        this.events = [...this.events, ...group.wastePlans];
        this.events.sort((a, b) => {
          // @ts-ignore
          return new Date(b.time) - new Date(a.time);
        });
      }
    }


    this.selectedEvents = this.events.filter(event => this.setOfCheckedId.has(event.id));
    this.current += 1;
  }

  public save(): void {
    this.modalService.warning({
      nzTitle: 'Es werden ' + this.setOfCheckedId.size + ' Aufgaben erzeugt',
      nzContent: 'Sind Sie sich sicher, dass Sie ' + this.setOfCheckedId.size + ' neue Aufgaben für dieses Objekt erzeugen möchten? Diese Aktion lässt sich nicht rückgängig machen!',
      nzOnOk: () => {
        this.loading = true;
        this.selectedEvents = this.events.filter(event => this.setOfCheckedId.has(event.id));

        this.api.setBulkWasteplan(this.selectedEvents).subscribe(value => {
          this.message.success('Import war erfolgreich');
          this.loading = false;
          this.modalService.closeAll();
        }, onerror => {
          this.message.error(onerror);
          console.log(onerror);
        });
      },
      nzCancelText: 'Abbrechen'
    });
  }

  public checkWeekend(date: moment.Moment): boolean {
    const weekday: number = date.day();
    return weekday === 0 || weekday === 6;
  }

  public selectColor(item: Wasteplan, color: string): void {
    item.color = color;
  }

  public handleFileUpload(event: NzUploadChangeParam) {
    const file: NzUploadFile = event.file;
    if (file.status === 'error' && !this.uploadCompleted) { // "error" um den API Call zu ignorieren.
      const reader = new FileReader();

      reader.onload = () => {
        const calendarData = reader.result as string;
        const events = this.ics(calendarData);
        this.events = [];
        this.events = events.map(ev => {
          let id = uuid.v4();
          this.onItemChecked(id, true);
          return {
            id: id,
            title: ev.title,
            description: ev.description,
            time: ev.time,
            object_id: this.objectId
          } as Wasteplan;
        });
        this.events.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          // @ts-ignore
          return new Date(a.time) - new Date(b.time);
        });


        const groupedEvents = groupBy(this.events, 'title');

        this.groupedWasteplans = [];
        for (let key in groupedEvents) {

          this.setOfCheckedIdGroup.add(key);
          this.groupedWasteplans.push({
            _originalName: key,
            name: key,
            wastePlans: groupedEvents[key],
            _in: {
              count: 1,
              day: 'day'
            } as WasteplanGroupAction,
            _out: {
              count: 1,
              day: 'day'
            } as WasteplanGroupAction
          } as GroupedWasteplan);
        }

        console.log(this.groupedWasteplans);
        this.onAllCheckedGroup(true);


        if (events.length > 0) {
          this.current += 1;
        }
        this.uploadCompleted = true;
        this.cdr.detectChanges();
      };
      const blob = new Blob([file.originFileObj], {type: file.type});
      reader.readAsText(blob);
    }
  }

  public onAllChecked(checked: boolean): void {
    this.events.forEach((event) => this.onItemChecked(event.id, checked));
    this.refreshCheckedStatus();
  }

  public onAllCheckedGroup(checked: boolean): void {
    this.groupedWasteplans.forEach((event) => this.onItemCheckedGroup(event._originalName, checked));
    this.refreshCheckedStatus();
  }

  public updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  public updateCheckedSetGroup(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedIdGroup.add(id);
    } else {
      this.setOfCheckedIdGroup.delete(id);
    }
  }

  public onCurrentPageDataChange(listOfCurrentPageData: any[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  public onCurrentPageDataChangeGroup(listOfCurrentPageData: any[]): void {
    this.listOfCurrentPageDataGroup = listOfCurrentPageData;
    this.refreshCheckedStatus();
  }

  public refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.length > 0 && this.listOfCurrentPageData.every(({id}) => this.setOfCheckedId.has(id));
    this.indeterminate = !this.checked && this.listOfCurrentPageData.some(({id}) => this.setOfCheckedId.has(id));

    this.checkedGroup = this.listOfCurrentPageDataGroup.length > 0 && this.listOfCurrentPageDataGroup.every(({_originalName}) => this.setOfCheckedIdGroup.has(_originalName));
    this.indeterminateGroup = !this.checkedGroup && this.listOfCurrentPageDataGroup.some(({_originalName}) => this.setOfCheckedIdGroup.has(_originalName));
  }

  public onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  public onItemCheckedGroup(id: string, checked: boolean): void {
    this.updateCheckedSetGroup(id, checked);
    this.refreshCheckedStatus();
  }

  public pre(): void {
    this.current -= 1;
    this.selectedEvents = [];
    this.uploadCompleted = false;
  }

  protected readonly momentTimezone = momentTimezone;
  protected readonly color = color;
  protected readonly options = options;
}
