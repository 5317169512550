import {Component, EventEmitter, inject, Input, OnInit, Optional, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import * as uuid from "uuid";

import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../../services/api.service';
import {Contact, ContactTypes, Object} from '../../../../../../database-models';
import {EditContactV2Component, ModalData as EditContactV2ModalData} from "../edit-contact-v2/edit-contact-v2.component";

export interface ModalData {
	contactId: any;
  contact: Contact;
  objectId?: string;
}

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.scss']
})
export class EditContactComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  @Input()
  public contactId: any;

  @Input()
  public objectId: any;

  @Output()
  public savedContact: EventEmitter<Contact> = new EventEmitter<Contact>();

  public contactForm: FormGroup;
  public contact: Contact;
  public contactLoading = true;
  public objects: Object[];
  public contacts: Contact[];
  public options: Contact[];
  public types: string[] = [];
  public filterTypes: string[] = [];

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    private message: NzMessageService,
    private modalService: NzModalService,
    private notification: NzNotificationService,
    @Optional() private modal: NzModalRef
  ) {
  }

  public ngOnInit(): void {
    this.contactId ??= this.data.contactId;
    this.objectId ??= this.data.objectId;
    this.route
      .params
      .subscribe(params => {
        if (!this.contactId) {
          this.contactId = params['id'] || null;
          if (!this.contactId) {
            this.router.navigate(['contacts']);
          }
        }
        if (this.contactId === 'new') {
          this.contact = {} as Contact;
          this.initForm();
          this.contactLoading = false;
        } else {
          this.getContact();
        }
        this.getObjects();
        this.getContacts();
        this.getContactTypes();
      });
  }

  private initForm(): void {
    this.contactForm = this.formBuilder.group({
      name: [this.contact.name, [Validators.required]],
      type: [this.contact.type, [Validators.required]],
      phone: [this.contact.phone],
      mobile: [this.contact.mobile],
      email: [this.contact.email, [Validators.email]],
      fax: [this.contact.fax],
      address: [this.contact.address],
      city: [this.contact.city],
      zip: [this.contact.zip],
      emergency: [this.contact.emergency]
    });
  }

  public getObjects(): void {
    this.api.getObjects().subscribe((objects: Object[]) => {
      this.objects = objects;
      console.log('Objects: ', this.objects);
    }, onerror => {
      console.log(onerror);
      this.message.error('Objekte konnten nicht geladen werden');
    });
  }

  public getContactTypes(): void {
    this.api.getContactTypes().subscribe((types: ContactTypes[]) => {
      console.log(types);
      for (let i = 0; i < types.length; i++) {
        this.types.unshift(types[i].name);
      }
      console.log('ContactTypes: ', this.types);
    }, onerror => {
      console.log(onerror);
      this.message.error('Kontakt Typen konnten nicht geladen werden');
    });
  }

  public getContacts(): void {
    this.api.getContacts().subscribe((contacts: Contact[]) => {
      this.contacts = contacts;
      this.setOptions();
      console.log('Contacts: ', contacts);
    }, onerror => {
      console.log(onerror);
    });
  }

  public updateContact(): void {
    const sendData = this.contactForm.getRawValue();
    this.contact.name = sendData.name;
    this.contact.type = sendData.type;
    this.contact.email = sendData.email;
    this.contact.phone = sendData.phone;
    this.contact.fax = sendData.fax;
    this.contact.mobile = sendData.mobile;
    this.contact.address = sendData.address;
    this.contact.city = sendData.city;
    this.contact.zip = sendData.zip;
    this.contact.emergency = sendData.emergency;
    this.api.setContact(this.contact).subscribe((contact: Contact) => {
      this.message.success('Erfolgreich gespeichert');
      this.savedContact.emit(contact);
      if (this.modal) {
        this.modal.destroy({
          data: contact
        });
      } else {
        this.router.navigate(['contacts']);
      }
    }, error => {
      this.notification.create(
        'error',
        'Speichern fehlgeschlagen!',
        error.error
      );
    });
  }

  public cancel(): void {
    if (this.contactForm.touched) {
      this.modalService.confirm({
        nzTitle: '<i>Bearbeiten abbrechen</i>',
        nzContent: 'Sind Sie sich sicher, dass Sie diesen Vorgang abbrechen möchten? Alle Änderungen gehen verloren.',
        nzOnOk: () => this.router.navigate(['contacts'])
      });
    } else {
      if (this.modal) {
        this.modal.destroy({
          data: null
        });
      } else {
        this.router.navigate(['contacts']);
      }
    }
  }

  public getContact(): void {
    this.api.getContactDetails(this.contactId).subscribe((contact: Contact) => {
      this.contact = contact;
      this.initForm();
      console.log(contact);
      this.contactLoading = false;
    }, onerror => {
      console.log(onerror);
      this.message.error('Kontakt konnten nicht geladen werden.');
    });
  }

  public compareById(f1: any, f2: any): boolean {
    return f1 && f2 && f1.id === f2.id;
  }

  public checkAllObjects(): void {
    this.contact.objects = this.objects;
    this.contactForm.get('contactObjects').setValue(this.objects);
  }

  public uncheckAllObjects(): void {
    this.contact.objects = [];
    this.contactForm.get('contactObjects').setValue([]);
  }


  public setOptions(): void {
    for (let i = 0; i < this.contacts.length; i++) {
      this.types.push(this.contacts[i].type);
    }
    this.types = this.removeDuplicates(this.types);
    this.filterTypes = this.types;
  }

  public removeDuplicates(myArr): string[] {
    const cleanArr = [];
    for (let i = 0; i < myArr.length; i++) {
      if (!cleanArr.includes(myArr[i])) {
        cleanArr.push(myArr[i]);
      }
    }
    return cleanArr;
  }

  onChange(value: string): void {
    this.filterTypes = this.types.filter(type => type.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public upgradeContact(contact: Contact): void {
    let newContact = {
      id: contact.id,
      name: contact.name,
      type: contact.type,
      contact_addresses: [],
      contact_contactPeople: [],
      contact_emails: [],
      contact_phones: [],
      v2: true
    } as Contact;

    if (contact.address || contact.city || contact.zip) {
      newContact.contact_addresses.push({
        id: uuid.v4(),
        type: 'Adresse',
        address: contact.address,
        zip: contact.zip,
        city: contact.city,
      });
    }

    if (contact.phone) {
      newContact.contact_phones.push({
        id: uuid.v4(),
        type: 'Andere',
        phonenumber: contact.phone
      });
    }

    if (contact.emergency) {
      newContact.contact_phones.push({
        id: uuid.v4(),
        type: 'Notfall',
        phonenumber: contact.emergency
      });
    }

    if (contact.fax) {
      newContact.contact_phones.push({
        id: uuid.v4(),
        type: 'Fax',
        phonenumber: contact.fax
      });
    }

    if (contact.email) {
      newContact.contact_emails.push({
        id: uuid.v4(),
        type: 'Privat',
        email: contact.email
      });
    }

    this.modal.close();
    this.update(newContact);
  }

  public update(newContact: Contact): void {
    this.api.updateContact(newContact).subscribe((contact: Contact) => {
      this.message.success('Erfolgreich aktualisiert');
    }, error => {
      this.notification.create(
          'error',
          'Speichern fehlgeschlagen!',
          error.error
      );
    });
  }

  public openContact(contact: Contact): void {
    const newModal = this.modalService.create<EditContactV2Component, EditContactV2ModalData>({
      nzContent: EditContactV2Component,
      nzData: {
        contact
      },
      nzWidth: '1000px',
      nzFooter: null,
      nzMaskClosable: false
    });
    newModal.afterClose.subscribe((data: any) => {
    });
  }
}
