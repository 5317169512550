import {Injectable} from '@angular/core';
import {AlertType, GlobalNews} from '../../../../../database-models';
import {ApiService} from '../../services/api.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import th from '@mobiscroll/angular/dist/js/i18n/th';
import {NzModalService} from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})
export class GlobalnewsService {

  /**
   * Level 1: Dashboard
   * Level 2: Global (on Top of Page everywhere)
   * Level 3: Global NOT mark as read (on Top of Page everywhere)
   */


  public news: GlobalNews[];
  public loadInterval;
  public readMsgIDs: string[] = [];

  constructor(
    private api: ApiService,
    private notification: NzNotificationService,
    private modal: NzModalService
  ) {
    this.readMsgIDs = JSON.parse(localStorage.getItem('globalNews:read')) || [];
    this.getNews().then(globalnews => {
      this.news = globalnews;
      this.showNews(globalnews);
    });

    this.loadInterval = setInterval(() => {
      this.getNews().then(async updatedNews => {
        if (updatedNews.length > this.news.length) {
          this.showNews(updatedNews);
        }
        this.news = updatedNews;
      });
    }, 50000);

  }


  private getNews(): Promise<GlobalNews[]> {
    return new Promise((resolve, reject) => {
      this.api.getGlobalNews().subscribe((globalnews: GlobalNews[]) => {
        resolve(globalnews);
      }, onerror => {
        clearInterval(this.loadInterval);
        reject();
      });
    });
  }

  public showNews(globalnews: GlobalNews[]): void {
    for (let news of globalnews) {
      if (news.level === 2 && !this.readMsgIDs.includes(news.id)) {
        this.createNotification(news);
      }
      if (news.level === 3) {
        this.createNotification(news);
      }
    }
  }

  public createNotification(news: GlobalNews): void {
    const msg = this.modal[news.type]({
      nzTitle: news.title,
      nzContent: news.text,
      nzFooter: null,
      nzOkText: null,
      nzClosable: true,
      nzMaskClosable: true,
      nzWidth: '80%'
    });

    msg.afterClose.subscribe(res => {
      if (!this.readMsgIDs.includes(news.id)) {
        this.readMsgIDs.push(news.id);
        localStorage.setItem('globalNews:read', JSON.stringify(this.readMsgIDs));
      }

    });
  }

  public getColor(type: AlertType): string {
    switch (type) {
      case 'success':
        return '#217b00';
      case 'error':
        return '#a10004';
      case 'info':
        return '#4382ff';
      case 'warning':
        return '#ff8f00';
      default:
        return 'color-light-grey';
    }
  }

  public getTypeTitle(type: AlertType): string {
    switch (type) {
      case 'error':
        return 'Dringende Meldung';
      case 'info':
        return 'Information';
      case 'warning':
        return 'Wichtige Meldung';
      default:
        return 'Meldung';
    }
  }

}
