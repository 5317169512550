<ng-container *ngIf="!showHeader">
    <form nz-form [formGroup]="customerForm" *ngIf="customer">
        <div nz-col>

            <div nz-col class="m-v-20">
                <nz-radio-group [(ngModel)]="customer.type" (ngModelChange)="customerTypeRadioChange()"
                                [ngModelOptions]="{standalone: true}">
                    <label nz-radio nzValue="person">Privat</label>
                    <label nz-radio nzValue="company">Firma</label>
                </nz-radio-group>
            </div>

            <div nz-col *ngIf="customer.type === 'company'">
                <nz-form-item nz-col nzSpan="24" class="p-r-5">
                    <nz-form-label nzFor="companyname" nzRequired>Firma</nz-form-label>
                    <nz-form-control nzErrorTip="Bitte geben Sie eine Firma an.">
                        <input formControlName="companyname" nz-input placeholder="Firma">
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-row>
                <nz-form-item nz-col nzSpan="4" class="p-r-5">
                    <nz-form-label nzFor="salutation">Anrede</nz-form-label>
                    <nz-form-control nzErrorTip="Bitte geben Sie ein Objekt an">
                        <nz-select style="width: 100%" formControlName="salutation"
                                   nzPlaceHolder="Anrede"
                        >
                            <nz-option *ngFor="let salutation of salutations" [nzValue]="salutation"
                                       [nzLabel]="salutation"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col nzSpan="10" class="p-r-5">
                    <nz-form-label nzFor="firstname" [nzRequired]="customer.type  === 'person'">Vorname
                    </nz-form-label>
                    <nz-form-control nzErrorTip="Bitte geben Sie den Vornamen ein.">
                        <input formControlName="firstname" nz-input placeholder="Vorname">
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col nzSpan="10">
                    <nz-form-label nzFor="lastname" [nzRequired]="customer.type  === 'person'">Nachname
                    </nz-form-label>
                    <nz-form-control nzErrorTip="Bitte geben Sie den Nachnamen ein.">
                        <input formControlName="lastname" nz-input placeholder="Nachname">
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-row>
                <nz-form-item nz-col nzSpan="12" class="p-r-5">
                    <nz-form-label nzFor="phone">Telefon</nz-form-label>
                    <nz-form-control nzErrorTip="Bitte geben Sie eine Nummer ein.">
                        <input formControlName="phone" nz-input placeholder="Telefon">
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col nzSpan="12">
                    <nz-form-label nzFor="email">Email</nz-form-label>
                    <nz-form-control nzErrorTip="Bitte geben Sie eine E-mail Adresse ein.">
                        <input formControlName="email" nz-input placeholder="E-Mail">
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div nz-row>
                <nz-form-item nz-col nzSpan="12" class="p-r-5">
                    <nz-form-label nzFor="address">
                        Straße, Hausnummer
                    </nz-form-label>
                    <nz-form-control nzErrorTip="Bitte geben Sie eine Adresse für den Kunden ein">
                        <input formControlName="address" nz-input placeholder="Adresse">
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col nzSpan="5" class="p-r-5">
                    <nz-form-label nzFor="zip">PLZ</nz-form-label>
                    <nz-form-control nzErrorTip="Postleitzahl eingeben">
                        <input formControlName="zip" nz-input placeholder="PLZ">
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col nzSpan="7">
                    <nz-form-label nzFor="city">Ort</nz-form-label>
                    <nz-form-control nzErrorTip="Ort eingeben">
                        <input formControlName="city" nz-input placeholder="Ort">
                    </nz-form-control>
                </nz-form-item>
            </div>

            <nz-divider></nz-divider>

            <button (click)="cancel()"
                    [disabled]="customerForm.touched"
                    class="m-r-10"
                    nz-button
                    nzType="default"
                    type="button">Abbrechen
            </button>

            <div class="pull-right">
                <button (click)="updateCustomer()"
                        [disabled]="customerForm.invalid || !customerForm.touched"
                        *appIsManager
                        nz-button
                        nzType="primary"
                        type="submit">
                    Speichern
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="showHeader">
    <nz-tabset>
        <nz-tab [nzTitle]="headerCustomer">
            <ng-template #headerCustomer>
                {{ title }}
            </ng-template>
            <nz-spin [nzSpinning]="loading">
                <h2 *ngIf="showHeader">{{ title }}</h2>
                <form nz-form [formGroup]="customerForm" *ngIf="customer">
                    <div nz-col>

                        <div nz-col class="m-v-20">
                            <nz-radio-group [(ngModel)]="customer.type" (ngModelChange)="customerTypeRadioChange()"
                                            [ngModelOptions]="{standalone: true}">
                                <label nz-radio nzValue="person">Privat</label>
                                <label nz-radio nzValue="company">Firma</label>
                            </nz-radio-group>
                        </div>

                        <div nz-col *ngIf="customer.type === 'company'">
                            <nz-form-item nz-col nzSpan="24" class="p-r-5">
                                <nz-form-label nzFor="companyname" nzRequired>Firma</nz-form-label>
                                <nz-form-control nzErrorTip="Bitte geben Sie eine Firma an.">
                                    <input formControlName="companyname" nz-input placeholder="Firma">
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-row>
                            <nz-form-item nz-col nzSpan="4" class="p-r-5">
                                <nz-form-label nzFor="salutation">Anrede</nz-form-label>
                                <nz-form-control nzErrorTip="Bitte geben Sie ein Objekt an">
                                    <nz-select style="width: 100%" formControlName="salutation"
                                               nzPlaceHolder="Anrede"
                                    >
                                        <nz-option *ngFor="let salutation of salutations" [nzValue]="salutation"
                                                   [nzLabel]="salutation"></nz-option>
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item nz-col nzSpan="10" class="p-r-5">
                                <nz-form-label nzFor="firstname" [nzRequired]="customer.type  === 'person'">Vorname
                                </nz-form-label>
                                <nz-form-control nzErrorTip="Bitte geben Sie den Vornamen ein.">
                                    <input formControlName="firstname" nz-input placeholder="Vorname">
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item nz-col nzSpan="10">
                                <nz-form-label nzFor="lastname" [nzRequired]="customer.type  === 'person'">Nachname
                                </nz-form-label>
                                <nz-form-control nzErrorTip="Bitte geben Sie den Nachnamen ein.">
                                    <input formControlName="lastname" nz-input placeholder="Nachname">
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-row>
                            <nz-form-item nz-col nzSpan="12" class="p-r-5">
                                <nz-form-label nzFor="phone">Telefon</nz-form-label>
                                <nz-form-control nzErrorTip="Bitte geben Sie eine Nummer ein.">
                                    <input formControlName="phone" nz-input placeholder="Telefon">
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item nz-col nzSpan="12">
                                <nz-form-label nzFor="email">Email</nz-form-label>
                                <nz-form-control nzErrorTip="Bitte geben Sie eine E-mail Adresse ein.">
                                    <input formControlName="email" nz-input placeholder="E-Mail">
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <div nz-row>
                            <nz-form-item nz-col nzSpan="12" class="p-r-5">
                                <nz-form-label nzFor="address">
                                    Straße, Hausnummer
                                </nz-form-label>
                                <nz-form-control nzErrorTip="Bitte geben Sie eine Adresse für den Kunden ein">
                                    <input formControlName="address" nz-input placeholder="Adresse">
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item nz-col nzSpan="5" class="p-r-5">
                                <nz-form-label nzFor="zip">PLZ</nz-form-label>
                                <nz-form-control nzErrorTip="Postleitzahl eingeben">
                                    <input formControlName="zip" nz-input placeholder="PLZ">
                                </nz-form-control>
                            </nz-form-item>
                            <nz-form-item nz-col nzSpan="7">
                                <nz-form-label nzFor="city">Ort</nz-form-label>
                                <nz-form-control nzErrorTip="Ort eingeben">
                                    <input formControlName="city" nz-input placeholder="Ort">
                                </nz-form-control>
                            </nz-form-item>
                        </div>

                        <nz-divider></nz-divider>

                        <button (click)="cancel()"
                                [disabled]="customerForm.touched"
                                class="m-r-10"
                                nz-button
                                nzType="default"
                                type="button">Abbrechen
                        </button>

                        <div class="pull-right">
                            <button (click)="updateCustomer()"
                                    [disabled]="customerForm.invalid || !customerForm.touched"
                                    *appIsManager
                                    nz-button
                                    nzType="primary"
                                    type="submit">
                                Speichern
                            </button>
                        </div>
                    </div>
                </form>
            </nz-spin>
        </nz-tab>

        <nz-tab [nzTitle]="linkedHeader">
            <ng-template #linkedHeader>
                <nz-badge [nzCount]="customer_Contacts?.length"
                          [nzStyle]="{ backgroundColor: '#f58b0a' }"><span>Verknüpfte Kontakte</span></nz-badge>
            </ng-template>

            <button *appIsManager nzSize="small" nz-button nzType="primary"
                    (click)="newContactByCustomer(customer.id)"><i
                    nz-icon
                    nzType="plus-circle"></i>Kontakt verknüpfen
            </button>
        </nz-tab>
    </nz-tabset>
</ng-container>
