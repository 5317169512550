<div nz-col>
    <nz-card>
        <h5>Arbeitsprotokoll teilen</h5>
        <div nz-row class="m-t-20">
            <nz-form-label>Link zum teilen erstellen</nz-form-label>
            <ng-container *ngIf="sharedWorkLog.link">
                <nz-input-group nzSearch [nzSuffix]="suffixTemplateInfo">
                    <input placeholder="Link zum teilen" nz-input [(ngModel)]="sharedWorkLog.link" readonly/>
                </nz-input-group>
                <ng-template #suffixTemplateInfo>
                    <a href="{{ sharedWorkLog.link }}" target="_blank">
                        <i nz-tooltip nzTooltipTitle="Link öffnen" class="color-orange" nz-icon nzType="select" nzTheme="outline"></i>
                    </a>
                </ng-template>
            </ng-container>

            <button *ngIf="!sharedWorkLog.link" class="m-t-10" nzSize="small" nzBlock (click)="generateLink()" nz-button
                    nzType="primary" nzSearch>Erstellen
            </button>

            <button *ngIf="sharedWorkLog.link" nzSize="small" nz-tooltip nzTooltipTitle="Link kopieren" nzBlock
                    class="copybtn m-t-10 w-100"
                    [cdkCopyToClipboard]="copyToClipboard" (mouseup)="clipboardCopy()"
                    nz-button nzType="primary" nzSearch><span nz-icon nzType="copy"></span>
            </button>
        </div>
    </nz-card>
</div>

<!--<div nz-col class="m-t-15">-->
<!--    <iframe class="w-100" *ngIf="sharedWorkLog?.link"-->
<!--            [src]="sanitizer.bypassSecurityTrustResourceUrl(sharedWorkLog.link)"></iframe>-->
<!--</div>-->
<nz-divider></nz-divider>
<div nz-col class="m-t-15">
    <nz-card>
        <app-resident-select [(appusers)]="sharedWithUser"
                             [placeholder]="'Hier können Sie mehrere Bewohner aufwählen'"
                             [objectId]="worklog.object_id"
                             [mode]="'multible'"></app-resident-select>
        <div nz-row style="display: flow-root;">
            <ng-container *ngIf="worklog.finalized">
                <small><i class="m-r-5" nz-icon nzType="info-circle" nzTheme="outline"></i>Die hier ausgewählten
                    Bewohner
                    haben Zugriff auf dieses Arbeitsprotokoll</small>
            </ng-container>

            <ng-container *ngIf="!worklog.finalized">
                <small style="color: #ff8686"><i class="m-r-5" nz-icon nzType="warning" nzTheme="outline"></i>Das Arbeitsprotokoll muss
                    <strong>Finalisiert</strong> sein damit es den geteilten Personen angezeigt wird</small>
            </ng-container>

            <button class="pull-right" nz-button nzSize="small" nzType="primary" (click)="setShareWorklogUsers();">
                Speichern
            </button>
        </div>
    </nz-card>
</div>

<ng-container *ngIf="false">
<nz-divider></nz-divider>
    <div nz-col class="m-t-15">
        <nz-card>
            <h5>Hausverwaltung</h5>
            <div nz-row style="display: flow-root">
                <label nz-checkbox [(ngModel)]="worklog.HV" (ngModelChange)="changePublicHV(this.worklog.HV)" [ngModelOptions]="{standalone: true}">Bereitstellen</label><br>
                <small class="m-t-5"><i class="m-r-5" nz-icon nzType="info-circle" nzTheme="outline"></i>Hier können Sie das Arbeitsprotokoll bereitstellen für die Hausverwaltung</small>
            </div>
        </nz-card>
    </div>
</ng-container>
