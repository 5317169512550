<nz-spin [nzSpinning]="isLoading">
    <h3>{{ title }}</h3>
    <nz-divider class="m-t-10 m-b-0"></nz-divider>
    <form nz-form [formGroup]="roomForm">
        <div nz-row>
            <nz-form-item nz-col nzSpan="24" class="m-t-10">
                <nz-form-label nzFor="name" nzRequired>Titel</nz-form-label>
                <nz-form-control nzErrorTip="Bitte geben Sie einen Namen für den Raum ein">
                    <input formControlName="name" nz-input placeholder="Name"/>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item nz-col nzSpan="24">
                <nz-form-label nzFor="description">Beschreibung</nz-form-label>
                <nz-form-control>
                    <textarea formControlName="description" [nzAutosize]="{ minRows: 4, maxRows: 6 }" nz-input
                              placeholder="Beschreibung (optional)" nzAutosize></textarea>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div style="min-height: 35px">
            <button
                    *ngIf="room?.id"
                    class="m-r-15"
                    nz-button
                    nzType="link"
                    nzDanger
                    (click)="delete()"
                    type="button"
            >
                Löschen
            </button>


            <button
                    class="pull-right"
                    nz-button
                    nzType="primary"
                    type="submit"
                    (click)="save()"
                    [disabled]="roomForm.invalid || !roomForm.touched"
            >
                Speichern
            </button>
        </div>

    </form>
</nz-spin>
