import {Component, inject, Input, OnInit} from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

export interface ModalData {
	color: any;
  label: any;
  rangeSelected: any;
}

@Component({
  selector: 'app-absent-overview',
  templateUrl: './absent-overview.component.html',
  styleUrls: ['./absent-overview.component.css']
})
export class AbsentOverviewComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  public label: any = [];
  public color: any = [];
  public rangeSelected: any = [];

  constructor(
  ) { }

  ngOnInit(): void {
    this.label ??= this.data.label;
    this.color ??= this.data.color;
    this.rangeSelected ??= this.data.rangeSelected;
  }
}
