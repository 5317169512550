import {Component, inject, Input, OnInit} from '@angular/core';
import {CheckListList, CheckListTemplate, Contact} from '../../../../../../../database-models';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../services/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

export interface ModalData {
	objectId: any;
  checkListTemplate: CheckListTemplate;
}

@Component({
  selector: 'app-edit-checklist-template',
  templateUrl: './edit-checklist-template.component.html',
  styleUrls: ['./edit-checklist-template.component.css']
})
export class EditChecklistTemplateComponent implements OnInit {
  public data = inject<ModalData>(NZ_MODAL_DATA, {optional: true});
  public checkListTemplate: CheckListTemplate;
  public objectId: any;

  public isGlobalTemplate = false;

  constructor(
    public api: ApiService,
    private message: NzMessageService,
    public modal: NzModalRef,
    private notification: NzNotificationService,
  ) {
  }

  public ngOnInit(): void {
    this.checkListTemplate ??= this.data.checkListTemplate;
    this.objectId ??= this.data.objectId;
    if (!this.checkListTemplate) {
      this.checkListTemplate = {} as CheckListTemplate;
      this.checkListTemplate.object_id = this.objectId;
      this.checkListTemplate.list = [{} as CheckListList];
    } else {
      this.isGlobalTemplate = !this.checkListTemplate.object_id;
    }
  }

  public addListItem(): void {
    this.checkListTemplate.list.push({} as CheckListList);
  }

  public deleteListItem(index: number): void {
    this.checkListTemplate.list.splice(index, 1);
  }

  public save(): void {
    if (this.isGlobalTemplate) {
      this.checkListTemplate.object_id = null;
    }

    this.api.setCheckListTemplate(this.checkListTemplate).subscribe((contact: Contact) => {
      this.message.success('Erfolgreich gespeichert');
      this.modal.triggerOk();
    }, error => {
      this.notification.create(
        'error',
        'Speichern fehlgeschlagen!',
        error.error
      );
    });
  }

  public isInvalid(): boolean {
    if (!this.checkListTemplate.name) {
      return true;
    } else {
      for (const item of this.checkListTemplate.list) {
        if (!item.description) {
          return true;
        }
      }
    }
    return false;
  }

}
